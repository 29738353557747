import React, { useEffect, useState } from 'react'

import './ShoppingItemModal.scss'
import H3Text from 'components/Text/Headings/H3Text'
import P16 from 'components/Text/Paragraphs/Medium/P16'
import P12 from 'components/Text/Paragraphs/Medium/P12'
import P14 from 'components/Text/Paragraphs/SemiBold/P14'
import P14M from 'components/Text/Paragraphs/Medium/P14'
import P14Regular from 'components/Text/Paragraphs/Regular/P14'
import NutritionCard from 'components/Cards/NutritionCard'
import RootModal from '../_RootModal'
import IItem from 'interfaces/backend/IItem'
import ItemApi from 'services/Core/ItemService/ItemApi'
import AllergenPill from 'components/Pills/AllergenPill'

type Props = {
  isOpen:       boolean
  close:        () => void
  shoppingItem: IItem
}

export default function ShoppingItemModal({
  isOpen,
  close,
  shoppingItem
}: Props) {
  const [itemData, setItemData] = useState<IItem | null>(null)

  useEffect(() => {
    if (!shoppingItem) return
    setItemData(null) // Prevents flashing of old data

    const itemApi = new ItemApi();

    itemApi.findItemById(shoppingItem.supermarketItemId).then((item) => {
      setItemData(item)
    })
  }, [shoppingItem])

  if (!shoppingItem) {
    return null
  }

  const itemInformation = [
    {
      title: 'Product Description',
      text: itemData?.description
    },
    {
      title: 'Storage',
      text: itemData?.storageInfo
    },
    {
      title: 'Produce of',
      text: itemData?.produceOf
    },
    {
      title: 'Number of uses',
      text:  itemData?.numberOfUses
    },
    {
      title: 'Recycling Info',
      text: itemData?.recyclingInfo
    },
    {
      title: 'Net Contents',
      text: itemData?.netContent
    }
  ]

  return (
    <RootModal
      isOpen    = { isOpen }
      close     = { close }
      padding   = 'small'
    >
      <div className = 'shopping-item-modal-container'>
        <div
          className = 'child lhs'
        >
          <div
            className = 'title-container'
          >
            <img
              className = 'image'
              alt       = ''
              src       = { shoppingItem.imageUrl }
            />

            <div
              className = 'title'
            >
              <H3Text
                text = { shoppingItem.name }
              />

              <div
                className = 'pricing'
              >
                {
                  (shoppingItem.promotion !== null) && (
                    <div
                      className = 'previous-price'
                    >
                      <P14M
                        text   = { `£${shoppingItem.promotion?.previousPrice.toFixed(2)}` }
                        colour = 'pink-1'
                      />
                    </div>
                  )
                }

                <P16
                  text = { `£${shoppingItem.price.toFixed(2)}` }
                />

                <P12
                  text = { itemData?.standardisedPrice }
                />
              </div>
            </div>
          </div>

          {
            itemData?.nutritionalInformation.length > 0 && (
              <>
                <div
                  className = 'information-container'
                >
                  <P14
                    text   = 'Nutritional Information'
                    colour = 'black-1'
                  />
      
                  <div
                    className = 'nutrition-information-header'
                  >
                    <P14Regular
                      text   = 'Per 100g'
                      colour = 'black-1'
                    />
      
                    <P14Regular
                      text   = 'Per Serving'
                      colour = 'pink-1'
                    />
                  </div>
                </div>

                <div
                  className = 'nutritional-grid'
                >
                  {itemData.nutritionalInformation.map((nutrition) => (
                    <div
                      key = { nutrition.prettyName }
                    >
                      <NutritionCard
                        title                = { nutrition.prettyName }
                        showPerServing       = { true }
                        perHundredGramsValue = { nutrition.perHundredUnits }
                        perServingValue      = { nutrition.perServing }
                      >
                        
                      </NutritionCard>
                    </div>
                  ))}
                </div>
              </>
            )
          }

          {
            itemData?.allergens.length > 0 && (
              <div
                className = 'allergens-container'
              >
                <P14
                  text   = 'Allergens'
                  colour = 'black-1'
                />

                <ul
                  className = 'allergens-list'
                >
                  {
                    itemData.allergens.map((allergen) => (
                      <li
                        key = { allergen.allergen }
                      >
                        <AllergenPill
                          allergen = { allergen.allergen }
                        />
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
          }
        </div>

        <div
          className = 'child rhs'
        >
          {
            itemInformation.map((info, index) => {
              return (
                info.text ?
                <div
                  key = { index }
                >
                  <P14
                    text = { info.title }
                    colour = 'black-1'
                  />

                  <P14Regular
                    text = { info.text }
                  />
                </div>
                : 
                null
              )
            })
          }
        </div>
      </div>

    </RootModal>
  )
}