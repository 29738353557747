import React from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'
import useOrdinalize from 'hooks/utils/useOrdinalize'

export default function SavedRecipeDashboardCard() {
  const params = new URLSearchParams(window.location.search)
  const date = params.get('date')
  const index = parseInt(params.get('index'))

  const ordinalize = useOrdinalize()

  const topLineTextObject: DashboardTextType[] = [
    {
      line:  1,
      value: `This was your`,
      bold:  false
    },
    {
      line:  1,
      value: `${ordinalize(index).toLowerCase()} meal`,
      bold:  true
    },
    {
      line:  1,
      value: `in the order you placed on the`,
      bold:  false
    },
    {
      line:  1,
      value: `${date}.`,
      bold:  true
    }
  ]

  return (
    <MealiaBotCard
      textObjects = { [...topLineTextObject] }
    />
  )
}
