import AlgorithmItemApi from 'services/Core/Algorithm/ItemService/AlgorithmItemApi';

export default function useRemoveAlgorithmItem() {
  function removeAlgorithmItem(algorithmChosenItemId: number): Promise<any> {
    const algorithmItemApi = new AlgorithmItemApi();

    return algorithmItemApi.removeAlgorithmItem(algorithmChosenItemId);
  }

  return removeAlgorithmItem;
}
