import { DashboardTextType } from 'types/DashboardTextType'

export default function useIntroductionMessages() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Hey, I’m Mealia 👋`,
    bold:  false
  }

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: `I have a few questions to find out how I can help.`,
    bold:  false
  }

  const introductionMessages = [
    topLineTextObject,
    bottomLineTextObject
  ]

  return introductionMessages
}
