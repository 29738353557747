import React from 'react'

import './CircleTopNavBarButton.scss'
import { ReactComponent as LeftArrow }  from 'assets/images/icons/_new/arrow/left-grey.svg'
import { ColourConstants } from 'utils/Constants'

type Props = {
  onClickHandler: () => void
}

export default function CircleTopNavBarButton({
  onClickHandler
}: Props) {
  return (
    <button
      className    = 'circle-top-nav-bar-button-container'
      onClick      = { onClickHandler }
    >
      <LeftArrow 
        color = { ColourConstants.Black[1] }
      />
    </button>
  )
}
