import useCreateHousehold from 'utils/Hooks/Actions/Household/useCreateHousehold';
import useNavigateTo from 'hooks/navigation/useNavigateTo';

export default function useHooks() {
  const navigateTo      = useNavigateTo();
  const createHousehold = useCreateHousehold();

  return {
    navigateTo,
    createHousehold
  }
}
