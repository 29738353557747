import { RouteName } from 'router/Config';
import { LoadingStatus } from 'enums/LoadingStatus';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

import useShowMealPlan from '../Operations/Effects/useShowMealPlan';
import useShowBasket from '../Operations/Effects/useShowBasket';
import useRedirectToWelcomeBack from '../Operations/Effects/useRedirectToWelcomeBack';
import useShowOtherRecipes from '../Operations/Effects/useShowOtherRecipes';
import useShowNotification from '../Operations/Effects/useShowNotification';
import useScrollToPosition from '../Operations/Effects/useScrollToPosition';
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';

export default function useEffects(
  loadingStatus:        LoadingStatus,
  setLoadingStatus:     (loadingStatus: LoadingStatus) => void,
  navigateTo:           (routeName: RouteName) => void,
  basketCheckedOut:     boolean,
  otherRecipes:         AlgorithmRecipe[],
  setOtherRecipes:      (otherRecipes: AlgorithmRecipe[]) => void,
  setShowNotification:  (showNotification: boolean) => void,
  algorithmMealPlan:    AlgorithmMealPlan,
  subsectionHeaderRef:  React.MutableRefObject<HTMLSpanElement>,
  observerRef:          React.MutableRefObject<IntersectionObserver>,
  hasSeenSubsectionRef: React.MutableRefObject<boolean>
) {
  useShowMealPlan(setLoadingStatus);
  useShowBasket();
  useShowOtherRecipes(setOtherRecipes);
  useRedirectToWelcomeBack(navigateTo, basketCheckedOut);
  useShowNotification(setShowNotification, subsectionHeaderRef, observerRef, hasSeenSubsectionRef);
  useScrollToPosition(loadingStatus, otherRecipes, algorithmMealPlan);
}
