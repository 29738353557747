import useDetailAlgorithmRecipe from 'utils/Hooks/Actions/Algorithm/Recipe/useDetailAlgorithmRecipe';
import useIncrementAdditionalItem from 'utils/Hooks/Actions/Algorithm/Recipe/useIncrementAdditionalItem'
import useDecrementAdditionalItem from 'utils/Hooks/Actions/Algorithm/Recipe/useDecrementAdditionalItem'
import useAddAlgorithmRecipe from 'utils/Hooks/Actions/Algorithm/MealPlan/useAddAlgorithmRecipe'
import useAddToBasket from 'utils/Hooks/Actions/Algorithm/Item/useAddToBasket';
import useRemoveAlgorithmItem from 'utils/Hooks/Actions/Algorithm/Item/useRemoveAlgorithmItem';
import useReplaceAlgorithmItem from 'utils/Hooks/Actions/Algorithm/Item/useReplaceAlgorithmItem';
import useNavigateTo from 'hooks/navigation/useNavigateTo';

export default function useHooks() {
  const [algorithmRecipe, setAlgorithmRecipe, isLoading] = useDetailAlgorithmRecipe();
  const incrementAdditionalItem = useIncrementAdditionalItem()
  const decrementAdditionalItem = useDecrementAdditionalItem()
  const addAlgorithmRecipe = useAddAlgorithmRecipe();
  const addCupboardItemToBasket = useAddToBasket();
  const removeAlgorithmItem = useRemoveAlgorithmItem();
  const replaceAlgorithmItem = useReplaceAlgorithmItem();
  const navigateTo = useNavigateTo();

  return {
    algorithmRecipe,
    setAlgorithmRecipe,
    isLoading,
    incrementAdditionalItem,
    decrementAdditionalItem,
    addAlgorithmRecipe,
    addCupboardItemToBasket,
    removeAlgorithmItem,
    replaceAlgorithmItem,
    navigateTo
  }
}
