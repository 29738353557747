import AlgorithmBasketApi from 'services/Core/Algorithm/BasketService/AlgorithmBasketApi';

export default function useShoppingItemDetails() {
  function shoppingItemDetails(): Promise<any> {
    const algorithmBasketApi = new AlgorithmBasketApi();

    return algorithmBasketApi.shoppingItemDetails();
  }

  return shoppingItemDetails;
}
