import React from 'react'

import './FadeInAnimationWrapper.scss'

type Props = {
  children:    React.ReactNode
  fadeColour?: 'white-1' | 'grey-3'
}

export default function FadeInAnimationWrapper({
  children,
  fadeColour = 'white-1'
}: Props) {
  return (
    <div
      className = {`
        fade-in-animation-wrapper
        fade-in-animation-wrapper--${fadeColour}
      `}
    >
      { children }
    </div>
  )
}
