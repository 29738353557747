import { LoadingStatus } from 'enums/LoadingStatus';

export default function useHandleImagesLoaded(
  setLoadingStatus: (loadingStatus: LoadingStatus) => void
) {
  const handleImagesLoaded = () => {
    // TODO: Why is there a delay here?
    setTimeout(() => {
      setLoadingStatus(LoadingStatus.ImagesLoaded);
    }, 1000);
  }

  return handleImagesLoaded;
}
