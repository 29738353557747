import UserPasswordApi from 'services/Core/User/PasswordService/UserPasswordApi';

export default function useUpdate() {
  function update(
    password:             string,
    passwordConfirmation: string,
    resetPasswordToken:   string
  ): Promise<any> {
    const userPasswordApi = new UserPasswordApi();

    return userPasswordApi.update(
      password,
      passwordConfirmation,
      resetPasswordToken
    );
  }

  return update;
}
