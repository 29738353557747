import React from 'react'

import './SavedPlan.scss'
import Navigation from '../Shared/Navigation'
import TitleText from 'components/Text/TitleText'
import AlgorithmRecipeCardGrid from 'components/Grids/AlgorithmRecipeCardGrid'
import { AlgorithmRecipeGridCard } from 'enums/Grids/AlgorithmRecipeGridCard'
import SavedPlanDashboardCard from 'components/Cards/Dashboard/SavedPlanDashboardCard'
import P14 from 'components/Text/Paragraphs/Medium/P14'

import useSavedPlan from 'hooks/Pages/PostLogin/09-SavedPlan/useSavedPlan'

export default function SavedPlan() {
  const {
    algorithmSavedPlan,
    handleRecipeCardClick
   } = useSavedPlan();

  if (!algorithmSavedPlan) {
    return <Navigation />
  }

  return (
    <>
      <Navigation>
        <div
          className = 'saved-plan-container'
        >
          <SavedPlanDashboardCard
            createdAt     = { algorithmSavedPlan?.prettyCreatedAt }
            numberOfMeals = { algorithmSavedPlan?.numberOfMeals }
          />

          <span
            style = {{ height: '8px' }}
          />

          <TitleText
            text = { `Basket from the ${algorithmSavedPlan?.prettyCreatedAt}` }
          >
            <div
              className = 'saved-plan-in-basket-container'
            >
              {
                <>
                  <div
                    className = 'saved-plan-in-basket-text-container'
                  >
                    <P14
                      text   = { `£${algorithmSavedPlan?.totalCost?.toFixed(2)} Total Cost` }
                      colour = 'white-1'
                    />
                  </div>

                  <div
                    className = 'saved-plan-in-basket-text-container'
                  >
                    <P14
                      text   = { `£${algorithmSavedPlan?.pricePerServing?.toFixed(2)} Per Serving` }
                      colour = 'white-1'
                    />
                  </div>
                </>
              }          
            </div>
          </TitleText>

          <span />

          <AlgorithmRecipeCardGrid
            algorithmRecipeMapping  = { algorithmSavedPlan?.algorithmRecipeMapping || [] }
            algorithmRecipeCardType = { AlgorithmRecipeGridCard.Counted }
            onCardClickHandler      = { handleRecipeCardClick }
          />       
        </div>      
      </Navigation>
    </>
  )
}
