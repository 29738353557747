import useCreateApi from 'utils/Hooks/Api/User/Password/useCreate';

export default function useCreate() {
  const createApi = useCreateApi();

  function create(
    email:    string,
    setPage:  (page: 'Input' | 'Confirmation') => void,
    setError: (error: any) => void
  ) {
    createApi(email).then(() => {
      setPage('Confirmation');
    }).catch((error) => {
      setError(error);
    });
  }

  return create;
}
