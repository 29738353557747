import { CoreConfig } from 'services/Core/Config';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

const user_wrapper = `api_${CoreConfig.CORE_API_VERSION}_user`;

export const CoreUserSessionEndpoints: BaseApiEndpoint = {
  signIn: (email: string, password: string) => ({
    url:    'users/sessions/sign_in',
    method: 'POST',
    auth:   false,
    body:   {
      [user_wrapper]: {
        email:    email,
        password: password
      }
    }
  }),
  signOut: () => ({
    url:    'users/sessions/sign_out',
    method: 'DELETE',
    auth:   true
  })
}
