import { BotPage } from 'enums/BotPage';
import MessageApi from 'services/Core/MessageService/MessageApi';

export default function useCreate() {
  function create(name: BotPage, content: string) {
    const messageApi = new MessageApi();

    return messageApi.create(name, content);
  }

  return create;
}
