// Constants which can be localised in the future.

export const LocalConstants = {
  PreLogin: {
    Login: {
      Title: 'Welcome back',
      SubTitle: 'Welcome back, please enter your details to get started',
      PrimaryButtonCopy: 'Log in',
      SecondaryButtonCopy: 'Log in with Google',
      TertiaryButtonCopy: 'Forgot password',
      QuaternaryButtonCopy: 'Sign up for free',
      EmailPlaceholder: 'Enter your email',
      PasswordPlaceholder: 'Password',
      OtherCopy: `Don't have an account?`,
    },
    Register: {
      Input: {
        Title: 'Create an account',
        SubTitle: 'It takes a few seconds.',
        PrimaryButtonCopy: 'Sign Up',
        SecondaryButtonCopy: 'Sign up with Google',
        TertiaryButtonCopy: 'Log In',
        OtherCopy: 'Already have an account?',
        FirstNamePlaceholder: 'First Name',
        EmailPlaceholder: 'Enter your email',
        PasswordPlaceholder: 'Password',
      },
      Confirmation: {
        Title: 'Check Your Email',
        SubTitle: (email: string) => `We sent a verification link to ${email}`,
        PrimaryButtonCopy: 'Back to Log In',
        OtherCopy: 'Haven\'t received your email?',
        TertiaryButtonCopy: 'Resend Confirmation',
      },
    },
    ForgotPassword: {
      Input: {
        Title: 'Forgot Password?',
        SubTitle: 'No worries, we\'ll send you reset instructions.',
        EmailPlaceholder: 'Enter your email',
        PrimaryButtonCopy: 'Reset Password',
        TertiaryButtonCopy: 'Back to Log In'
      },
      Confirmation: {
        Title: 'Check Your Email',
        SubTitle: (email: string) => `We sent a password reset link to ${email}`,
        PrimaryButtonCopy: 'Open Email App',
        TertiaryButtonCopy: 'Back to Log In'
      }
    },
    ResetPassword: {
      Input: {
        Title: 'Set New Password',
        SubTitle: 'Your new password must be different to previously used passwords.',
        PasswordPlaceholder: 'New Password (must be at least 6 characters)',
        ConfirmPasswordPlaceholder: 'Confirm Password',
        PrimaryButtonCopy: 'Reset Password',
        TertiaryButtonCopy: 'Back to Log In'
      },
      Confirmation: {
        Title: 'Password Reset',
        SubTitle: 'Your password has been successfuly reset. Click below to log in magically.',
        PrimaryButtonCopy: 'Continue',
        TertiaryButtonCopy: 'Back to Log In'
      }
    },
    Confirmation: {
      Success: {
        Title: 'Email Confirmed',
        Subtitle: 'Thank you for confirming your email! You will be logged in shortly.'
      },
      Error: {
        Title: 'Email Already Confirmed',
        Subtitle: `You've already confirmed your email.`
      }
    }
  },

  PostLogin: {
    Disclaimers: [
      {
        type: 'Recipe',
        disclaimer: (recipeName: string, link: string) => `The recipes featured on Mealia have been carefully adapted and standardized from various sources to provide a cost-effective culinary experience. ${recipeName} was inspired by ${link}, from which the accompanying image was sourced. Please be aware that ingredient measurements, cooking instructions, and other details may have been modified to suit our platform's standards and ensure affordability. Mealia acknowledges the original sources of inspiration for these recipes and aims to maintain their essence while making them accessible to our users.`
      }
    ]
  },

  Error: {
    Generic:  'Something went wrong. Please try again later.'
  }
}
