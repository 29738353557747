import React from 'react'

import './SkeletonAlgorithmRecipeCard.scss'

export default function SkeletonAlgorithmRecipeCard() {
  return (
    <div
      className = 'root-algorithm-recipe-card'
    >
      <div
        className = {`
          card-recipe-image-container
          skeleton-card-recipe-image-container
        `}
      >
        <div
          className = 'skeleton-card-recipe-image-price'
        />
      </div>

      <div
        className = 'card-content'
      >
        <div
          className = {`
            card-title
            skeleton-card-title
          `}
        />

        <div
          className = 'card-pills'
        >
          <div
            className = {`
              card-pills-row
              skeleton-card-pills-row
            `}
          >
            <div
              className = {`
                recipe-card-pill
                skeleton-recipe-card-pill
              `}
            />
            <div
              className = {`
                recipe-card-pill
                skeleton-recipe-card-pill
              `}
            />
          </div>
          <div
            className = {`
              card-pills-row
              skeleton-card-pills-row
            `}
          >
            <div
              className = {`
                recipe-card-pill
                skeleton-recipe-card-pill-2
              `}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
