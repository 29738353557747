import CoreApi from 'services/Core/CoreApi';
import { CoreAlgorithmRecipeEndpoints } from './Config'

export default class AlgorithmRecipeApi extends CoreApi {
  constructor() {
    super(CoreAlgorithmRecipeEndpoints);
  }

  detailAlgorithmRecipe = (algorithmRecipeId: number) => {
    const response = super.request(
      this.apiEndpoints.detailAlgorithmRecipe(algorithmRecipeId)
    );

    return this.respondWithJson(response);
  };

  incrementAdditionalItem = (algorithmRecipeId: number, supermarketItemId: number) => {
    const response = super.request(
      this.apiEndpoints.incrementAdditionalItem(algorithmRecipeId, supermarketItemId)
    );

    return this.respondWithJson(response);
  }

  decrementAdditionalItem = (algorithmRecipeId: number, supermarketItemId: number) => {
    const response = super.request(
      this.apiEndpoints.decrementAdditionalItem(algorithmRecipeId, supermarketItemId)
    );

    return this.respondWithJson(response);
  }
}
