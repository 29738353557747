import React from 'react'

import './CloseButton.scss'
import { ReactComponent as CrossIcon } from 'assets/images/icon/cross/root.svg'
import { ColourConstants } from 'utils/Constants'

type Props = {
  onClickHandler: () => void
}

export default function CloseButton({
  onClickHandler
}: Props) {
  return (
    <button
      className = 'close-button'
      onClick   = { onClickHandler }
    >
      <CrossIcon
        color = { ColourConstants.Black[1] }
      />
    </button>
  )
}
