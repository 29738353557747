import React, { useState } from 'react'

import './Root.scss';
import SliderRange from './SliderRange';

type Props = {
  type?:       'money' | 'people'
  orientation: 'vertical' | 'horizontal'
  min:         number
  max:         number
  values:      number[]
  setValues:   (values: number[]) => void
}

export default function Root({
  type = 'money',
  orientation,
  min,
  max,
  values,
  setValues
}: Props) {
  const [tempValues, setTempValues] = useState(values);

  const handleDrag = (newValues) => {
    setTempValues(newValues);
  };

  const handleDragEnd = () => {
    setValues(tempValues);
  };

  return (
    <div
      className = 'slider-container'
    >
      <div
        className = {`slider ${orientation}`}
      >
        <SliderRange
          type        = { type }
          orientation = { orientation }
          values      = { tempValues }
          setValues   = { handleDrag }
          min         = { min }
          max         = { max }
          onDragEnd   = { handleDragEnd }
        />
      </div>
    </div>
  );
}
