import { RouteName } from 'router/Config';

export default function useRedirectedFrom() {
  function redirectedFrom(routeName: RouteName): boolean {
    const params = new URLSearchParams(window.location.search)
    const redirectLocation = params.get('from')

    return redirectLocation === routeName
  }

  return redirectedFrom;
}
