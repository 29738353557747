import React from 'react'

import './Divider.scss'
import P14 from 'components/Text/Paragraphs/Regular/P14'

export default function Divider() {
  return(
    <div className = 'divider-container'>
      <div className = 'divider-line' />
      
      <div className = 'divider-text'>
        <P14 
          text = 'or' 
        />
      </div>

      <div className = 'divider-line' />
    </div>
  )
}
