import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie';

import animationData from 'assets/animations/onboarding.json';

type Props = {
  onAnimationComplete: () => void
  completed:           boolean
}

export default function OnboardingAnimation({
  onAnimationComplete,
  completed
}: Props) {
  const [firstPartCompleted, setFirstPartCompleted] = useState(false);

  const defaultOptions = {
    loop:          false,
    autoplay:      true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFirstPartCompleted(true);
    }, 9000); // 9 seconds for the first part, then 10 seconds for the second part

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (completed && firstPartCompleted) {
      onAnimationComplete();
    }
  }, [completed, firstPartCompleted]);

  return (
    <Lottie
      options = { defaultOptions }
      height  = { 256 }
      style   = {{
        border:       '2px solid #EBEBEB',
        boxSizing:    'border-box',
        borderRadius: '12px'
      }}
    />
  )
}
