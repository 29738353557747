import UserRegistrationApi from 'services/Core/User/RegistrationService/UserRegistrationApi';

export default function useUpdate() {
  function update(firstName: string): Promise<any> {
    const userRegistrationApi = new UserRegistrationApi();

    return userRegistrationApi.update(firstName);
  }

  return update;
}
