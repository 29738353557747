import { useEffect } from 'react';

import IUserPreferences from 'interfaces/frontend/IUserPreferences';

import { useDeselectPill } from 'utils/Hooks/Helper/useDeselectPill'

export default function useMultiSelectPill(
  type:              'allergies' | 'equipment',
  userPreferences:    IUserPreferences,
  setUserPreferences: (userPreferences: IUserPreferences) => void
) {
  const initialOptions = type === 'allergies' ? userPreferences.allergies : userPreferences.kitchenEquipment;
  const deselector     = type === 'allergies' ? 'No Allergies' : '';

  const [selectedOptions, handleOptionClick] = useDeselectPill(initialOptions, deselector)

  useEffect(() => {
    type === 'allergies' ?
    setUserPreferences({
      ...userPreferences,
      allergies: selectedOptions
    })
    :
    setUserPreferences({
      ...userPreferences,
      kitchenEquipment: selectedOptions
    })
  }, [selectedOptions])

  return [selectedOptions, handleOptionClick] as const
}
