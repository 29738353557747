export const HouseholdConstants = {
  NumberOfPeople: {
    Adults:   [
      0, 1, 2, 3, 4, 5
    ],
    Children: [
      0, 1, 2, 3, 4, 5
    ]
  },
  NumberOfMeals: [
    3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14
  ],
  DietaryPreferences: [
    'Vegetarian', 'Low Sugar', 'Gluten Free', 'Low Calories', 'Low Carb', 'Dairy Free', 'High Protein', 'Halal'
  ],
  Allergies: [
    'Eggs', 'Fish', 'Peanuts', 'Gluten', 'Sesame', 'Soybeans', 'Milk', 'Nuts', 'Celery', 'Mustard',  'Sulphur', 'Lupin', 'Molluscs', 'Crustaceans'
  ],
  KitchenEquipment: [
    'Cooking Hub or Stove', 'Oven', 'Blender', 'Microwave', 'Kettle', 'Slow Cooker', 'Air Fryer'
  ],
  YesNoOptions: {
    Yes: 'Yes, I do',
    No:  'No, I don\'t'
  }
}
