import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import IRecipeStep from 'interfaces/backend/IRecipeStep';

export default function useImputeInstructions(
  recipeSteps:    IRecipeStep[],
  algorithmItems: IAlgorithmItem[]
) {
  // TODO: `imputedSteps` Constant which is the recipeSteps but looking at each of the ingredientsUsedInStep array
  // and if this has a cupboardItem value of false, we look at the algorithmItems and find the first item that has the same
  // recipe ingredient name and then we overwrite the image url and name with the algorithm item's image url and name
  
  const imputedSteps = recipeSteps.map((step: IRecipeStep) => {
    const imputedIngredients = step.ingredientsUsedInStep.map((ingredient) => {
      if (!ingredient.cupboardItem) {
        const algorithmItem = algorithmItems.find((algorithmItem) => algorithmItem.recipeIngredient.name === ingredient.name)

        if (algorithmItem) {
          return {
            ...ingredient,
            imageUrl: algorithmItem.item.imageUrl,
            name:     algorithmItem.recipeIngredient.name
          }
        }
      }

      return ingredient
    })

    return {
      ...step,
      ingredientsUsedInStep: imputedIngredients
    }
  })

  return imputedSteps
}
