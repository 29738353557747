import useSmoothScrollTo from 'hooks/ui/useSmoothScrollTo';

export default function useHandleNotificationClick(
  setShowNotification: (showNotification: boolean) => void
) {
  const smoothScrollTo = useSmoothScrollTo();

  const handleNotificationClick = () => {

    const scrollDuration = 800;

    const didScroll = smoothScrollTo('other-recipes-title-text-container', scrollDuration);

    if (didScroll) {
      setTimeout(() => {
        setShowNotification(false);
      }, scrollDuration + 100);
    } else {
      setShowNotification(false);
    }
  }

  return handleNotificationClick
}
