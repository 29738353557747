import CoreApi from 'services/Core/CoreApi';
import { CoreAlgorithmBasketEndpoints } from './Config'

export default class AlgorithmBasketApi extends CoreApi {
  constructor() {
    super(CoreAlgorithmBasketEndpoints);
  }

  show() {
    const response = super.request(
      this.apiEndpoints.show()
    );

    return this.respondWithJson(response);
  }

  itemDetails() {
    const response = super.request(
      this.apiEndpoints.itemDetails()
    );

    return this.respondWithJson(response);
  }

  shoppingItemDetails() {
    const response = super.request(
      this.apiEndpoints.shoppingItemDetails()
    );

    return this.respondWithJson(response);
  }

  removeItem(itemId: number) {
    const response = super.request(
      this.apiEndpoints.removeItem(itemId)  
    );

    return this.respondWithJson(response);
  }

  incrementShoppingItem(supermarketItemId: number) {
    const response = super.request(
      this.apiEndpoints.incrementShoppingItem(supermarketItemId)
    );

    return this.respondWithJson(response);
  }

  decrementShoppingItem(supermarketItemId: number) {
    const response = super.request(
      this.apiEndpoints.decrementShoppingItem(supermarketItemId)
    );

    return this.respondWithJson(response);
  }

  addCupboardItem(itemId: number) {
    const response = super.request(
      this.apiEndpoints.addCupboardItem(itemId)
    );
      
    return this.respondWithJson(response);
  }

  checkout() {
    const response = super.request(
      this.apiEndpoints.checkout()
    );

    return this.respondWithJson(response);
  }
}
