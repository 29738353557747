import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';

export default function useHandleCancelTopItemSwap(
  setTopItemGridType:             (topItemGridType: 'recipeItems' | 'swappingItems' | 'additionalItems') => void,
  setSelectedAlgorithmTopItem:    (item: IAlgorithmItem | null) => void,
  setSelectedAlgorithmBottomItem: (item: IAlgorithmItem | null) => void,
) {
  const handleCancelTopItemSwap = () => {
    setTopItemGridType('recipeItems');

    // We need to cancel both the fresh item and cupboard item swaps because
    // if the cupboard item was being swapped after in_basket was set to true,
    // then the swap UI will appear on the fresh item grid.
    setSelectedAlgorithmTopItem(null);
    setSelectedAlgorithmBottomItem(null);
  }

  return handleCancelTopItemSwap;
}
