import IItem from 'interfaces/backend/IItem';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import { RecipeModalType } from 'enums/Modals/RecipeModalType';

export default function useHandleAlgorithmItemClick(
  openModal:               (modalType: RecipeModalType) => void,
setSelectedShoppingItem: (item: IItem | null) => void
) {
  const handleAlgorithmItemClick = (algorithmItem: IAlgorithmItem) => {
    setSelectedShoppingItem(algorithmItemAsShoppingItem(algorithmItem));

    openModal(RecipeModalType.ShoppingItem);
  }

  function algorithmItemAsShoppingItem(algorithmItem: IAlgorithmItem): IItem {
    return algorithmItem.item as unknown as IItem;
  }

  return handleAlgorithmItemClick;
}