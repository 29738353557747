import React from 'react'

import './ErrorModal.scss'
import H3Text from 'components/Text/Headings/H3Text'
import P14 from 'components/Text/Paragraphs/Regular/P14'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import RootModal from '../_RootModal'
import { ErrorType } from 'utils/Errors'
import { getPreLoginError } from 'utils/Errors/PreLogin'

type Props = {
  close:          () => void
  error:          ErrorType | null
  email?:         string
  onClickAction?: () => void
}

export default function ErrorModal({
  close,
  error,
  email,
  onClickAction
}: Props) {
  
  const getError = () => getPreLoginError(error)

  const clickHandler = () => {
    close()

    if (error === ErrorType.EmailNotVerified) {
      onClickAction && onClickAction()
    }
  }

  const size = (error === ErrorType.EmailNotVerified) ? 'large' : ''

  return (
    <RootModal
      isOpen  = { error !== null }
      close   = { close }
      padding = 'small'
    >
      <div
        className = {`
          error-modal-container
          ${size}
        `}
      >
        <div>
          <H3Text
            text = { getError()?.title }
          />

          <P14
            text      = { getError()?.message(email) }
            className = 'message'
          />
        </div>

        <PrimaryButton
          text           = { getError()?.buttonCopy }
          size           = 'fill'
          onClickHandler = { clickHandler }
        />
      </div>
    </RootModal>
  )
}