import React, { useContext } from 'react'

import './ShoppingItemCard.scss'
import P12 from 'components/Text/Paragraphs/Medium/P12'
import P14 from 'components/Text/Paragraphs/SemiBold/P14'
import P14M from 'components/Text/Paragraphs/Medium/P14'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'
import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import IItem from 'interfaces/backend/IItem'
import { RouteName } from 'router/Config'
import { isOnRoute } from 'router/Navigator'

import useIncrementShoppingItem from 'utils/Hooks/Actions/Algorithm/Basket/useIncrementShoppingItem'
import useDecrementShoppingItem from 'utils/Hooks/Actions/Algorithm/Basket/useDecrementShoppingItem'
import AddToBasketButton from 'components/Buttons/AddToBasketButton'
import FallbackItemImage from 'components/Images/Fallback/FallbackItemImage'
import FullWithCountIncrementorButton from 'components/Buttons/IncrementorButton/FullWithCount'
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket'

type Props = {
  shoppingItem:       IItem,
  onCardClickHandler: (shoppingItem: IItem) => void
}

enum ShoppingItemButton {
  Basket      = 'basket',
  Incrementor = 'incrementor',
}

export default function ShoppingItemCard({
  shoppingItem,
  onCardClickHandler
}: Props) {
  const { algorithmBasket }: { algorithmBasket: AlgorithmBasket } = useContext(AlgorithmBasketContext);

  const incrementShoppingItem = useIncrementShoppingItem()
  const decrementShoppingItem = useDecrementShoppingItem()

  const clickTest = () => {
    onCardClickHandler(shoppingItem)
  }

  const shoppingItemCardButton = () => {
    if (isOnRoute(RouteName.Search)) {
      if (algorithmBasket.getShoppingItemMappingForItem(shoppingItem)) {
        return ShoppingItemButton.Incrementor
      } else {
        return ShoppingItemButton.Basket;
      }
    } else if (isOnRoute(RouteName.Basket)) {
      return ShoppingItemButton.Incrementor;
    } else {
      console.error('No route found for shopping item card button', window.location.pathname);
    }
  }

  return (
    <div 
      className = 'shopping-item-card'
      onClick   = { clickTest }
    >
      <div className = 'shopping-item-card-container'>
        <FallbackItemImage
          src       = { shoppingItem.imageUrl }
          alt       = { shoppingItem.name }
          className = 'shopping-item-card-image'
        />

        <div className = 'shopping-item-card-content'>
          <div>
            <div
              className = 'shopping-item-card-title'
            >
              <P14
                text = { shoppingItem.name }
                colour = 'black-1'
              />
            </div>
            

            <P12
              text = { shoppingItem.standardisedPrice }
            />
          </div>
          
          <div className = 'shopping-item-card-other'>
            {
              (shoppingItem.promotion !== null) && (
                <div
                  className = 'shopping-item-card-other-previous-price'
                >
                  <P14M
                    text   = { `£${shoppingItem.promotion?.previousPrice?.toFixed(2)}` }
                    colour = 'pink-1'
                  />
                </div>
              )
            }
            
            <P16
              text = { `£${shoppingItem.price.toFixed(2)}` }
            />
          </div>
        </div>

        <div
          className = 'shopping-item-card-button'
        >
          {
            shoppingItemCardButton() === ShoppingItemButton.Basket && (
              <AddToBasketButton
                onClick = { (e) => incrementShoppingItem(e, shoppingItem) }
                size    = 'medium'
              />
            )
          }

          {
            shoppingItemCardButton() === ShoppingItemButton.Incrementor && (
              <div
                className = { shoppingItemCardButton() }
              >
                <FullWithCountIncrementorButton
                  quantity     = { algorithmBasket.getShoppingItemMappingForItem(shoppingItem)?.quantity || 0 }
                  onTrashClick = { (e) => decrementShoppingItem(e, shoppingItem) }
                  onAddClick   = { (e) => incrementShoppingItem(e, shoppingItem) }
                />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}