import { useContext } from 'react';

import useShowApi from 'utils/Hooks/Api/Algorithm/MealPlan/useShow'
import { LoadingStatus } from 'enums/LoadingStatus';
import AlgorithmMealPlanContext from 'context/AlgorithmMealPlanContext';
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useShow() {
  const { setAlgorithmMealPlan }: { setAlgorithmMealPlan: (algorithmMealPlan: AlgorithmMealPlan) => void } = useContext(AlgorithmMealPlanContext)

  const showApi = useShowApi();

  function show(
    setLoadingStatus?: (loadingStatus: LoadingStatus) => void
  ) {
    showApi().then((response) => {
      const data: AlgorithmMealPlan = new AlgorithmMealPlan({
        ...response.data,
        algorithmRecipeMapping: response.data.algorithmRecipeMapping.map(mapping => ({
            ...mapping,
            algorithmRecipe: mapping.algorithmRecipe 
                ? new AlgorithmRecipe(mapping.algorithmRecipe)
                : null
        }))
      });

      setAlgorithmMealPlan(data);

      setLoadingStatus && setLoadingStatus(LoadingStatus.APIFinished)
    })
  }

  return show;
}
