import React from 'react'

import './CupboardItemModal.scss'
import RootModal from '../_RootModal/RootModal'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import H3Text from 'components/Text/Headings/H3Text'
import P12 from 'components/Text/Paragraphs/Medium/P12'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

type Props = {
  isOpen:        boolean
  close:         () => void
  algorithmItem: IAlgorithmItem
}

export default function CupboardItemModal({
  isOpen,
  close,
  algorithmItem
}: Props) {

  if (algorithmItem === null) {
    return null
  }

  // TODO: also used in RecipeStepCard - refactor
  function prettyUnits(quantity: number, units: string): string {
    switch (units) {
      case 'LOOSE':
        return `${quantity}`
      case 'GRAMS':
        return `${quantity}g`
      case 'ML':
        return `${quantity}ml`
      default:
        throw new Error(`Unknown units: ${units}`)
    }
  }

  const topLineTextObject: DashboardTextType[] = [
    {
      line:  1,
      value: `This recipe contains`,
      bold:  false
    },
    {
      line:  1,
      value: `${algorithmItem.recipeIngredient.name}.`,
      bold:  true
    }
  ]

  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'small'
    >
      <div
        className = 'cupboard-item-modal-container'
      >
        <MealiaBotCard
          textObjects = { topLineTextObject }
          closeModal  = { close }
        />

        <div
          className = 'cupboard-item-modal-recipe-item-container'
        >
          <img
            src       = { algorithmItem.recipeIngredient.imageUrl }
            alt       = { algorithmItem.recipeIngredient.name }
            className = 'cupboard-item-modal-recipe-item-image'
          />

          <div
            className = 'cupboard-item-modal-recipe-item-text-container'
          >
            <H3Text
              text = { algorithmItem.recipeIngredient.name }
            />

            <P12
              text = { prettyUnits(algorithmItem.contentRequired, algorithmItem.recipeIngredient.units) }
            />
          </div>
        </div>
      </div>
    </RootModal>
  )
}
