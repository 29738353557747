export default interface IMessage {
  id:        number;
  sender:    Sender;
  content:   string;
  createdAt: Date;
}

export enum Sender {
  User      = 'user',
  Assistant = 'assistant'
}
