import React from 'react'

import './MessageRenderer.scss'
import IMessage, { Sender } from 'interfaces/backend/IMessage'
import UserMessage from '../UserMessage'
import BotMessage from '../BotMessage'
import TypingMessage from '../TypingMessage'

type Props = {
  messages: IMessage[]
}

export default function MessageRenderer({
  messages
}: Props) {
  if (!messages) {
    return null;
  }

  const isDifferentSender = (index: number) => {
    const previousMessage = index > 0 ? messages[index - 1] : null;

    return previousMessage && previousMessage.sender !== messages[index].sender
  }

  const isLastMessageFromUser = () => {
    const lastMessage = messages[messages.length - 1];

    return lastMessage && lastMessage.sender === Sender.User;
  };

  return (
    <ul
      className = 'chatbot-message-renderer-container'
    >
      {
        messages.map((message, index) => (
          <li
            key       = { index }
            className = { isDifferentSender(index) ? 'different-sender' : 'same-sender' }
          >
            {
              message.sender === Sender.User && <UserMessage
                message = { message }
              />
            }

            {
              message.sender === Sender.Assistant && <BotMessage
                message    = { message }
                newMessage = { index === messages.length - 1 && index !== 0 }
              />
            }
          </li>
        ))
      }

      {
        isLastMessageFromUser() && <div
          className = 'different-sender'
        >
          <TypingMessage />
        </div>
      }
    </ul>
  )
}
