import useNavigateTo from 'hooks/navigation/useNavigateTo';
import { RouteName } from 'router/Config';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import { KeyConstants } from 'utils/Constants';

export default function useHandleRecipeCardClick() {
  const navigateTo = useNavigateTo();

  function handleRecipeCardClick(algorithmRecipe: AlgorithmRecipe, e: React.MouseEvent<HTMLDivElement>) {
    sessionStorage.setItem(KeyConstants.SessionStorage.scrollPosition, window.scrollY.toString());

    navigateTo(RouteName.Recipe, `/?id=${algorithmRecipe.id}`);
  }

  return handleRecipeCardClick;
}
