import { useContext } from 'react';

import UserContext from 'context/UserContext';
import User from 'models/User';

export default function useAuthorisedStatus() {
  const { user }: { user: User } = useContext(UserContext);

  function isAuthorised() {
    return user !== null
  }

  return isAuthorised()
}
