import React from 'react'

import './BasketButton.scss'
import P16 from 'components/Text/Paragraphs/Medium/P16';
import { ReactComponent as PinkBag } from 'assets/images/icons/_new/pink-bag.svg'

type Props = {
  onClickHandler: () => void;
  isOnRoute:      boolean;
  basketValue:    number | undefined;
}

export default function BasketButton({
  onClickHandler,
  isOnRoute,
  basketValue
}: Props) {
  return (
    <button
      className = {`
        basket-button-container
        ${isOnRoute ? 'highlight-border' : ''}
      `}
      onClick   = { onClickHandler }
    >
      <PinkBag
        style = {{
          flexShrink: 0
        }}
      />

      <P16
        text = { `£${basketValue ? basketValue?.toFixed(2) : ''}` }
      />
    </button>
  )
}
