import AlgorithmMealPlanApi from 'services/Core/Algorithm/MealPlanService/AlgorithmMealPlanApi';

export default function useOtherAlgorithmRecipes() {
  function otherAlgorithmRecipes(): Promise<any> {
    const algorithmMealPlan = new AlgorithmMealPlanApi();

    return algorithmMealPlan.otherAlgorithmRecipes();
  }

  return otherAlgorithmRecipes;
}
