import { DashboardTextType } from 'types/DashboardTextType'

export default function useEndingMessages() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Creating your profile...`,
    bold:  false
  }

  const endingMessages = [
    topLineTextObject
  ]

  return endingMessages
}
