import { useEffect } from 'react';

import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import useOtherAlgorithmRecipes from 'utils/Hooks/Actions/Algorithm/MealPlan/useOtherAlgorithmRecipes';

export default function useShowOtherRecipes(
  setOtherRecipes: (otherRecipes: AlgorithmRecipe[]) => void
) {
  const otherAlgorithmRecipes = useOtherAlgorithmRecipes();

  useEffect(() => {
    otherAlgorithmRecipes(setOtherRecipes);
  }, []);
}
