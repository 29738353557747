import { ItemMapping } from 'types/Mappings/ItemMapping';

export default function useHandleAcceptDeleteItem(
  tempItem:       { name: string; id: number; },
  setItemMapping: (itemMapping: ItemMapping[]) => void,
  removeItem:     (itemId: number, setItemMapping: (itemMapping: ItemMapping[]) => void) => void,
  closeModal:     () => void
) {
  const handleAcceptDeleteItem = () => {
    removeItem(tempItem.id, setItemMapping);

    closeModal();
  }

  return handleAcceptDeleteItem;
}
