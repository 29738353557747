import { useEffect } from 'react';

import { pathOf } from 'router/Navigator';
import { RouteName } from 'router/Config';
import { LoadingStatus } from 'enums/LoadingStatus';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';
import useSmoothScrollTo from 'hooks/ui/useSmoothScrollTo';
import { KeyConstants } from 'utils/Constants';

export default function useScrollToPosition(
  loadingStatus:     LoadingStatus,
  otherRecipes:      AlgorithmRecipe[],
  algorithmMealPlan: AlgorithmMealPlan
) {
  const smoothScrollTo = useSmoothScrollTo();

  useEffect(() => {
    if (algorithmMealPlan?.algorithmRecipeMapping.length === 0) return;
    if (loadingStatus !== LoadingStatus.ImagesLoaded) return; // Due to the Image Loading component, have to wait

    const savedScrollPosition = sessionStorage.getItem(KeyConstants.SessionStorage.scrollPosition);
    const shouldScroll = sessionStorage.getItem(KeyConstants.SessionStorage.shouldScroll);
    const addedToMealPlan = sessionStorage.getItem(KeyConstants.SessionStorage.addedToMealPlan);

    if (savedScrollPosition !== null) {
      if (addedToMealPlan === 'true') {
        smoothScrollTo('last-algorithm-recipe', 800);

        sessionStorage.removeItem(KeyConstants.SessionStorage.addedToMealPlan);
      } else if (shouldScroll === 'true') {
        window.scrollTo({
          top:      parseInt(savedScrollPosition),
          behavior: 'smooth'
        });
        
        sessionStorage.removeItem(KeyConstants.SessionStorage.shouldScroll);
      }

      sessionStorage.removeItem(KeyConstants.SessionStorage.scrollPosition);
    }
  }, [algorithmMealPlan, loadingStatus]);

  useEffect(() => {
    if (otherRecipes.length === 0) return;

    const savedScrollPosition = sessionStorage.getItem(KeyConstants.SessionStorage.scrollPosition);
    const navigatedFrom = sessionStorage.getItem(KeyConstants.SessionStorage.navigatedFrom);
  
    if (savedScrollPosition !== null && navigatedFrom === pathOf(RouteName.Recipe)) {
      window.scrollTo({
        top:      parseInt(savedScrollPosition),
        behavior: 'smooth'
      });

      sessionStorage.removeItem(KeyConstants.SessionStorage.scrollPosition);
      sessionStorage.removeItem(KeyConstants.SessionStorage.navigatedFrom);
    }
  }, [otherRecipes]);
}
