import { CoreUserEndpoints } from 'services/Core/UserService/Config';
import CoreApi from 'services/Core/CoreApi';

export default class UserApi extends CoreApi {
  constructor() {
    super(CoreUserEndpoints);
  }

  userGoogleRegister(code: string) {
    const response = super.request(
      this.apiEndpoints.userGoogleRegister(code)
    );

    this.storeAuthHeader(response);

    return this.respondWithJson(response);
  }
}
