import useActions  from './Core/useActions';
import useComputed from './Core/useComputed';
import useEffects  from './Core/useEffects';
import useHooks    from './Core/useHooks';
import useMessages from './Core/useMessages';
import useStates   from './Core/useStates';

export default function useGenerateNewMeals() {
  const {
    navigateTo,
    createNewMeals
  } = useHooks();

  const {
    forceUpdate,
    setForceUpdate,
    page,
    setPage,
    userPreferences,
    setUserPreferences
  } = useStates();

  const {
    numberOfPeopleMessages,
    numberOfMealsMessages,
    budgetMessages,
    dietaryPreferencesMessages,
    allergiesMessages,
    kitchenEquipmentMessages,
    grocerMessages,
    endingMessages
  } = useMessages(userPreferences);

  const {
    disabledPrimaryButton,
    botMessage
  } = useComputed(page, userPreferences, numberOfPeopleMessages, numberOfMealsMessages, budgetMessages, dietaryPreferencesMessages, allergiesMessages, kitchenEquipmentMessages, grocerMessages, endingMessages);

  const {
    handlePreviousButtonClick,
    handleNextButtonClick,
    handleCancelClick
  } = useActions(page, setPage, userPreferences, setUserPreferences, navigateTo, createNewMeals);

  useEffects(page, setForceUpdate)

  return {
    forceUpdate,
    page,
    userPreferences,
    setUserPreferences,
    botMessage,
    disabledPrimaryButton,
    handlePreviousButtonClick,
    handleNextButtonClick,
    handleCancelClick
  }
}
