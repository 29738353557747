import React from 'react'

import './SliderTick.scss'
import P18 from 'components/Text/Paragraphs/Medium/P18';

type Props = {
  type:        'money' | 'people'
  orientation: 'vertical' | 'horizontal'
  value:       number
}

export default function SliderTick({
  type,
  orientation,
  value
}: Props) {
  const tickText = () => {
    switch (type) {
      case 'money':
        return '£' + value.toFixed(0)
      case 'people':
        return value.toFixed(0) + ' people'
    }
  }

  return (
    <div
      className = {`slider-tick ${orientation}`}
    >
      <div
        className = {`slider-tick-triangle ${orientation}`}
      />

      <P18
        text   = { tickText() }
        colour = 'white-1'
      />
    </div>
  )
}
