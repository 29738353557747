import React from 'react'

import { OnboardingQuestionProps } from '../Shared/OnboardingQuestionProps'
import useMultiSelectPill from 'utils/Hooks/Helper/useMultiSelectPill'
import KitchenEquipmentPreference from 'components/features/UserPreferences/KitchenEquipmentPreference'

export default function KitchenEquipment({
  userPreferences,
  setUserPreferences
}: OnboardingQuestionProps) {
  const [selectedOptions, handleOptionClick] = useMultiSelectPill('equipment', userPreferences, setUserPreferences)

  return (
    <KitchenEquipmentPreference
      selectedOptions   = { selectedOptions }
      handleOptionClick = { handleOptionClick }
    />
  )
}
