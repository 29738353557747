import { useContext } from 'react';

import User from 'models/User';
import UserContext from 'context/UserContext';
import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import useCreateHouseholdApi from 'utils/Hooks/Api/Household/useCreateHousehold';

export default function useCreateHousehold() {
  const { setUser }: { setUser: (user: User) => void} = useContext(UserContext);

  const createHouseholdApi = useCreateHouseholdApi();

  async function createHousehold(
    numberOfAdults:       number,
    numberOfChildren:     number,
    numberOfMealsPerWeek: number,
    weeklyBudget:         number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    chosenSupermarket:    SupportedSupermarket,
    onSuccess:            () => void
  ) {
    createHouseholdApi(
      numberOfAdults,
      numberOfChildren,
      numberOfMealsPerWeek,
      weeklyBudget,
      allergies,
      dietaryPreferences,
      kitchenEquipment,
      chosenSupermarket
    ).then((response) => {
      const user: User = User.fromApiResponse(response.data);

      setUser(user);

      onSuccess();
    })
  };

  return createHousehold;
}