import React from 'react'

import './AskMealiaButton.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import SparkleAnimation from 'components/Animations/AskMealia/Sparkle/SparkleAnimation'

type Props = {
  onClick: () => void
}

export default function AskMealiaButton({
  onClick
}: Props) {
  return (
    <button
      className = 'ask-mealia-button-container'
      onClick   = { onClick }
    >
      <SparkleAnimation />

      <P14
        text   = 'Ask Mealia'
        colour = 'white-1'
      />
    </button>
  )
}
