import { useEffect } from 'react';

import { LoadingStatus } from 'enums/LoadingStatus';
import useShow from 'utils/Hooks/Actions/Algorithm/MealPlan/useShow';

export default function useShowMealPlan(setLoadingStatus: (loadingStatus: LoadingStatus) => void) {
  const show = useShow();

  useEffect(() => {
    show(setLoadingStatus);
  }, []);
}
