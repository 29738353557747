import IItem from 'interfaces/backend/IItem';
import IAlgorithmBasket from 'interfaces/backend/Algorithm/IAlgorithmBasket';
import { ShoppingItemMapping } from 'types/Mappings/ShoppingItemMapping';

export default class AlgorithmBasket implements IAlgorithmBasket {
  totalValue:            number;
  pricePerServing:       number;
  numberOfRecipes:       number;
  numberOfShoppingItems: number;
  numberOfItems:         number;
  checkedOut:            boolean;
  shoppingItemMapping:   ShoppingItemMapping[]

  constructor(data: IAlgorithmBasket) {
    Object.assign(this, data);
  }

  get hasNoRecipes(): boolean {
    return this.numberOfRecipes === 0;
  }

  getShoppingItemMappingForItem(item: IItem): ShoppingItemMapping | undefined {
    return this.shoppingItemMapping.find(mapping => mapping.supermarketItemId === item.supermarketItemId);
  }
}