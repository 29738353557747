import AlgorithmSavedPlanApi from 'services/Core/Algorithm/SavedPlanService/AlgorithmSavedPlanApi';

export default function useShow() {
  function show(algorithmSavedPlanId: number): Promise<any> {
    const algorithmSavedPlanApi = new AlgorithmSavedPlanApi();

    return algorithmSavedPlanApi.show(algorithmSavedPlanId);
  }

  return show;
}
