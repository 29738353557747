import React from 'react'

import './CupboardItemCard.scss'
import IItem from 'interfaces/backend/IItem'
import RootItemCard from '../_RootItemCard'
import AddToBasketButton from 'components/Buttons/AddToBasketButton'

type Props = {
  item:                IItem
  onCardClick:         (item: IItem) => void
  onAddToBasketClick:  (item: IItem, e: React.MouseEvent<HTMLDivElement>) => void
}

export default function CupboardItemCard({
  item,
  onCardClick,
  onAddToBasketClick
}: Props) {
  return (
    <RootItemCard
      item           = { item }
      onCardClick    = { onCardClick }
      buttonChildren = {
        <div
          className = 'cupboard-item-card-buttons'
        >
          {/* TODO: Add Substituion Button */}
          
          <AddToBasketButton
            onClick = { (e: React.MouseEvent<HTMLDivElement>) => onAddToBasketClick(item, e) }
            size    = 'medium'
          />
        </div>
      }
    />
  )
}
