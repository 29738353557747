import React from 'react'

import './RootItemCard.scss'
import IItem from 'interfaces/backend/IItem'
import FallbackItemImage from 'components/Images/Fallback/FallbackItemImage'
import P12 from 'components/Text/Paragraphs/SemiBold/P12'
import P14 from 'components/Text/Paragraphs/SemiBold/P14'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'
import P14M from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  item:           IItem
  onCardClick:    (item: IItem) => void
  buttonChildren: React.ReactNode
}

export default function RootItemCard({
  item,
  onCardClick,
  buttonChildren
}: Props) {
  return (
    <div
      className = 'root-item-card-container'
      onClick   = { () => onCardClick(item) }
    >
      <FallbackItemImage
        className = 'image'
        src       = { item.imageUrl }
        alt       = { item.name }
      />

      <div
        className = 'card-content'
      >
        <div>
          <div
            className = 'title'
          >
            <P14
              text   = { item.name }
              colour = 'black-1'
            />
          </div>
          
          <P12
            text   = { item.standardisedPrice }
            colour = 'grey-1'
          />
        </div>

        <div
          className = 'price'
        >
          {
            item.promotion !== null &&
            <div
              className = 'previous-price'
            >
              <P14M
                text   = { `£${item.promotion.previousPrice.toFixed(2)}` }
                colour = 'pink-1'
              />
            </div>
          }

          <P16
            text   = { `£${item.price.toFixed(2)}` }
            colour = 'black-1'
          />
        </div>

        <div
          className = 'card-button'
        >
          { buttonChildren }
        </div>
      </div>
    </div>
  )
}
