import React from 'react'

import './SlideUpAnimationWrapper.scss'

type Props = {
  children: React.ReactNode;
  delay?:   number;
}

export default function SlideUpAnimationWrapper({
  children,
  delay = 0
}: Props) {
  return (
    <div
      className = 'slide-up-animation-wrapper'
    >
      <div
        className = 'slide-up-animation-content'
        style     = {{
          animationDelay: `${delay}ms`
        }}
      >
        { children }
      </div>
    </div>
  )
}
