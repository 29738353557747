import { useEffect, useState } from 'react';

import useDetailAlgorithmRecipeApi from 'utils/Hooks/Api/Algorithm/Recipe/useDetailAlgorithmRecipe';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useDetailAlgorithmRecipe() {
  const detailAlgorithmRecipeApi = useDetailAlgorithmRecipeApi();

  const [data, setData]           = useState<AlgorithmRecipe | null>(null);
  const [error, setError]         = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [algorithmRecipe, setAlgorithmRecipe] = useState<AlgorithmRecipe | null>(null);
  const algorithmRecipeId = Number(window.location.search.split('?')[1].split('&')[0].split('=')[1]);

  useEffect(() => {
    data && setAlgorithmRecipe(data);

    return () => {
      setAlgorithmRecipe(null);
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(true);

    detailAlgorithmRecipeApi(algorithmRecipeId)
      .then((response) => {
        const fetchedRecipe = new AlgorithmRecipe(response.data);
        
        setData(fetchedRecipe);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return [algorithmRecipe, setAlgorithmRecipe, isLoading] as const;
}