import { useContext } from 'react';

import useIncrementAdditionalItemApi from 'utils/Hooks/Api/Algorithm/Recipe/useIncrementAdditionalItem';
import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';
import useShow from '../Basket/useShow';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useIncrementAdditionalItem() {
  const { setAlert } = useContext(AlertContext);
  const incrementAdditionalItemApi = useIncrementAdditionalItemApi();

  const show = useShow();

  function incrementAdditionalItem(
    algorithmRecipeId:  number, 
    supermarketItemId:  number,
    setAlgorithmRecipe: (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal?:        () => void,
  ) {
    incrementAdditionalItemApi(algorithmRecipeId, supermarketItemId).then((response) => {
      const data: AlgorithmRecipe = response.data;

      setAlgorithmRecipe(data);

      setAlert(AlertType.AddedIngredient)

      closeModal && closeModal();
    }).then(() => {
      // TODO: This only needs to be done for recipes in the meal plan
      show();
    });
  }

  return incrementAdditionalItem;
}
