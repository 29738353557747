import { DashboardTextType } from 'types/DashboardTextType'

export default function useEndingMessages() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `We’re good to go!`,
    bold:  false
  }

  const endingMessages = [
    topLineTextObject
  ]

  return endingMessages
}
