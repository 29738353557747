import React from 'react'

import useMultiSelectPill from 'utils/Hooks/Helper/useMultiSelectPill'
import { OnboardingQuestionProps } from '../Shared/OnboardingQuestionProps'
import AllergiesPreference from 'components/features/UserPreferences/AllergiesPreference'

export default function Allergies({
  userPreferences,
  setUserPreferences
}: OnboardingQuestionProps) {
  const [selectedOptions, handleOptionClick] = useMultiSelectPill('allergies', userPreferences, setUserPreferences)

  const handleYesClick = (option: string) => {
    setUserPreferences({
      ...userPreferences,
      allergiesPresent: option
    })
  }

  return (
    <div
      style = {{
        width: '469px'
      }}
    >
      <AllergiesPreference
        showYesNoButtons  = { true }
        selectedOption    = { userPreferences.allergiesPresent }
        handleYesNoClick  = { handleYesClick }
        selectedOptions   = { selectedOptions }
        handleOptionClick = { handleOptionClick }
      />
    </div>
  )
}
