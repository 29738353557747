import { useContext } from 'react';

import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import useIncrementShoppingItemApi from 'utils/Hooks/Api/Algorithm/Basket/useIncrementShoppingItem';
import IItem from 'interfaces/backend/IItem';
import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';

export default function useIncrementShoppingItem() {
  const { setAlgorithmBasket }: { setAlgorithmBasket: (algorithmBasket: AlgorithmBasket) => void } = useContext(AlgorithmBasketContext);
  const { setAlert }: { setAlert: (alert: AlertType) => void } = useContext(AlertContext);
  
  const incrementShoppingItemApi = useIncrementShoppingItemApi();

  function incrementShoppingItem(e: React.MouseEvent<HTMLDivElement, MouseEvent>, shoppingItem: IItem) {
    e.stopPropagation()

    incrementShoppingItemApi(shoppingItem.supermarketItemId).then((response) => {
      const algorithmBasket = new AlgorithmBasket(response.data);
      
      setAlgorithmBasket(algorithmBasket);

      setAlert(AlertType.AddedToBasket)
    })
  };

  return incrementShoppingItem
}
