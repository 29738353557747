import React from 'react'
import Typist from 'react-typist';

import './BotMessage.scss'
import IMessage, { Sender } from 'interfaces/backend/IMessage'
import RootMessage from '../RootMessage'
import P16 from 'components/Text/Paragraphs/Regular/P16'
import { ReactComponent as MealiaIcon } from 'assets/images/logos/mealia/small.svg'

type Props = {
  message:    IMessage
  newMessage: boolean
}

export default function BotMessage({
  message,
  newMessage
}: Props) {
  return (
    <RootMessage
      sender = { Sender.Assistant }
    >
      <div
        className = 'chatbot-message-bot-container'
      >
        <MealiaIcon
          className = 'mealia-mascot'
        />

        <div
          className = 'chatbot-message-bot-message-container'
        >
          {
            // newMessage ?
            // <Typist
            //   cursor         = { { show: false } }
            //   avgTypingDelay = { 20 }
            // >
            //   <div
            //     className = 'p16-regular' 
            //   >
            //     { message.content }
            //   </div>
            // </Typist>
            // :
            <P16
              text   = { message.content }
              colour = 'black-2'
            />
          }
        </div>
      </div>
    </RootMessage>
  )
}
