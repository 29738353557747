import { useContext } from 'react';

import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import useDecrementShoppingItemApi from 'utils/Hooks/Api/Algorithm/Basket/useDecrementShoppingItem';
import IItem from 'interfaces/backend/IItem';
import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';

export default function useDecrementShoppingItem() {
  const { setAlgorithmBasket }: { setAlgorithmBasket: (algorithmBasket: AlgorithmBasket) => void } = useContext(AlgorithmBasketContext);
  const { setAlert }: { setAlert: (alert: AlertType) => void } = useContext(AlertContext);

  const decrementShoppingItemApi = useDecrementShoppingItemApi();

  function decrementShoppingItem(e: React.MouseEvent<HTMLDivElement, MouseEvent>, shoppingItem: IItem) {
    e.stopPropagation()

    decrementShoppingItemApi(shoppingItem.supermarketItemId).then((response) => {
      const algorithmBasket = new AlgorithmBasket(response.data);
      
      setAlgorithmBasket(algorithmBasket);

      setAlert(AlertType.RemovedFromBasket)
    })
  };

  return decrementShoppingItem
}
