import React, { useContext } from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'
import UserContext from 'context/UserContext'
import User from 'models/User'

export default function WelcomeBackDashboardCard() {
  const { user }: { user: User } = useContext(UserContext) 

  const generateTopTextObjects: DashboardTextType = {
    line: 1,
    value: `Hi ${user.firstName}, welcome back!`,
    bold: false
  }

  const generateBottomTextObjects: DashboardTextType = {
    line: 2,
    value: 'Here’s a summary of your last meals, would you like to continue with it up or create a new one?',
    bold: false
  }


  return (
    <MealiaBotCard
      textObjects = { [generateTopTextObjects, generateBottomTextObjects] }
    />
  )
}
