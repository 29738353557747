import IItem from 'interfaces/backend/IItem';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import { DisclaimerType } from 'types/DisclaimerType';
import { RecipeModalType } from 'enums/Modals/RecipeModalType';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

import useHandleSwapClick from '../Operations/Actions/useHandleSwapClick';
import useHandleTrashClick from '../Operations/Actions/useHandleTrashClick';
import useHandleNutritionalInfoClick from '../Operations/Actions/useHandleNutritionalInfoClick';
import useHandleAddDifferentItemClick from '../Operations/Actions/useHandleAddDifferentItemClick';
import useHandleRecipeImageClick from '../Operations/Actions/useHandleRecipeImageClick';
import useHandleNutritionalInfoDisclaimerClick from '../Operations/Actions/useHandleNutritionalInfoDisclaimerClick';
import useHandleAddItemClick from '../Operations/Actions/useHandleAddItemClick';
import useHandleAddAdditionalItem from '../Operations/Actions/useHandleAddAdditionalItem';
import useHandleAlgorithmItemClick from '../Operations/Actions/useHandleAlgorithmItemClick';
import useHandleRecipeStepImageClick from '../Operations/Actions/useHandleRecipeStepImageClick';
import useHandleRecipeDisclaimerClick from '../Operations/Actions/useHandleRecipeDisclaimerClick';
import useHandleAddAlgorithmRecipe from '../Operations/Actions/useHandleAddAlgorithmRecipe';
import useHandleAddCupboardItem from '../Operations/Actions/useHandleAddCupboardItem';
import useHandleRemoveAlgorithmItem from '../Operations/Actions/useHandleRemoveAlgorithmItem';
import useHandleDecreaseAdditionalItem from '../Operations/Actions/useHandleDecreaseAdditionalItem';
import useHandleSubstituteTopItem from '../Operations/Actions/useHandleSubstituteTopItem';
import useHandleCancelTopItemSwap from '../Operations/Actions/useHandleCancelTopItemSwap';
import useHandleCancelCupboardItemSwap from '../Operations/Actions/useHandleCancelCupboardItemSwap';
import useHandleSubstituteCupboardItem from '../Operations/Actions/useHandleSubstituteCupboardItem';
import { RouteName } from 'router/Config';

export default function useActions(
  closeModal:                     () => void,
  openModal:                      (modalType: RecipeModalType) => void,
  algorithmRecipe:                AlgorithmRecipe,
  setAlgorithmRecipe:             (algorithmRecipe: AlgorithmRecipe) => void,
  setSelectedAlgorithmTopItem:    (item: IAlgorithmItem | null) => void,
  setSelectedAlgorithmBottomItem: (item: IAlgorithmItem | null) => void,
  setSelectedRecipeStepItem:      (item: IAlgorithmItem | null) => void,
  setSelectedShoppingItem:        (item: IItem | null) => void,
  setDisclaimer:                  (disclaimer: DisclaimerType) => void,
  setTopItemGridType:             (topItemGridType: 'recipeItems' | 'swappingItems' | 'additionalItems') => void,
  setBottomItemGridType:          (bottomItemGridType: 'recipeItems' | 'swappingItems') => void,
  incrementAdditionalItem:        (
    algorithmRecipeId:  number,
    supermarketItemId:  number,
    setAlgorithmRecipe: (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal?:        () => void,
  ) => void,
  decrementAdditionalItem:   (
    algorithmRecipeId:  number,
    supermarketItemId:  number,
    setAlgorithmRecipe: (algorithmRecipe: AlgorithmRecipe) => void
  ) => void,
  addAlgorithmRecipe:        (
    algorithmRecipeId: number,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    onSuccess?: () => void
  ) => void,
  removeAlgorithmItem:       (
    algorithmChosenItemId: number,
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal:            () => void,
    e:                     React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void,
  addCupboardItemToBasket:   (
    algorithmChosenItemId: number,
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void
  ) => void,
  replaceAlgorithmItem:      (
    algorithmChosenItemId: number,
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal:            () => void
  ) => void,
  navigateTo:              (routeName: RouteName) => void
) {
  const handleSwapClick  = useHandleSwapClick(setSelectedAlgorithmTopItem, setSelectedAlgorithmBottomItem, setTopItemGridType, setBottomItemGridType, openModal);
  const handleTrashClick = useHandleTrashClick(openModal, setSelectedAlgorithmTopItem, setSelectedAlgorithmBottomItem);
  const handleNutritionalInfoClick = useHandleNutritionalInfoClick(openModal);
  const handleNutritionalInfoDisclaimerClick = useHandleNutritionalInfoDisclaimerClick();
  const handleAddDifferentItemClick = useHandleAddDifferentItemClick(setTopItemGridType);
  const handleRecipeImageClick = useHandleRecipeImageClick(openModal);
  const handleAddItemClick = useHandleAddItemClick(setTopItemGridType);
  const handleAddAdditionalItem = useHandleAddAdditionalItem(algorithmRecipe, setAlgorithmRecipe, setTopItemGridType, closeModal, incrementAdditionalItem);
  const handleDecreaseAdditionalItem = useHandleDecreaseAdditionalItem(algorithmRecipe, setAlgorithmRecipe, decrementAdditionalItem);
  const handleAlgorithmItemClick = useHandleAlgorithmItemClick(openModal, setSelectedShoppingItem);
  const handleRecipeStepImageClick = useHandleRecipeStepImageClick(algorithmRecipe, setSelectedRecipeStepItem, openModal);
  const handleRecipeDisclaimerClick = useHandleRecipeDisclaimerClick(openModal, setDisclaimer);
  const handleAddAlgorithmRecipe = useHandleAddAlgorithmRecipe(algorithmRecipe, addAlgorithmRecipe, navigateTo)
  const handleAddCupboardItem = useHandleAddCupboardItem(setAlgorithmRecipe, addCupboardItemToBasket)
  const handleRemoveAlgorithmItem = useHandleRemoveAlgorithmItem(closeModal, setAlgorithmRecipe, removeAlgorithmItem);
  const handleSubstituteTopItem = useHandleSubstituteTopItem(openModal, closeModal, setAlgorithmRecipe, setSelectedAlgorithmTopItem, setTopItemGridType, replaceAlgorithmItem);
  const handleSubstituteCupboardItem = useHandleSubstituteCupboardItem(closeModal, setAlgorithmRecipe, setSelectedAlgorithmBottomItem, setBottomItemGridType, replaceAlgorithmItem);
  const handleCancelTopItemSwap = useHandleCancelTopItemSwap(setTopItemGridType, setSelectedAlgorithmTopItem, setSelectedAlgorithmBottomItem)
  const handleCancelCupboardItemSwap = useHandleCancelCupboardItemSwap(setBottomItemGridType, setSelectedAlgorithmBottomItem)

  return {
    handleSwapClick,
    handleTrashClick,
    handleNutritionalInfoClick,
    handleNutritionalInfoDisclaimerClick,
    handleAddDifferentItemClick,
    handleRecipeImageClick,
    handleAddItemClick,
    handleAddAdditionalItem,
    handleDecreaseAdditionalItem,
    handleAlgorithmItemClick,
    handleRecipeStepImageClick,
    handleRecipeDisclaimerClick,
    handleAddAlgorithmRecipe,
    handleAddCupboardItem,
    handleRemoveAlgorithmItem,
    handleSubstituteTopItem,
    handleSubstituteCupboardItem,
    handleCancelTopItemSwap,
    handleCancelCupboardItemSwap
  }
}
