import AlgorithmBasketApi from 'services/Core/Algorithm/BasketService/AlgorithmBasketApi';

export default function useCheckout() {
  function checkout(): Promise<any> {
    const algorithmBasketApi = new AlgorithmBasketApi();

    return algorithmBasketApi.checkout();
  }

  return checkout;
}
