import { useEffect } from 'react';

export default function useShowNotification(
  setShowNotification:  (showNotification: boolean) => void,
  subsectionHeaderRef:  React.MutableRefObject<HTMLSpanElement>,
  observerRef:          React.MutableRefObject<IntersectionObserver>,
  hasSeenSubsectionRef: React.MutableRefObject<boolean>
) {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!hasSeenSubsectionRef.current) {
        setShowNotification(true);
      }
    }, 7000);

    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        hasSeenSubsectionRef.current = true;

        setTimeout(() => {
          setShowNotification(false);
          // Stop observing after the element is found
          observerRef.current.unobserve(subsectionHeaderRef.current);
        }, 1000);
      }
    });

    observerRef.current.observe(subsectionHeaderRef.current);

    return () => {
      clearTimeout(timer);

      if (subsectionHeaderRef.current) {
        observerRef.current.unobserve(subsectionHeaderRef.current);
      }
    };
  }, []);
}