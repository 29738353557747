import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import AlgorithmMealPlanApi from 'services/Core/Algorithm/MealPlanService/AlgorithmMealPlanApi';

export default function useChangeSupermarket() {
  function changeSupermarket(supermarket: SupportedSupermarket): Promise<any> {
    const algorithmMealPlan = new AlgorithmMealPlanApi();

    return algorithmMealPlan.changeSupermarket(supermarket);
  }

  return changeSupermarket;
}
