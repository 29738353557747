import { CoreItemEndpoints } from 'services/Core/ItemService/Config';
import CoreApi from 'services/Core/CoreApi';

export default class ItemApi extends CoreApi {
  constructor() {
    super(CoreItemEndpoints);
  }

  searchForItem(query: string) {
    const response = super.request(
      this.apiEndpoints.searchForItem(query)
    );

    return this.respondWithJson(response);
  }

  searchSuggestions(query: string) {
    const response = super.request(
      this.apiEndpoints.searchSuggestions(query)
    );

    return this.respondWithJson(response);
  }

  findItemById(supermarketItemId: number) {
    const response = super.request(
      this.apiEndpoints.findItemById(supermarketItemId)
    );

    return this.respondWithJson(response);
  }
}
