import React from 'react'
import Lottie from 'react-lottie';

import animationData from 'assets/animations/ask_mealia_sparkle.json';

export default function SparkleAnimation() {
  const defaultOptions = {
    loop:          true,
    autoplay:      true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      options = { defaultOptions }
      height  = { 21 }
      width   = { 22 }
      style   = {{
        'margin': 0
      }}
    />
  )
}
