import React, { useContext } from 'react'
import MediaQuery from 'react-responsive';
import { motion } from 'framer-motion'

import './Navigation.scss'
import TopNavBar from 'components/Navigation/TopNavBar'
import Loading from 'components/Loading';
import Alert from 'components/Alert';
import PageTooSmallFallback from 'components/base/Fallback/PageTooSmallFallback';

import { Breakpoints } from 'utils/Breakpoints';
import LoadingContext from 'context/LoadingContext';
import FooterNavBar from 'components/Navigation/FooterNavBar';

type Props = {
  children?: React.ReactNode
}

export default function Navigation({
  children
}: Props) {
  const { loading } = useContext(LoadingContext);

  return (
    <>
      <TopNavBar />
      
      <MediaQuery
        minWidth = { Breakpoints.small.min }
        maxWidth = { Breakpoints.small.max }
      >
        <PageTooSmallFallback />
      </MediaQuery>

      <MediaQuery
        minWidth = { Breakpoints.desktop.min }
        maxWidth = { Breakpoints.wideDesktop.max }
      >
        { loading && <Loading /> }

        <Alert />

        <motion.div
          className  = 'content-body'
          initial    = {{ opacity: 0 }}
          animate    = {{ opacity: 1 }}
          exit       = {{ opacity: 0 }}
          transition = {{
            delay:    0.1,
            duration: 0.3
          }}
        >
          { children }
        </motion.div>

        <FooterNavBar />
      </MediaQuery>
    </>
  )
}
