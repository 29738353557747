import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import { GenerateNewMealsPage } from 'enums/GenerateNewMealsPage';
import { RouteName } from 'router/Config';

export default function useHandlePreviousButtonClick(
  page:               GenerateNewMealsPage,
  setPage:            React.Dispatch<React.SetStateAction<GenerateNewMealsPage>>,
  userPreferences:    IUserPreferences,
  setUserPreferences: React.Dispatch<React.SetStateAction<IUserPreferences>>,
  navigateTo:         (routeName: RouteName) => void
) {
  const handlePreviousButtonClick = () => {
    if (page === GenerateNewMealsPage.NumberOfPeople) {
      navigateTo(RouteName.MyMeals)

      return;
    }

    if (page === GenerateNewMealsPage.DietaryPreferences && userPreferences.dietaryPreferencesPresent) {
      setUserPreferences({
        ...userPreferences,
        dietaryPreferencesPresent: null,
        dietaryPreferences: null
      })
    } else if (page === GenerateNewMealsPage.Allergies && userPreferences.allergiesPresent) {
      setUserPreferences({
        ...userPreferences,
        allergiesPresent: null,
        allergies: []
      })
    } else {
      setPage(prevPage => prevPage - 1);
    }    
  }

  return handlePreviousButtonClick;
}
