import { useEffect } from 'react';

import ItemApi from 'services/Core/ItemService/ItemApi';

export default function useHandleSuggestionSearch(
  searchValue:    string,
  setSuggestions: (suggestions: string[]) => void
) {
  useEffect(() => {
    if (searchValue.trim().length === 0) return;
  
    const itemApi = new ItemApi();
  
    itemApi.searchSuggestions(searchValue).then((response) => {
      setSuggestions(response);
    })
  }, [searchValue]);
}
