import { OnboardingPage } from 'enums/OnboardingPage';
import IUserPreferences from 'interfaces/frontend/IUserPreferences';

import useDisabledPrimaryButton from '../Operations/Computed/useDisabledPrimaryButton';
import useBotMessage from '../Operations/Computed/useBotMessage';
import { DashboardTextType } from 'types/DashboardTextType';

export default function useComputed(
  onboardingPage:             OnboardingPage,
  userPreferences:            IUserPreferences,
  introductionMessages:       DashboardTextType[],
  numberOfPeopleMessages:     DashboardTextType[],
  numberOfMealsMessages:      DashboardTextType[],
  budgetMessages:             DashboardTextType[],
  dietaryPreferencesMessages: DashboardTextType[],
  allergiesMessages:          DashboardTextType[],
  kitchenEquipmentMessages:   DashboardTextType[],
  grocerMessages:             DashboardTextType[],
  endingMessages:             DashboardTextType[]
) {
  const disabledPrimaryButton = useDisabledPrimaryButton(onboardingPage, userPreferences);
  const botMessage = useBotMessage(onboardingPage, introductionMessages, numberOfPeopleMessages, numberOfMealsMessages, budgetMessages, dietaryPreferencesMessages, allergiesMessages, kitchenEquipmentMessages, grocerMessages, endingMessages);

  return {
    disabledPrimaryButton,
    botMessage
  }
}
