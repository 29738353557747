import React from 'react'

import './FullControlAlgorithmRecipeCard.scss'
import RootAlgorithmRecipeCard from '../_RootAlgorithmRecipeCard'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'

type Props = {
  algorithmRecipe: AlgorithmRecipe
  ordinalNumber:   string
  onCardClick:     (algorithmRecipe: AlgorithmRecipe, e: React.MouseEvent<HTMLElement>) => void
  onSwapClick:     (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function FullControlAlgorithmRecipeCard({
  algorithmRecipe,
  ordinalNumber,
  onCardClick,
  onSwapClick
}: Props) {
  return (
    <RootAlgorithmRecipeCard 
      algorithmRecipe = { algorithmRecipe }
      ordinalNumber   = { ordinalNumber }
      onCardClick     = { onCardClick }
      buttonChildren  = {
        // TODO: Move to Button Component
        <button
          onClick   = { (e) => onSwapClick(e) }
          className = 'swap-button'
        >
          <P14
            text   = 'Remove'
            colour = 'white-1'
          />
        </button>
      }
    />
  )
}
