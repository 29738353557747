import React from 'react'
import { Navigate } from 'react-router-dom'

import Login           from 'pages/PreLogin/Login'
import Register        from 'pages/PreLogin/Register'
import ForgotPassword  from 'pages/PreLogin/ForgotPassword'
import ResetPassword   from 'pages/PreLogin/ResetPassword'
import Confirmation    from 'pages/PreLogin/Confirmation'

import Onboarding        from 'pages/PostLogin/00-Onboarding/Root'
import MyMeals           from 'pages/PostLogin/01-MyMeals'
import GenerateNewMeals  from 'pages/PostLogin/02-GenerateNewMeals'
import CheckedOut        from 'pages/PostLogin/03-CheckedOut'
import WelcomeBack       from 'pages/PostLogin/03-WelcomeBack'
import Recipe            from 'pages/PostLogin/05-Recipe'
import Groceries         from 'pages/PostLogin/06-Groceries'
import Search            from 'pages/PostLogin/07-Search'
import MyOrders          from 'pages/PostLogin/08-MyOrders'
import SavedPlan         from 'pages/PostLogin/09-SavedPlan'
import SavedRecipe       from 'pages/PostLogin/10-SavedRecipe'
import Basket            from 'pages/PostLogin/11-Basket'
import DownloadExtension from 'pages/PostLogin/13-DownloadExtension'
import Settings          from 'pages/PostLogin/14-Settings/Root'

import { Route } from 'types/Route'

export enum RouteName {
  // PreLogin
  Root           = 'Root',
  Login          = 'Login',
  Register       = 'Register',
  ForgotPassword = 'ForgotPassword',
  ResetPassword  = 'ResetPassword',
  Confirmation   = 'Confirmation',

  // PostLogin
  Onboarding        = 'Onboarding',
  MyMeals           = 'MyMeals',
  CheckedOut        = 'CheckedOut',
  WelcomeBack       = 'WelcomeBack',
  GenerateNewMeals  = 'GenerateNewMeals',
  Recipe            = 'Recipe',
  Groceries         = 'Groceries',
  Search            = 'Search',
  MyOrders          = 'MyOrders',
  SavedPlan         = 'SavedPlan',
  SavedRecipe       = 'SavedRecipe',
  Basket            = 'Basket',
  DownloadExtension = 'DownloadExtension',
  Settings          = 'Settings',
}

export const AllRoutes: Route[] = [
  {
    path:    '/',
    name:    RouteName.Root,
    private: false,
    element: <Navigate to = '/login' />
  },
  {
    path:    '/login',
    name:    RouteName.Login,
    private: false,
    element: <Login />
  },
  {
    path:    '/register',
    name:    RouteName.Register,
    private: false,
    element: <Register />
  },
  {
    path:    '/forgot-password',
    name:    RouteName.ForgotPassword,
    private: false,
    element: <ForgotPassword />
  },
  {
    path:    '/reset-password',
    name:    RouteName.ResetPassword,
    private: false,
    element: <ResetPassword />
  },
  {
    path:    '/confirmation',
    name:    RouteName.Confirmation,
    private: false,
    element: <Confirmation />
  },
  {
    path:    '/onboarding',
    name:    RouteName.Onboarding,
    private: true,
    element: <Onboarding />
  },
  {
    path:   '/my-meals',
    name:    RouteName.MyMeals,
    private: true,
    element: <MyMeals />
  },
  {
    path:   '/checked-out',
    name:    RouteName.CheckedOut,
    private: true,
    element: <CheckedOut />
  },
  {
    path:   '/welcome-back',
    name:    RouteName.WelcomeBack,
    private: true,
    element: <WelcomeBack />
  },
  {
    path:   '/generate-new-meals',
    name:    RouteName.GenerateNewMeals,
    private: true,
    element: <GenerateNewMeals />
  },
  {
    path:    '/recipe',
    name:    RouteName.Recipe,
    private: true,
    element: <Recipe />
  },
  {
    path:    '/groceries',
    name:    RouteName.Groceries,
    private: true,
    element: <Groceries />
  },
  {
    path:    '/search',
    name:    RouteName.Search,
    private: true,
    element: <Search />
  },
  {
    path:   '/my-orders',
    name:    RouteName.MyOrders,
    private: true,
    element: <MyOrders />
  },
  {
    path:   '/saved-plan',
    name:    RouteName.SavedPlan,
    private: true,
    element: <SavedPlan />
  },
  {
    path:    '/basket',
    name:    RouteName.Basket,
    private: true,
    element: <Basket />
  },
  {
    path:    '/saved-recipe',
    name:    RouteName.SavedRecipe,
    private: true,
    element: <SavedRecipe />
  },
  {
    path:   '/download-extension',
    name:    RouteName.DownloadExtension,
    private: true,
    element: <DownloadExtension />
  },
  {
    path:   '/settings',
    name:    RouteName.Settings,
    private: true,
    element: <Settings />
  }
]

// Routes
export const PrivateRoutes = AllRoutes.filter(route => route.private);
