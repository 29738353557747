import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import { RecipeModalType } from 'enums/Modals/RecipeModalType';

export default function useHandleTrashClick(
  openModal:                      (modalType: RecipeModalType) => void,
  setSelectedAlgorithmTopItem:    (item: IAlgorithmItem | null) => void,
  setSelectedAlgorithmBottomItem: (item: IAlgorithmItem | null) => void
) {
  const handleTrashClick = (
    algorithmItem: IAlgorithmItem,
    e:             React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();

    if (algorithmItem.recipeIngredient.cupboardItem) {
      setSelectedAlgorithmBottomItem(algorithmItem);
    } else {
      setSelectedAlgorithmTopItem(algorithmItem);
    }

    openModal(RecipeModalType.DeleteItem);
  }

  return handleTrashClick;
}
