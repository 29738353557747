import useAddAlgorithmRecipe from 'utils/Hooks/Actions/Algorithm/MealPlan/useAddAlgorithmRecipe'
import useBasketCheckedOut from 'utils/Hooks/Helper/useBasketCheckedOut';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useFilter from 'utils/Hooks/Actions/Algorithm/MealPlan/useFilter';

export default function useHooks() {
  const addAlgorithmRecipe = useAddAlgorithmRecipe();
  const filter = useFilter();
  const basketCheckedOut = useBasketCheckedOut();
  const navigateTo = useNavigateTo();
  
  return {
    addAlgorithmRecipe,
    filter,
    basketCheckedOut,
    navigateTo
  };
}
