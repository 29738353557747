import { ItemMapping } from 'types/Mappings/ItemMapping';
import useItemDetailsApi from 'utils/Hooks/Api/Algorithm/Basket/useItemDetails';

export default function useItemDetails() {
  const itemDetailsApi = useItemDetailsApi();

  const itemDetails = (
    setItemMapping: (itemMapping: ItemMapping[]) => void
  ): Promise<any> => {
    return itemDetailsApi().then((response: any) => {
      const data: ItemMapping[] = response;

      setItemMapping(data);
    });
  }

  return itemDetails;
}