import React from 'react'

import { HouseholdConstants } from 'utils/Constants'
import OnboardingPills from 'components/Inputs/OnboardingPills'

type Props = {
  showYesNoButtons:  boolean
  selectedOption?:   string
  handleYesNoClick?: (answer: string) => void
  selectedOptions:   string[]
  handleOptionClick: (option: string) => void
}

export default function DietaryPreference({
  showYesNoButtons,
  selectedOption,
  handleYesNoClick,
  selectedOptions,
  handleOptionClick
}: Props) {
  return (
    <>
      {
        showYesNoButtons && selectedOption !== HouseholdConstants.YesNoOptions.Yes && <OnboardingPills
          answerOptions     = { Object.values(HouseholdConstants.YesNoOptions) }
          selectedOptions   = { [ selectedOption ] }
          applyPadding      = { true }
          handleOptionClick = { handleYesNoClick }
        />
      }

      {
        (selectedOption === HouseholdConstants.YesNoOptions.Yes || !showYesNoButtons) && <OnboardingPills
          answerOptions     = { HouseholdConstants.DietaryPreferences }
          selectedOptions   = { selectedOptions }
          applyPadding      = { true }
          handleOptionClick = { handleOptionClick }
        />
      }
    </>
  )
}
