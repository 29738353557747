import User from 'models/User';
import { MyMealsFilterType } from 'enums/MyMealsFilterType';
import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import { EnergyCost } from 'enums/EnergyCost';
import { CategoryType } from 'enums/CategoryType';

export default function useHandleFilterContinue(
  user:            User,
  setOtherRecipes: (otherRecipes: AlgorithmRecipe[]) => void,
  filter:          (numberOfAdults: number, numberOfChildren: number, allergies: string[], dietaryPreferences: string[], kitchenEquipment: string[], energyCost: EnergyCost, categories: CategoryType[]) => Promise<any>,
  closeModal:      () => void,
) {
  const handleFilterContinue = (userPreferences: IUserPreferences, filterType: MyMealsFilterType) => {
    if (!shouldUpdateUser(userPreferences, filterType)) {
      closeModal();

      return;
    }

    filter(
      userPreferences.numberOfAdults,
      userPreferences.numberOfChildren,
      userPreferences.allergies,
      userPreferences.dietaryPreferences ? [userPreferences.dietaryPreferences] : [],
      userPreferences.kitchenEquipment,
      user.household.energyCost,
      user.household.categories
    ).then((response) => {
      const algorithmRecipes = response.data.map(data => new AlgorithmRecipe(data));

      setOtherRecipes(algorithmRecipes);

      closeModal();
    })
    .catch((err: any) => {
      console.log(err);
    });
  }

  const shouldUpdateUser = (userPreferences: IUserPreferences, filterType: MyMealsFilterType) => {
    switch (filterType) {
      case MyMealsFilterType.Allergies:
        return areArraysEqualRegardlessOfOrder(user.household.allergies, userPreferences.allergies);
      case MyMealsFilterType.Diet:
        const isHouseholdDietaryPreferenceEmpty = user.household.dietaryPreferences[0] === undefined || user.household.dietaryPreferences[0] === null;
        const isUserPreferenceDietaryEmpty = userPreferences.dietaryPreferences === null;

        if (isHouseholdDietaryPreferenceEmpty && isUserPreferenceDietaryEmpty) {
          return false;
        } else if (user.household.dietaryPreferences[0] !== userPreferences.dietaryPreferences) {
          return true;
        }
        
        return false;
      case MyMealsFilterType.KitchenEquipment:
        return areArraysEqualRegardlessOfOrder(user.household.kitchenEquipment, userPreferences.kitchenEquipment);
      case MyMealsFilterType.NumberOfPeople:
        const diffNumberOfAdults   = user.household.numberOfAdults   !== userPreferences.numberOfAdults;
        const diffNumberOfChildren = user.household.numberOfChildren !== userPreferences.numberOfChildren;

        if (diffNumberOfAdults || diffNumberOfChildren) return true;

        return false;
      default:
        throw new Error('Invalid filter type');
    }
  }

  const areArraysEqualRegardlessOfOrder = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return true;
  
    const sortedArr1 = [...arr1].sort().toString();
    const sortedArr2 = [...arr2].sort().toString();
  
    return sortedArr1 !== sortedArr2;
  };

  return handleFilterContinue;
}
