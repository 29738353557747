import React from 'react'

import './RecipeInfoCard.scss'

export default function RecipeInfoCard({
  children
}) {
  return (
    <div className = 'recipe-info-card'>
      { children }

      <div className = 'recipe-info-card-border' />
    </div>
  )
}