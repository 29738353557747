import IItem from 'interfaces/backend/IItem';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useHandleAddAdditionalItem(
  algorithmRecipe:         AlgorithmRecipe,
  setAlgorithmRecipe:      (algorithmRecipe: AlgorithmRecipe) => void,
  setTopItemGridType:      (topItemGridType: 'recipeItems' | 'swappingItems' | 'additionalItems') => void,
  closeModal:              () => void,
  incrementAdditionalItem: (
    algorithmRecipeId:  number, 
    supermarketItemId:  number,
    setAlgorithmRecipe: (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal?:        () => void
  ) => void
) {
  const handleAddAdditionalItem = (item: IItem) => {
    incrementAdditionalItem(algorithmRecipe.id, item.supermarketItemId, setAlgorithmRecipe, closeModal);

    setTopItemGridType('recipeItems');
  }

  return handleAddAdditionalItem;
}
