import AlgorithmItemApi from 'services/Core/Algorithm/ItemService/AlgorithmItemApi';

export default function useAddToBasket() {
  function addToBasket(algorithmChosenItemId: number): Promise<any> {
    const algorithmItemApi = new AlgorithmItemApi();

    return algorithmItemApi.addToBasket(algorithmChosenItemId);
  }

  return addToBasket;
}
