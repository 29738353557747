import React, { useState } from 'react'

import './DropDownCard.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  suggestions:       string[]
  onSuggestionClick: (suggestion: string) => void
}

export default function DropDownCard({
  suggestions,
  onSuggestionClick
}: Props) {
  return (
    <div
      className = 'drop-down-card'
    >
      {
        suggestions.map((suggestion, index) => (
          <li
            key       = { index }
            className = 'options-container'
          >
            <DropDownItem 
              suggestion        = { suggestion }
              onSuggestionClick = { onSuggestionClick }
            />
          </li>
        ))
      }
    </div>
  )
}

function DropDownItem({
  suggestion,
  onSuggestionClick,
}) {
  const [isHoveredOver, setIsHoveredOver] = useState(false)

  return (
    <button
      className    = 'option'
      onClick      = { () => onSuggestionClick(suggestion) }
      onMouseEnter = { () => setIsHoveredOver(true) }
      onMouseLeave = { () => setIsHoveredOver(false) }
    >
      <P14
        text   = { suggestion }
        colour = { isHoveredOver ? 'pink-1' : 'black-1' }
      />
    </button>
  )
}