import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreAlgorithmBasketEndpoints: BaseApiEndpoint = {
  show: () => ({
    url:    'algorithms/basket/show',
    method: 'GET',
    auth:   true
  }),
  itemDetails: () => ({
    url:    'algorithms/basket/item_details',
    method: 'GET',
    auth:   true
  }),
  shoppingItemDetails: () => ({
    url:    'algorithms/basket/shopping_item_details',
    method: 'GET',
    auth:   true
  }),
  removeItem: (itemId: number) => ({
    url:    'algorithms/basket/remove_item',
    method: 'DELETE',
    auth:   true,
    body:   {
      item_id: itemId
    }
  }),
  incrementShoppingItem: (supermarketItemId: number) => ({
    url:    'algorithms/basket/increment_shopping_item',
    method: 'POST',
    auth:   true,
    body:   {
      supermarket_item_id: supermarketItemId
    }
  }),
  decrementShoppingItem: (supermarketItemId: number) => ({
    url:    'algorithms/basket/decrement_shopping_item',
    method: 'PUT',
    auth:   true,
    body:   {
      supermarket_item_id: supermarketItemId
    }
  }),
  addCupboardItem: (itemId: number) => ({
    url:    'algorithms/basket/add_cupboard_item',
    method: 'POST',
    auth:   true,
    body:   {
      item_id: itemId
    }
  }),
  checkout: () => ({
    url:    'algorithms/basket/checkout',
    method: 'GET',
    auth:   true
  })
}
