import React from 'react'

import './AddAlgorithmItemCard.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import { ReactComponent as AddIcon } from 'assets/images/icons/recipe-card/add.svg'

type Props = {
  onClick: () => void
}

export default function AddAlgorithmItemCard({
  onClick
}: Props) {
  return (
    <div
      className = 'add-algorithm-item-card-container'
      onClick   = { onClick }
    >
      <div
        className = 'tagline'
      >
        <P14
          text   = 'Add A New Ingredient'
          colour = 'black-1'
        />
      </div>

      <div
        className = 'root-algorithm-item-card'
      >
        <div
          className = {`
            image
            add-image
          `}
        />

        <div
          className = 'card-content'
        >
          <div
            className = 'title-skeleton'
          />

          <div
            className = 'subtitle-skeleton'
          />

          <div
            className = 'card-button'
          >
            <div
              className = 'add-button'
            >
              <AddIcon 
                className = 'add-icon'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
