import { useContext } from 'react';

import useChangeSupermarketApi from 'utils/Hooks/Api/Algorithm/MealPlan/useChangeSupermarket';
import LoadingContext from 'context/LoadingContext';
import { SupportedSupermarket } from 'enums/SupportedSupermarket';

export default function useChangeSupermarket() {
  const { setCompleted }: { setCompleted: (completed: boolean) => void } = useContext(LoadingContext);

  const createNewMealPlanApi = useChangeSupermarketApi();

  function createNewMealPlan(supermarket: SupportedSupermarket) {
    createNewMealPlanApi(supermarket).then(() => {
      setCompleted(true);
    })
  }

  return createNewMealPlan;
}
