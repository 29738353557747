import { OnboardingPage } from 'enums/OnboardingPage';
import { DashboardTextType } from 'types/DashboardTextType';

export default function useBotMessage(
  onboardingPage:             OnboardingPage,
  introductionMessages:       DashboardTextType[],
  numberOfPeopleMessages:     DashboardTextType[],
  numberOfMealsMessages:      DashboardTextType[],
  budgetMessages:             DashboardTextType[],
  dietaryPreferencesMessages: DashboardTextType[],
  allergiesMessages:          DashboardTextType[],
  kitchenEquipmentMessages:   DashboardTextType[],
  grocerMessages:             DashboardTextType[],
  endingMessages:             DashboardTextType[]
) {
  const botMessage = () => {
    switch (onboardingPage) {
      case OnboardingPage.Intro:
        return introductionMessages;
      case OnboardingPage.NumberOfPeople:
        return numberOfPeopleMessages;
      case OnboardingPage.NumberOfMeals:
        return numberOfMealsMessages;
      case OnboardingPage.Budget:
        return budgetMessages;
      case OnboardingPage.DietaryPreferences:
        return dietaryPreferencesMessages;
      case OnboardingPage.Allergies:
        return allergiesMessages;
      case OnboardingPage.KitchenEquipment:
        return kitchenEquipmentMessages;
      case OnboardingPage.Grocer:
        return grocerMessages;
      case OnboardingPage.Ending:
        return endingMessages;
    }
  }

  return botMessage
}
