import { RouteName } from 'router/Config';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import { KeyConstants } from 'utils/Constants';

export default function useHandleAddAlgorithmRecipe(
  algorithmRecipe:    AlgorithmRecipe,
  addAlgorithmRecipe: (
    algorithmRecipeId: number,
    e?:                React.MouseEvent<HTMLDivElement, MouseEvent>,
    onSuccess?:        () => void
  ) => void,
  navigateTo:         (routeName: RouteName) => void
) {
  const handleAddAlgorithmRecipe = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    addAlgorithmRecipe(algorithmRecipe.id, e, () => {
      sessionStorage.setItem(KeyConstants.SessionStorage.addedToMealPlan, 'true');
      
      navigateTo(RouteName.MyMeals)
    });
  }

  return handleAddAlgorithmRecipe
}
