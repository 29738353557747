import AlgorithmBasketApi from 'services/Core/Algorithm/BasketService/AlgorithmBasketApi';

export default function useShow() {
  function show(): Promise<any> {
    const algorithmBasketApi = new AlgorithmBasketApi();

    return algorithmBasketApi.show();
  }

  return show;
}
