import React from 'react'
import { IThumbProps } from 'react-range/lib/types';

import './SliderThumb.scss'
import SliderTick from '../SliderTick';

type Props = {
  type:        'money' | 'people'
  orientation: 'vertical' | 'horizontal'
  value: number
  props: IThumbProps
}

export default function SliderThumb({
  type,
  orientation,
  value,
  props
}: Props) {
  return (
    <div
      { ...props }
      className = 'slider-thumb'
    >
      <SliderTick
        type        = { type }
        orientation = { orientation }
        value       = { value }
      />
    </div>
  )
}
