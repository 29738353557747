import React from 'react'

import './CardRecipeImage.scss'
import RootRecipeImage from '../_RootRecipeImage'
import RecipeImagePill from 'components/Pills/RecipeImagePill'
import OrdinalPill from 'components/Pills/OrdinalPill'
import ModifiedIngredientPill from 'components/Pills/ModifiedIngredientPill'
import P14 from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  imageUrl:       string
  text:           string
  modified:       boolean
  ordinalNumber?: string
  isHovered:      boolean
}

export default function CardRecipeImage({
  imageUrl,
  text,
  modified,
  ordinalNumber,
  isHovered
}: Props) {
  return (
    <div
      className = 'card-recipe-image-container'
    >
      <RootRecipeImage
        imageUrl        = { imageUrl }
        overlayChildren = {
          <div
            className = 'card-recipe-image-overlay'
          >
            <div
              className = 'card-recipe-image-overlay-lhs'
            >
              {
                ordinalNumber ?
                <OrdinalPill
                  ordinalNumber = { ordinalNumber }
                />
                :
                <div/>
              }

              {
                modified ?
                <ModifiedIngredientPill />
                :
                <div/>
              }
            </div>

            <RecipeImagePill
              text = { text }
            />

            <div
              className = {`
                card-recipe-image-overlay-centre
                ${isHovered ? 'hovered' : ''}
              `}
            >
              <div
                className = 'card-recipe-image-overlay-centre-inner'
              >
                <P14
                  text   = 'Click to View Recipe'
                  colour = 'white-1'
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}
