import React, { useEffect, useState } from 'react'

import './FilterModal.scss'
import RootModal from '../_RootModal'
import User from 'models/User'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { MyMealsFilterType } from 'enums/MyMealsFilterType'
import IUserPreferences from 'interfaces/frontend/IUserPreferences'
import { initUserPreferences } from 'utils/Hooks/Helper/useUserPreferencesUtils'
import useMultiSelectPill from 'utils/Hooks/Helper/useMultiSelectPill'

import NumberOfPeoplePreference   from 'components/features/UserPreferences/NumberOfPeoplePreference'
import DietaryPreference          from 'components/features/UserPreferences/DietaryPreference'
import AllergiesPreference        from 'components/features/UserPreferences/AllergiesPreference'
import KitchenEquipmentPreference from 'components/features/UserPreferences/KitchenEquipmentPreference'

type Props = {
  user:           User,
  isOpen:         boolean,
  close:          () => void,
  filterType:     MyMealsFilterType,
  handleContinue: (userPreferences: IUserPreferences, filterType: MyMealsFilterType) => void
}

export default function FilterModal({
  user,
  isOpen,
  close,
  filterType,
  handleContinue
}: Props) {
  const [userPreferences, setUserPreferences] = useState<IUserPreferences>(initUserPreferences());

  useEffect(() => {
    if (isOpen) {
      setUserPreferences({
        numberOfAdults:            user.household.numberOfAdults,
        numberOfChildren:          user.household.numberOfChildren,
        numberOfMealsPerWeek:      user.household.numberOfMealsPerWeek,
        dietaryPreferencesPresent: null,
        dietaryPreferences:        user.household.dietaryPreferences[0],
        allergiesPresent:          null,
        allergies:                 user.household.allergies,
        kitchenEquipment:          user.household.kitchenEquipment,
        weeklyBudget:              null,
        chosenSupermarket:         null
      });
    }
  }, [isOpen, user]);

  const handleNumberOfAdultsChange = (value: number) => {
    setUserPreferences({
      ...userPreferences,
      numberOfAdults: value,
    })
  }

  const handleNumberOfChildrenChange = (value: number) => {
    setUserPreferences({
      ...userPreferences,
      numberOfChildren: value,
    })
  }

  const handleDietSelection = (diet: string) => {
    if (diet === userPreferences.dietaryPreferences) {
      diet = null;
    }

    setUserPreferences({
      ...userPreferences,
      dietaryPreferences: diet
    })
  }

  const disabledPrimaryButton = () => {
    if (filterType === MyMealsFilterType.NumberOfPeople) {
      const numberOfAdults = userPreferences.numberOfAdults || 0;
      const numberOfChildren = userPreferences.numberOfChildren || 0;

      return (numberOfAdults + numberOfChildren) < 2;
    }

    return false;
  }

  const [allergiesSelectedOptions, handleAllergiesOptionClick] = useMultiSelectPill('allergies', userPreferences, setUserPreferences)
  const [kitchenEquipmentSelectedOptions, handleKitchenEquipmentOptionClick] = useMultiSelectPill('equipment', userPreferences, setUserPreferences)

  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'small'
    >
      <div
        className = 'filter-modal-container'
      >
        <div
          className = 'filter-modal-options-container'
        >
          {
            filterType === MyMealsFilterType.NumberOfPeople && <NumberOfPeoplePreference
              adultSelectedOptions   = { [ userPreferences.numberOfAdults ] }
              handleAdultOptionClick = { handleNumberOfAdultsChange }
              childSelectedOptions   = { [ userPreferences.numberOfChildren ] }
              handleChildOptionClick = { handleNumberOfChildrenChange }
            />
          }

          {
            filterType === MyMealsFilterType.Diet && <div
              className = 'filter-modal-dietary-preference-container'
            >
              <DietaryPreference
                showYesNoButtons  = { false }
                selectedOptions   = { [ userPreferences.dietaryPreferences ] }
                handleOptionClick = { handleDietSelection}
              />
            </div>
          }

          {
            filterType === MyMealsFilterType.Allergies && <div
              className = 'filter-modal-allergies-container'
            >
              <AllergiesPreference
                showYesNoButtons  = { false }
                selectedOptions   = { allergiesSelectedOptions }
                handleOptionClick = { handleAllergiesOptionClick }
              />
            </div>
          }

          {
            filterType === MyMealsFilterType.KitchenEquipment && <KitchenEquipmentPreference
              selectedOptions   = { kitchenEquipmentSelectedOptions }
              handleOptionClick = { handleKitchenEquipmentOptionClick }
            />
          }
        </div>

        <PrimaryButton
          text           = 'Continue'
          disabled       = { disabledPrimaryButton() }
          onClickHandler = { () => handleContinue(userPreferences, filterType) }
        />
      </div>
    </RootModal>
  )
}
