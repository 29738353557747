import React from 'react'

import RootAlgorithmRecipeCard from '../_RootAlgorithmRecipeCard'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'

type Props = {
  algorithmRecipe: AlgorithmRecipe
  ordinalNumber:   string
  onCardClick:     (algorithmRecipe: AlgorithmRecipe, e: React.MouseEvent<HTMLElement>) => void
}

export default function CountedAlgorithmRecipeCard({
  algorithmRecipe,
  ordinalNumber,
  onCardClick,
}: Props) {
  return (
    <RootAlgorithmRecipeCard 
      algorithmRecipe = { algorithmRecipe }
      ordinalNumber   = { ordinalNumber }
      onCardClick     = { onCardClick }
    />
  )
}
