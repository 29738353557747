import React from 'react'

import './Root.scss'
import Navigation          from 'pages/PostLogin/Shared/Navigation'
import RedButton           from 'components/Buttons/RedButton';
import P14Medium           from 'components/Text/Paragraphs/Medium/P14';
import PersonalInformation from '../Cards/01-PersonalInformation';
import SettingsDashboardCard from 'components/Cards/Dashboard/SettingsDashboardCard';
import TitleText from 'components/Text/TitleText';

import useSettings from 'utils/Hooks/Pages/PostLogin/useSettings';

export default function Root() {
  const [
    firstName,
    setFirstName,
    email,
    setEmail,
    handleLogout
  ] = useSettings();

  return (
    <>
      <Navigation>
        <div 
          className = 'settings-container'
        >
          <SettingsDashboardCard />

          <span
            style = {{ height: '8px' }}
          />

          <div
            className = 'settings-title'
          >
            <TitleText
              text = 'General Settings'
            >
              <RedButton
                text           = 'Log Out'
                onClickHandler = { handleLogout }
              />
            </TitleText>

            <P14Medium
              text = 'Update personal details here. Every change is automatically saved'
            />
          </div>

          <span />

          <PersonalInformation
            firstName    = { firstName }
            email        = { email }
            setFirstName = { setFirstName }
            setEmail     = { setEmail }
          />
        </div>
      </Navigation>
    </>
  )
}
