import React from 'react'

import './Recipe.scss'
import Navigation                  from '../Shared/Navigation';
import { AlgorithmItemGridCard }   from 'enums/Grids/AlgorithmItemGridCard'
import RecipeStatsCard             from 'components/Cards/RecipeStatsCard'
import RecipeDetailsCard           from 'components/Cards/RecipeDetailsCard'
import RecipeDashboardCard         from 'components/Cards/Dashboard/RecipeDashboardCard';
import PageRecipeImage             from 'components/Images/RecipeImage/PageRecipeImage';
import AlgorithmItemCardGrid       from 'components/Grids/AlgorithmItemCardGrid';
import ShoppingItemModal           from 'components/Modals/ShoppingItemModal';
import DeleteItemModal             from 'components/Modals/DeleteItemModal';
import RecipeImageModal            from 'components/Modals/RecipeImageModal';
import FreshItemModal              from 'components/Modals/FreshItemModal';
import CupboardItemModal           from 'components/Modals/CupboardItemModal';
import NutritionalInformationModal from 'components/Modals/NutritionalInformationModal';
import DisclaimerModal              from 'components/Modals/DisclaimerModal';

import { RecipeModalType } from 'enums/Modals/RecipeModalType';
import Instructions from 'components/features/Instructions';
import TitleText from 'components/Text/TitleText';

import useRecipe from 'hooks/Pages/PostLogin/05-Recipe/useRecipe';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import AdditionalItemAdder from 'components/features/Interactives/AdditionalItemAdder';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import ItemSwapper from 'components/features/Interactives/ItemSwapper';
import NoAlternativesModal from 'components/Modals/NoAlternativesModal';

export default function Recipe() {
  const {
    algorithmRecipe,
    isLoading,
    activeModal,
    closeModal,
    freshItems,
    cupboardItems,
    recipeImageText,
    handleSwapClick,
    handleTrashClick,
    handleNutritionalInfoClick,
    handleNutritionalInfoDisclaimerClick,
    handleAddDifferentItemClick,
    handleRecipeImageClick,
    handleAddItemClick,
    handleAddAdditionalItem,
    handleDecreaseAdditionalItem,
    handleAlgorithmItemClick,
    handleRecipeStepImageClick,
    handleRecipeDisclaimerClick,
    handleAddAlgorithmRecipe,
    handleAddCupboardItem,
    handleRemoveAlgorithmItem,
    handleSubstituteTopItem,
    selectedAlgorithmTopItem,
    handleSubstituteCupboardItem,
    selectedAlgorithmBottomItem,
    selectedShoppingItem,
    selectedRecipeStepItem,
    disclaimer,
    topItemGridType,
    bottomItemGridType,
    recipeInMealPlan,
    topSubsectionHeader,
    bottomSubsectionHeader,
    handleCancelTopItemSwap,
    handleCancelCupboardItemSwap
   } = useRecipe();

  if (!algorithmRecipe || isLoading) {
    return <Navigation />
  }

  return (
    <>
      <Navigation>
        <div className = 'recipe-container-desktop'>
          <RecipeDashboardCard
            algorithmRecipe = { algorithmRecipe }
          />

          <span
            style = {{ marginTop: '8px'}}
          />

          <div
            className = 'recipe-top-content'
          >
            <PageRecipeImage
              imageUrl     = { algorithmRecipe.recipe.imageUrl }
              onInfoClick  = { handleRecipeDisclaimerClick }
              onImageClick = { handleRecipeImageClick }
              text         = { recipeImageText }
            />

            <div className = 'details'>
              <div
                className = 'lhs'
              >
                <RecipeDetailsCard
                  algorithmRecipe = { algorithmRecipe }
                  onClick         = { handleNutritionalInfoClick }
                />
              </div>

              <div
                className = 'rhs'
              >
                <RecipeStatsCard
                  algorithmRecipe  = { algorithmRecipe }
                  recipeInMealPlan = { recipeInMealPlan }
                />
              </div>
            </div>
          </div>

          {
            !recipeInMealPlan && <PrimaryButton
              text           = { 'Add this Meal to Your Plan' }
              onClickHandler = { handleAddAlgorithmRecipe }
            />
          }

          <span
            style = {{ marginTop: '40px'}}
          />

          <TitleText
            text  = { topSubsectionHeader() }
            id    = 'top-subsection-header'
            title = 'h2'
          >
            {
              topItemGridType === 'swappingItems' && <div
                className = 'swapping-items-buttons'
              >
                <SecondaryButton
                  text    = 'Look for Another Ingredient'
                  icon    = 'search'
                  onClick = { handleAddDifferentItemClick }
                />

                <SecondaryButton
                  text   = 'Cancel Swap'
                  onClick = { handleCancelTopItemSwap }
                />
              </div>
            }

            {
              topItemGridType === 'additionalItems' && <SecondaryButton
                text   = 'Cancel'
                onClick = { handleCancelTopItemSwap }
              />
            }
            
          </TitleText>

          {
            topItemGridType === 'recipeItems' && <AlgorithmItemCardGrid
              algorithmItems             = { freshItems() }
              additionalItems            = { algorithmRecipe.additionalItems }
              algorithmItemGridCard      = { AlgorithmItemGridCard.Replaceable }
              onCardClick                = { handleAlgorithmItemClick }
              onSubstitutionClickHandler = { handleSwapClick }
              onTrashClickHandler        = { handleTrashClick }
              onAddToBasketClickHandler  = { handleAddCupboardItem }
              onIncrementAdditionalItem  = { handleAddAdditionalItem }
              onDecrementAdditionalItem  = { handleDecreaseAdditionalItem }
              onAddCardClickHandler      = { handleAddItemClick }
            />
          }

          {
            topItemGridType === 'swappingItems' && <ItemSwapper
              algorithmItem      = { selectedAlgorithmTopItem }
              handleSubstitution = { handleSubstituteTopItem }
            />
          }

          {
            topItemGridType === 'additionalItems' && <AdditionalItemAdder
              onAddToBasketButtonClick = { handleAddAdditionalItem }
            />
          }

          <span
            style={{ marginTop: '40px'}}
          />

          <TitleText
            text  = { bottomSubsectionHeader() }
            id    = 'bottom-subsection-header'
            title = 'h2'
          >
            {
              bottomItemGridType === 'swappingItems' && <SecondaryButton
                text   = 'Cancel'
                onClick = { handleCancelCupboardItemSwap }
              />
            }
          </TitleText>

          {
            bottomItemGridType === 'recipeItems' && <AlgorithmItemCardGrid
              algorithmItems             = { cupboardItems() }
              additionalItems            = { algorithmRecipe.additionalItems }
              algorithmItemGridCard      = { AlgorithmItemGridCard.CupboardItem }
              onCardClick                = { handleAlgorithmItemClick }
              onSubstitutionClickHandler = { handleSwapClick }
              onTrashClickHandler        = { handleTrashClick }
              onAddToBasketClickHandler  = { handleAddCupboardItem }
              onIncrementAdditionalItem  = { handleAddAdditionalItem }
              onDecrementAdditionalItem  = { handleDecreaseAdditionalItem }
              onAddCardClickHandler      = { handleAddItemClick }
            />
          }

          {
            bottomItemGridType === 'swappingItems' && <ItemSwapper
              algorithmItem      = { selectedAlgorithmBottomItem }
              handleSubstitution = { handleSubstituteCupboardItem }
            />
          }          

          <span
            style = {{ marginTop: '40px'}}
          />

          <TitleText
            text  = { 'Instructions' }
            title = 'h2'
          />

          <Instructions
            recipeSteps    = { algorithmRecipe.recipe.steps }
            algorithmItems = { algorithmRecipe.algorithmItems }
            onClick        = { handleRecipeStepImageClick }
          />
        </div>
      </Navigation>

      <ShoppingItemModal
        isOpen       = { activeModal === RecipeModalType.ShoppingItem }
        close        = { closeModal }
        shoppingItem = { selectedShoppingItem }
      />

      <DeleteItemModal
        isOpen            = { activeModal === RecipeModalType.DeleteItem }
        close             = { closeModal }
        algorithmItem     = { selectedAlgorithmTopItem || selectedAlgorithmBottomItem }
        deleteItemHandler = { handleRemoveAlgorithmItem }
      />

      <FreshItemModal
        isOpen        = { activeModal === RecipeModalType.FreshItem }
        close         = { closeModal }
        algorithmItem = { selectedRecipeStepItem }
      />

      <CupboardItemModal
        isOpen        = { activeModal === RecipeModalType.CupboardItem }
        close         = { closeModal }
        algorithmItem = { selectedRecipeStepItem }
      />

      <DisclaimerModal
        isOpen          = { activeModal === RecipeModalType.Disclaimer }
        close           = { closeModal }
        disclaimer      = { disclaimer }
        algorithmRecipe = { algorithmRecipe }
      />

      <RecipeImageModal
        imageUrl = { algorithmRecipe.recipe.imageUrl }
        text     = { recipeImageText }
        isOpen   = { activeModal === RecipeModalType.Image }
        close    = { closeModal }
      />

      <NutritionalInformationModal
        isOpen                 = { activeModal === RecipeModalType.NutritionalInformation }
        close                  = { closeModal }
        nutritionalInformation = { algorithmRecipe.nutritionalInformation }
        onInfoClick            = { handleNutritionalInfoDisclaimerClick }
        allergens              = { algorithmRecipe.allergens }
      />

      <NoAlternativesModal
        isOpen                  = { activeModal === RecipeModalType.NoAlternatives }
        close                   = { closeModal }
        addDifferentIngredient  = { handleAddDifferentItemClick }
      />
    </>
  )
}
