import React from 'react'

import './ReplacingAlgorithmItemCard.scss'
import RootAlgorithmItemCard from '../_RootAlgorithmItemCard'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import PinkCircle from 'components/base/Shapes/PinkCircle'

type Props = {
  algorithmItem: IAlgorithmItem
  onCardClick:   () => void
  highlight?:    boolean
}

export default function ReplacingAlgorithmItemCard({
  algorithmItem,
  onCardClick,
  highlight = false
}: Props) {
  return (
    <RootAlgorithmItemCard
      algorithmItem  = { algorithmItem }
      price          = { algorithmItem.item.price }
      className      = {`
        ${highlight ? 'highlight' : ''}
      `}
      onCardClick    = { onCardClick }
      buttonChildren = {
        <PinkCircle
          number = { algorithmItem.quantityRequired }
        />
      }
    />
  )
}