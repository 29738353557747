import { RecipeModalType } from 'enums/Modals/RecipeModalType';

export default function useHandleNutritionalInfoClick(
  openModal: (modalType: RecipeModalType) => void
) {
  const handleNutritionalInfoClick = () => {
    openModal(RecipeModalType.NutritionalInformation);
  }

  return handleNutritionalInfoClick;
}
