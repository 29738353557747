import { useNavigate } from 'react-router-dom';

import { Route }                from 'types/Route';
import { AllRoutes, RouteName } from 'router/Config';

export default function useNavigateTo() {
  const navigate = useNavigate();

  function navigateTo(routeName: RouteName, params?: any) {
    const route = findRoute(routeName)
    const path = params ? `${route.path}${params}` : route.path

    navigate(path)
  }

  function findRoute(routeName: RouteName): Route {
    return AllRoutes.find(route => route.name === routeName) as Route
  }

  return navigateTo
}
