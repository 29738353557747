import { useContext } from 'react';

import useShow from './useShow';
import useRemoveItemApi from 'utils/Hooks/Api/Algorithm/Basket/useRemoveItem';
import useDetailBasketItems from 'utils/Hooks/Actions/Algorithm/Basket/useDetailBasketItems'
import { ItemMapping } from 'types/Mappings/ItemMapping';
import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';

export default function useRemoveItem() {
  const { setAlert }: { setAlert: (alert: AlertType) => void } = useContext(AlertContext);
  const removeItemApi = useRemoveItemApi();

  const show = useShow();
  const itemDetails = useDetailBasketItems();

  function removeItem(
    itemId:         number,
    setItemMapping: (itemMapping: ItemMapping[]) => void
  ) {
    removeItemApi(itemId).then(() => {
      itemDetails(setItemMapping);

      setAlert(AlertType.RemovedFromBasket);
    }).then(() => {
      show();
    })
  }

  return removeItem;
}
