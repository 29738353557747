import IItem from 'interfaces/backend/IItem';
import { RouteName } from 'router/Config';
import { ItemMapping } from 'types/Mappings/ItemMapping';

import useRedirect from '../Operations/Effects/useRedirect';
import useLoadItems from '../Operations/Effects/useLoadItems';

export default function useEffects(
  basketCheckedOut:    boolean,
  navigateTo:          (path: RouteName) => void,
  setShoppingItems:    (items: IItem[]) => void,
  shoppingItemDetails: (setShoppingItems: (items: IItem[]) => void) => Promise<any>,
  setItemMapping:      (itemMapping: ItemMapping[]) => void,
  itemDetails:         (setItemMapping: (itemMapping: ItemMapping[]) => void) => Promise<any>
) {
  useRedirect(basketCheckedOut, navigateTo);
  useLoadItems(setShoppingItems, shoppingItemDetails, setItemMapping, itemDetails);
}
