import { useState } from 'react';

import { BasketModalType } from 'enums/Modals/BasketModalType';

export default function useModal() {
  const [activeModal, setActiveModal] = useState<BasketModalType>(BasketModalType.None);

  const openModal = (modalType: BasketModalType) => {
    setActiveModal(modalType);
  };

  const closeModal = () => {
    setActiveModal(BasketModalType.None);
  };

  return {
    activeModal,
    openModal,
    closeModal,
  };
}
