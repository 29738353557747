import { useContext } from 'react';

import useRemoveAlgorithmRecipeApi from 'utils/Hooks/Api/Algorithm/MealPlan/useRemoveAlgorithmRecipe';
import { AlertType } from 'enums/AlertType';
import AlertContext from 'context/AlertContext';
import useShow from '../Basket/useShow';
import useShowMealPlan from './useShow'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import useOtherAlgorithmRecipes from './useOtherAlgorithmRecipes';

export default function useRemoveAlgorithmRecipe() {
  const { setAlert } = useContext(AlertContext);

  const removeAlgorithmRecipeApi = useRemoveAlgorithmRecipeApi();
  const showBasket = useShow();
  const showMealPlan = useShowMealPlan()
  const otherAlgorithmRecipes = useOtherAlgorithmRecipes();

  function removeAlgorithmRecipe(
    algorithmRecipeId: number,
    setOtherRecipes:   (otherRecipes: AlgorithmRecipe[]) => void,
    e:                 React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.stopPropagation();

    removeAlgorithmRecipeApi(algorithmRecipeId).then((response) => {
      if (response) {
        setAlert(AlertType.RemovedFromPlan);
      }
    }).then(() => {
      // TODO: This only needs to be done for recipes in the meal plan
      showBasket();
    }).then(() => {
      showMealPlan();
    }).then(() => {
      otherAlgorithmRecipes(setOtherRecipes);
    });
  }

  return removeAlgorithmRecipe;
}
