import React from 'react'

import './NutritionalInformationModal.scss'
import P14 from 'components/Text/Paragraphs/Regular/P14'
import NutritionCard from 'components/Cards/NutritionCard'
import RootModal from '../_RootModal'
import INutritionInformation from 'interfaces/backend/INutritionInformation'
import H3Text from 'components/Text/Headings/H3Text'
import NutritionPill from 'components/Pills/NutritionPill'
import AllergenPill from 'components/Pills/AllergenPill'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

type Props = {
  isOpen:                 boolean
  close:                  () => void
  nutritionalInformation: INutritionInformation
  onInfoClick:            () => void
  allergens:              string[]
}

export default function NutritionalInformationModal({
  isOpen,
  close,
  nutritionalInformation,
  onInfoClick,
  allergens
}: Props) {
  if (nutritionalInformation === null) {
    console.error('Nutritional information is undefined')

    return null
  }

  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Here's a breakdown of this meal's nutritional information.`,
    bold:  false
  }

  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'small'
    >
      <div className = 'nutritional-modal-container'>
        <MealiaBotCard
          textObjects = { [topLineTextObject] }
          closeModal  = { close }
        />

        <div
          className = 'nutritional-content'
        >
          <div
            className = 'nutritional-header'
          >
            <H3Text
              text = 'Each serving contains'
            />

            <P14
              text = '(% of the reference intake)'
            />
          </div>
          
          <div
            className = 'nutritional-grid'
          >
            {Object.entries(nutritionalInformation).map((nutrition) => (
              <div
                key = { nutrition[0] }
              >
                <NutritionCard
                  title                = { nutrition[0] }
                  showPerServing       = { false }
                  perHundredGramsValue = { nutrition[1].value }
                >
                  <NutritionPill
                    percentage = { nutrition[1].percent }
                    nutrient   = { nutrition[0] }
                  />
                </NutritionCard>
              </div>
            ))}
          </div>

          <span />

          <div
            className = 'allergens-container'
          >
            <div
              className = 'nutritional-header'
            >
              <H3Text
                text = 'Allergens'
              />

              <button
                className = 'info-button'
                onClick   = { onInfoClick }
              >
                <P14
                  text   = 'Disclaimer'
                  colour = 'grey-1'
                />
              </button>
            </div>
            
            {
              allergens.length === 0 ?
                <AllergenPill
                  allergen = 'No allergens found in the ingredients'
                />
                :
                <ul
                  className = 'allergens-list'
                >
                  {
                    allergens.map((allergen) => (
                      <li
                        key = { allergen }
                      >
                        <AllergenPill
                          allergen = { allergen }
                        />
                      </li>
                    ))
                  }
                </ul>
            }
          </div>
          
        </div>
      </div>
    </RootModal>
  )
}
