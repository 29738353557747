import UserPasswordApi from 'services/Core/User/PasswordService/UserPasswordApi';

export default function useCreate() {
  function create(email: string): Promise<any> {
    const userConfirmationApi = new UserPasswordApi();

    return userConfirmationApi.create(email);
  }

  return create;
}
