import React, { Component, ErrorInfo, ReactNode } from 'react';

import ErrorPage from 'pages/ErrorPage';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError:   boolean;
  error?:     Error;
  backtrace?: string;
}

// TODO: Update to use RFC
export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError:  false,
    error:     undefined,
    backtrace: undefined
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      backtrace: errorInfo.componentStack || ''
    });

    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage
        error     = { this.state.error }
        backtrace = { this.state.backtrace }
      />
    }

    return this.props.children;
  }
}