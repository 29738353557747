import UserRegistrationApi from 'services/Core/User/RegistrationService/UserRegistrationApi';

export default function useRegister() {
  function register(firstName: string, email: string, password: string): Promise<any> {
    const userRegistrationApi = new UserRegistrationApi();

    return userRegistrationApi.register(firstName, email, password);
  }

  return register;
}
