import React from 'react'

import './PreLoginResponsive.scss'
import H1Text from 'components/Text/Headings/H1Text';
import P14 from 'components/Text/Paragraphs/Regular/P14';

export default function PreLoginResponsive() {
  return (
    <div
      className = 'pre-login-responsive-container'
    >
      <H1Text
        text = { `Mealia doesn't work on mobile just yet.` }
      />

      <P14
        text = { `Hold on! Mealia is currently optimised for desktop and tablet use. If you're already on your tablet, you can try rotating it.` }
      />
    </div>
  )
}
