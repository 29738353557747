import AlgorithmMealPlanApi from 'services/Core/Algorithm/MealPlanService/AlgorithmMealPlanApi';

export default function useContinueMealPlan() {
  function continueMealPlan(maintainSubstitutions: boolean): Promise<any> {
    const algorithmMealPlan = new AlgorithmMealPlanApi();

    return algorithmMealPlan.continueMealPlan(maintainSubstitutions);
  }

  return continueMealPlan;
}
