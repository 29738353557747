import { useState, useRef } from 'react';
import { useSpring } from 'react-spring';

export default function useAnimation() {
  const [isOpen, setIsOpen] = useState(false);

  const contentRef    = useRef<HTMLDivElement | null>(null);
  const prevIsOpenRef = useRef(isOpen);

  const { height } = contentRef.current?.getBoundingClientRect() || { height: 0 };

  const expandProps = useSpring({
    height: isOpen ? height : 0,
    overflow: 'hidden',
    config: { tension: 250, friction: 30 },
    onChange: { isOpen: () => {} } // Forces a re-render when isOpen changes
  });

  if (prevIsOpenRef.current !== isOpen) {
    prevIsOpenRef.current = isOpen;

    expandProps.height.start({
      from: isOpen ? 0 : height,
      to:   isOpen ? height : 0
    });
  }

  const toggleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();

    setIsOpen(!isOpen);
  };

  return [expandProps, contentRef, isOpen, toggleOpen] as const;
}
