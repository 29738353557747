import { CoreHouseholdEndpoints } from 'services/Core/HouseholdService/Config';
import CoreApi from 'services/Core/CoreApi';
import { SupportedSupermarket } from 'enums/SupportedSupermarket';

export default class HouseholdApi extends CoreApi {
  constructor() {
    super(CoreHouseholdEndpoints);
  }

  createHousehold(
    numberOfAdults:       number,
    numberOfChildren:     number,
    numberOfMealsPerWeek: number,
    weeklyBudget:         number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    chosenSupermarket:    SupportedSupermarket
  ) {
    const response = super.request(
      this.apiEndpoints.createHousehold(
        numberOfAdults,
        numberOfChildren,
        numberOfMealsPerWeek,
        weeklyBudget,
        allergies,
        dietaryPreferences,
        kitchenEquipment,
        chosenSupermarket
      )
    );
        
    return this.respondWithJson(response);
  }
}
