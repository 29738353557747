import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import { RecipeModalType } from 'enums/Modals/RecipeModalType';
import { IngredientsUsedInStep } from 'interfaces/backend/IRecipeStep';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useHandleRecipeStepImageClick(
  algorithmRecipe:           AlgorithmRecipe,
  setSelectedRecipeStepItem: (item: IAlgorithmItem) => void,
  openModal:                 (modal: RecipeModalType) => void
) {
  const handleRecipeStepImageClick = (
    ingredient: IngredientsUsedInStep,
    e:          React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();

    const selectedItem = algorithmRecipe.algorithmItems.find((algorithmItem) => algorithmItem.recipeIngredient.name === ingredient.name);
    setSelectedRecipeStepItem(selectedItem)

    if (selectedItem?.recipeIngredient.cupboardItem) {
      openModal(RecipeModalType.CupboardItem);
    } else {
      openModal(RecipeModalType.FreshItem);
    }
  }

  return handleRecipeStepImageClick
}
