import React from 'react'

import './P18.scss'

type Props = {
  text: string,
  colour?: 'black-1' | 'white-1'
}

export default function P18({
  text,
  colour = 'black-1'
}: Props) {
  return (
    <p 
      className = {`
        p18-semi-bold
        ${colour}
      `}
    >
      { text }
    </p>
  )
}