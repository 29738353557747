import { RouteName } from 'router/Config';
import useNavigateTo from '../../../../hooks/navigation/useNavigateTo';
import useConfirmApi from 'utils/Hooks/Api/User/Confirmation/useConfirm'
import { ErrorType } from 'utils/Errors';
import { ParamConstants } from 'utils/Constants';

export default function useConfirm() {
  const navigateTo = useNavigateTo()
  const confirmApi = useConfirmApi();

  function confirm(
    setError:       (error: ErrorType) => void,
    shouldRedirect: boolean = true
  ) {
    const params = new URLSearchParams(window.location.search)
    const confirmationToken = params.get(ParamConstants.ConfirmationToken)

    if (confirmationToken) {
      confirmApi(confirmationToken).then((response) => {
        if (!shouldRedirect) return;

        setTimeout(() => {
          navigateTo(RouteName.Onboarding);
        }, 5000)
      }).catch((error) => {
        setError(error)
      })
    }
  }

  return confirm;
}
