import React, { useState } from 'react'
import MediaQuery from 'react-responsive';

import './Root.scss'

import { Breakpoints } from 'utils/Breakpoints'
import { OnboardingPage } from 'enums/OnboardingPage'
import { ColourConstants } from 'utils/Constants';
import PageTooSmallFallback from 'components/base/Fallback/PageTooSmallFallback';
import UserOnboardingAnswerCard from 'components/Cards/UserOnboardingAnswerCard';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import MealiaBotCard from 'components/Cards/MealiaBotCard';
import { ReactComponent as LeftArrow } from 'assets/images/icon/left-arrow/root.svg'

import NumberOfPeople     from '../Questions/01-NumberOfPeople'
import NumberOfMeals      from '../Questions/02-NumberOfMeals'
import Budget             from '../Questions/03-Budget'
import DietaryPreferences from '../Questions/04-DietaryPreferences'
import Allergies          from '../Questions/05-Allergies'
import KitchenEquipment   from '../Questions/06-KitchenEquipment';
import Grocer             from '../Questions/07-Grocer'
import Ending             from '../Main/Ending'

import useOnboarding    from 'hooks/Pages/PostLogin/00-Onboarding/useOnboarding';

export default function Root() {
   const {
    forceUpdate,
    completed,
    onboardingPage,
    userPreferences,
    setUserPreferences,
    disabledPrimaryButton,
    botMessage,
    handlePreviousButtonClick,
    handleNextButtonClick,
    handleFinishLoading
  } = useOnboarding();

  const [startFadeIn, setStartFadeIn] = useState(false);

  const handleFadeOutComplete = () => {
    if (onboardingPage === OnboardingPage.Ending) {
      setStartFadeIn(true);
    }
  };

  return (
    <>
      <MediaQuery
        minWidth = { Breakpoints.small.min }
        maxWidth = { Breakpoints.small.max }
      >
        <PageTooSmallFallback />
      </MediaQuery>

      <MediaQuery
        minWidth = { Breakpoints.desktop.min }
        maxWidth = { Breakpoints.wideDesktop.max }
      >
        <div
          className = 'first-login-container'
        >
          <div
            className = 'onboarding-carousel-container'
          >
            <MealiaBotCard
              textObjects = { botMessage() }
              startTyping = { forceUpdate }
            />

            {
              onboardingPage !== OnboardingPage.Intro && <div
                className      = { onboardingPage === OnboardingPage.Ending ? 'fade-out' : '' }
                style          = {{
                  display: startFadeIn ? 'none' : 'block'
                }}
                onAnimationEnd = { handleFadeOutComplete }
              >
                <UserOnboardingAnswerCard
                  page            = { onboardingPage }
                  size            = 'small'
                  childComponents = {
                    {
                      [OnboardingPage.NumberOfPeople]: <NumberOfPeople
                        userPreferences    = { userPreferences }
                        setUserPreferences = { setUserPreferences }
                      />,
                      [OnboardingPage.NumberOfMeals]: <NumberOfMeals
                        userPreferences    = { userPreferences }
                        setUserPreferences = { setUserPreferences }
                      />,
                      [OnboardingPage.Budget]: <Budget
                        userPreferences    = { userPreferences }
                        setUserPreferences = { setUserPreferences }
                      />,
                      [OnboardingPage.DietaryPreferences]: <DietaryPreferences
                        userPreferences    = { userPreferences }
                        setUserPreferences = { setUserPreferences }
                      />,
                      [OnboardingPage.Allergies]: <Allergies
                        userPreferences    = { userPreferences }
                        setUserPreferences = { setUserPreferences }
                      />,
                      [OnboardingPage.KitchenEquipment]: <KitchenEquipment
                        userPreferences    = { userPreferences }
                        setUserPreferences = { setUserPreferences }
                      />,
                      [OnboardingPage.Grocer]: <Grocer
                        userPreferences    = { userPreferences }
                        setUserPreferences = { setUserPreferences }
                      />
                    }
                  }
                />
              </div>
            }

            {
              startFadeIn && <div
                className = 'fade-in'
              >
                <Ending
                  onAnimationComplete = { handleFinishLoading }
                  completed           = { completed }
                />
              </div>
            }

            <div
              className = 'onboarding-button-container'
            >
              {
                onboardingPage !== OnboardingPage.Intro && (
                  <button
                    className = {`
                      go-back-container
                      ${onboardingPage === OnboardingPage.Ending ? 'disabled' : ''}
                    `}
                    onClick   = { handlePreviousButtonClick }
                  >
                    <LeftArrow
                      color = { ColourConstants.White[1] }
                    />
                  </button>
                )
              }

              <PrimaryButton
                text           = { onboardingPage === OnboardingPage.Intro ? 'Get Started' : 'Continue' }
                icon           = 'arrow'
                size           = 'fill'
                disabled       = { disabledPrimaryButton() }
                onClickHandler = { handleNextButtonClick }
              />
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  )
}
