import { LocalConstants } from 'utils/Constants';

// TODO: use LocalConstants.PostLogin.Disclaimers
export enum DisclaimerType {
  Recipe = 'Recipe'
}

export function getDisclaimerValue(disclaimerType: DisclaimerType): string | ((recipeName: string, link: string) => string) {
  return LocalConstants.PostLogin.Disclaimers.find(d => d.type === disclaimerType)?.disclaimer;
}
