import React, { useEffect, useRef, useState } from 'react'

import './AdditionalItemAdder.scss'
import SearchBox from 'components/Inputs/SearchBox'
import IItem from 'interfaces/backend/IItem';
import ItemApi from 'services/Core/ItemService/ItemApi';
import RootGrid from 'components/Grids/_RootGrid';
import AddableItemCard from 'components/Cards/ItemCard/AddableItemCard';
import DropDownCard from 'components/Cards/DropDownCard';

type Props = {
  onAddToBasketButtonClick: (item: IItem) => void
}

export default function AdditionalItemAdder({
  onAddToBasketButtonClick
}: Props) {
  const [suggestions, setSuggestions] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [shoppingItems, setShoppingItems] = useState<IItem[]>([])
  const skipNextSuggestionFetch = useRef(false)

  function handleDocumentClick() {
    setSuggestions([])
  }

  const handleSearchSuggestions = (query: string, setSuggestions: any) => {
    if (query.length === 0) { return }
  
    const itemApi = new ItemApi();
  
    itemApi.searchSuggestions(query).then((response) => {
      setSuggestions(response);
    })
  }

  const handleSearch = () => {
    setSearchValue(searchValue)

    if (searchValue.length === 0) { return }

    const itemApi = new ItemApi();

    itemApi.searchForItem(searchValue).then((response) => {
      setShoppingItems(response);
      setSuggestions([]);
    })
  }

  const handleDropDownItemClick = (suggestion: string) => {
    skipNextSuggestionFetch.current = true;

    setSearchValue(suggestion)
    setShoppingItems([])

    if (suggestion.length === 0) { return }

    const itemApi = new ItemApi();

    itemApi.searchForItem(suggestion).then((response) => {
      setSuggestions([]);
      setShoppingItems(response);
    })
  }

  useEffect(() => {
    // Attach the event listener to the document when the dropdown card is mounted
    document.addEventListener('click', handleDocumentClick);
    
    // Detach the event listener from the document when the dropdown card is unmounted
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (skipNextSuggestionFetch.current) {
      skipNextSuggestionFetch.current = false;
      
      return;
    }

    handleSearchSuggestions(searchValue, setSuggestions);
  }, [searchValue]);

  return (
    <div
      className = 'additional-item-adder-container'
    >
      <SearchBox
        placeholder = 'Search ingredients'
        value       = { searchValue }
        setValue    = { setSearchValue }
        onEnter     = { handleSearch }
      />

      {
        suggestions.length > 0 &&  searchValue !== '' &&
        <div
          className = 'additional-item-adder-dropdown-card'
        >
          <DropDownCard
            suggestions       = { suggestions }
            onSuggestionClick = { handleDropDownItemClick }
          />
        </div>
      }

      <div
        className = 'additional-item-adder-results'
      >
        <RootGrid
          numberOfColumns = { 3 }
          gap             = { 20 }
        >
          {
            shoppingItems.map((item: IItem) => (
              <li
                key = { item.id }
              >
                <AddableItemCard
                  item                     = { item }
                  onAddToBasketButtonClick = { (item: IItem) => onAddToBasketButtonClick(item) }
                />
              </li>
            ))
          }
        </RootGrid>
      </div>
    </div>
  )
}
