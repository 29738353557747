import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useShow from 'utils/Hooks/Actions/Algorithm/SavedPlan/useShow'

export default function useHooks() {
  const [algorithmSavedPlan] = useShow();
  const navigateTo           = useNavigateTo();

  return {
    algorithmSavedPlan,
    navigateTo
  }
}
