import React from 'react'
import { DebounceInput } from 'react-debounce-input'

import './SecondaryTextfield.scss'
import { ReactComponent as PersonIcon } from 'assets/images/icons/settings-page/person-icon.svg'
import { ReactComponent as EmailIcon }  from 'assets/images/icons/settings-page/email-icon.svg'
import P16 from 'components/Text/Paragraphs/Medium/P16'

export enum Icons {
  PersonIcon,
  EmailIcon
}

type Props = {
  label: string
  placeholder?: string
  required?: boolean
  type?: 'text' | 'password' | 'email'
  icon?: Icons
  disabled?: boolean
  value: string
  setValue: (value: string) => void
}

export default function SecondaryTextfield({ 
  label,
  placeholder,
  required = true,
  type = 'text',
  icon,
  disabled = false,
  value,
  setValue
}: Props) {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const getIcon = () => {
    switch (icon) {
    case Icons.PersonIcon:
      return <PersonIcon />
    case Icons.EmailIcon:
      return <EmailIcon />
    default:
      return null
    }
  }

  return (
    <div  
      className = 'secondary-textfield-container'
    >
      <div className='label-container'>
        <P16
          text = { label }
        />

        { required && 
          <P16
            text   = '*'
            colour = 'red-1'
          />
        }
      </div>

      <div 
        className = {`
          secondary-textfield-input-container
          ${disabled ? 'secondary-textfield-input-container-disabled' : ''}
        `}
      >
        { getIcon() }

        <DebounceInput 
          className   = 'secondary-textfield-input'
          type        = { type }
          placeholder = { placeholder }
          value       = { value }
          onChange    = { handleChange }
          disabled    = { disabled }

          // DebounceInput Props
          debounceTimeout = { 500 }
        />
      </div>
    </div>
  )
}