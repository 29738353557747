import { useContext } from 'react';

import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import useShowApi from 'utils/Hooks/Api/Algorithm/Basket/useShow';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';

export default function useShow() {
  const showApi = useShowApi();

  const { setAlgorithmBasket }: {
    setAlgorithmBasket: (algorithmBasket: AlgorithmBasket) => void 
  } = useContext(AlgorithmBasketContext)

  function show() {
    showApi().then((response) => {
      const algorithmBasket = new AlgorithmBasket(response.data);
      
      setAlgorithmBasket(algorithmBasket);
    })
  }

  return show;
}