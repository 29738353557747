import { useContext } from 'react';

import UserContext from 'context/UserContext';
import useUpdateApi from 'utils/Hooks/Api/User/Registration/useUpdate';
import User from 'models/User';

export default function useUpate() {
  const { setUser }: { setUser: (user: User) => void } = useContext(UserContext);
  const updateApi = useUpdateApi();

  function update(firstName: string) {
    updateApi(firstName).then((response) => {
      const user: User = User.fromApiResponse(response.data);

      setUser(user);
    });
  }

  return update;
}
