import React from 'react'

import './PreLoginCard.scss'
import H1Text from 'components/Text/Headings/H1Text'
import P14 from 'components/Text/Paragraphs/Regular/P14'
import BetaPill from 'components/Pills/BetaPill'

type Props = {
  title: string
  subtitle: string
  showBetaPill?: boolean
  children?: React.ReactNode
}

export default function PreLoginCard({
  title,
  subtitle,
  showBetaPill = false,
  children,
}: Props) {
  return (
    <div
      className = 'pre-login-card'
    >
      <div
        className = 'title-and-subtitle-container'
      >
        <div
          className = 'title-and-beta-container'
        >
          <H1Text
            text = { title }
          />

          {
            showBetaPill && <BetaPill />
          }
        </div>

        <P14
          text = { subtitle }
        />
      </div>

      { children }
    </div>
  )
}