import React from 'react'

import { OnboardingQuestionProps } from '../Shared/OnboardingQuestionProps'
import { SupportedSupermarket } from 'enums/SupportedSupermarket'
import GrocerPreference from 'components/features/UserPreferences/GrocerPreference'

export default function Grocer({
  userPreferences,
  setUserPreferences
}: OnboardingQuestionProps) {
  const handleSupermarketSelection = (supermarket: SupportedSupermarket) => {
    setUserPreferences({
      ...userPreferences,
      chosenSupermarket: supermarket
    })
  }

  return (
    <GrocerPreference
      chosenSupermarket          = { userPreferences.chosenSupermarket }
      handleSupermarketSelection = { handleSupermarketSelection }
    />
  )
}
