import React from 'react'

import './BetaPill.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import { KeyConstants } from 'utils/Constants'

type Props = {
  hide?: boolean
}

export default function BetaPill({
  hide = false
}: Props) {
  return (
    <div
      className = {`
        beta-pill
        ${hide ? ' beta-pill-hide' : '' }
      `}
    >
      <P14
        text   = { `BETA ${KeyConstants.BetaVersion}` }
        colour = 'grey-1'
      />
    </div>
  )
}
