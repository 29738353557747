import React from 'react'

import './P14.scss'

type Props = {
  text: string
  colour?: 'white-1' | 'black-1' | 'pink-1'
}

export default function P14({
  text,
  colour = 'white-1'
}: Props) {
  return (
    <p 
      className = {`
        p14-semi-bold
        ${ colour === 'white-1' ? 'white-1' : '' }
        ${ colour === 'black-1' ? 'black-1' : '' }
        ${ colour === 'pink-1'  ? 'pink-1' : '' }
      `}
    >
      { text }
    </p>
  )
}