import { CoreConfig } from 'services/Core/Config';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

const user_wrapper = `api_${CoreConfig.CORE_API_VERSION}_user`;

export const CoreUserConfirmationEndpoints: BaseApiEndpoint = {
  create: (email: string) => ({
    url:    'users/confirmations/create',
    method: 'POST',
    auth:   false,
    body:   {
      [user_wrapper]: {
        email: email
      }
    }
  }),
  confirm : (emailConfirmationToken: string) => ({
    url:    `users/confirmations/confirm?confirmation_token=${emailConfirmationToken}`,
    method: 'GET',
    auth:   false
  })
}
