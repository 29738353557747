import useCategorisedAlgorithmItems from '../Operations/Computed/useCategorisedAlgorithmItems';
import useRecipeImageText from '../Operations/Computed/useRecipeImageText';
import useRecipeInMealPlan from '../Operations/Computed/useRecipeInMealPlan';
import useSubsectionHeader from '../Operations/Computed/useSubsectionHeader';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import User from 'models/User';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';

export default function useComputed(
  selectedAlgorithmTopItem:    IAlgorithmItem,
  selectedAlgorithmBottomItem: IAlgorithmItem,
  algorithmRecipe:             AlgorithmRecipe,
  user:                        User,
  topItemGridType:             'recipeItems' | 'swappingItems' | 'additionalItems',
  bottomItemGridType:          'recipeItems' | 'swappingItems',
  algorithmMealPlan:           AlgorithmMealPlan
) {
  const { freshItems, cupboardItems } = useCategorisedAlgorithmItems(algorithmRecipe);
  const recipeImageText = useRecipeImageText(algorithmRecipe, user);
  const recipeInMealPlan = useRecipeInMealPlan(algorithmMealPlan, algorithmRecipe)
  const { topSubsectionHeader, bottomSubsectionHeader } = useSubsectionHeader(topItemGridType, bottomItemGridType, selectedAlgorithmTopItem, selectedAlgorithmBottomItem)

  return {
    freshItems,
    cupboardItems,
    recipeImageText,
    recipeInMealPlan,
    topSubsectionHeader,
    bottomSubsectionHeader
  }
}
