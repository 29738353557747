import AlgorithmBasketApi from 'services/Core/Algorithm/BasketService/AlgorithmBasketApi';

export default function useRemoveItem() {
  function removeItem(itemId: number): Promise<any> {
    const algorithmBasketApi = new AlgorithmBasketApi();

    return algorithmBasketApi.removeItem(itemId);
  }

  return removeItem;
}
