// Base API Class
import camelcaseKeys from 'camelcase-keys';

import { KeyConstants, LocalStorageConstants } from 'utils/Constants';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';
import { BaseApiHeaders } from 'services/BaseApiHeaders';
import { getError } from 'utils/Errors';
import { AllRoutes } from 'router/Config';
import { Route } from 'types/Route';
import IError from 'interfaces/backend/IError';

export default class BaseApi {
  apiBaseUrl:   string;
  apiVersion:   string;
  apiHeaders:   BaseApiHeaders;
  apiEndpoints: BaseApiEndpoint;

  public async request(url: any, method: any, auth: boolean, body: any, params: any) {
    url = this.joinURL(url);

    if (params && Object.keys(params).length > 0) {
      const queryString = new URLSearchParams(params).toString();
      url = `${url}?${queryString}`;
    }

    let headers = this.apiHeaders;

    if (auth === true) {
      headers = this.addAuthHeader(headers);
    } else {
      delete headers['Authorization'];
    }

    const options = {
      method,
      headers: headers,
      body:    body ? JSON.stringify(body) : null
    }

    try {
      const response = await fetch(url, options);

      this.storeLastVisit();

      if (!response.ok) {
        const error: IError = await response.json();

        this.redirectToLogin(response);

        return Promise.reject(getError(error.code));
      } else {
        return Promise.resolve(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async respondWithJson(response: any) {
    const completedResponse = await response;
    const data = await completedResponse.json();

    return camelcaseKeys(data, { deep: true });
  }

  private addAuthHeader(headers: BaseApiHeaders) {
    const token = localStorage.getItem(KeyConstants.LocalStorage.authToken);
    headers['Authorization'] = token;

    return headers;
  }

  private joinURL(url: any) {
    return `${this.apiBaseUrl}/${this.apiVersion}/${url}`;
  }

  private redirectToLogin(response: any) {
    // Check if the current route is one of the public routes
    // If it is, then do not redirect to login
    // If it is not, then redirect to login

    const publicRoutes  = AllRoutes.filter((route: Route) => !route.private);
    const currentRoute  = window.location.pathname;
    const isPublicRoute = publicRoutes.some((route: Route) => route.path === currentRoute);

    if (!isPublicRoute) {
      if (response.status === 401) {
        localStorage.removeItem(KeyConstants.LocalStorage.authToken);
        window.location.href = '/login';
      }
    }
  }

  private storeLastVisit = () => {
    const currentTime = new Date().getTime(); // Current time in milliseconds.

    localStorage.setItem(LocalStorageConstants.lastVisit, String(currentTime));
  }
}

