import React, { useState } from 'react'

import './SavedRecipe.scss'
import Navigation from '../Shared/Navigation'
import useDetailAlgorithmRecipe from 'utils/Hooks/Actions/Algorithm/Recipe/useDetailAlgorithmRecipe';
import SavedRecipeDashboardCard from 'components/Cards/Dashboard/SavedRecipeDashboardCard'
import PageRecipeImage from 'components/Images/RecipeImage/PageRecipeImage';
import RecipeDetailsCard from 'components/Cards/RecipeDetailsCard';
import RecipeStatsCard from 'components/Cards/RecipeStatsCard';
import TitleText from 'components/Text/TitleText';
import Instructions from 'components/features/Instructions';
import NutritionalInformationModal from 'components/Modals/NutritionalInformationModal';
import { SavedRecipeModalType } from 'enums/Modals/SavedRecipeModalType';
import RecipeImageModal from 'components/Modals/RecipeImageModal';
import { IngredientsUsedInStep } from 'interfaces/backend/IRecipeStep';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import FreshItemModal from 'components/Modals/FreshItemModal';
import CupboardItemModal from 'components/Modals/CupboardItemModal';
import DisclaimerModal from 'components/Modals/DisclaimerModal';
import { DisclaimerType } from 'types/DisclaimerType';
import AlgorithmItemCardGrid from 'components/Grids/AlgorithmItemCardGrid';
import { AlgorithmItemGridCard } from 'enums/Grids/AlgorithmItemGridCard';
import IItem from 'interfaces/backend/IItem';
import ShoppingItemModal from 'components/Modals/ShoppingItemModal';

export default function SavedRecipe() {
  const [disclaimer, setDisclaimer] = useState<DisclaimerType | null>(null);
  const [selectedRecipeStepItem, setSelectedRecipeStepItem] = useState<IAlgorithmItem | null>(null);
  const [activeModal, setActiveModal] = useState<SavedRecipeModalType>(SavedRecipeModalType.None);
  const [selectedShoppingItem, setSelectedShoppingItem] = useState<IItem | null>(null);

  const [algorithmRecipe, _, isLoading] = useDetailAlgorithmRecipe();

  const closeModal = () => {
    setActiveModal(SavedRecipeModalType.None);
  };

  const handleNutritionalInfoButtonClick = () => {
    setActiveModal(SavedRecipeModalType.NutritionalInformation);
  }

  const handleRecipeImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setActiveModal(SavedRecipeModalType.Image);
  }

  function handleRecipeStepImageClick(ingredient: IngredientsUsedInStep, e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    const selectedItem = algorithmRecipe.algorithmItems.find((algorithmItem) => algorithmItem.recipeIngredient.name === ingredient.name);
    setSelectedRecipeStepItem(selectedItem)

    if (selectedItem?.recipeIngredient.cupboardItem) {
      setActiveModal(SavedRecipeModalType.CupboardItem);
    } else {
      setActiveModal(SavedRecipeModalType.FreshItem);
    }
  }

  const handleRecipeDisclaimerClick = () => {
    setActiveModal(SavedRecipeModalType.Disclaimer);

    setDisclaimer(DisclaimerType.Recipe);
  }

  const onAlgorithmItemClick = (algorithmItem: IAlgorithmItem) => {
    setSelectedShoppingItem(algorithmItemAsShoppingItem(algorithmItem))

    setActiveModal(SavedRecipeModalType.ShoppingItem);
  }

  const openNutritionalDisclaimerModal = () => {
    window.open('https://mealia.notion.site/Nutritional-Information-012f44c8e8fe47c4817427237b66e266?pvs=4', '_blank');
  }

  function algorithmItemAsShoppingItem(algorithmItem: IAlgorithmItem): IItem {
    return algorithmItem.item as unknown as IItem;
  }

  const freshItems = () => {
    return algorithmRecipe.algorithmItems.filter((algorithmItem) => (!algorithmItem.recipeIngredient.cupboardItem || algorithmItem.inBasket) && !algorithmItem.deleted);
  }

  const cupboardItems = () => {
    return algorithmRecipe.algorithmItems.filter((algorithmItem) => (algorithmItem.recipeIngredient.cupboardItem && !algorithmItem.inBasket) && !algorithmItem.deleted);
  }

  if (!algorithmRecipe || isLoading) {
    return <Navigation />
  }

  const text = () => {
    return `Total Cost £${ algorithmRecipe.totalCost.toFixed(2) } for X Servings`
  }

  return (
    <>
      <Navigation>
        <div
          className = 'saved-recipe-container'
        >
          <SavedRecipeDashboardCard />

          <span
            style = {{ height: '8px' }}
          />

          <div
            className = 'recipe-top-content'
          >
            <PageRecipeImage
              imageUrl     = { algorithmRecipe.recipe.imageUrl }
              onInfoClick  = { handleRecipeDisclaimerClick }
              onImageClick = { handleRecipeImageClick }
              text         = { text() }
            />

            <div className = 'details'>
              <div
                className = 'lhs'
              >
                <RecipeDetailsCard
                  algorithmRecipe = { algorithmRecipe }
                  onClick         = { handleNutritionalInfoButtonClick }
                />
              </div>

              <div
                className = 'rhs'
              >
                <RecipeStatsCard
                  algorithmRecipe  = { algorithmRecipe }
                  recipeInMealPlan = { true }
                />
              </div>
            </div>
          </div>

          <span
            style={{ marginTop: '40px'}}
          />

          <TitleText
            text  = { 'Fresh Ingredients' }
            title = 'h2'
          />

          <AlgorithmItemCardGrid
            algorithmItems             = { freshItems() }
            additionalItems            = { algorithmRecipe.additionalItems }
            algorithmItemGridCard      = { AlgorithmItemGridCard.SavedItem }
            onCardClick                = { onAlgorithmItemClick }
            onSubstitutionClickHandler = { () => {} }
            onTrashClickHandler        = { () => {} }
            onAddToBasketClickHandler  = { () => {} }
            onAddCardClickHandler      = { () => {} }
            onIncrementAdditionalItem  = { () => {} }
            onDecrementAdditionalItem  = { () => {} }
          />

          <span
            style={{ marginTop: '40px'}}
          />

          <TitleText
            text  = { 'Cupboard Ingredients' }
            title = 'h2'
          />

          <AlgorithmItemCardGrid
            algorithmItems             = { cupboardItems() }
            additionalItems            = { [] }
            algorithmItemGridCard      = { AlgorithmItemGridCard.SavedItem }
            onCardClick                = { onAlgorithmItemClick }
            onSubstitutionClickHandler = { () => {} }
            onTrashClickHandler        = { () => {} }
            onAddToBasketClickHandler  = { () => {} }
            onAddCardClickHandler      = { () => {} }
            onIncrementAdditionalItem  = { () => {} }
            onDecrementAdditionalItem  = { () => {} }
          />

          <span
            style={{ marginTop: '40px'}}
          />
          
          <TitleText
            text  = { 'Instructions' }
            title = 'h2'
          />

          <Instructions
            recipeSteps    = { algorithmRecipe.recipe.steps }
            algorithmItems = { algorithmRecipe.algorithmItems }
            onClick        = { handleRecipeStepImageClick }
          />
        </div>
      </Navigation>

      <RecipeImageModal
        imageUrl = { algorithmRecipe.recipe.imageUrl }
        text     = { text() }
        isOpen   = { activeModal === SavedRecipeModalType.Image }
        close    = { closeModal }
      />

      <NutritionalInformationModal
        isOpen                 = { activeModal === SavedRecipeModalType.NutritionalInformation }
        close                  = { closeModal }
        allergens              = { algorithmRecipe.allergens }
        onInfoClick            = { openNutritionalDisclaimerModal }
        nutritionalInformation = { algorithmRecipe.nutritionalInformation }
      />

      <FreshItemModal
        isOpen        = { activeModal === SavedRecipeModalType.FreshItem }
        close         = { closeModal }
        algorithmItem = { selectedRecipeStepItem }
      />

      <CupboardItemModal
        isOpen        = { activeModal === SavedRecipeModalType.CupboardItem }
        close         = { closeModal }
        algorithmItem = { selectedRecipeStepItem }
      />

      <DisclaimerModal
        isOpen          = { activeModal === SavedRecipeModalType.Disclaimer }
        close           = { closeModal }
        disclaimer      = { disclaimer }
        algorithmRecipe = { algorithmRecipe }
      />

      <ShoppingItemModal
        isOpen       = { activeModal === SavedRecipeModalType.ShoppingItem }
        close        = { closeModal }
        shoppingItem = { selectedShoppingItem }
      />
    </>
  )
}
