import CurrentUserApi from 'services/Core/User/CurrentUserService/CurrentUserApi';

export default function useShow() {
  function show(): Promise<any> {
    const currentUserApi = new CurrentUserApi();

    return currentUserApi.show();
  }

  return show;
}
