import React, { useContext, useEffect } from 'react'
import { animated, useSpring } from 'react-spring';

import './Loading.scss'
import LoadingContext from 'context/LoadingContext';

export default function Loading() {
  const { loading, completed }: { loading: boolean, completed: boolean } = useContext(LoadingContext);

  const { width } = useSpring({
    from: { width: '0%' },
    to: { width: '100%' },
    config: {
      duration: completed ? 2000 : 10000
    },
    onRest: () => {
      // TODO: Make this dynamic - so we can set the function to call when the animation is complete
      window.location.reload();
    }
  });

  // TODO: Use width.get() to determine when the animation is complete

  useEffect(() => {
    const root = document.getElementById('root');

    if (loading) {
      root.style.pointerEvents = 'none';
    } else {
      root.style.pointerEvents = 'auto';
    }

    return () => {
      root.style.pointerEvents = 'auto';  // Reset it when the component unmounts
    }
  }, [loading]);

  return (
    <animated.div
      className = 'loading-container'
      style     = { { width } }
    />
  )
}
