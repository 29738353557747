import React, { useState } from 'react'
import LazyLoad from 'react-lazyload';

import './RootRecipeImage.scss'

type Props = {
  imageUrl:        string
  shouldHover?:    boolean
  onImageClick?:   (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
  overlayChildren: React.ReactNode
}

export default function RootRecipeImage({
  imageUrl,
  shouldHover = false,
  onImageClick,
  overlayChildren
}: Props) {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <div
      className    = 'root-recipe-image-container'
      onMouseEnter = { handleMouseEnter }
      onMouseLeave = { handleMouseLeave }
    >
      <LazyLoad
        style  = {{ height: 'inherit' }}
        offset = { 500 }
      >
        <img
          className = {`
            root-recipe-image
            ${shouldHover && isHovered ? 'root-recipe-image-hovered' : ''}
          `}
          src       = { imageUrl }
          alt       = 'Recipe'
          onClick   = { onImageClick }
        />
      </LazyLoad>

      <div
        className = 'root-recipe-image-overlay'
      >
        { overlayChildren }
      </div>
    </div>
  )
}
