import React from 'react'

import './ModifiedIngredientPill.scss'
import P12 from 'components/Text/Paragraphs/Medium/P12'

export default function ModifiedIngredientPill() {
  return (
    <div
      className = 'modified-ingredient-pill-container'
    >
      <P12
        text   = 'Ingredients Modified'
        colour = 'black-1'
      />
    </div>
  )
}
