import React from 'react'
import FallbackImage from '../FallbackImage/FallbackImage'
import { KeyConstants } from 'utils/Constants'

type Props = {
  src:       string
  alt:       string
  className: string
}

export default function FallbackItemImage({
  src,
  alt,
  className
}: Props) {
  return (
    <FallbackImage
      src           = { src }
      alt           = { alt }
      className     = { className }
      fallbackImage = { KeyConstants.Images.Fallback.item }
    />
  )
}
