import { RecipeModalType } from 'enums/Modals/RecipeModalType';
import useSmoothScrollTo from 'hooks/ui/useSmoothScrollTo';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';

export default function useHandleSwapClick(
  setSelectedAlgorithmTopItem:    (item: IAlgorithmItem | null) => void,
  setSelectedAlgorithmBottomItem: (item: IAlgorithmItem | null) => void,
  setTopItemGridType:             (topItemGridType: 'recipeItems' | 'swappingItems' | 'additionalItems') => void,
  setBottomItemGridType:          (bottomItemGridType: 'recipeItems' | 'swappingItems') => void,
  openModal:                      (modalType: RecipeModalType) => void
) {
  const smoothScrollTo = useSmoothScrollTo();

  const handleSwapClick = (
    algorithmItem: IAlgorithmItem,
    e:             React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();

    if (!algorithmItem.hasAlternatives) {
      openModal(RecipeModalType.NoAlternatives)

      return;
    }

    const scrollDuration = 600;

    const isFromTopSubsection = !algorithmItem.recipeIngredient.cupboardItem || algorithmItem.inBasket;
    const container = isFromTopSubsection ? 'top-subsection-header-title-text-container' : 'bottom-subsection-header-title-text-container';

    const didScroll = smoothScrollTo(container, scrollDuration);

    if (didScroll) {
      setTimeout(() => {
        postScroll(algorithmItem)
      }, scrollDuration + 100);
    } else {
      postScroll(algorithmItem)
    }
  }

  const postScroll = (algorithmItem: IAlgorithmItem) => {
    if (algorithmItem.recipeIngredient.cupboardItem) {
      if (algorithmItem.inBasket) {
        setTopItemGridType('swappingItems')
        setSelectedAlgorithmTopItem(algorithmItem);
      } else {
        setBottomItemGridType('swappingItems')
        setSelectedAlgorithmBottomItem(algorithmItem);
      }
    } else {
      setTopItemGridType('swappingItems')
      setSelectedAlgorithmTopItem(algorithmItem);
    }
  }

  return handleSwapClick;
}
