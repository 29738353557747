import IUserPreferences from 'interfaces/frontend/IUserPreferences'
import { DashboardTextType } from 'types/DashboardTextType'

export default function useNumberOfMealsMessages(
  userPreferences: IUserPreferences
) {
  const topLineTextObject: DashboardTextType[] = [
    {
      line: 1,
      value: 'Select the number of meals you’ll cook this week.',
      bold: false
    }
  ]

  const bottomLineTextObject: DashboardTextType[] = [
    {
      line:  2,
      value: 'Each meal will feed',
      bold:  false
    },
    {
      line:  2,
      value: `${userPreferences.numberOfAdults + userPreferences.numberOfChildren}`,
      bold:  true
    },
    {
      line:  2,
      value: 'people.',
      bold:  false
    }
  ]

  const numberOfMealsMessages = [
    ...topLineTextObject,
    ...bottomLineTextObject
  ]

  return numberOfMealsMessages
}
