import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';

export default function useSubsectionHeader(
  topItemGridType:             'recipeItems' | 'swappingItems' | 'additionalItems',
  bottomItemGridType:          'recipeItems' | 'swappingItems',
  selectedAlgorithmTopItem:    IAlgorithmItem,
  selectedAlgorithmBottomItem: IAlgorithmItem,
) {
  const topSubsectionHeader = () => {
    switch (topItemGridType) {
      case 'recipeItems':
        return 'Ingredients Going in Your Basket';
      case 'swappingItems':
        return `Swapping: ${selectedAlgorithmTopItem?.item.name}`;
      case 'additionalItems':
        return 'Adding Another Item';
      default:
        throw new Error('Invalid topItemGridType');
    }
  }

  const bottomSubsectionHeader = () => {
    switch (bottomItemGridType) {
      case 'recipeItems':
        return 'Cupboard Items You Will Also Need';
      case 'swappingItems':
        return `Swapping: ${selectedAlgorithmBottomItem?.item.name}`;
      default:
        throw new Error('Invalid bottomItemGridType');
    }
  }

  return {
    topSubsectionHeader,
    bottomSubsectionHeader
  };
}
