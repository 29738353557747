import React from 'react'

import IItem from 'interfaces/backend/IItem'
import RootItemCard from '../_RootItemCard'
import HalfIncrementorButton from 'components/Buttons/IncrementorButton/Half'

type Props = {
  item:             IItem
  quantityRequired: number
  onCardClick:      (item: IItem) => void
  onTrashClick:     (itemName: string, itemId: number, e: React.MouseEvent<HTMLDivElement>) => void
}

export default function DeletableItemCard({
  item,
  quantityRequired,
  onCardClick,
  onTrashClick
}: Props) {
  return (
    <RootItemCard
      item           = { item }
      onCardClick    = { onCardClick }
      buttonChildren = {
        <HalfIncrementorButton
          quantityRequired = { quantityRequired }
          onTrashClick     = { (e) => onTrashClick(item.name, item.id, e) }
        />
      }
    />
  )
}
