import useReportApi from 'utils/Hooks/Api/Error/useReport';

export default function useReport() {
  const reportApi = useReportApi();

  async function report(
    message:   string,
    backtrace: string
  ) {
    await reportApi(
      message,
      backtrace
    );
  }

  return report;
}
