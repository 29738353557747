import React from 'react'

import './NumberOfMealsPreference.scss'
import OnboardingPills from 'components/Inputs/OnboardingPills'
import { HouseholdConstants } from 'utils/Constants'

type Props = {
  selectedOptions:   number[]
  handleOptionClick: (option: number) => void
}

export default function NumberOfMealsPreference({
  selectedOptions,
  handleOptionClick
}: Props) {
  return (
    <div
      className = 'number-of-meals-preference-container'
    >
      <OnboardingPills
        answerOptions     = { HouseholdConstants.NumberOfMeals }
        selectedOptions   = { selectedOptions }
        handleOptionClick = { handleOptionClick }
      />
    </div>
  )
}
