import React, { useEffect, useState } from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

type Props = {
  searchQuery: string
}

export default function SearchDashboardCard({
  searchQuery
}: Props) {
  const [forceUpdate, setForceUpdate] = useState(false)

  useEffect(() => {
    setForceUpdate(!forceUpdate)
  }, [searchQuery])

  const generateTopTextObjects = (): DashboardTextType[] => {
    return [
      {
        line: 1,
        value: `Here’s what I could find for`,
        bold: false
      },
      {
        line: 1,
        value: `${searchQuery}.`,
        bold: true
      }
    ]
  }

  return (
    <MealiaBotCard
      textObjects = { [ ...generateTopTextObjects() ] }
      startTyping = { forceUpdate }
    />
  )
}
