import IAlgorithmSavedPlan, { RecipeData } from 'interfaces/backend/Algorithm/IAlgorithmSavedPlan';
import { AlgorithmRecipeMapping } from 'types/Mappings/AlgorithmRecipeMapping';

export default class AlgorithmSavedPlan implements IAlgorithmSavedPlan {
  id:                     number;
  createdAt:              string;
  totalCost:              number;
  pricePerServing:        number;
  numberOfIngredients:    number;
  numberOfMeals:          number;
  numberOfPeople:         number;
  recipeData:             RecipeData[];
  algorithmRecipeMapping: AlgorithmRecipeMapping[];

  constructor(data: IAlgorithmSavedPlan) {
    // TODO: Overwrite createdAt to be a Date object.

    Object.assign(this, data);
  }

  get prettyCreatedAt() {
    return this.formatDateString(this.createdAt);
  }

  private formatDateString(dateString) {
    const date = new Date(dateString);

    const day   = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-11
    const year  = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
}