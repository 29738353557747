import React from 'react'

import './DeleteBasketItemModal.scss'
import RootModal from '../_RootModal/RootModal'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import ModalSecondaryButton from 'components/Buttons/ModalSecondaryButton'
import { DashboardTextType } from 'types/DashboardTextType'

type Props = {
  isOpen:            boolean
  close:             () => void
  ingredientName:    string | null
  deleteItemHandler: () => void
}

export default function DeleteBasketItemModal({
  isOpen,
  close,
  ingredientName,
  deleteItemHandler
}: Props) {
  if (!ingredientName) {
    return null
  }

  const topLineTextObject: DashboardTextType[] = [
    {
      line:  1,
      value: `You'll need`,
      bold:  false
    },
    {
      line:  1,
      value: `${ingredientName}`,
      bold:  true
    },
    {
      line:  1,
      value: `in your meals.`,
      bold:  false
    }
  ]

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: `Are you sure you want to remove this item?`,
    bold:  false
  }

  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'small'
    >
      <div
        className = 'delete-basket-item-modal-container'
      >
        <MealiaBotCard
          textObjects = { [ ...topLineTextObject, bottomLineTextObject ] }
          closeModal  = { close }
        />

        <div
          className = 'delete-basket-item-modal-buttons'
        >
          <PrimaryButton
            text           = 'Keep'
            onClickHandler = { close }
          />

          <ModalSecondaryButton
            text           = 'Remove'
            onClick        = { deleteItemHandler }
          />
        </div>
      </div>
    </RootModal>
  )
}
