import { useCallback } from 'react';

export default function useSmoothScrollTo() {
  // TODO: Make smoothScrollTo work with integer values
  const smoothScrollTo = useCallback((targetId: string, duration: number, element: HTMLElement | Window = window) => {
    const target = document.getElementById(targetId);
    if (!target) return false;

    const padding = 90; 
    const targetY = target.getBoundingClientRect().top + (element === window ? window.scrollY : (element as HTMLElement).scrollTop) - padding;
    const isWindow = element === window;
    const start = isWindow ? window.scrollY : (element as HTMLElement).scrollTop;

    if (Math.abs(targetY - start) < 5) {  // A small threshold to avoid unnecessary scroll
      return false;
    }

    const change = targetY - start;
    let startTime: number | undefined;

    function animateScroll(currentTime: number) {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;

      const progress = Math.min(timeElapsed / duration, 1);
      const easeInOutQuad = progress < 0.5
        ? 2 * progress * progress
        : (4 - 2 * progress) * progress - 1;

      const newY = start + easeInOutQuad * change;
      if (isWindow) {
        window.scrollTo(0, newY);
      } else {
        (element as HTMLElement).scrollTop = newY;
      }

      if (progress < 1) window.requestAnimationFrame(animateScroll);
    }

    window.requestAnimationFrame(animateScroll);

    return true;
  }, []);

  return smoothScrollTo;
}
