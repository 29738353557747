import React from 'react'

import './UserMessage.scss'
import IMessage, { Sender } from 'interfaces/backend/IMessage'
import RootMessage from '../RootMessage'
import P16 from 'components/Text/Paragraphs/Regular/P16'

type Props = {
  message: IMessage
}

export default function UserMessage({
  message
}: Props) {
  return (
    <RootMessage
      sender = { Sender.User }
    >
      <div
        className = 'chatbot-message-user-message-container'
      >
        <P16
          text   = { message.content }
          colour = 'white-1'
        />
      </div>
    </RootMessage>
  )
}
