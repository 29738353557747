import { useState } from 'react';

export default function useStates() {
  const [suggestions, setSuggestions] = useState([])
  const [searchValue, setSearchValue] = useState('');

  return {
    suggestions,
    setSuggestions,
    searchValue,
    setSearchValue
  };
}
