import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useHandleSubstituteCupboardItem(
  closeModal:                     () => void,
  setAlgorithmRecipe:             (algorithmRecipe: AlgorithmRecipe) => void,
  setSelectedAlgorithmBottomItem: (item: IAlgorithmItem | null) => void,
  setBottomItemGridType:          (bottomItemGridType: 'recipeItems' | 'swappingItems') => void,
  replaceAlgorithmItem:           (
    algorithmChosenItemId: number,
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal:            () => void
  ) => void
) {
  const handleSubstituteCupboardItem = (
    chosenAlgorithmItem:      IAlgorithmItem,
    alternativeAlgorithmItem: IAlgorithmItem
  ) => {
    replaceAlgorithmItem(alternativeAlgorithmItem.id, setAlgorithmRecipe, closeModal);

    setSelectedAlgorithmBottomItem(null);

    setBottomItemGridType('recipeItems');
  }

  return handleSubstituteCupboardItem;
}