import React from 'react'

import './PersonalInformation.scss'
import SettingsCard from 'components/Cards/SettingsCard'
import SecondaryTextfield, { Icons } from 'components/Inputs/SecondaryTextfield'

export default function PersonalInformation({
  firstName,
  email,
  setFirstName,
  setEmail
}) {
  return (
    <SettingsCard
      title = 'Personal Information'
    >
      <div
        className = 'settings-card-content-row'
      >
        <SecondaryTextfield
          label    = 'First Name'
          value    = { firstName }
          setValue = { setFirstName }
          icon     = { Icons.PersonIcon }
        />
      </div>

      <SecondaryTextfield
        label    = 'Email Address'
        value    = { email }
        setValue = { setEmail }
        icon     = { Icons.EmailIcon }
        disabled = { true }
      />
    </SettingsCard>
  )
}
