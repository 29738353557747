import useActions  from './Core/useActions';
import useContexts from './Core/useContexts';
import useModal    from './Core/useModal';
import useEffects  from './Core/useEffects';
import useHooks    from './Core/useHooks';
import useStates   from './Core/useStates';
import useComputed from './Core/useComputed';
import useRefs     from './Core/useRefs';

export default function useMyMeals() {
  const {
    algorithmBasket,
    algorithmMealPlan,
    user
  } = useContexts();

  const {
    addAlgorithmRecipe,
    filter,
    basketCheckedOut,
    navigateTo
  } = useHooks();

  const {
    activeModal,
    openModal,
    closeModal
  } = useModal();

  const {
    loadingStatus,
    setLoadingStatus,
    otherRecipes,
    setOtherRecipes,
    filterType,
    setFilterType,
    showNotification,
    setShowNotification
  } = useStates();

  const {
    subsectionHeaderRef,
    observerRef,
    hasSeenSubsectionRef
  } = useRefs();

  const {
    filterTexts
  } = useComputed(user);
  
  const {
    handleRecipeCardClick,
    handleAddRecipe,
    handleRemoveRecipe,
    handleImagesLoaded,
    handleFilterClick,
    handleCategoryClick,
    handleFilterContinue,
    handleNewMealsClick,
    handleNotificationClick
  } = useActions(setLoadingStatus, openModal, closeModal, setFilterType, addAlgorithmRecipe, setOtherRecipes, navigateTo, user, filter, setShowNotification);

  useEffects(loadingStatus, setLoadingStatus, navigateTo, basketCheckedOut, otherRecipes, setOtherRecipes, setShowNotification, algorithmMealPlan, subsectionHeaderRef, observerRef, hasSeenSubsectionRef);

  return {
    algorithmBasket,
    algorithmMealPlan,
    user,
    activeModal,
    closeModal,
    handleRecipeCardClick,
    handleAddRecipe,
    handleRemoveRecipe,
    loadingStatus,
    filterType,
    showNotification,
    subsectionHeaderRef,
    otherRecipes,
    filterTexts,
    handleImagesLoaded,
    handleFilterClick,
    handleCategoryClick,
    handleFilterContinue,
    handleNewMealsClick,
    handleNotificationClick
   };
}
