import React, { useRef } from 'react'

import './Groceries.scss'
import Navigation from 'pages/PostLogin/Shared/Navigation'
import GroceriesDashboardCard from 'components/Cards/Dashboard/GroceriesDashboardCard'
import SearchBox from 'components/Inputs/SearchBox';
import TitleText from 'components/Text/TitleText'
import DropDownCard from 'components/Cards/DropDownCard';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import P14 from 'components/Text/Paragraphs/Medium/P14';
import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import { ReactComponent as TescoLogo } from 'assets/images/logos/tesco/groceries.svg'
import { ReactComponent as AsdaLogo } from 'assets/images/logos/asda/groceries.svg'

import useGroceries from 'hooks/Pages/PostLogin/06-Groceries/useGroceries';

export default function Groceries() {
  const {
    linkedSupermarket,
    suggestions,
    searchValue,
    setSearchValue,
    search,
    handleDropDownSuggestionClick
  } = useGroceries();

  const searchBoxRef = useRef(null);

  const handleClick = () => {
    if (searchBoxRef && searchBoxRef.current) {
      searchBoxRef.current.focus();
    }
  };

  return (
    <>
      <Navigation>
        <div
          className = 'groceries-container'
        >
          <GroceriesDashboardCard />

          <span
            style = {{ height: '8px' }}
          />

          <TitleText
            text = 'Groceries & Household Essentials'
          >
            <button
              onClick = { handleClick }
              className = {`
                groceries-title-text-subtitle
                ${linkedSupermarket}
              `}
            >
              <P14
                text   = 'Find Any Items Available at'
                colour = 'white-1'
              />

              {
                linkedSupermarket === SupportedSupermarket.Asda ?
                  <AsdaLogo /> :
                  <TescoLogo />
              }
            </button>
          </TitleText>

          <span />

          <div
            className = 'grocery-search-container'
          >
            <img
              src = '/images/illustrations/groceries.svg'
              alt = 'Groceries'
            />

            <div
              className = 'grocery-search-box-container'
            >
              <SearchBox
                placeholder = 'Food, Household Essentials,...'
                value       = { searchValue }
                setValue    = { setSearchValue }
                onEnter     = { search }
                ref         = { searchBoxRef }
              />

              <PrimaryButton
                text           = 'Search'
                size           = 'fill'
                height         = 'x-large'
                onClickHandler = { search }
              />
            </div>

            {
              suggestions.length > 0 &&  searchValue !== '' &&
              <div
                className = 'grocery-search-suggestions'
              >
                <DropDownCard
                  suggestions       = { suggestions }
                  onSuggestionClick = { handleDropDownSuggestionClick }
                />
              </div>
            }
          </div>
        </div>
      </Navigation>
    </>
  )
}