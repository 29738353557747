import UserSessionApi from 'services/Core/User/SessionService/UserSessionApi';

export default function useSignIn() {
  function signIn(email: string, password: string): Promise<any> {
    const userSessionApi = new UserSessionApi();

    return userSessionApi.signIn(email, password);
  }

  return signIn;
}
