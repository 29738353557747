import { RouteName } from 'router/Config';
import useHandleSuggestionSearch from '../Operations/Effects/useHandleSuggestionSearch';
import useHideDropdown from '../Operations/Effects/useHideDropdown';
import useRedirect from '../Operations/Effects/useRedirect';

export default function useEffects(
  searchValue:      string,
  setSuggestions:   (suggestions: string[]) => void,
  basketCheckedOut: boolean,
  navigateTo:       (routeName: RouteName) => void
) {
  useHideDropdown(setSuggestions)
  useHandleSuggestionSearch(searchValue, setSuggestions)
  useRedirect(basketCheckedOut, navigateTo)
}
