import IUserPreferences from 'interfaces/frontend/IUserPreferences';

import useIntroductionMessages from '../Operations/Messages/useIntroductionMessages';
import useNumberOfPeopletMessages from '../Operations/Messages/useNumberOfPeopleMessage';
import useNumberOfMealsMessages from '../Operations/Messages/useNumberOfMealsMessage';
import useBudgetMessages from '../Operations/Messages/useBudgetMessage';
import useEndingMessages from '../Operations/Messages/useEndingMessage';
import useDietaryPreferencesMessages from '../Operations/Messages/useDietaryPreferencesMessage';
import useAllergiesMessages from '../Operations/Messages/useAllergiesMessage';
import useKitchenEquipmentMessages from '../Operations/Messages/useKitchenEquipmentMessage';
import useGrocerMessages from '../Operations/Messages/useGrocerMessage';

export default function useMessages(
  userPreferences: IUserPreferences
) {
  const introductionMessages       = useIntroductionMessages();
  const numberOfPeopleMessages     = useNumberOfPeopletMessages();
  const numberOfMealsMessages      = useNumberOfMealsMessages(userPreferences);
  const budgetMessages             = useBudgetMessages(userPreferences);
  const dietaryPreferencesMessages = useDietaryPreferencesMessages();
  const allergiesMessages          = useAllergiesMessages();
  const kitchenEquipmentMessages   = useKitchenEquipmentMessages();
  const grocerMessages             = useGrocerMessages();
  const endingMessages             = useEndingMessages();

  return {
    introductionMessages,
    numberOfPeopleMessages,
    numberOfMealsMessages,
    budgetMessages,
    dietaryPreferencesMessages,
    allergiesMessages,
    kitchenEquipmentMessages,
    grocerMessages,
    endingMessages
  }
}
