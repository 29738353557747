import React from 'react'

import './P10.scss'

type Props = {
  text: string
  colour?: 'white-1'
}

export default function P10({
  text,
  colour
}: Props) {
  return (
    <p 
      className = {`
        p10-regular
        ${colour}
      `}
    >
      { text }
    </p>
  )
}