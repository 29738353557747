import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreHouseholdEndpoints: BaseApiEndpoint = {
  createHousehold: (
    numberOfAdults:       number,
    numberOfChildren:     number,
    numberOfMealsPerWeek: number,
    weeklyBudget:         number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    chosenSupermarket:    SupportedSupermarket
  ) => ({
    url:    'households/create',
    method: 'POST',
    auth:   true,
    body: {
      number_of_adults:         numberOfAdults,
      number_of_children:       numberOfChildren,
      number_of_meals_per_week: numberOfMealsPerWeek,
      weekly_budget:            weeklyBudget,
      allergies:                allergies,
      dietary_preferences:      dietaryPreferences,
      kitchen_equipment:        kitchenEquipment,
      chosen_supermarket:       chosenSupermarket
    }
  })
}
