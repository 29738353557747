import AlgorithmRecipeApi from 'services/Core/Algorithm/RecipeService/AlgorithmRecipeApi';

export default function useDetailAlgorithmRecipe() {
  function detailAlgorithmRecipe(algorithmRecipeId: number): Promise<any> {
    const algorithmRecipeApi = new AlgorithmRecipeApi();

    return algorithmRecipeApi.detailAlgorithmRecipe(algorithmRecipeId);
  }

  return detailAlgorithmRecipe;
}
