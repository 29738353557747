import React from 'react'

import './PreLogin.scss'
import MealiaMascot from 'components/Animations/MealiaMascot'

export default function PreLoginIllustration() {
  return (
    <div
      className = 'pre-login-illustration-container'
    >
      <img
        className = 'pre-login-illustration'
        alt       = 'Mealia helps you make the most of your budget'
        src       = '/images/illustrations/pre-login.svg'
      />

      <div
        className = 'pre-login-illustration-mascot'
      >
        <MealiaMascot
          size = 'medium'
        />
      </div>
    </div>
  )
}
