import React from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

export default function GroceriesDashboardCard() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Need some groceries or other essentials?`,
    bold:  false
  }

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: `Pet food, baby nappies, toothpaste, it's all here. ✨`,
    bold:  false
  }

  return (
    <MealiaBotCard
      textObjects = { [topLineTextObject, bottomLineTextObject] }
    />
  )
}
