import { useContext } from 'react';

import UserContext from 'context/UserContext';
import useSignInApi from 'utils/Hooks/Api/User/Session/useSignIn';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import { RouteName } from 'router/Config';
import User from 'models/User';

export default function useSignIn() {
  const { setUser }: { setUser: (user: User) => void } = useContext(UserContext);

  const navigateTo = useNavigateTo();
  const signInApi = useSignInApi();

  async function signIn(email: string, password: string): Promise<any> {
    const response = await signInApi(email, password);

    const user: User = User.fromApiResponse(response.data);

    setUser(user);

    navigateTo(RouteName.Onboarding);

    return response;
  }

  return signIn;
}
