import { useEffect } from 'react';

import useShow from 'utils/Hooks/Actions/Algorithm/Basket/useShow';

export default function useShowBasket() {
  const show = useShow();

  useEffect(() => {
    show();
  }, []);
}
