import { useEffect, useState } from 'react';

import { Sender } from 'interfaces/backend/IMessage';
import { PromptConstants } from 'utils/Constants';
import { BotPage } from 'enums/BotPage';
import useShow from 'utils/Hooks/Actions/Message/useShow';
import useCreate from 'utils/Hooks/Actions/Message/useCreate';
import IConversation from 'interfaces/backend/IConversation';

export default function useAskMealia() {
  const currentPage = () => {
    switch (window.location.pathname) {
      case '/basket':
        return BotPage.Basket;
      case '/my-meals':
        return BotPage.MyMeals;
      case '/recipe/':
        return BotPage.Recipe;
      default:
        return null
    }
  }
  
  const [conversation, setConversation] = useState<IConversation>(null);

  const showMessages = useShow();
  const createMessage = useCreate();

  const initialMessage = {
    id:        -1,
    sender:    Sender.Assistant,
    content:   PromptConstants[currentPage()]?.initialMessage,
    createdAt: new Date()
  }

  const handleSendMessage = (content: string, setValue: (value: string) => void) => {
    const newConversation = {
      ...conversation,
      messages: [
        ...conversation.messages,
        {
          id:        conversation.messages.length + 1,
          sender:    Sender.User,
          content:   content,
          createdAt: new Date()
        }
      ]
    }

    setConversation(newConversation);
    setValue('');

    createMessage(currentPage(), content).then((response) => {
      handleResponse(response);
    })
  }

  const handleResponse = (response: any) => {
    const data: IConversation = response.data;

    data.messages.unshift(initialMessage);

    setConversation(data);
  }

  useEffect(() => {
    if (!currentPage()) return;

    showMessages(currentPage()).then((response) => {
      handleResponse(response);
    })
  }, []);

  return {
    botPage:      currentPage(),
    conversation,
    handleSendMessage
  }
}
