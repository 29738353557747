import React from 'react'

import './Root.scss'
import PersonalPlanOutlineCard from './PersonalPlanOutlineCard'
import PersonalPlanRecipeListCard from './PersonalPlanRecipeListCard'
import { AlgorithmRecipeMapping } from 'types/Mappings/AlgorithmRecipeMapping'
import { RecipeData } from 'interfaces/backend/Algorithm/IAlgorithmSavedPlan'

type Props = {
  totalCost:               number
  pricePerServing:         number
  numberOfMeals:           number
  numberOfPeople:          number
  numberOfIngredients:     number
  algorithmRecipeMapping:  AlgorithmRecipeMapping[]
}

export default function Root({
  totalCost,
  pricePerServing,
  numberOfMeals,
  numberOfPeople,
  numberOfIngredients,
  algorithmRecipeMapping
}: Props) {
  if (!algorithmRecipeMapping) {
    throw new Error('algorithmRecipeMapping must be provided')
  }

  const data = () => {
    return algorithmRecipeMapping
      .filter(item => item.algorithmRecipe?.recipe)
      .map(item => ({
        imageUrl: item.algorithmRecipe.recipe.imageUrl,
        name:     item.algorithmRecipe.recipe.name,
        quantity: 1 // TODO: Add quantity to algorithmRecipeMapping
      })) as RecipeData[];
  }

  return (
    <div
      className = 'personal-plan-root-container'
    >
      <PersonalPlanOutlineCard
        totalPrice        = { totalCost }
        perServing        = { pricePerServing }
        meals             = { numberOfMeals }
        servings          = { numberOfPeople }
        ingredients       = { numberOfIngredients }
      />

      <PersonalPlanRecipeListCard
        recipeData = { data() }
      />
    </div>
  )
}
