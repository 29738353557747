import React from 'react'

import './Prompt.scss'
import { BotPage } from 'enums/BotPage'
import { PromptConstants } from 'utils/Constants'
import P16 from 'components/Text/Paragraphs/Regular/P16'

type Props = {
  type:    BotPage
  onClick: (prompt: string) => void
}

export default function Prompt({
  type,
  onClick
}: Props) {
  return (
    <div
      className = 'chatbot-prompt-container'
    >
      {
        PromptConstants[type].prompts.map((prompt, index) => (
          <button
            key       = { index }
            onClick   = { () => onClick(prompt) }
            className = 'chatbot-prompt'
          >
            <P16
              text   = { prompt }
              colour = 'black-1'
            />
          </button>
        ))
      }
    </div>
  )
}
