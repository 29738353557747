import { useState } from 'react';
import { MyMealsModalType } from 'enums/Modals/MyMealsModalType';

export default function useModal() {
  const [activeModal, setActiveModal] = useState<MyMealsModalType>(MyMealsModalType.None);

  const openModal = (modalType: MyMealsModalType) => {
    setActiveModal(modalType);
  };

  const closeModal = () => {
    setActiveModal(MyMealsModalType.None);
  };

  return {
    activeModal,
    openModal,
    closeModal
  };
}
