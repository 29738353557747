import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useCreateNewMealPlan from 'utils/Hooks/Actions/Algorithm/MealPlan/useCreateNewMealPlan';

export default function useHooks() {
  const navigateTo = useNavigateTo();
  const createNewMeals = useCreateNewMealPlan();

  return {
    navigateTo,
    createNewMeals
  }
}
