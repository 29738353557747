import React, { useState } from 'react'

import './PageRecipeImage.scss'
import RootRecipeImage from '../_RootRecipeImage'
import RecipeImagePill from 'components/Pills/RecipeImagePill'
import RecipeInfoButton from 'components/Buttons/RecipeInfoButton'
import P14 from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  imageUrl:     string
  onInfoClick:  () => void
  onImageClick: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
  text:         string
}

export default function PageRecipeImage({
  imageUrl,
  onInfoClick,
  onImageClick,
  text
}: Props) {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <div
      className = 'page-recipe-image-container'
      onMouseEnter = { handleMouseEnter }
      onMouseLeave = { handleMouseLeave }
    >
      <RootRecipeImage
        imageUrl        = { imageUrl }
        shouldHover     = { true }
        onImageClick    = { onImageClick }
        overlayChildren = {
          <>
            <div
              className = 'recipe-image-overlay-top-right'
            >
              <RecipeImagePill
                text = { text }
              />

              <RecipeInfoButton
                onInfoClick = { onInfoClick }
              />
            </div>

            <div
              className = {`
                page-recipe-image-overlay-centre
                ${isHovered ? 'hovered' : ''}
              `}
              onClick = { onImageClick }
            >
              <div
                className = 'page-recipe-image-overlay-centre-inner'
              >
                <P14
                  text   = 'Click to Zoom'
                  colour = 'white-1'
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  )
}
