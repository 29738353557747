import { useEffect } from 'react';

import { GenerateNewMealsPage } from 'enums/GenerateNewMealsPage';

export default function useForceUpdate(
  page:           GenerateNewMealsPage,
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    setTimeout(() => {
      setForceUpdate(forceUpdate => !forceUpdate)
    }, 1000)
  }, [page])
}
