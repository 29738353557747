import { useRef } from 'react';

export default function useRefs() {
  const subsectionHeaderRef = useRef(null);
  const observerRef         = useRef(null);

  const hasSeenSubsectionRef = useRef(false);

  return {
    subsectionHeaderRef,
    observerRef,
    hasSeenSubsectionRef
  }
}
