import ErrorApi from 'services/Core/ErrorService/ErrorApi';

export default function useReport() {
  function createHousehold(
    message:   string,
    backtrace: string
  ): Promise<any> {
    const errorApi = new ErrorApi();

    return errorApi.report(
      message,
      backtrace
    );
  }

  return createHousehold;
}
