import { RouteName } from 'router/Config';

export default function useSearch(
  searchValue: string,
  navigateTo: (routeName: RouteName, params?: any) => void
) {
  const search = () => {
    if (searchValue.trim().length === 0) return;

    navigateTo(RouteName.Search, `?query=${searchValue}`);
  }

  return search;
}
