import React from 'react'

import './PersonalPlanOutlineCard.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import P12 from 'components/Text/Paragraphs/Medium/P12'

type Props = {
  totalPrice:         number
  perServing:         number
  meals:              number
  servings:           number
  ingredients:        number
}

export default function PersonalPlanOutlineCard({
  totalPrice,
  perServing,
  meals,
  servings,
  ingredients
}: Props) {
  const sections = [
    {
      title: 'Total Price',
      value: `£${ totalPrice?.toFixed(2) }`
    },
    {
      title: 'Per Serving',
      value: `£${ perServing?.toFixed(2) }`
    },
    {
      title: 'Meals',
      value: String(meals)
    },
    {
      title: 'Servings',
      value: String(servings)
    },
    {
      title: 'Ingredients',
      value: String(ingredients)
    }
  ]

  return (
    <div
      className = 'personal-plan-outline-card-container'
    >
      {
        sections.map((section, index) => {
          return (
            <div
              key       = { index }
              className = 'personal-plan-outline-card-section'
            >
              <P14
                text   = { section.title }
                colour = 'black-1'
              />
              
              <P12
                text   = { section.value }
                colour = 'grey-1'
              />
            </div>
          )
        })
      }
    </div>
  )
}
