import { useEffect, useState } from 'react';
import useGetBrowser from './useGetBrowser';
import { SupportedBrowser } from 'enums/SupportedBrowser';

export default function useIsExtensionInstalled() {
  const [isExtensionInstalledPreviously, setIsExtensionInstalledPreviously] = useState(false);
  const [isExtensionJustInstalled, setIsExtensionJustInstalled] = useState(false);

  const currentBrowser = useGetBrowser();

  const BROWSER_EXTENSION_IDS = {
    [SupportedBrowser.Chrome]: process.env.REACT_APP_CHROME_EXTENSION_ID,
    [SupportedBrowser.Edge]:   process.env.REACT_APP_EDGE_EXTENSION_ID,
  };

  const getExtensionId = () => {
    return BROWSER_EXTENSION_IDS[currentBrowser];
  }

  const checkForExtension = (isPolling: boolean, extensionId: string) => {
    switch (currentBrowser) {
      case SupportedBrowser.Chrome:
        return checkViaChromeRuntime(isPolling, extensionId);
      case SupportedBrowser.Edge:
        return checkViaChromeRuntime(isPolling, extensionId);
      case SupportedBrowser.Firefox:
        return checkViaPostMessage(isPolling);
      default:
        return null;
    }
  }

  const checkViaPostMessage = (isPolling: boolean) => {
    window.addEventListener('ExtensionInstalled', (event) => {
      if (event) {
        if (!isPolling && !isExtensionInstalledPreviously) {
          setIsExtensionInstalledPreviously(true);
        } else if (isPolling && !isExtensionJustInstalled) {
          setIsExtensionJustInstalled(true);
        }
      } else {
        console.log('Extension not installed.');
      }
    });

    const checkEvent = new Event('CheckExtensionInstalled');
    window.dispatchEvent(checkEvent);
  }

  const checkViaChromeRuntime = (isPolling: boolean, extensionId: string) => {
    if (!window.chrome || !chrome.runtime || !chrome.runtime.sendMessage) {
      console.log('Chrome runtime API not available.');

      return;
    }

    chrome.runtime.sendMessage(extensionId, { type: 'checkExtension' }, (response) => {
      if (chrome.runtime.lastError) {
        console.error('Error communicating with the extension:', chrome.runtime.lastError);

        return;
      }

      console.log('Extension response:', response);

      if (response && response.success) {
        if (!isPolling && !isExtensionInstalledPreviously) {
          setIsExtensionInstalledPreviously(true);
        } else if (isPolling && !isExtensionJustInstalled) {
          setIsExtensionJustInstalled(true);
        }
      }
    });
  }

  const initialCheckForExtension = () => {
    checkForExtension(false, getExtensionId());
  };

  const pollForNewInstallation = () => {
    const intervalId = setInterval(() => {
      if (isExtensionInstalledPreviously || isExtensionJustInstalled) {
        clearInterval(intervalId);
        return;
      }

      checkForExtension(true, getExtensionId());
    }, 2000);

    return intervalId;
  }

  useEffect(() => {
    initialCheckForExtension();
    
    const intervalId = pollForNewInstallation();
    
    return () => clearInterval(intervalId);
  }, []);

  return {
    isExtensionInstalledPreviously,
    isExtensionJustInstalled
  };
}
