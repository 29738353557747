import React from 'react'

import RootModal from '../_RootModal'
import ModalRecipeImage from 'components/Images/RecipeImage/ModalRecipeImage'

type Props = {
  imageUrl: string
  text:     string
  isOpen:   boolean
  close:    () => void
}

export default function RecipeImageModal({
  imageUrl,
  text,
  isOpen,
  close
}: Props) {
  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'none'
    >
      <ModalRecipeImage
        imageUrl = { imageUrl }
        text     = { text }
      />
    </RootModal>
  )
}
