import React from 'react'

import './UserTypePill.scss'
import useCapitalize from 'hooks/utils/useCapitalize'
import P14 from 'components/Text/Paragraphs/Regular/P14'
import P10 from 'components/Text/Paragraphs/Regular/P10'
import { ReactComponent as AdultIcon } from 'assets/images/onboarding/adult.svg'
import { ReactComponent as ChildrenIcon } from 'assets/images/onboarding/children.svg'

type Props = {
  userType: 'adult' | 'children'
}

export default function UserTypePill({
  userType
}: Props) {
  const text = useCapitalize(userType)

  return (
    <div
      className = 'user-type-pill-container'
    >
      {
        userType === 'adult' && <AdultIcon />
      }

      {
        userType === 'children' && <ChildrenIcon />
      }

      <div
        className = 'text-container'
      >
        <P14
          text   = { text }
          colour = 'white-1'
        />

        {
          userType === 'children' && <P10
            text   = '(Under 13)'
            colour = 'white-1'
          />
        }
      </div>
      
    </div>
  )
}
