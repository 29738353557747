import React from 'react'

import './AllergenPill.scss'
import P14 from 'components/Text/Paragraphs/Regular/P14'

type Props = {
  allergen: string
}

export default function AllergenPill({
  allergen
}: Props) {
  return (
    <div
      className = 'allergen-pill-container'
    >
      <P14
        text   = { allergen }
        colour = 'grey-1'
      />
    </div>
  )
}
