import { RecipeModalType } from 'enums/Modals/RecipeModalType';
import { DisclaimerType } from 'types/DisclaimerType';

export default function useHandleRecipeDisclaimerClick(
  openModal:     (modal: RecipeModalType) => void,
  setDisclaimer: (disclaimer: DisclaimerType) => void
) {
  const handleRecipeDisclaimerClick = () => {
    setDisclaimer(DisclaimerType.Recipe);

    openModal(RecipeModalType.Disclaimer);
  }

  return handleRecipeDisclaimerClick
}