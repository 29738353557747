import React from 'react'

import './GenerateNewMeals.scss'
import Navigation from '../Shared/Navigation'
import TitleText from 'components/Text/TitleText'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { ColourConstants } from 'utils/Constants'
import { ReactComponent as LeftArrow } from 'assets/images/icon/left-arrow/root.svg'
import { GenerateNewMealsPage } from 'enums/GenerateNewMealsPage'
import UserOnboardingAnswerCard from 'components/Cards/UserOnboardingAnswerCard'

import NumberOfPeople     from '../00-Onboarding/Questions/01-NumberOfPeople'
import NumberOfMeals      from '../00-Onboarding/Questions/02-NumberOfMeals'
import BudgetQuestion     from '../00-Onboarding/Questions/03-Budget'
import DietaryPreferences from '../00-Onboarding/Questions/04-DietaryPreferences'
import Allergies          from '../00-Onboarding/Questions/05-Allergies'
import KitchenEquipment   from '../00-Onboarding/Questions/06-KitchenEquipment'
import GrocerQuestion     from '../00-Onboarding/Questions/07-Grocer'
import Ending             from '../00-Onboarding/Main/Ending'

import useGenerateNewMeals from 'hooks/Pages/PostLogin/02-GenerateNewMeals/useGenerateNewMeals'

export default function GenerateNewMeals() {
  const {
    forceUpdate,
    page,
    userPreferences,
    setUserPreferences,
    botMessage,
    disabledPrimaryButton,
    handlePreviousButtonClick,
    handleNextButtonClick,
    handleCancelClick
  } = useGenerateNewMeals();

  return (
    <Navigation>
      <div
        className = 'start-new-plan-container'
      >
        <MealiaBotCard
          textObjects = { botMessage() }
          startTyping = { forceUpdate }
        />

        <span
          style = {{ height: '8px' }}
        />

        <TitleText
          text = 'Generate New Meals'
        >
          <SecondaryButton
            text    = 'Cancel'
            onClick = { handleCancelClick }
          />
        </TitleText>

        <span />

        <div
          className = 'start-new-plan-container-2'
        >
          <div
            className = 'start-new-plan'
          >
            {
              page !== GenerateNewMealsPage.Ending && <UserOnboardingAnswerCard
                page            = { page }
                offset          = { true }
                size            = 'medium'
                childComponents = {
                  {
                    [GenerateNewMealsPage.NumberOfPeople]: <NumberOfPeople
                      userPreferences    = { userPreferences }
                      setUserPreferences = { setUserPreferences }
                    />,
                    [GenerateNewMealsPage.NumberOfMeals]: <NumberOfMeals
                      userPreferences    = { userPreferences }
                      setUserPreferences = { setUserPreferences }
                    />,
                    [GenerateNewMealsPage.Budget]: <BudgetQuestion
                      userPreferences    = { userPreferences }
                      setUserPreferences = { setUserPreferences }
                    />,
                    [GenerateNewMealsPage.DietaryPreferences]: <DietaryPreferences
                      userPreferences    = { userPreferences }
                      setUserPreferences = { setUserPreferences }
                    />,
                    [GenerateNewMealsPage.Allergies]: <Allergies
                      userPreferences    = { userPreferences }
                      setUserPreferences = { setUserPreferences }
                    />,
                    [GenerateNewMealsPage.KitchenEquipment]: <KitchenEquipment
                      userPreferences    = { userPreferences }
                      setUserPreferences = { setUserPreferences }
                    />,
                    [GenerateNewMealsPage.Grocer]: <GrocerQuestion
                      userPreferences    = { userPreferences }
                      setUserPreferences = { setUserPreferences }
                    />
                  }
                }
              />
            }

            {
              page === GenerateNewMealsPage.Ending && <Ending
                onAnimationComplete = { () => {} }
                completed           = { false }
              />
            }
          </div>
        </div>

        <div
          className = 'generate-new-meals-button-container'
        >
          {/* TODO: Disabled Back Button for last Page + UI */}
          <button
            className = 'go-back-container'
            onClick   = { handlePreviousButtonClick }
          >
            <LeftArrow
              color = { ColourConstants.White[1] }
            />
          </button>

          <PrimaryButton
            text           = 'Continue'
            icon           = 'arrow'
            size           = 'fill'
            disabled       = { disabledPrimaryButton() }
            onClickHandler = { handleNextButtonClick }
          />
        </div>
      </div>
    </Navigation>
  )
}
