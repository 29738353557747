import React, { useEffect, useState, useRef } from 'react';
import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';

export const AlertProvider = (props: any) => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const setAlertAndClear = (newAlert: AlertType | null) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    setAlert(null);

    if (newAlert) {
      timerRef.current = setTimeout(() => {
        setAlert(newAlert);

        timerRef.current = setTimeout(() => {
          setAlert(null);
        }, 3000);

      }, 100);
    }
  }

  useEffect(() => {
    return () => {
      if(timerRef.current) clearTimeout(timerRef.current);
    }
  }, []);

  return (
    <AlertContext.Provider
      value = {{
        alert, 
        setAlert: setAlertAndClear
      }}
    >
      { props.children }
    </AlertContext.Provider>
  );
};
