import React from 'react'

import './NumberOfPeoplePreference.scss'

import { HouseholdConstants } from 'utils/Constants'
import UserTypePill from 'components/Pills/UserTypePill'
import OnboardingPills from 'components/Inputs/OnboardingPills'

type Props = {
  adultSelectedOptions:   number[]
  handleAdultOptionClick: (option: number) => void
  childSelectedOptions:   number[]
  handleChildOptionClick: (option: number) => void
}

export default function NumberOfPeoplePreference({
  adultSelectedOptions,
  handleAdultOptionClick,
  childSelectedOptions,
  handleChildOptionClick
}: Props) {
  return (
    <div
      className = 'number-of-people-preference-container'
    >
      <div
        className = 'child-container'
      >
        <UserTypePill
          userType = 'adult'
        />

        <OnboardingPills
          answerOptions     = { HouseholdConstants.NumberOfPeople.Adults }
          selectedOptions   = { adultSelectedOptions }
          handleOptionClick = { handleAdultOptionClick }
        />
      </div>

      <div
        className = 'child-container'
      >
        <UserTypePill
          userType = 'children'
        />

        <OnboardingPills
          answerOptions     = { HouseholdConstants.NumberOfPeople.Children }
          selectedOptions   = { childSelectedOptions }
          handleOptionClick = { handleChildOptionClick }
        />
      </div>
    </div>
  )
}
