import { useContext } from 'react';

import UserContext from 'context/UserContext';
import useSignOutApi from 'utils/Hooks/Api/User/Session/useSignOut';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import { RouteName } from 'router/Config';
import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import User from 'models/User';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';

export default function useSignOut() {
  const { setUser }: { setUser: (user: User) => void } = useContext(UserContext);
  const { setAlgorithmBasket }: { setAlgorithmBasket: (algorithmBasket: AlgorithmBasket) => void } = useContext(AlgorithmBasketContext);

  const signOutApi = useSignOutApi();
  const navigateTo = useNavigateTo();

  function signOut() {
    signOutApi().then(() => {
      setUser(null);

      setAlgorithmBasket(null);

      navigateTo(RouteName.Login);
    });
  }

  return signOut;
}
