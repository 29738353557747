import React from 'react'

import './H1Text.scss'

type Props = {
  text: string
  colour?: 'pink-1' | 'grey-1' | 'white-1'
}

export default function H1Text({ 
  text,
  colour
}: Props) {
  return (
    <h1
      className = { colour }
    >
      { text }
    </h1>
  )
}
