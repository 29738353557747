import React from 'react'

import './AddableAlgorithmRecipeCard.scss'
import RootAlgorithmRecipeCard from '../_RootAlgorithmRecipeCard'
import AddToBasketButton from 'components/Buttons/AddToBasketButton'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'

type Props = {
  algorithmRecipe:   AlgorithmRecipe
  onCardClick:       (algorithmRecipe: AlgorithmRecipe, e: React.MouseEvent<HTMLElement>) => void
  onBasketClick:     (algorithmRecipe: AlgorithmRecipe, e: React.MouseEvent<HTMLElement>) => void
}

export default function AddableAlgorithmRecipeCard({
  algorithmRecipe,
  onCardClick,
  onBasketClick
}: Props) {
  return (
    <RootAlgorithmRecipeCard
      algorithmRecipe  = { algorithmRecipe }
      onCardClick      = { onCardClick }
      buttonChildren   = { 
        <AddToBasketButton
          onClick = { (e: React.MouseEvent<HTMLDivElement>) => onBasketClick(algorithmRecipe, e) }
        />
       }
    />
  )
}
