import IItem from 'interfaces/backend/IItem';
import { ItemMapping } from 'types/Mappings/ItemMapping';

export default function useHandleAddCupboardItem(
  show:            () => void,
  setItemMapping:  (itemMapping: ItemMapping[]) => void,
  itemDetails:     (setItemMapping: (itemMapping: ItemMapping[]) => void) => Promise<any>,
  addCupboardItem: (itemId: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<any>
) {
  const handleAddCupboardItem = (item: IItem, e: React.MouseEvent<HTMLDivElement>) => {
    addCupboardItem(item.id, e).then(() => {
      itemDetails(setItemMapping)
    }).then(() => {
      show()
    })
  }

  return handleAddCupboardItem;
}
