import React from 'react'

import './P14.scss'

type Props = {
  text:    string
  colour?: 'pink-1' | 'grey-1' | 'grey-2' | 'white-1' | 'black-1' | 'pink-2' | 'red-1'
}

export default function P14({
  text,
  colour
}: Props) {
  return (
    <p
      className = {`
        p14-medium
        ${colour}
      `}
    >
      { text }
    </p>
  )
}
