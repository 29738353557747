import React from 'react'

import './MyOrders.scss'
import Navigation from '../Shared/Navigation'
import TitleText from 'components/Text/TitleText'
import SavedPlansDashboardCard from 'components/Cards/Dashboard/SavedPlansDashboardCard'
import PersonalPlanSavedOutline from 'components/Cards/PersonalPlan/PersonalPlanSavedOutline'
import useSavedPlans from 'utils/Hooks/Pages/PostLogin/useSavedPlans'

export default function MyOrders() {
  const {
    algorithmSavedPlans,
    handleClick
  } = useSavedPlans();

  return (
    <>
      <Navigation>
        <div
          className = 'saved-plans-container'
        >
          <SavedPlansDashboardCard
            savedPlansCount = { algorithmSavedPlans.length }
          />

          <span
            style = {{ height: '8px' }}
          />

          <TitleText
            text = { algorithmSavedPlans.length > 0 ? 'My Transferred Baskets' : `You Don't Have Any Baskets Transferred Yet` }
          />

          <span />

          {
            algorithmSavedPlans.length > 0 && <PersonalPlanSavedOutline
              algorithmSavedPlans = { algorithmSavedPlans }
              handleClick         = { handleClick }
            />
          }
        </div>
      </Navigation>
    </>
  )
}
