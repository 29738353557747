import useHooks    from './Core/useHooks';
import useContexts from './Core/useContexts';
import useStates   from './Core/useStates';
import useEffects  from './Core/useEffects';
import useModal    from './Core/useModal';
import useActions  from './Core/useActions';

export default function useBasket() {
  const {
    activeModal,
    openModal,
    closeModal
  } = useModal();

  const {
    shoppingCategory,
    setShoppingCategory,
    tempItem,
    setTempItem,
    showLoadingBar,
    setShowLoadingBar,
    shoppingItems,
    setShoppingItems,
    itemMapping,
    setItemMapping
  } = useStates();

  const {
    algorithmBasket,
    algorithmMealPlan,
    user
  } = useContexts();

  const {
    navigateTo,
    show,
    removeItem,
    linkedSupermarket,
    basketCheckedOut,
    shoppingItemDetails,
    itemDetails,
    addCupboardItem,
    checkout,
    isExtensionInstalledPreviously
  } = useHooks();

  const {
    handleAcceptDeleteItem,
    handleAddCupboardItem,
    handleStartLoading,
    handleFinishLoading,
    handleRemoveItem
  } = useActions(show, tempItem, setTempItem, setShowLoadingBar, setItemMapping, removeItem, addCupboardItem, itemDetails, navigateTo, user, isExtensionInstalledPreviously, checkout ,openModal, closeModal);

  useEffects(basketCheckedOut, navigateTo, setShoppingItems, shoppingItemDetails, setItemMapping, itemDetails);

  return {
    activeModal,
    shoppingCategory,
    setShoppingCategory,
    tempItem,
    showLoadingBar,
    shoppingItems,
    itemMapping,
    algorithmBasket,
    algorithmMealPlan,
    user,
    linkedSupermarket,
    closeModal,
    handleAcceptDeleteItem,
    handleAddCupboardItem,
    handleStartLoading,
    handleFinishLoading,
    handleRemoveItem
  }
}
