export const ColourConstants = {
  Pink: {
    1: '#FF8399',
    2: '#FFB6C3',
  },
  Grey: {
    1: '#757575',
    2: '#C4C4C4',
    3: '#EBEBEB',
  },
  White: {
    1: '#FFFFFF',
    2: '#F7F9FA',
    3: '#F1F1F1',
  },
  Black: {
    1: '#1A1A1A',
  },
  Red: {
    1: '#EF7575',
  },
  Amber: {
    1: '#FFA552',
  },
  Green: {
    1: '#55C053',
  }
}
