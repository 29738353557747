export enum RecipeModalType {
  None,
  ShoppingItem,
  DeleteItem,
  FreshItem,
  CupboardItem,
  Disclaimer,
  Image,
  NutritionalInformation,
  NoAlternatives
}
