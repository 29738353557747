import { createContext } from 'react';

import User from 'models/User';

export const UserContext = createContext({
  user:    null as User | null,
  setUser: ( user: User ) => {}
});

export default UserContext;
