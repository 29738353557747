import React from 'react'

import './GrocerPreference.scss'
import { SupportedSupermarket } from 'enums/SupportedSupermarket'
import { ReactComponent as AsdaLogo } from 'assets/images/logos/asda/onboarding.svg'
import { ReactComponent as TescoLogo } from 'assets/images/logos/tesco/onboarding.svg'

type Props = {
  chosenSupermarket:          SupportedSupermarket
  handleSupermarketSelection: (supermarket: SupportedSupermarket) => void
}

export default function GrocerPreference({
  chosenSupermarket,
  handleSupermarketSelection
}: Props) {
  return (
    <div
      className = 'grocer-preference-container'
    >
      <AsdaLogo
        className = {`
          grocer-logo
          ${ chosenSupermarket === SupportedSupermarket.Asda ? 'selected' : '' }
        `}
        onClick = { () => handleSupermarketSelection(SupportedSupermarket.Asda) }
      />

      <TescoLogo
        className = {`
          grocer-logo
          ${ chosenSupermarket === SupportedSupermarket.Tesco ? 'selected' : '' }
        `}
        onClick = { () => handleSupermarketSelection(SupportedSupermarket.Tesco) }
      />
    </div>
  )
}
