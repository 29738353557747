import React from 'react'

import './P20.scss'

type Props = {
  text: string
  colour?: 'black-1'
}

export default function P20({
  text,
  colour
}: Props) {
  return (
    <p 
      className = {`
        p20-medium
        ${colour}
      `}
    >
      { text }
    </p>
  )
}