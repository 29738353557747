import React from 'react'

import { DashboardTextType } from 'types/DashboardTextType';
import MealiaBotCard from 'components/Cards/MealiaBotCard';

export default function CheckedOutDashboardCard() {
  const generateTopTextObjects: DashboardTextType = {
    line: 1,
    value: 'Looks like you’ve checked out already.',
    bold: false
  }

  const generateBottomTextObject: DashboardTextType = {
    line: 2,
    value: 'Here’s a summary of your last plan, would you like to continue with it or create a new one?',
    bold: false
  }

  return (
    <MealiaBotCard
      textObjects = { [ generateTopTextObjects, generateBottomTextObject ] }
    />
  )
}
