import IUserPreferences from 'interfaces/frontend/IUserPreferences'
import { DashboardTextType } from 'types/DashboardTextType'

export default function useNumberOfMealsMessages(
  userPreferences: IUserPreferences
) {
  const topLineTextObject: DashboardTextType[] = [
    {
      line: 1,
      value: 'Great, now how many meals with ',
      bold: false
    },
    {
      line: 1,
      value: `${userPreferences.numberOfAdults + userPreferences.numberOfChildren}`,
      bold: true
    },
    {
      line: 1,
      value: ' servings will',
      bold: false
    }
  ]

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: 'you be cooking this week?',
    bold:  false
  }

  const numberOfMealsMessages = [
    ...topLineTextObject,
    bottomLineTextObject
  ]

  return numberOfMealsMessages
}
