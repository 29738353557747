import React from 'react'

import './Selector.scss'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'
import P14 from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  categoriesList:      string[]
  supertextCategory?:  string
  supertext?:          string
  selectedCategory:    string
  setSelectedCategory: any
}

export default function Selector({
  categoriesList,
  supertextCategory,
  supertext,
  selectedCategory,
  setSelectedCategory
}: Props) {
  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category)
  }

  const supertextFinder = (category: string) => {
    if (!supertextCategory) {
      return [false, '']
    }

    // supertext will be one of the categories in categoriesList
    if (supertextCategory === category) {
      return [true, supertext]
    } else {
      return [false, '']
    }
  }

  return (
    <div className = 'shopping-categories'>
      {
        categoriesList.map((category, index) => (
          <button 
            key          = { index }
            onClick      = { () => handleCategoryClick(category) }
            className = {`
              category-button
              ${ category === selectedCategory ? 'selected-category' : 'unselected-category' }
            `}
          >
            {
              supertextFinder(category)[0] ? 
              <div
                className = 'supertext'
              >
                <P16
                  text = { supertext }
                  colour = 'white-1'
                />
              </div>
              :
              null
            }

            <P14
              text   = { category }
              colour = { category === selectedCategory ? 'white-1' : 'black-1' }
            />
          </button>
        ))
      }
    </div>
  )
}
