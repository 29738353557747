import useContinueMealPlanApi from 'utils/Hooks/Api/Algorithm/MealPlan/useContinueMealPlan';

export default function useContinueMealPlan() {
  const continueMealPlanApi = useContinueMealPlanApi();

  function continueMealPlan(
    maintainSubstitutions: boolean,
    onSuccess:             () => void
  ) {
    continueMealPlanApi(maintainSubstitutions).then((response) => {
      onSuccess();
    })
  }

  return continueMealPlan;
}
