import React from 'react'
import { Helmet } from 'react-helmet';

export default function MouseflowScript() {
  return (
    <Helmet>
      <script 
        type = 'text/javascript'
      >
        {`
          window._mfq = window._mfq || [];
          (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript";
            mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/8afaf937-fb91-48e3-afae-a4472b503329.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
          })();
        `}
      </script>
    </Helmet>
  )
}
