import React from 'react';

import './Login.scss';
import PrimaryButton                   from 'components/Buttons/PrimaryButton';
import GoogleButton, { GoogleContext } from 'components/Buttons/GoogleButton';
import TertiaryButton                  from 'components/Buttons/TertiaryButton';
import Textfield, { Icons }            from 'components/Inputs/Textfield';
import Divider                         from 'components/base/Divider';
import P14                             from 'components/Text/Paragraphs/Regular/P14';
import ErrorModal                      from 'components/Modals/ErrorModal';
import PreLoginCard                    from 'components/Cards/PreLoginCard';
import Form                            from 'pages/PreLogin/Shared/Form';

import useLogin from 'utils/Hooks/Pages/PreLogin/useLogin';

export default function Login() {
  const [
    email,
    setEmail,
    password,
    setPassword,
    error,
    closeModal,
    signIn,
    googleLogInSuccess,
    googleLoginFailure,
    resendEmailConfirmation,
    navigateToForgotPassword,
    navigateToRegister,
    LocalConstants
  ] = useLogin();

  return (
    <Form
      modal = {
        <ErrorModal
          close         = { closeModal }
          error         = { error }
          email         = { email }
          onClickAction = { resendEmailConfirmation }
        />
      }
    >
      <PreLoginCard
        title        = { LocalConstants.PreLogin.Login.Title }
        subtitle     = { LocalConstants.PreLogin.Login.SubTitle }
        showBetaPill = { true }
      >
        
        <GoogleButton 
          context   = { GoogleContext.SignIn }
          onError   = { googleLoginFailure }
          onSuccess = { googleLogInSuccess }
        />

        <Divider />

        <div
          className = 'pre-login-textfield-container'
        >
          <Textfield
            placeholder = { LocalConstants.PreLogin.Login.EmailPlaceholder }
            icon        = { Icons.EmailIcon }
            value       = { email }
            setValue    = { setEmail }
          />

          <Textfield
            type        = 'password'
            placeholder = { LocalConstants.PreLogin.Login.PasswordPlaceholder }
            icon        = { Icons.PasswordIcon }
            value       = { password }
            setValue    = { setPassword }
          />
        </div>

        <div
          className = 'login-forgot-password-container'
        >
          <TertiaryButton
            text           = { LocalConstants.PreLogin.Login.TertiaryButtonCopy }
            colour         = 'grey-1'
            onClickHandler = { navigateToForgotPassword }
          />
        </div>

        <PrimaryButton
          text           = { LocalConstants.PreLogin.Login.PrimaryButtonCopy }
          size           = 'fill'
          onClickHandler = { signIn }
        />
        
        <div
          className = 'login-tertiary-button-container'
        >
          <P14
            text   = { LocalConstants.PreLogin.Login.OtherCopy }
            colour = 'pink-1'
          />
          
          <TertiaryButton
            text           = { LocalConstants.PreLogin.Login.QuaternaryButtonCopy }
            onClickHandler = { navigateToRegister }
          />
        </div>
      </PreLoginCard>
    </Form>
  )
};
