const CORE_API_BASE_URL = process.env.REACT_APP_CORE_API_BASE_URL;
const CORE_API_VERSION  = process.env.REACT_APP_CORE_API_VERSION;

const CORE_API_HEADERS = {
  'Content-Type': 'application/json',
  'Accept':       'application/json',
}

export const CoreConfig = {
  CORE_API_BASE_URL,
  CORE_API_VERSION,
  CORE_API_HEADERS
}
