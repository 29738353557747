import React from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'

type Props = {
  algorithmRecipe: AlgorithmRecipe
}

export default function RecipeDashboardCard({
  algorithmRecipe
}: Props) {
  const generateTopTextObjects = (): DashboardTextType[] => {
    return [
      {
        line: 1,
        value: 'I’ve found the ',
        bold: false
      },
      {
        line: 1,
        value: `${algorithmRecipe.numberOfFreshItems}`,
        bold: true
      },
      {
        line: 1,
        value: 'most affordable ingredients for this meal (you can change them if you want).',
        bold: false
      }
    ];
  }

const generateBottomTextObjects = (): DashboardTextType[] => {
    return [
      {
        line: 2,
        value: 'There also are ',
        bold: false
      },
      {
        line: 2,
        value: `${algorithmRecipe.numberOfCupboardItems}`,
        bold: true
      },
      {
        line: 2,
        value: 'Cupboard Items you might need.',
        bold: false
      }
    ];
  }

  return (
    <MealiaBotCard
      textObjects = { [ ...generateTopTextObjects(), ...generateBottomTextObjects() ] }
    />
  )
}
