import React from 'react'

import './TopNavBar.scss'
import BasketButton from 'components/Buttons/BasketButton';
import CircleTopNavBarButton from 'components/Buttons/TopNavBarButton/CircleTopNavBarButton';
import RoundedTopNavBarButton from 'components/Buttons/TopNavBarButton/RoundedTopNavBarButton';
import TopNavBarSupermarketImage from 'components/Images/Supermarket/TopNavBar';
import AskMealiaButton from 'components/Buttons/AskMealiaButton';
import { NavigationModalType } from 'enums/Modals/NavigationModalType';
import ChatbotModal from 'components/features/Chatbot/Types/ChatbotModal';
import ChangeSupermarketModal from 'components/Modals/ChangeSupermarketModal';

import useNavigation from 'utils/Hooks/Helper/useNavigation';

export default function TopNavBar() {
  const {
    navigateBack,
    navigateToMyMeals,
    navigateToMyOrders,
    navigateToMyProfile,
    navigateToBasket,
    handleSupermarketImageClick,
    handleAskMealiaClick,
    handleSendMessage,
    activeModal,
    botPage,
    conversation,
    shouldFloat,
    closeModal,
    onMyMeals,
    onMyOrders,
    onGroceries,
    onBasket,
    basketValue
   } = useNavigation()

  return (
    <>
      { 
        shouldFloat && <div
          className = 'top-nav-bar-buffer'
        />
      }

      <div
        className = {`
          top-nav-bar-container
          ${ shouldFloat ? 'float' : '' }
        `}
      >
        <div
          className = 'top-nav-bar-section'
        >
          <CircleTopNavBarButton
            onClickHandler = { navigateBack }
          />

          <RoundedTopNavBarButton
            onClickHandler = { navigateToMyMeals }
            isOnRoute      = { onMyMeals }
            type           = 'myMeals'
          />

          <RoundedTopNavBarButton
            onClickHandler = { navigateToMyOrders }
            isOnRoute      = { onMyOrders }
            type           = 'myOrders'
          />

          <RoundedTopNavBarButton
            onClickHandler = { navigateToMyProfile }
            isOnRoute      = { onGroceries }
            type           = 'myProfile'
          />
        </div>

        <div
          className = 'top-nav-bar-section'
        >
          {
            botPage !== null && <AskMealiaButton
              onClick = { handleAskMealiaClick }
            />
          }

          <BasketButton
            onClickHandler = { navigateToBasket }
            isOnRoute      = { onBasket }
            basketValue    = { basketValue }
          />

          <TopNavBarSupermarketImage
            onClick = { handleSupermarketImageClick }
          />
        </div>
      </div>

      <ChangeSupermarketModal
        isOpen = { activeModal === NavigationModalType.ChangeSupermarket }
        close  = { closeModal }
      />

      <ChatbotModal
        type        = { botPage }
        isOpen      = { activeModal === NavigationModalType.AskMealia }
        close       = { closeModal }
        messages    = { conversation?.messages }
        onSendClick = { handleSendMessage }
      />
    </>
  )
}
