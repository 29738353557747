import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useCategorisedAlgorithmItems(
  algorithmRecipe: AlgorithmRecipe
) {
  const freshItems = () => {
    return algorithmRecipe.algorithmItems.filter((algorithmItem) => (!algorithmItem.recipeIngredient.cupboardItem || algorithmItem.inBasket) && !algorithmItem.deleted);
  }

  const cupboardItems = () => {
    return algorithmRecipe.algorithmItems.filter((algorithmItem) => (algorithmItem.recipeIngredient.cupboardItem && !algorithmItem.inBasket) && !algorithmItem.deleted);
  }

  return {
    freshItems,
    cupboardItems
  };
}
