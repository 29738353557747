import React from 'react'

import './BudgetPreference.scss'
import Slider from 'components/Inputs/Slider';
import P14 from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  lowerBudget:        number,
  upperBudget:        number,
  weeklyBudget:       number[],
  handleBudgetChange: (values: number[]) => void
}

export default function BudgetPreference({
  lowerBudget,
  upperBudget,
  weeklyBudget,
  handleBudgetChange
}: Props) {
  return (
    <div
      className = 'budget-preference-container'
    >
      <Slider
        orientation = 'horizontal'
        min         = { lowerBudget }
        max         = { upperBudget }
        values      = { weeklyBudget }
        setValues   = { handleBudgetChange }
      />

      <div
        className = 'illustration most-affordable-illustration'
      >
        <P14
          text = 'Minimum'
        />
      </div>

      <div
        className = 'illustration most-expensive-illustration'
      >
        <P14
          text = 'Maximum'
        />
      </div>
    </div>
  )
}
