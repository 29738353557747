import User from 'models/User';
import { RouteName } from 'router/Config';
import { OnboardingPage } from 'enums/OnboardingPage';

import useRedirectToMyMeals from '../Operations/Effects/useRedirectToMyMeals';
import useForceUpdate from '../Operations/Effects/useForceUpdate';

export default function useEffects(
  user:           User,
  navigateTo:     (routeName: RouteName) => void,
  onboardingPage: OnboardingPage,
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>
) {
  useRedirectToMyMeals(user, navigateTo);
  useForceUpdate(onboardingPage, setForceUpdate)
}
