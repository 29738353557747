import { useEffect, useState } from 'react';

import useShowApi from 'utils/Hooks/Api/Algorithm/SavedPlan/useShow';
import AlgorithmSavedPlan from 'models/Algorithm/AlgorithmSavedPlan';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useShow() {
  const showApi = useShowApi();
  const [algorithmSavedPlan, setAlgorithmSavedPlan] = useState<AlgorithmSavedPlan | null>(null);

  function show() {
    const algorithmSavedPlanId: number =  Number(window.location.search.split('?')[1].split('=')[1]);

    showApi(algorithmSavedPlanId).then((response) => {
      const data: AlgorithmSavedPlan = new AlgorithmSavedPlan({
        ...response.data,
        algorithmRecipeMapping: response.data.algorithmRecipeMapping.map(mapping => ({
            ...mapping,
            algorithmRecipe: mapping.algorithmRecipe 
                ? new AlgorithmRecipe(mapping.algorithmRecipe)
                : null
        }))
      });

      setAlgorithmSavedPlan(data);
    });
  }

  useEffect(() => {
    show();
  }, []);

  return [algorithmSavedPlan];
}
