import React, { useState } from 'react';

import LoadingContext from 'context/LoadingContext';

export const LoadingProvider = (props: any) => {
  const [loading, setLoading]     = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  return (
    <LoadingContext.Provider 
      value = { { loading, setLoading, completed, setCompleted } }
    >
      { props.children }
    </LoadingContext.Provider>
  );
};
