import { CoreUserSessionEndpoints } from 'services/Core/User/SessionService/Config';
import CoreApi from 'services/Core/CoreApi';

export default class UserSessionApi extends CoreApi {
  constructor() {
    super(CoreUserSessionEndpoints);
  }

  signIn(email: string, password: string) {
    const response = super.request(
      this.apiEndpoints.signIn(email, password)
    );

    this.storeAuthHeader(response);

    return this.respondWithJson(response);
  }

  signOut() {
    const response = super.request(
      this.apiEndpoints.signOut()
    );

    this.removeAuthHeader(response);

    return this.respondWithJson(response);
  }
}
