import React from 'react'

import './TypingMessage.scss'
import RootMessage from '../RootMessage'
import { Sender } from 'interfaces/backend/IMessage'
import TypingAnimation from 'components/Animations/AskMealia/Typing/TypingAnimation'
import { ReactComponent as MealiaIcon } from 'assets/images/logos/mealia/small.svg'

export default function TypingMessage() {
  return (
    <RootMessage
      sender = { Sender.Assistant }
    >
      <div
        className = 'chatbot-message-bot-container'
      >
        <MealiaIcon
          className = 'mealia-mascot'
        />

        <div
          className = 'chatbot-message-typing-container'
        >
          <TypingAnimation />
        </div>
      </div>
    </RootMessage>
  )
}
