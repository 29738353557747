import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import { OnboardingPage } from 'enums/OnboardingPage';

export default function useHandlePreviousButtonClick(
  onboardingPage:     OnboardingPage,
  setOnboardingPage:  React.Dispatch<React.SetStateAction<OnboardingPage>>,
  userPreferences:    IUserPreferences,
  setUserPreferences: React.Dispatch<React.SetStateAction<IUserPreferences>>
) {
  const handlePreviousButtonClick = () => {
    if (onboardingPage === OnboardingPage.Intro) return

    if (onboardingPage === OnboardingPage.DietaryPreferences && userPreferences.dietaryPreferencesPresent) {
      setUserPreferences({
        ...userPreferences,
        dietaryPreferencesPresent: null,
        dietaryPreferences: null
      })
    } else if (onboardingPage === OnboardingPage.Allergies && userPreferences.allergiesPresent) {
      setUserPreferences({
        ...userPreferences,
        allergiesPresent: null,
        allergies: []
      })
    } else {
      setOnboardingPage(prevPage => prevPage - 1);
    }    
  }

  return handlePreviousButtonClick;
}
