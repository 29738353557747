import AlgorithmBasketApi from 'services/Core/Algorithm/BasketService/AlgorithmBasketApi';

export default function useItemDetails() {
  function itemDetails(): Promise<any> {
    const algorithmBasketApi = new AlgorithmBasketApi();

    return algorithmBasketApi.itemDetails();
  }

  return itemDetails;
}
