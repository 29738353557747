import AlgorithmBasketApi from 'services/Core/Algorithm/BasketService/AlgorithmBasketApi';

export default function useDecrementShoppingItem() {
  function decrementShoppingItem(itemId: number): Promise<any> {
    const algorithmBasketApi = new AlgorithmBasketApi();

    return algorithmBasketApi.decrementShoppingItem(itemId);
  }

  return decrementShoppingItem;
}
