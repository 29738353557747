import useActions  from './Core/useActions';
import useComputed from './Core/useComputed';
import useContexts from './Core/useContexts';
import useHooks    from './Core/useHooks';
import useModal    from './Core/useModal';
import useStates   from './Core/useStates';

import useAskMealia from '../shared/useAskMealia';
import { BotPage } from 'enums/BotPage';

export default function useRecipe() {
  const {
    user,
    algorithmMealPlan
  } = useContexts();

  const {
    activeModal,
    openModal,
    closeModal
  } = useModal();

  const {
    algorithmRecipe,
    setAlgorithmRecipe,
    isLoading,
    incrementAdditionalItem,
    decrementAdditionalItem,
    addAlgorithmRecipe,
    addCupboardItemToBasket,
    removeAlgorithmItem,
    replaceAlgorithmItem,
    navigateTo
  } = useHooks();

  const {
    selectedAlgorithmTopItem,
    setSelectedAlgorithmTopItem,
    selectedAlgorithmBottomItem,
    setSelectedAlgorithmBottomItem,
    selectedRecipeStepItem,
    setSelectedRecipeStepItem,
    selectedShoppingItem,
    setSelectedShoppingItem,
    disclaimer,
    setDisclaimer,
    topItemGridType,
    setTopItemGridType,
    bottomItemGridType,
    setBottomItemGridType
  } = useStates();

  const {
    freshItems,
    cupboardItems,
    recipeImageText,
    recipeInMealPlan,
    topSubsectionHeader,
    bottomSubsectionHeader
  } = useComputed(selectedAlgorithmTopItem, selectedAlgorithmBottomItem, algorithmRecipe, user, topItemGridType, bottomItemGridType, algorithmMealPlan);

  const  {
    handleSwapClick,
    handleTrashClick,
    handleNutritionalInfoClick,
    handleNutritionalInfoDisclaimerClick,
    handleAddDifferentItemClick,
    handleRecipeImageClick,
    handleAddItemClick,
    handleAddAdditionalItem,
    handleDecreaseAdditionalItem,
    handleAlgorithmItemClick,
    handleRecipeStepImageClick,
    handleRecipeDisclaimerClick,
    handleAddAlgorithmRecipe,
    handleAddCupboardItem,
    handleRemoveAlgorithmItem,
    handleSubstituteTopItem,
    handleSubstituteCupboardItem,
    handleCancelTopItemSwap,
    handleCancelCupboardItemSwap
  } = useActions(closeModal, openModal, algorithmRecipe, setAlgorithmRecipe, setSelectedAlgorithmTopItem, setSelectedAlgorithmBottomItem, setSelectedRecipeStepItem, setSelectedShoppingItem, setDisclaimer, setTopItemGridType, setBottomItemGridType, incrementAdditionalItem, decrementAdditionalItem, addAlgorithmRecipe, removeAlgorithmItem, addCupboardItemToBasket, replaceAlgorithmItem, navigateTo);

  return {
    algorithmRecipe,
    isLoading,
    activeModal,
    closeModal,
    freshItems,
    cupboardItems,
    recipeImageText,
    handleSwapClick,
    handleTrashClick,
    handleNutritionalInfoClick,
    handleNutritionalInfoDisclaimerClick,
    handleAddDifferentItemClick,
    handleRecipeImageClick,
    handleAddItemClick,
    handleAddAdditionalItem,
    handleDecreaseAdditionalItem,
    handleAlgorithmItemClick,
    handleRecipeStepImageClick,
    handleRecipeDisclaimerClick,
    handleAddAlgorithmRecipe,
    handleAddCupboardItem,
    handleRemoveAlgorithmItem,
    handleSubstituteTopItem,
    selectedAlgorithmTopItem,
    handleSubstituteCupboardItem,
    selectedAlgorithmBottomItem,
    selectedShoppingItem,
    selectedRecipeStepItem,
    disclaimer,
    topItemGridType,
    bottomItemGridType,
    recipeInMealPlan,
    topSubsectionHeader,
    bottomSubsectionHeader,
    handleCancelTopItemSwap,
    handleCancelCupboardItemSwap
  };
}
