import CoreApi from 'services/Core/CoreApi';
import { CoreAlgorithmItemEndpoints } from './Config'

export default class AlgorithmItemApi extends CoreApi {
  constructor() {
    super(CoreAlgorithmItemEndpoints);
  }

  alternativeAlgorithmItems = (algorithmItemId: number) => {
    const response = super.request(
      this.apiEndpoints.alternativeAlgorithmItems(algorithmItemId)
    );

    return this.respondWithJson(response);
  };

  replaceAlgorithmItem = (algorithmItemId: number) => {
    const response = super.request(
      this.apiEndpoints.replaceAlgorithmItem(algorithmItemId)
    );

    return this.respondWithJson(response);
  }

  addToBasket = (algorithmItemId: number) => {
    const response = super.request(
      this.apiEndpoints.addToBasket(algorithmItemId)
    );

    return this.respondWithJson(response);
  }

  removeAlgorithmItem = (algorithmItemId: number) => {
    const response = super.request(
      this.apiEndpoints.removeAlgorithmItem(algorithmItemId)
    );

    return this.respondWithJson(response);
  }
}
