import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import { pathOf } from './Navigator';
import { AllRoutes, RouteName } from 'router/Config'
import useAuthorisedStatus from 'utils/Hooks/Helper/useAuthorisedStatus';
import useScrollToTop from 'hooks/ui/useScrollToTop';

function RequireAuth({ children }) {
  const userAuthorised = useAuthorisedStatus();

  if (userAuthorised) {
    return children
  }

  return <Navigate to = { pathOf(RouteName.Login) } />
}

function UnrequireAuth({ children }) { // TODO: Check - does this do anything?
  const userAuthorised = useAuthorisedStatus();

  if (userAuthorised) {
    return <Navigate to = { pathOf(RouteName.MyMeals) } />
  }

  return children
}

function CoreRoutes() {
  useScrollToTop();

  function GetRoutes() {
    return Object.entries(AllRoutes).map(([, value]) => {
      return (
        <Route 
          key     = { value.path }
          path    = { value.path }
          element = { 
            value.private ?
            <RequireAuth>
              { value.element }
            </RequireAuth>
            :
            <UnrequireAuth>
              { value.element }
            </UnrequireAuth>
           }
        />
      )
    })
  }

  return (
    <AnimatePresence>
      <Routes>
        { GetRoutes() }
      </Routes>
    </AnimatePresence>
  )
}

export default function CoreRouter() {
  return (
    <Router>
      <CoreRoutes />
    </Router>
  )
}
