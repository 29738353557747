import { CoreUserConfirmationEndpoints } from 'services/Core/User/ConfirmationService/Config';
import CoreApi from 'services/Core/CoreApi';

export default class UserConfirmationApi extends CoreApi {
  constructor() {
    super(CoreUserConfirmationEndpoints);
  }

  create(email: string) {
    const response = super.request(
      this.apiEndpoints.create(email)
    );

    return this.respondWithJson(response);
  }

  confirm(emailConfirmationToken: string) {
    const response = super.request(
      this.apiEndpoints.confirm(emailConfirmationToken)
    );

    this.storeAuthHeader(response);

    return this.respondWithJson(response);
  }
}
