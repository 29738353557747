import React from 'react'

import { OnboardingQuestionProps } from '../Shared/OnboardingQuestionProps'
import NumberOfMealsPreference from 'components/features/UserPreferences/NumberOfMealsPreference'

export default function NumberOfMeals({
  userPreferences,
  setUserPreferences
}: OnboardingQuestionProps) {
  const handleSelectedOption = (value: number) => {
    setUserPreferences({
      ...userPreferences,
      numberOfMealsPerWeek: value
    })
  }
  
  return (
    <NumberOfMealsPreference
      selectedOptions = { [ userPreferences.numberOfMealsPerWeek ] }
      handleOptionClick = { handleSelectedOption }
    />
  )
}
