import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import { RecipeModalType } from 'enums/Modals/RecipeModalType';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useHandleSubstituteTopItem(
  openModal:                   (modalType: RecipeModalType) => void,
  closeModal:                  () => void,
  setAlgorithmRecipe:          (algorithmRecipe: AlgorithmRecipe) => void,
  setSelectedAlgorithmTopItem: (item: IAlgorithmItem | null) => void,
  setTopItemGridType:          (topItemGridType: 'recipeItems' | 'swappingItems' | 'additionalItems') => void,
  replaceAlgorithmItem:        (
    algorithmChosenItemId: number,
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal:            () => void
  ) => void
) {
  const handleSubstituteTopItem = (
    chosenAlgorithmItem:      IAlgorithmItem,
    alternativeAlgorithmItem: IAlgorithmItem
  ) => {
    replaceAlgorithmItem(alternativeAlgorithmItem.id, setAlgorithmRecipe, closeModal);

    setSelectedAlgorithmTopItem(null);

    setTopItemGridType('recipeItems');
  }

  return handleSubstituteTopItem;
}