import { useState } from 'react';

import { GenerateNewMealsPage } from 'enums/GenerateNewMealsPage';
import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import { initUserPreferences } from 'utils/Hooks/Helper/useUserPreferencesUtils';

export default function useStates() {
  const [forceUpdate, setForceUpdate] = useState(false);

  const [page, setPage] = useState(GenerateNewMealsPage.NumberOfPeople);
  const [userPreferences, setUserPreferences] = useState<IUserPreferences>(initUserPreferences());

  return {
    forceUpdate,
    setForceUpdate,
    page,
    setPage,
    userPreferences,
    setUserPreferences
  }
}
