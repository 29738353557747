import { useEffect } from 'react';

import { RouteName } from 'router/Config';
import { LocalStorageConstants } from 'utils/Constants';

export default function useRedirectToWelcomeBack(
  navigateTo:        (routeName: RouteName, params?: any) => void,
  basketCheckedOut:  boolean
) {
  useEffect(() => {
    if (basketCheckedOut) {
      navigateTo(RouteName.CheckedOut, `?from=${RouteName.MyMeals}`);

      return;
    }

    // Cannot use API for this value as the moment the user logs in, the last visit is updated
    const lastVisitString = localStorage.getItem(LocalStorageConstants.lastVisit);
    
    if (lastVisitString) {
      const lastVisitDate = new Date(Number(lastVisitString));
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - lastVisitDate.getTime();
      const timeDifferenceInHours = timeDifference / (1000 * 3600); // Convert milliseconds to hours

      if (timeDifferenceInHours > 24) {
        navigateTo(RouteName.WelcomeBack);

        return;
      }
    }    
  }, [basketCheckedOut]);
}
