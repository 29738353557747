import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import { getEffectiveWeeklyBudget } from 'utils/Hooks/Helper/useUserPreferencesUtils';
import { GenerateNewMealsPage } from 'enums/GenerateNewMealsPage';

export default function useHandleNextButtonClick(
  page:               GenerateNewMealsPage,
  setPage:            React.Dispatch<React.SetStateAction<GenerateNewMealsPage>>,
  userPreferences:    IUserPreferences,
  setUserPreferences: React.Dispatch<React.SetStateAction<IUserPreferences>>,
  createNewMealPlan:  ( 
    numberOfAdults:        number,
    numberOfChildren:      number,
    numberOfMealsPerWeek:  number,
    weeklyBudget:          number,
    allergies:             string[],
    dietaryPreferences:    string[],
    kitchenEquipment:      string[],
    chosenSupermarket:     string
  ) => void
) {
  const weeklyBudget = getEffectiveWeeklyBudget(userPreferences);

  const handleNextButtonClick = () => {
    if (page === GenerateNewMealsPage.Ending) return;

    setPage(prevPage => prevPage + 1);

    if (page === GenerateNewMealsPage.NumberOfMeals) {
      setUserPreferences({
        ...userPreferences,
        weeklyBudget: weeklyBudget
      })
    }

    if (page === GenerateNewMealsPage.Grocer) {
      createNewMealPlan(
        userPreferences.numberOfAdults,
        userPreferences.numberOfChildren || 0,
        userPreferences.numberOfMealsPerWeek,
        userPreferences.weeklyBudget,
        userPreferences.allergies,
        userPreferences.dietaryPreferences ? [userPreferences.dietaryPreferences] : [],
        userPreferences.kitchenEquipment,
        userPreferences.chosenSupermarket
      )
    }
  }

  return handleNextButtonClick;
}
