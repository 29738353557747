import { useContext } from 'react';

import useAddAlgorithmRecipeApi from 'utils/Hooks/Api/Algorithm/MealPlan/useAddAlgorithmRecipe';
import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';

export default function useAddAlgorithmRecipe() {
  const { setAlert } = useContext(AlertContext);

  const addAlgorithmRecipeApi = useAddAlgorithmRecipeApi();

  function addAlgorithmRecipe(
    algorithmRecipeId: number, 
    e?:                React.MouseEvent<HTMLDivElement, MouseEvent>,
    onSuccess?:        () => void
  ) {
    e && e.stopPropagation();
    
    addAlgorithmRecipeApi(algorithmRecipeId).then((response) => {
      if (response) {
        setAlert(AlertType.AddedToPlan);

        onSuccess && onSuccess();
      }
    })
  }

  return addAlgorithmRecipe;
}
