import React from 'react'

import RootGrid from '../_RootGrid'
import { AlgorithmRecipeMapping } from 'types/Mappings/AlgorithmRecipeMapping'
import { AlgorithmRecipeGridCard } from 'enums/Grids/AlgorithmRecipeGridCard'
import useOrdinalize from 'hooks/utils/useOrdinalize'

import FullControlAlgorithmRecipeCard from 'components/Cards/AlgorithmRecipeCard/FullControlAlgorithmRecipeCard'
import CountedAlgorithmRecipeCard     from 'components/Cards/AlgorithmRecipeCard/CountedAlgorithmRecipeCard'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'
import AddableAlgorithmRecipeCard from 'components/Cards/AlgorithmRecipeCard/AddableAlgorithmRecipeCard'

type Props = {
  algorithmRecipeMapping?: AlgorithmRecipeMapping[]
  algorithmRecipes?:       AlgorithmRecipe[]
  algorithmRecipeCardType: AlgorithmRecipeGridCard
  onCardClickHandler:      (algorithmRecipe:   AlgorithmRecipe, e: React.MouseEvent<HTMLDivElement>) => void
  onRemoveClickHandler?:   (algorithmRecipeId: number,          e: React.MouseEvent<HTMLButtonElement>) => void
  onAddClickHandler?:      (algorithmRecipe:   AlgorithmRecipe, e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function AlgorithmRecipeCardGrid({
  algorithmRecipeMapping,
  algorithmRecipes,
  algorithmRecipeCardType,
  onCardClickHandler,
  onRemoveClickHandler,
  onAddClickHandler
}: Props) {
  const ordinalize = useOrdinalize()

  return (
    <RootGrid
      numberOfColumns = { 2 }
    >
      {
        algorithmRecipeCardType === AlgorithmRecipeGridCard.Addable &&
          algorithmRecipes.map((algorithmRecipe: AlgorithmRecipe, index: number) => (
            <li
              key = { index }
            >
              <AddableAlgorithmRecipeCard
                algorithmRecipe = { algorithmRecipe }
                onCardClick     = { onCardClickHandler }
                onBasketClick   = { onAddClickHandler }
              />
            </li>
          ))
      }

      {
        algorithmRecipeCardType === AlgorithmRecipeGridCard.FullControl &&
          algorithmRecipeMapping.map((algorithmRecipeMap: AlgorithmRecipeMapping, index: number) => (
            <li
              key = { algorithmRecipeMap.position }
              id  = { index === algorithmRecipeMapping.length - 1 ? 'last-algorithm-recipe' : undefined }
            >
              <FullControlAlgorithmRecipeCard
                algorithmRecipe = { algorithmRecipeMap.algorithmRecipe }
                ordinalNumber   = { ordinalize(algorithmRecipeMap.position) }
                onCardClick     = { onCardClickHandler }
                onSwapClick     = { (e) => onRemoveClickHandler(algorithmRecipeMap.algorithmRecipe.id, e) }
              />
            </li>
          ))
      }

      {
        algorithmRecipeCardType === AlgorithmRecipeGridCard.Counted &&
        algorithmRecipeMapping.map((algorithmRecipeMapping: AlgorithmRecipeMapping) => (
            <li
              key = { algorithmRecipeMapping.position }
            >
              <CountedAlgorithmRecipeCard
                algorithmRecipe = { algorithmRecipeMapping.algorithmRecipe }
                ordinalNumber   = { ordinalize(algorithmRecipeMapping.position) }
                onCardClick     = { onCardClickHandler }
              />
            </li>
          ))
      }
    </RootGrid>
  )
}
