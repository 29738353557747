import React from 'react'

import './NutritionPill.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  nutrient:   string
  percentage: number
}

export default function NutritionPill({
  nutrient,
  percentage
}: Props) {
  const nutrientColors = {
    Carbs: [
      {range: [0, 15], color: "red"},
      {range: [15, 35], color: "amber"},
      {range: [35, 65], color: "green"},
      {range: [65, Infinity], color: "amber"}
    ],
    Fat: [
      {range: [0, 15], color: "red"},
      {range: [15, 35], color: "amber"},
      {range: [35, 65], color: "green"},
      {range: [65, Infinity], color: "amber"}
    ],
    Fibre: [
      {range: [0, 15], color: "red"},
      {range: [15, 35], color: "amber"},
      {range: [35, 65], color: "green"},
      {range: [65, Infinity], color: "amber"}
    ],
    Protein: [
      {range: [0, 15], color: "red"},
      {range: [15, 35], color: "amber"},
      {range: [35, 65], color: "green"},
      {range: [65, Infinity], color: "amber"}
    ],
    Calories: [
      {range: [0, 35], color: "green"},
      {range: [35, 50], color: "amber"},
      {range: [50, Infinity], color: "red"}
    ],
    Sugars: [
      {range: [0, 28], color: "green"},
      {range: [28, 44], color: "amber"},
      {range: [44, Infinity], color: "red"}      
    ],
    Salt: [
      {range: [0, 35], color: "green"},
      {range: [35, 65], color: "amber"},
      {range: [65, Infinity], color: "red"} 
    ],
    Saturated: [
      {range: [0, 35], color: "green"},
      {range: [35, 65], color: "amber"},
      {range: [65, Infinity], color: "red"}
    ]
  }
  
  const getColor = (nutrient: string, value: number) => {
    nutrient = nutrient.charAt(0).toUpperCase() + nutrient.slice(1);
    
    let colorRanges = nutrientColors[nutrient];

    if (colorRanges) {
      for(let item of colorRanges) {
        let [start, end] = item.range;
        if (value >= start && value <= end) {
          return item.color;
        }
      }
    }

    console.error(`Nutrient ${nutrient} not found`);

    return undefined;
  }

  return (
    <div
      className = {`
        nutrition-pill
        ${getColor(nutrient, percentage)}
      `}
    >
      <P14
        text = { percentage.toFixed(0) + '%' }
        colour = 'white-1'
      />
    </div>
  )
}