export default function useHandleStartLoading(
  setShowLoadingBar: (showLoadingBar: boolean) => void,
  closeModal: () => void
) {
  const handleStartLoading = () => {
    closeModal();

    setShowLoadingBar(true);

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return handleStartLoading;
}
