import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group';

import './UserOnboardingAnswerCard.scss'
import ProgressTracker from 'components/ProgressTrackers/ProgressTracker'

type Props = {
  page:            number
  offset?:         boolean
  size:            'small' | 'medium'
  childComponents: {
    [key: number]: React.ReactNode;
  }
}

export default function UserOnboardingAnswerCard({
  page,
  offset = false,
  size,
  childComponents
}: Props) {
  const [activePage, setActivePage] = useState(page);
  const [isVisible, setIsVisible] = useState(true);
  const [slideDirection, setSlideDirection] = useState('forward');
  const prevPageRef = useRef(page);

  useEffect(() => {
    if (page !== prevPageRef.current) {
      setIsVisible(false);
    }
  }, [page]);

  useEffect(() => {
    if (page > prevPageRef.current) {
      setSlideDirection('forward');
    } else {
      setSlideDirection('backward');
    }
    prevPageRef.current = page;
  }, [page]);

  const handleExited = () => {
    // Update the active page after the exit transition is complete
    setActivePage(page);
    setIsVisible(true);
  };

  return (
    <div>
      <ProgressTracker
        indexAt = { offset ? page : page - 1 }
      />

      <div
        className = 'user-onboarding-answer-card-container'
        style     = {{
          width: size === 'small' ? '650px' : '1065px'
        }}
      >
        <CSSTransition
          in         = { isVisible }
          timeout    = { 500 }
          classNames = { `slide-${slideDirection}-${size}` }
          onExited   = { handleExited }
          unmountOnExit
        >
          <div>
            { childComponents[activePage] }
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}
