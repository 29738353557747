import CoreApi from 'services/Core/CoreApi';
import { CoreAlgorithmMealPlanEndpoints } from './Config'
import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import { EnergyCost } from 'enums/EnergyCost';
import { CategoryType } from 'enums/CategoryType';

export default class AlgorithmMealPlanApi extends CoreApi {
  constructor() {
    super(CoreAlgorithmMealPlanEndpoints);
  }

  show = () => {
    const response = super.request(
      this.apiEndpoints.show()
    );

    return this.respondWithJson(response);
  }

  filter = (
    numberOfAdults:     number,
    numberOfChildren:   number,
    allergies:          string[],
    dietaryPreferences: string[],
    kitchenEquipment:   string[],
    energyCost:         EnergyCost | null,
    categories:         CategoryType[]
  ) => {
    const response = super.request(
      this.apiEndpoints.filter(
        numberOfAdults,
        numberOfChildren,
        allergies,
        dietaryPreferences,
        kitchenEquipment,
        energyCost,
        categories
      )
    );

    return this.respondWithJson(response);
  }

  addAlgorithmRecipe = (algorithmRecipeId: number) => {
    const response = super.request(
      this.apiEndpoints.addAlgorithmRecipe(algorithmRecipeId)
    );

    return this.respondWithJson(response);
  }

  removeAlgorithmRecipe = (algorithmRecipeId: number) => {
    const response = super.request(
      this.apiEndpoints.removeAlgorithmRecipe(algorithmRecipeId)
    );

    return this.respondWithJson(response);
  }

  otherAlgorithmRecipes = () => {
    const response = super.request(
      this.apiEndpoints.otherAlgorithmRecipes()
    );

    return this.respondWithJson(response);
  }

  createNewMealPlan = (
    numberOfAdults:       number,
    numberOfChildren:     number,
    numberOfMealsPerWeek: number,
    weeklyBudget:         number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    chosenSupermarket:    SupportedSupermarket
  ) => {
    const response = super.request(
      this.apiEndpoints.createNewMealPlan(
        numberOfAdults,
        numberOfChildren,
        numberOfMealsPerWeek,
        weeklyBudget,
        allergies,
        dietaryPreferences,
        kitchenEquipment,
        chosenSupermarket
      )
    );

    return this.respondWithJson(response);
  }

  continueMealPlan = (
    maintainSubstitutions: boolean
  ) => {
    const response = super.request(
      this.apiEndpoints.continueMealPlan(maintainSubstitutions)
    );

    return this.respondWithJson(response);
  }

  changeSupermarket = (chosenSupermarket: SupportedSupermarket) => {
    const response = super.request(
      this.apiEndpoints.changeSupermarket(chosenSupermarket)
    );

    return this.respondWithJson(response);
  }
}
