import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';

export default function useHandleCancelCupboardItemSwap(
  setBottomItemGridType:          (bottomItemGridType: 'recipeItems' | 'swappingItems') => void,
  setSelectedAlgorithmBottomItem: (item: IAlgorithmItem | null) => void
) {
  const handleCancelCupboardItemSwap = () => {
    setBottomItemGridType('recipeItems');

    setSelectedAlgorithmBottomItem(null);
  }

  return handleCancelCupboardItemSwap;
}
