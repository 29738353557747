import { CoreUserRegistrationEndpoints } from 'services/Core/User/RegistrationService/Config';
import CoreApi from 'services/Core/CoreApi';

export default class UserRegistrationApi extends CoreApi {
  constructor() {
    super(CoreUserRegistrationEndpoints);
  }

  register(firstName: string, email: string, password: string) {
    const response = super.request(
      this.apiEndpoints.register(firstName, email, password)
    );

    return this.respondWithJson(response);
  }

  update(firstName: string) {
    const response = super.request(
      this.apiEndpoints.update(firstName)
    );

    return this.respondWithJson(response);
  }
}
