import React from 'react'

import './AlgorithmItemCardGrid.scss'
import RootGrid from '../_RootGrid'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import { AlgorithmItemGridCard } from 'enums/Grids/AlgorithmItemGridCard'

import ReplaceableAlgorithmItemCard  from 'components/Cards/AlgorithmItemCard/ReplaceableAlgorithmItemCard'
import CupboardItemAlgorithmItemCard from 'components/Cards/AlgorithmItemCard/CupboardItemAlgorithmItemCard'
import AddAlgorithmItemCard          from 'components/Cards/AlgorithmItemCard/AddAlgorithmItemCard'
import SavedItemAlgorithmItemCard    from 'components/Cards/AlgorithmItemCard/SavedItemAlgorithmItemCard'
import SavedAdditionalItemCard       from 'components/Cards/ItemCard/SavedAdditionalItemCard'
import AdjustableAdditionalItemCard  from 'components/Cards/ItemCard/AdjustableAdditionalItemCard'

import { ItemMapping } from 'types/Mappings/ItemMapping'
import IItem from 'interfaces/backend/IItem'

type Props = {
  algorithmItems:             IAlgorithmItem[]
  additionalItems:            ItemMapping[]
  algorithmItemGridCard:      AlgorithmItemGridCard
  onCardClick:                (algorithmItem: IAlgorithmItem) => void
  onTrashClickHandler:        (algorithmItem: IAlgorithmItem, e: React.MouseEvent<HTMLDivElement>) => void
  onSubstitutionClickHandler: (algorithmItem: IAlgorithmItem, e: React.MouseEvent<HTMLDivElement>) => void
  onAddToBasketClickHandler:  (algorithmItem: IAlgorithmItem, e: React.MouseEvent<HTMLDivElement>) => void
  onIncrementAdditionalItem:  (item: IItem, e: React.MouseEvent<HTMLDivElement>) => void
  onDecrementAdditionalItem:  (item: IItem, e: React.MouseEvent<HTMLDivElement>) => void
  onAddCardClickHandler:      () => void
}

export default function AlgorithmItemCardGrid({
  algorithmItems,
  additionalItems,
  algorithmItemGridCard,
  onCardClick,
  onTrashClickHandler,
  onSubstitutionClickHandler,
  onAddToBasketClickHandler,
  onIncrementAdditionalItem,
  onDecrementAdditionalItem,
  onAddCardClickHandler
}: Props) {
  return (
    <RootGrid
      gap            = { 20 }
      containerClass = 'algorithm-item-card-grid'
    >
      {
        algorithmItems.map((algorithmItem: IAlgorithmItem) => (
          <li
            key = { algorithmItem.id }
          >
            {
              algorithmItemGridCard === AlgorithmItemGridCard.Replaceable &&
                <ReplaceableAlgorithmItemCard
                  algorithmItem       = { algorithmItem }
                  onCardClick         = { onCardClick }
                  onSubstitutionClick = { onSubstitutionClickHandler }
                  onTrashClick        = { onTrashClickHandler }
                />
            }

            {
              algorithmItemGridCard === AlgorithmItemGridCard.CupboardItem &&
                <CupboardItemAlgorithmItemCard
                  algorithmItem       = { algorithmItem }
                  onCardClick         = { onCardClick }
                  onSubstitutionClick = { onSubstitutionClickHandler }
                  onAddToBasketClick  = { onAddToBasketClickHandler }
                />
            }

            {
              algorithmItemGridCard === AlgorithmItemGridCard.SavedItem &&
                <SavedItemAlgorithmItemCard
                  algorithmItem = { algorithmItem }
                  onCardClick   = { onCardClick }
                />
            }
          </li>
        ))
      }

      {/* TODO: Refactor to separate component */}
      {
        algorithmItemGridCard !== AlgorithmItemGridCard.CupboardItem && additionalItems.map((itemMapping: ItemMapping) => (
          <li
            key       = { itemMapping.item.id }
            className = 'additional-item'
          >
            <div
              className = 'tagline'
            >
              <P14
                text   = 'Added Ingredient'
                colour = 'grey-1'
              />
            </div>

            {
              algorithmItemGridCard === AlgorithmItemGridCard.SavedItem ?
              <SavedAdditionalItemCard
                item        = { itemMapping.item }
                onCardClick = { () => {} }
              /> :
              <AdjustableAdditionalItemCard
                item                    = { itemMapping.item }
                quantityRequired        = { itemMapping.quantityRequired }
                onCardClick             = { () => {} }
                incrementAdditionalItem = { onIncrementAdditionalItem }
                decrementAdditionalItem = { onDecrementAdditionalItem }
              />
            }
          </li>
        ))
      }

      {/* This Component is only used on the Recipe page -> will always show Add Button */}
      {
        algorithmItemGridCard !== AlgorithmItemGridCard.CupboardItem && algorithmItemGridCard !== AlgorithmItemGridCard.SavedItem && <AddAlgorithmItemCard 
          onClick = { onAddCardClickHandler }
        />
      }
    </RootGrid>
  )
}
