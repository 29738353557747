import useActions from './Core/useActions';
import useHooks   from './Core/useHooks';

export default function useSavedPlan() {
  const {
    algorithmSavedPlan,
    navigateTo
  } = useHooks();

  const {
    handleRecipeCardClick
  } = useActions(algorithmSavedPlan, navigateTo);

  return {
    algorithmSavedPlan,
    handleRecipeCardClick
  };
}
