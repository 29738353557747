import React from 'react'

import './H2Text.scss'

type Props = {
  text:    string
  colour?: 'pink-1'
}

export default function H2Text({ 
  text,
  colour
}: Props) {
  return (
    <h2
      className = { colour }
    >
      { text }
    </h2>
  )
}
