import React from 'react'

import './RootAlgorithmItemCard.scss'
import P14 from 'components/Text/Paragraphs/SemiBold/P14'
import P12 from 'components/Text/Paragraphs/SemiBold/P12'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import FallbackItemImage from 'components/Images/Fallback/FallbackItemImage'

type Props = {
  algorithmItem:   IAlgorithmItem
  price:           number
  onCardClick?:    (algorithmItem: IAlgorithmItem) => void
  className?:      string
  buttonChildren?: React.ReactNode
}

export default function RootAlgorithmItemCard({
  algorithmItem,
  price,
  onCardClick,
  className,
  buttonChildren
}: Props) {
  return (
    <div
      className = {`
        root-algorithm-item-card
        ${className || ''}
      `}
      onClick   = { () => onCardClick && onCardClick(algorithmItem) }
    >
      <FallbackItemImage
        className = 'image'
        src       = { algorithmItem.item.imageUrl }
        alt       = { algorithmItem.item.name }
      />

      <div
        className = 'card-content'
      >
        <div>
          <div
            className = 'title'
          >
            <P14
              text   = { algorithmItem.item.name }
              colour = 'black-1'
            />
          </div>

          <P12
            text   = { algorithmItem.item.standardisedPrice }
            colour = 'grey-1'
          />
        </div>

        <div
          className = 'price'
        >
          {
            algorithmItem.item.promotion !== null &&
            <div
              className = 'previous-price'
            >
              <P12
                text   = { `£${algorithmItem.item.promotion.previousPrice.toFixed(2)}` }
                colour = 'pink-1'
              />
            </div>
          }

          <P16
            text   = { `£${price.toFixed(2)}` }
            colour = 'black-1'
          />
        </div>

        <div
          className = 'card-button'
        >
          { buttonChildren }
        </div>
      </div>
    </div>
  )
}
