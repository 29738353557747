import React from 'react'

import './MealiaBotCard.scss'
import { DashboardTextType } from 'types/DashboardTextType'
import DashboardText from 'components/Text/DashboardText'
import CloseButton from 'components/Buttons/CloseButton'
import MealiaMascot from 'components/Animations/MealiaMascot'

type Props = {
  textObjects:  DashboardTextType[]
  startTyping?: boolean
  closeModal?:  () => void
}

export default function MealiaBotCard({
  textObjects,
  startTyping = true,
  closeModal
}: Props) {
  return (
    <div
      className = 'mealia-bot-card-container'
    >
      <MealiaMascot
        shouldPause = { !closeModal }
      />
      
      <div
        className = 'mealia-bot-card-text'
      >
        <DashboardText
          textObjects       = { textObjects }
          shouldDelayTyping = { false }
          startTyping       = { startTyping }
        />
      </div>

      {
        closeModal && <div
          className = 'mealia-bot-card-close-button'
        >
          <CloseButton
            onClickHandler = { closeModal }
          />
        </div>
      }
    </div>
  )
}
