import { useEffect } from 'react';

import { RouteName } from 'router/Config';

export default function useRedirect(
  basketCheckedOut: boolean,
  navigateTo:       (routeName: RouteName, params?: any) => void
) {
  useEffect(() => {
    if (!basketCheckedOut) return

    navigateTo(RouteName.CheckedOut, `?from=${RouteName.Groceries}`);
  }, [])
}
