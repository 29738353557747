import { RouteName } from 'router/Config';

export default function useHandleDropDownSuggestionClick(
  setSearchValue: (value: string) => void,
  navigateTo:     (routeName: RouteName, params?: any) => void
) {
  const handleDropDownSuggestionClick = (suggestion: string) => {
    setSearchValue(suggestion);
    
    if (suggestion.length === 0) return;
    
    navigateTo(RouteName.Search, `?query=${suggestion}`);
  }

  return handleDropDownSuggestionClick;
}
