import React from 'react'

import { OnboardingQuestionProps } from '../Shared/OnboardingQuestionProps'
import BudgetPreference from 'components/features/UserPreferences/BudgetPreference'
import { getBudgetLimits } from 'utils/Hooks/Helper/useUserPreferencesUtils'

export default function Budget({
  userPreferences,
  setUserPreferences
}: OnboardingQuestionProps) {
  const [upperBudget, lowerBudget] = getBudgetLimits(userPreferences)
  
  const handleBudgetChange = (value: number[]) => {
    setUserPreferences({
      ...userPreferences,
      weeklyBudget: value[0]
    })
  }

  return (
    <BudgetPreference
      lowerBudget        = { lowerBudget }
      upperBudget        = { upperBudget }
      weeklyBudget       = { [ userPreferences.weeklyBudget ] }
      handleBudgetChange = { handleBudgetChange }
    />
  )
}
