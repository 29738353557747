import React, { useContext, useState } from 'react'

import './ChangeSupermarketModal.scss'
import RootModal from '../_RootModal'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import ModalSecondaryButton from 'components/Buttons/ModalSecondaryButton'
import UserContext from 'context/UserContext'
import useChangeSupermarket from 'utils/Hooks/Actions/Algorithm/MealPlan/useChangeSupermarket'
import LoadingContext from 'context/LoadingContext'
import User from 'models/User'
import { SupportedSupermarket } from 'enums/SupportedSupermarket'
import GrocerPreference from 'components/features/UserPreferences/GrocerPreference'

type Props = {
  isOpen: boolean
  close:  () => void
}

export default function ChangeSupermarketModal({
  isOpen,
  close
}: Props) {
  const { user }: { user: User } = useContext(UserContext)
  const [chosenSupermarket, setChosenSupermarket] = useState<SupportedSupermarket>(user.chosenSupermarket)

  const { loading, setLoading }: { loading: boolean, setLoading: (loading: boolean) => void } = useContext(LoadingContext);

  const changeSupermarket = useChangeSupermarket()

  const generateTopTextObject = (): DashboardTextType => {
    if (loading) {
      return {
        line:  1,
        value: `Changing supermarket`,
        bold:  false
      }
    }

    return {
      line:  1,
      value: `Would you like to change supermarket?`,
      bold:  false
    }
  }

  const generateBottomTextObject = (): DashboardTextType => {
    if (loading) {
      return {
        line:  2,
        value: `Give me a moment...`,
        bold:  false
      }
    }

    return {
      line:  2,
      value: `Just a heads up, a new plan will be generated if you do.`,
      bold:  false
    }
  }

  function changeSupermarketClick() {
    if (chosenSupermarket === user.chosenSupermarket) {
      close()

      return
    }

    setLoading(true)

    changeSupermarket(chosenSupermarket) // This refreshes the page so no need to close the modal
  }

  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'small'
    >
      <div
        className = 'change-supermarket-modal-container'
      >
        <MealiaBotCard
          textObjects = { [ generateTopTextObject(), generateBottomTextObject() ] }
          startTyping = { loading }
          closeModal  = { close }
        />

        {
          !loading && <div
            className = 'change-supermarket-modal-content'
          >
            <GrocerPreference
              chosenSupermarket          = { chosenSupermarket }
              handleSupermarketSelection = { setChosenSupermarket }
            />
          </div>
        }

        {
          !loading && <div
            className = 'change-supermarket-modal-buttons'
          >
            <PrimaryButton
              text           = 'Change Supermarket'
              onClickHandler = { changeSupermarketClick }
            />

            <ModalSecondaryButton
              text    = 'Cancel'
              onClick = { close }
            />
          </div>
        }
      </div>
    </RootModal>
  )
}
