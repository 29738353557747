import { useEffect, useState } from 'react';

export function useDeselectPill(
  initialOptions: string[],
  deselector:     string
) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialOptions);

  useEffect(() => {
    setSelectedOptions(initialOptions);
  }, [initialOptions]);
  
  const handleOptionClick = (option: string) => {
    if (option === deselector) {
      setSelectedOptions([deselector]);
    } else {
      if (selectedOptions.includes(deselector)) {
        setSelectedOptions([option]);
      } else if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption !== option));
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    }    
  }

  return [
    selectedOptions,
    handleOptionClick
  ] as const;
}
