import UserApi from 'services/Core/UserService/UserApi';

export default function useGoogleRegister() {
  function googleRegister(code: string): Promise<any> {
    const userApi = new UserApi();

    return userApi.userGoogleRegister(code);
  }

  return googleRegister;
}
