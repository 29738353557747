import React from 'react'

import P14 from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  text:           string,
  colour?:        'pink-1' | 'grey-1',
  onClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function TertiaryButton({ 
  text, 
  colour = 'pink-1',
  onClickHandler 
}: Props) {
  return (
    <button
      onClick = { (e) => onClickHandler(e) }
    >
      <P14
        text    = { text }
        colour  = { colour }
      />
    </button>
  )
}
