import AlgorithmMealPlanApi from 'services/Core/Algorithm/MealPlanService/AlgorithmMealPlanApi';

export default function useShow() {
  function show(): Promise<any> {
    const algorithmMealPlan = new AlgorithmMealPlanApi();

    return algorithmMealPlan.show();
  }

  return show;
}
