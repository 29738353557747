import { BotPage } from 'enums/BotPage';
import MessageApi from 'services/Core/MessageService/MessageApi';

export default function useShow() {
  function show(name: BotPage) {
    const messageApi = new MessageApi();

    return messageApi.show(name);
  }

  return show;
}
