import { useContext } from 'react';

import AlertContext from 'context/AlertContext';
import useAddToBasketApi from 'utils/Hooks/Api/Algorithm/Item/useAddToBasket';
import { AlertType } from 'enums/AlertType';
import useShow from '../Basket/useShow';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useAddToBasket() {
  const { setAlert } = useContext(AlertContext);
  const addToBasketApi = useAddToBasketApi();

  const show = useShow();

  function addToBasket(
    algorithmChosenItemId: number,
    setAlgorithmRecipe: (algorithmRecipe: AlgorithmRecipe) => void
  ) {
    addToBasketApi(algorithmChosenItemId).then((response) => {
      const algorithmRecipe: AlgorithmRecipe = new AlgorithmRecipe(response.data);

      setAlgorithmRecipe(algorithmRecipe);

      setAlert(AlertType.AddedIngredient)
    }).then(() => {
      show();
    });
  }

  return addToBasket;
}
