import { useEffect } from 'react';

import User from 'models/User';
import { RouteName } from 'router/Config';

export default function useRedirectToMyMeals(
  user:       User,
  navigateTo: (routeName: RouteName) => void
) {
  useEffect(() => {
    // Prevents user from accessing onboarding if they already have a household
    if (!user.household) return

    if (user.household) {
      navigateTo(RouteName.MyMeals);
    }
  }, []);
}
