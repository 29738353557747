import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import useCreateNewMealPlanApi from 'utils/Hooks/Api/Algorithm/MealPlan/useCreateNewMealPlan';

export default function useCreateNewMealPlan() {
  const createNewMealPlanApi = useCreateNewMealPlanApi();

  function createNewMealPlan(
    numberOfAdults:       number,
    numberOfChildren:     number,
    numberOfMealsPerWeek: number,
    weeklyBudget:         number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    chosenSupermarket:    SupportedSupermarket
  ) {
    createNewMealPlanApi(
      numberOfAdults,
      numberOfChildren,
      numberOfMealsPerWeek,
      weeklyBudget,
      allergies,
      dietaryPreferences,
      kitchenEquipment,
      chosenSupermarket
    ).then(() => {
      window.location.reload();
    });
  }

  return createNewMealPlan;
}
