import { ErrorType } from 'utils/Errors';
import useCreateApi from 'utils/Hooks/Api/User/Confirmation/useCreate';

export default function useCreate() {
  const createApi = useCreateApi();

  function create(
    email: string,
    setError: (error: ErrorType) => void,
    setPage: (page: 'Input' | 'Confirmation' | 'Reconfirm') => void
  ) {
    createApi(email).then((response) => {
      setPage('Reconfirm');
    }).catch((error) => {
      setError(error);
    })
  }

  return create;
}
