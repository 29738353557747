import { createContext } from 'react';

import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';

export const AlgorithmMealPlanContext = createContext({
  algorithmMealPlan:    null as AlgorithmMealPlan | null, 
  setAlgorithmMealPlan: ( algorithmMealPlan: AlgorithmMealPlan ) => {}
});

export default AlgorithmMealPlanContext;
