import IRecipe from 'interfaces/backend/IRecipe';
import IAlgorithmRecipe from 'interfaces/backend/Algorithm/IAlgorithmRecipe';
import INutritionInformation from 'interfaces/backend/INutritionInformation';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import { ItemMapping } from 'types/Mappings/ItemMapping';

export default class AlgorithmRecipe implements IAlgorithmRecipe {
  id:                     number;
  recipe:                 IRecipe;
  pricePerServing:        number;
  totalCost:              number;
  numberOfAdults:         number;
  numberOfChildren:       number;
  nutritionalInformation: INutritionInformation;
  nutritionalScore:       number;
  allergens:              string[];
  modified:               boolean;
  algorithmItems:         IAlgorithmItem[];
  additionalItems:        ItemMapping[];

  constructor(data: IAlgorithmRecipe) {
    Object.assign(this, data);
  }

  get numberOfCupboardItems(): number {
    return this.cupboardItems.length || 0;
  }

  get numberOfFreshItems(): number {
    return this.freshItems.length;
  }

  get numberOfPeople(): number {
    return this.numberOfAdults + this.numberOfChildren;
  }

  private get cupboardItems(): IAlgorithmItem[] {
    return this.algorithmItems.filter((algorithmItem) => {
      return algorithmItem.recipeIngredient.cupboardItem
    });
  }

  private get freshItems(): IAlgorithmItem[] {
    return this.algorithmItems.filter((algorithmItem) => {
      return !algorithmItem.recipeIngredient.cupboardItem
    });
  }
}
