import { useContext } from 'react';

import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';

export default function useBasketCheckedOut() {
  const { algorithmBasket }: { algorithmBasket: AlgorithmBasket } = useContext(AlgorithmBasketContext)

  return algorithmBasket?.checkedOut;
}
