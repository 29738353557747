import { CoreConfig } from 'services/Core/Config';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

const user_wrapper = `api_${CoreConfig.CORE_API_VERSION}_user`;

export const CoreUserRegistrationEndpoints: BaseApiEndpoint = {
  register: (firstName: string, email: string, password: string) => ({
    url:    'users/registrations/register',
    method: 'POST',
    auth:   false,
    body:   {
      [user_wrapper]: {
        first_name: firstName,
        email:      email,
        password:   password
      }
    }
  }),
  update: (firstName: string) => ({
    url:    'users/registrations/update',
    method: 'PUT',
    auth:   true,
    body:   {
      [user_wrapper]: {
        first_name: firstName
      }
    }
  })
}
