import React from 'react'

import './MyMeals.scss'
import Navigation from '../Shared/Navigation'
import { LoadingStatus } from 'enums/LoadingStatus'
import { AlgorithmRecipeGridCard } from 'enums/Grids/AlgorithmRecipeGridCard'
import { CategoryType } from 'enums/CategoryType'
import { MyMealsModalType } from 'enums/Modals/MyMealsModalType'
import { MyMealsFilterType } from 'enums/MyMealsFilterType'
import ImageLoadChecker from 'components/base/ImageLoadChecker'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import MyMealsDashboardCard from 'components/Cards/Dashboard/MyMealsDashboardCard'
import TitleText from 'components/Text/TitleText'
import Notification from 'components/Notification'
import AlgorithmRecipeCardGrid from 'components/Grids/AlgorithmRecipeCardGrid'
import CategoryCard from 'components/Cards/CategoryCard'
import AlgorithmRecipeSkeletonGrid from 'components/Grids/SkeletonGrids/AlgorithmRecipeSkeletonGrid'
import FilterModal from 'components/Modals/FilterModal'

import useMyMeals from 'hooks/Pages/PostLogin/01-MyMeals/useMyMeals';

export default function MyMeals() {
  const {
    algorithmBasket,
    algorithmMealPlan,
    user,
    activeModal,
    closeModal,
    handleRecipeCardClick,
    handleAddRecipe,
    handleRemoveRecipe,
    loadingStatus,
    filterType,
    showNotification,
    subsectionHeaderRef,
    otherRecipes,
    filterTexts,
    handleImagesLoaded,
    handleFilterClick,
    handleCategoryClick,
    handleFilterContinue,
    handleNewMealsClick,
    handleNotificationClick
  } = useMyMeals();

  return (
    <>
      <Navigation>
        <div
          className = 'my-meals-container'
        >
          <MyMealsDashboardCard />

          <span
            style = {{ height: '8px' }}
          />

          <TitleText
            text = { `Meals In My Basket (${algorithmBasket?.numberOfRecipes})` }
            id   = 'my-meals'
          >
            <SecondaryButton
              text    = 'Generate New Meals'
              onClick = { handleNewMealsClick }
            />
          </TitleText>

          <span />

          {
            loadingStatus === LoadingStatus.Initial ?
            <AlgorithmRecipeSkeletonGrid
              numberOfCards = { user.household.numberOfMealsPerWeek }
            />
            :
            loadingStatus === LoadingStatus.APIFinished ?
            <ImageLoadChecker
              images            = { algorithmMealPlan?.imageUrls || [] }
              onAllImagesLoaded = { handleImagesLoaded }
            />
            :
            <AlgorithmRecipeCardGrid
              algorithmRecipeMapping  = { algorithmMealPlan?.algorithmRecipeMapping || [] }
              algorithmRecipeCardType = { AlgorithmRecipeGridCard.FullControl }
              onCardClickHandler      = { handleRecipeCardClick }
              onRemoveClickHandler    = { handleRemoveRecipe }
            />
          }

          <span
            style = {{ height: '60px' }}
          />

          <TitleText
            text = { `Other Recipes (${otherRecipes.length === 0 ? '...' : otherRecipes.length})` }
            id   = 'other-recipes'
          >
            <div
              className = 'my-meals-other-recipes-filters'
            >
              {
                Object.keys(filterTexts).map((filterType, index) => {
                  return (
                    <SecondaryButton
                      key     = { index }
                      text    = { filterTexts[filterType] }
                      onClick = { () => handleFilterClick(filterType as MyMealsFilterType) }
                    />
                  )
                })
              }
            </div>
          </TitleText>

          <div
            className = 'my-meals-other-recipes-category-filters'
          >
            {
              Object.keys(CategoryType).filter(key => isNaN(Number(key))).map((categoryType, index) => {
                return (
                  <CategoryCard
                    key        = { index }
                    type       = { CategoryType[categoryType as keyof typeof CategoryType] }
                    isSelected = { categoryType === CategoryType.All ? user.household.categories.length === 0 : user.household.categories.includes(CategoryType[categoryType as keyof typeof CategoryType]) }
                    onClick    = { handleCategoryClick }
                  />
                )
              })
            }
          </div>

          <span
            ref = { subsectionHeaderRef }
          />

          <AlgorithmRecipeCardGrid
            algorithmRecipes        = { otherRecipes }
            algorithmRecipeCardType = { AlgorithmRecipeGridCard.Addable }
            onCardClickHandler      = { handleRecipeCardClick }
            onAddClickHandler       = { handleAddRecipe }
          />

          {
            otherRecipes.length === 0 && <span
              style = {{ height: '900px' }}
            />
          }
        </div>
      </Navigation>

      <FilterModal
        user           = { user }
        isOpen         = { activeModal === MyMealsModalType.Filter }
        close          = { closeModal }
        filterType     = { filterType }
        handleContinue = { handleFilterContinue }
      />

      <Notification
        showNotification     = { showNotification }
        numberOfOtherRecipes = { otherRecipes?.length || 0 }
        onClick              = { handleNotificationClick }
      />
    </>
  )
}
