import CoreApi from 'services/Core/CoreApi';
import { CoreAlgorithmSavedPlanEndpoints } from './Config'

export default class AlgorithmSavedPlanApi extends CoreApi {
  constructor() {
    super(CoreAlgorithmSavedPlanEndpoints);
  }

  getAllSavedPlans = () => {
    const response = super.request(
      this.apiEndpoints.getAllSavedPlans()
    );

    return this.respondWithJson(response);
  }

  show = (algorithmSavedPlanId: number) => {
    const response = super.request(
      this.apiEndpoints.show(algorithmSavedPlanId)
    );

    return this.respondWithJson(response);
  }
}
