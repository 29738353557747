import { ItemMapping } from 'types/Mappings/ItemMapping';

import { RouteName } from 'router/Config';
import { BasketModalType } from 'enums/Modals/BasketModalType';

import useHandleAcceptDeleteItem from '../Operations/Actions/useHandleAcceptDeleteItem';
import useHandleAddCupboardItem  from '../Operations/Actions/useHandleAddCupboardItem';
import useHandleFinishLoading    from '../Operations/Actions/useHandleFinishLoading';
import useHandleRemoveItem       from '../Operations/Actions/useHandleRemoveItem';
import useHandleStartLoading     from '../Operations/Actions/useHandleStartLoading';
import User from 'models/User';

export default function useActions(
  show:              () => void,
  tempItem:          { name: string; id: number; },
  setTempItem:       (tempItem: { name: string; id: number; }) => void,
  setShowLoadingBar: (showLoadingBar: boolean) => void,
  setItemMapping:    (itemMapping: ItemMapping[]) => void,
  removeItem:        (itemId: number, setItemMapping: (itemMapping: ItemMapping[]) => void) => void,
  addCupboardItem:   (itemId: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<any>,
  itemDetails:       (setItemMapping: (itemMapping: ItemMapping[]) => void) => Promise<any>,
  navigateTo:        (routeName: RouteName) => void,
  user:              User,
  isExtensionInstalledPreviously: boolean,
  checkout:          () => void,
  openModal:         (modalType: BasketModalType) => void,
  closeModal:        () => void
) {
  const handleAcceptDeleteItem = useHandleAcceptDeleteItem(tempItem, setItemMapping, removeItem, closeModal);
  const handleAddCupboardItem  = useHandleAddCupboardItem(show,  setItemMapping, itemDetails, addCupboardItem);
  const handleStartLoading     = useHandleStartLoading(setShowLoadingBar, closeModal);
  const handleFinishLoading    = useHandleFinishLoading(navigateTo, checkout, user, isExtensionInstalledPreviously);
  const handleRemoveItem       = useHandleRemoveItem(setTempItem, openModal);
  
  return {
    handleAcceptDeleteItem,
    handleAddCupboardItem,
    handleStartLoading,
    handleFinishLoading,
    handleRemoveItem
  }
}
