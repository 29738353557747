import { useState } from 'react';

import { OnboardingPage } from 'enums/OnboardingPage';
import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import { initUserPreferences } from 'utils/Hooks/Helper/useUserPreferencesUtils';

export default function useStates() {
  const [forceUpdate, setForceUpdate] = useState(false);

  const [completed, setCompleted] = useState(false);

  const [onboardingPage, setOnboardingPage]   = useState(OnboardingPage.Intro);
  const [userPreferences, setUserPreferences] = useState<IUserPreferences>(initUserPreferences());

  return {
    forceUpdate,
    setForceUpdate,
    completed,
    setCompleted,
    onboardingPage,
    setOnboardingPage,
    userPreferences,
    setUserPreferences
  }
}
