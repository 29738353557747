import React from 'react'

import './FreshItemAlgorithmItemCard.scss'
import RootAlgorithmItemCard from '../_RootAlgorithmItemCard'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import PinkCircle from 'components/base/Shapes/PinkCircle'

type Props = {
  algorithmItem: IAlgorithmItem
}

export default function FreshItemAlgorithmItemCard({
  algorithmItem,
}: Props) {
  return (
    <div
      className = 'fresh-item-algorithm-item-card-container'
    >
      <RootAlgorithmItemCard
        algorithmItem = { algorithmItem }
        price         = { algorithmItem.totalPrice }
        buttonChildren = {
          <PinkCircle
            number = { algorithmItem.quantityRequired }
          />
        }
      />
    </div>
  )
}