import { ErrorType } from '..'

type PreLoginError = {
  type:       ErrorType
  title:      string
  message?:   (email: string) => string
  buttonCopy: string
}

export const preLoginError: PreLoginError[] = [
  {
    type: ErrorType.IncorectDetails,
    title: 'Incorrect details',
    message: () => 'The details you entered are incorrect. Please try again',
    buttonCopy: 'Try Again'
  },
  {
    type: ErrorType.WrongProvider,
    title: 'Uh oh!',
    message: () => 'Looks like you have signed up using Google. Try that instead.',
    buttonCopy: 'Try Google Log In'
  },
  {
    type: ErrorType.EmailNotVerified,
    title: 'Email not verified',
    message: (email: string) => `You need to confirm your email address to log in. We can send a new verification link to ${email} if you want!`,
    buttonCopy: 'Send a new link'
  },
  {
    type: ErrorType.EmailAlreadyInUse,
    title: 'Email already in use',
    message: () => 'This email is already in use. Please try again.',
    buttonCopy: 'Try Again'
  },
  {
    type: ErrorType.PasswordTooShort,
    title: 'Password too short',
    message: () => 'Your password must be at least 6 characters long.',
    buttonCopy: 'Try Again'
  },
  {
    type: ErrorType.BlankField,
    title: 'Blank field',
    message: () => 'Please fill in all fields.',
    buttonCopy: 'Try Again'
  },
  {
    type: ErrorType.PasswordsDontMatch,
    title: 'Passwords don\'t match',
    message: () => 'Your passwords don\'t match. Please try again.',
    buttonCopy: 'Try Again'
  },
  {
    type: ErrorType.EmailNotValid,
    title: 'Email not valid',
    message: () => 'Please enter a valid email address.',
    buttonCopy: 'Try Again'
  },
  {
    type: ErrorType.SignatureHasExpired,
    title: 'Signature has expired',
    message: () => 'The signature has expired. Please try again.',
    buttonCopy: 'Try Again'
  },
  {
    type: ErrorType.UsedPasswordToken,
    title: 'Used password token',
    message: () => 'This password token has already been used. Please request a new one.',
    buttonCopy: 'Try Again'
  },
  {
    type: ErrorType.AlreadyConfirmed,
    title: 'Already confirmed',
    message: () => 'This email has already been confirmed. Please log in.',
    buttonCopy: 'Got it!'
  },
  {
    type: ErrorType.Unknown,
    title: 'Unknown error',
    message: () => 'Something went wrong. Please try again.',
    buttonCopy: 'Try Again'
  }
]

export const getPreLoginError = (type: ErrorType) => {
  return preLoginError.find(error => error.type === type)
}