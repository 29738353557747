import AlgorithmBasketApi from 'services/Core/Algorithm/BasketService/AlgorithmBasketApi';

export default function useIncrementShoppingItem() {
  function incrementShoppingItem(supermarketItemId: number): Promise<any> {
    const algorithmBasketApi = new AlgorithmBasketApi();
    
    return algorithmBasketApi.incrementShoppingItem(supermarketItemId);
  }

  return incrementShoppingItem;
}
