import { RouteName } from 'router/Config';
import { MyMealsModalType } from 'enums/Modals/MyMealsModalType';
import { MyMealsFilterType } from 'enums/MyMealsFilterType';
import { LoadingStatus } from 'enums/LoadingStatus';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

import useHandleRemoveRecipe from '../Operations/Actions/useHandleRemoveRecipe';
import useHandleImagesLoaded from '../Operations/Actions/useHandleImagesLoaded';
import useHandleFilterClick from '../Operations/Actions/useHandleFilterClick';
import useHandleRecipeCardClick from '../Operations/Actions/useHandleRecipeCardClick';
import useHandleFilterContinue from '../Operations/Actions/useHandleFilterContinue';
import useHandleAddRecipe from '../Operations/Actions/useHandleAddRecipe';
import useHandleNewMealsClick from '../Operations/Actions/useHandleNewMealsClick';
import User from 'models/User';
import useHandleNotificationClick from '../../02-GenerateNewMeals/Operations/Actions/useHandleNotificationClick';
import { EnergyCost } from 'enums/EnergyCost';
import useHandleCategoryClick from '../Operations/Actions/useHandleCategoryClick';
import { CategoryType } from 'enums/CategoryType';

export default function useActions(
  setLoadingStatus:    (loadingStatus: LoadingStatus) => void,
  openModal:           (modalType: MyMealsModalType) => void,
  closeModal:          () => void,
  setFilterType:       (filterType: MyMealsFilterType | null) => void,
  addAlgorithmRecipe:  (algorithmRecipeId: number, e?: React.MouseEvent<HTMLDivElement, MouseEvent>, onSuccess?: () => void) => void,
  setOtherRecipes:     (otherRecipes: AlgorithmRecipe[]) => void,
  navigateTo:          (routeName: RouteName) => void,
  user:                User,
  filter:              (numberOfAdults: number, numberOfChildren: number, allergies: string[], dietaryPreferences: string[], kitchenEquipment: string[], energyCost: EnergyCost, categories: CategoryType[]) => Promise<any>,
  setShowNotification: (showNotification: boolean) => void
) {
  const handleRecipeCardClick   = useHandleRecipeCardClick();
  const handleAddRecipe         = useHandleAddRecipe(addAlgorithmRecipe, setOtherRecipes);
  const handleRemoveRecipe      = useHandleRemoveRecipe(setOtherRecipes);
  const handleImagesLoaded      = useHandleImagesLoaded(setLoadingStatus);
  const handleFilterClick       = useHandleFilterClick(openModal, setFilterType);
  const handleCategoryClick     = useHandleCategoryClick(user, setOtherRecipes, filter); 
  const handleFilterContinue    = useHandleFilterContinue(user, setOtherRecipes, filter, closeModal);
  const handleNewMealsClick     = useHandleNewMealsClick(navigateTo);
  const handleNotificationClick = useHandleNotificationClick(setShowNotification)

  return {
    handleRecipeCardClick,
    handleAddRecipe,
    handleRemoveRecipe,
    handleImagesLoaded,
    handleFilterClick,
    handleCategoryClick,
    handleFilterContinue,
    handleNewMealsClick,
    handleNotificationClick
  }
}
