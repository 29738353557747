import React, { useContext } from 'react'

import RootItemCard from '../_RootItemCard/RootItemCard'
import IItem from 'interfaces/backend/IItem'
import AlgorithmBasketContext from 'context/AlgorithmBasketContext'
import { ShoppingItemMapping } from 'types/Mappings/ShoppingItemMapping'

import useIncrementShoppingItem from 'utils/Hooks/Actions/Algorithm/Basket/useIncrementShoppingItem'
import useDecrementShoppingItem from 'utils/Hooks/Actions/Algorithm/Basket/useDecrementShoppingItem'
import AddToBasketButton from 'components/Buttons/AddToBasketButton/AddToBasketButton'
import FullWithCountIncrementorButton from 'components/Buttons/IncrementorButton/FullWithCount'
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket'

type Props = {
  item:        IItem
  onCardClick: (item: IItem) => void
}

export default function AdjustableItemCard({
  item,
  onCardClick
}: Props) {
  const { algorithmBasket }: { algorithmBasket: AlgorithmBasket } = useContext(AlgorithmBasketContext);

  const incrementShoppingItem = useIncrementShoppingItem()
  const decrementShoppingItem = useDecrementShoppingItem()

  const itemMapping: ShoppingItemMapping = algorithmBasket.getShoppingItemMappingForItem(item);

  const showIncrementor = () => {
    return itemMapping && itemMapping.quantity > 0;
  }

  return (
    <RootItemCard
      item           = { item }
      onCardClick    = { onCardClick }
      buttonChildren = {
        showIncrementor() ?
        <FullWithCountIncrementorButton
          quantity     = { itemMapping.quantity }
          onTrashClick = { (e: React.MouseEvent<HTMLDivElement>) => decrementShoppingItem(e, item) }
          onAddClick   = { (e: React.MouseEvent<HTMLDivElement>) => incrementShoppingItem(e, item) }
        />
        :
        <AddToBasketButton
          onClick = { (e) => incrementShoppingItem(e, item) }
          size    = 'medium'
        />
      }
    />
  )
}
