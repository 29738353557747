import UserConfirmationApi from 'services/Core/User/ConfirmationService/UserConfirmationApi';

export default function useConfirm() {
  function confirm(emailConfirmationToken: string): Promise<any> {
    const userConfirmationApi = new UserConfirmationApi();

    return userConfirmationApi.confirm(emailConfirmationToken);
  }

  return confirm;
}
