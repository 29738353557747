import { DashboardTextType } from 'types/DashboardTextType';
import { GenerateNewMealsPage } from 'enums/GenerateNewMealsPage';
import IUserPreferences from 'interfaces/frontend/IUserPreferences';

import useDisabledPrimaryButton from '../Operations/Computed/useDisabledPrimaryButton';
import useBotMessage from '../Operations/Computed/useBotMessage';

export default function useComputed(
  page:                       GenerateNewMealsPage,
  userPreferences:            IUserPreferences,
  numberOfPeopleMessages:     DashboardTextType[],
  numberOfMealsMessages:      DashboardTextType[],
  budgetMessages:             DashboardTextType[],
  dietaryPreferencesMessages: DashboardTextType[],
  allergiesMessages:          DashboardTextType[],
  kitchenEquipmentMessages:   DashboardTextType[],
  grocerMessages:             DashboardTextType[],
  endingMessages:             DashboardTextType[]
) {
  const disabledPrimaryButton = useDisabledPrimaryButton(page, userPreferences);
  const botMessage = useBotMessage(page, numberOfPeopleMessages, numberOfMealsMessages, budgetMessages, dietaryPreferencesMessages, allergiesMessages, kitchenEquipmentMessages, grocerMessages, endingMessages);

  return {
    disabledPrimaryButton,
    botMessage
  }
}
