import React from 'react'

import './RootModal.scss'
import useDisableScrolling from 'hooks/ui/useDisableScrolling'

type Props = {
  isOpen:        boolean
  close:         () => void
  padding?:      'default' | 'small' | 'none'
  children?:     React.ReactNode
}

export default function RootModal({
  isOpen,
  close,
  padding = 'default',
  children
}: Props) {  
  useDisableScrolling(isOpen);

  function handleDocumentClick(event) {
    const modalChildrenContainer = document.querySelector('.root-modal-children-container');
    const modalChildren = document.querySelector('.root-modal-children');
    
    if (!modalChildrenContainer.contains(event.target)) {
      if (modalChildren.contains(event.target)) return;

      handleCloseModalClick();
    }
  }

  // TODO: Bug fix - when there is a close button within the modal children, there is no animation,
  // just a sudden close.
  const handleCloseModalClick = () => {
    const rootModal = document.querySelector('.root-modal-test');
    const modalCardContainer = document.getElementsByClassName('root-modal-children-container')[0]

    if (rootModal) {
      rootModal.classList.remove('root-modal-test-open');
      rootModal.classList.add('root-modal-test-closing');
    }

    if (modalCardContainer) {
      modalCardContainer.classList.add('root-modal-children-container-closing')
    }

    setTimeout(() => {
      if (rootModal) {
        rootModal.classList.remove('root-modal-test-closing');
      }
      close()
    }, 500)
  }

  if (!isOpen) {
    return null
  }

  return (
    <div
      className = {`root-modal-test ${isOpen ? 'root-modal-test-open' : ''}`}
      onClick   = { (e) => { handleDocumentClick(e) } }
    >
      <div
        className = 'root-modal-children-container'
      >
        <div
          className = {'root-modal-children' + (padding === 'small' ? ' root-modal-children-padding-small' : '') + (padding === 'none' ? ' root-modal-children-padding-none' : '')}
        >
          { children }

          <div className='end-of-content' />
        </div>
      </div>
    </div>
  )
}
