import Bowser from 'bowser';

import { SupportedBrowser } from 'enums/SupportedBrowser';

export default function useGetBrowser() {
  const getBrowser = (): SupportedBrowser => {
    const parser = Bowser.getParser(window.navigator.userAgent);
    const browserName = parser.getBrowserName().toLowerCase();

    if (Object.values(SupportedBrowser).includes(browserName as SupportedBrowser)) {
      return browserName as SupportedBrowser;
    }

    console.error('Browser not supported for extension');
    return SupportedBrowser.Unknown;
  };

  return getBrowser();
}
