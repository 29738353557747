import React from 'react'
import Typist from 'react-typist';

import './DashboardText.scss'

import { DashboardTextType } from 'types/DashboardTextType';

type Props = {
  textObjects:        DashboardTextType[]
  delay?:             number
  shouldDelayTyping?: boolean
  startTyping?:       boolean
}

export default function DashboardText({
  textObjects,
  delay = 0,
  shouldDelayTyping = false,
  startTyping
}: Props) {
  if (shouldDelayTyping && !startTyping) {
    return null
  }

  return (
    <Typist 
      cursor         = { { show: false } }
      avgTypingDelay = { 20 }
      key            = { startTyping }
    >
      <Typist.Delay ms = { delay } />

      {
        [1, 2].map(lineNumber => (
          <div
            key = { lineNumber }
          >
            <div
              className = 'dashboard-card-text-container'
            >
              {
                textObjects.filter(obj => obj.line === lineNumber)?.map((textObj, index) => (
                  <div
                    key       = { index }
                    className = { textObj.bold ? 'p18-bold' : 'p18-medium' }
                  >
                    { textObj.value }
                  </div>
                ))
              }
            </div>
            
            {
              lineNumber !== 2 ? (
                <div
                  className = 'dashboard-card-text-break'
                />
              )
              :
              <div/>
            }
          </div>
        ))
      }
    </Typist>
  )
}
