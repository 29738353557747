import { MyMealsFilterType } from 'enums/MyMealsFilterType';
import { MyMealsModalType } from 'enums/Modals/MyMealsModalType';

export default function useHandleFilterClick(
  openModal:     (modalType: MyMealsModalType) => void,
  setFilterType: (filterType: MyMealsFilterType | null) => void
) {
  const handleFilterClick = (filterType: MyMealsFilterType) => {
    setFilterType(filterType);
    
    openModal(MyMealsModalType.Filter);
  }

  return handleFilterClick;
}
