import React from 'react'

import './NutritionCard.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import P20 from 'components/Text/Paragraphs/Medium/P20'
import useCapitalize from 'hooks/utils/useCapitalize'

type Props = {
  children:              any,
  title:                 string,
  showPerServing:        boolean,
  perHundredGramsValue?: string,
  perServingValue?:      string,
}

export default function NutritionCard({
  children,
  title,
  showPerServing,
  perHundredGramsValue,
  perServingValue,
}: Props) {
  return (
    <div
      className = 'nutrition-card'
    >
      <div
        className = 'title'
      >
        <P14
          text = { useCapitalize(title) }
          colour = 'black-1'
        />

        <div className = 'separator'/>

        <P20
          text   = { perHundredGramsValue ? perHundredGramsValue : '-' }
          colour = 'black-1'
        />

        { showPerServing && (
          <P14
            text   = { perServingValue ? perServingValue : '-' }
            colour = 'pink-1'
          />
        )}
      </div>

      { children }
    </div>
  )
}