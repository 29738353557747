import React from 'react'

import './H3Text.scss'

type Props = {
  text:    string
  colour?: 'pink-1'
}

export default function H3Text({ 
  text,
  colour
}: Props) {
  return (
    <h3
      className = {`
        ${colour}
      `}
    >
      { text }
    </h3>
  )
}