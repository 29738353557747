import React from 'react';

import './ForgotPassword.scss';
import Form from 'pages/PreLogin/Shared/Form';
import Textfield, { Icons } from 'components/Inputs/Textfield';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import TertiaryButton from 'components/Buttons/TertiaryButton';
import { LocalConstants } from 'utils/Constants';
import ErrorModal from 'components/Modals/ErrorModal';
import PreLoginCard from 'components/Cards/PreLoginCard';

import useForgotPassword from 'utils/Hooks/Pages/PreLogin/useForgotPassword';

export default function ForgotPassword() {
  const [
    email,
    setEmail,
    page,
    error,
    closeModal,
    forgotPassword,
    navigateToLogin,
    openEmailApp
  ] = useForgotPassword();

  return (
    <Form
      modal = {
        <ErrorModal
          close   = { closeModal }
          error   = { error }
        />
      }
    >
      <PreLoginCard
        title = { page === 'Input' ? LocalConstants.PreLogin.ForgotPassword.Input.Title : LocalConstants.PreLogin.ForgotPassword.Confirmation.Title }
        subtitle = { page === 'Input' ? LocalConstants.PreLogin.ForgotPassword.Input.SubTitle : LocalConstants.PreLogin.ForgotPassword.Confirmation.SubTitle(email) }
      >
        { page === 'Input' ?
          <>
            <div
              className = 'pre-login-textfield-container'
            >
              <Textfield
                placeholder = { LocalConstants.PreLogin.ForgotPassword.Input.EmailPlaceholder }
                icon        = { Icons.EmailIcon }
                value       = { email }
                setValue    = { setEmail }
              />
            </div>

            <div
              className = 'forgot-password-buttons-container'
            >
              <PrimaryButton
                text           = { LocalConstants.PreLogin.ForgotPassword.Input.PrimaryButtonCopy }
                size           = 'fill'
                onClickHandler = { forgotPassword }
              />
              
              <TertiaryButton
                text           = { LocalConstants.PreLogin.ForgotPassword.Input.TertiaryButtonCopy }
                onClickHandler = { navigateToLogin }
              />
            </div>
          </>
          :
          <>
            <div
              className = 'forgot-password-buttons-container'
            >
              <PrimaryButton
                text           = { LocalConstants.PreLogin.ForgotPassword.Confirmation.PrimaryButtonCopy }
                size           = 'fill'
                onClickHandler = { openEmailApp }
              />
              
              <TertiaryButton
                text           = { LocalConstants.PreLogin.ForgotPassword.Confirmation.TertiaryButtonCopy }
                onClickHandler = { navigateToLogin }
              />
            </div>
          </>
          
        }
      </PreLoginCard>
    </Form>
  );
};