import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreAlgorithmItemEndpoints: BaseApiEndpoint = {
  alternativeAlgorithmItems: (algorithmItemId: number) => ({
    url:    'algorithms/item/item_alternatives',
    method: 'POST',
    auth:   true,
    body:   {
      algorithm_item_id: algorithmItemId
    }
  }),
  replaceAlgorithmItem: (algorithmItemId: number) => ({
    url:    'algorithms/item/replace_item',
    method: 'POST',
    auth:   true,
    body:   {
      algorithm_item_id: algorithmItemId
    }
  }),
  addToBasket: (algorithmItemId: number) => ({
    url:    'algorithms/item/add_to_basket',
    method: 'POST',
    auth:   true,
    body:   {
      algorithm_item_id: algorithmItemId
    }
  }),
  removeAlgorithmItem: (algorithmItemId: number) => ({
    url:    'algorithms/item/remove_item',
    method: 'POST',
    auth:   true,
    body:   {
      algorithm_item_id: algorithmItemId
    }
  })
}
