import React from 'react'
import MediaQuery from 'react-responsive';

import './Form.scss'
import FormCard from 'components/Cards/FormCard';
import PreLoginIllustration from 'components/Images/Illustrations/PreLogin'
import { Breakpoints } from 'utils/Breakpoints';
import PreLoginResponsive from 'components/base/Fallback/PreLoginResponsive';

type Props = {
  children: React.ReactNode
  modal?:   React.ReactNode
  preloginResponsive?: React.ReactNode
}

export default function Form({
  children,
  modal,
  preloginResponsive
}: Props) {
  return (
    <>
      <MediaQuery
        minWidth = { Breakpoints.small.min }
        maxWidth = { Breakpoints.small.max }
      >
        {
          preloginResponsive ?
          <div
            className = {`form-container prelogin-responsive`}
          >
            { preloginResponsive }
          </div>
          :
          <PreLoginResponsive />
        }
      </MediaQuery>

      <MediaQuery
        minWidth = { Breakpoints.desktop.min }
        maxWidth = { Breakpoints.wideDesktop.max }
      >
        <div
          className = 'form-container'
        >
          <div
            className = 'form'
          >
            <div
              className = 'children'
            >
              <div
                className = 'child'
              >
                <FormCard>
                  { children }
                </FormCard>
              </div>

              <div
                className = 'child'
              >
                <PreLoginIllustration />
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>

      { modal }
    </>
  )
}
