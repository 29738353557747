import React from 'react'
import { animated, useSpring } from 'react-spring';

import './SupermarketLoadingPill.scss'
import { SupportedSupermarket } from 'enums/SupportedSupermarket';

type Props = {
  supermarketName: SupportedSupermarket
  onFinished:      () => void
}

export default function SupermarketLoadingPill({
  supermarketName,
  onFinished
}: Props) {
  const { width } = useSpring({
    from: { width: '0%' },
    to:   { width: '100%' },
    config: {
      duration: 2000
    },
    onRest: onFinished
  });

  return (
    <div
      className = {`
        supermarket-loading-pill-container
        ${supermarketName}
      `}
    >
      <div
        className = {`
          supermarket-loading-pill-background
          ${supermarketName}
        `}
      />

      <animated.div
        className = 'supermarket-loading-pill'
        style     = { { width } }
      />
    </div>
  )
}
