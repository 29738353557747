import { RouteName } from 'router/Config';
import useHandleRecipeCardClick from '../Operations/Actions/useHandleRecipeCardClick';
import AlgorithmSavedPlan from 'models/Algorithm/AlgorithmSavedPlan';

export default function useActions(
  algorithmSavedPlan: AlgorithmSavedPlan,
  navigateTo:         (routeName: RouteName, params?: any) => void
) {
  const handleRecipeCardClick = useHandleRecipeCardClick(algorithmSavedPlan, navigateTo)

  return {
    handleRecipeCardClick
  }
}
