import React, { useState } from 'react'

import './RootAlgorithmRecipeCard.scss'
import CardRecipeImage from 'components/Images/RecipeImage/CardRecipeImage'
import P18 from 'components/Text/Paragraphs/SemiBold/P18'
import P14 from 'components/Text/Paragraphs/Regular/P14'

import SlideUpAnimationWrapper from 'components/Animations/Wrappers/SlideUpAnimationWrapper'
import FadeInAnimationWrapper from 'components/Animations/Wrappers/FadeInAnimationWrapper'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'

type Props = {
  algorithmRecipe:    AlgorithmRecipe
  ordinalNumber?:     string
  onCardClick?:       (algorithmRecipe: AlgorithmRecipe, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  buttonChildren?:    React.ReactNode
}

export default function RootAlgorithmRecipeCard({
  algorithmRecipe,
  ordinalNumber,
  onCardClick,
  buttonChildren
}: Props) {
  const recipePillInfo = [
    `£${algorithmRecipe.totalCost.toFixed(2)} for ${algorithmRecipe.numberOfPeople} People`,
    `· ${algorithmRecipe.recipe.energyCost === 'Moderate' ? 'Mid' : algorithmRecipe.recipe.energyCost} Energy Cost ·`,
    `${algorithmRecipe.recipe.totalTime} Mins of Cooking`
  ]

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <div
      className = 'root-algorithm-recipe-card'
      onClick   = { (e) => onCardClick && onCardClick(algorithmRecipe, e) }
      onMouseEnter = { handleMouseEnter }
      onMouseLeave = { handleMouseLeave }
    >
      <FadeInAnimationWrapper
        fadeColour = 'grey-3'
      >
        <CardRecipeImage
          imageUrl      = { algorithmRecipe.recipe.imageUrl }
          text          = { `£${ algorithmRecipe.pricePerServing.toFixed(2) } Per Person` }
          modified      = { algorithmRecipe.modified }
          ordinalNumber = { ordinalNumber }
          isHovered     = { isHovered }
        />
      </FadeInAnimationWrapper>

      <div
        className = 'card-content'
      >
        <SlideUpAnimationWrapper>
          <div
            className = 'card-title'
          >
            <P18
              text = { algorithmRecipe.recipe.name }
            />
          </div>
        </SlideUpAnimationWrapper>

        <SlideUpAnimationWrapper 
          delay = { 400 }
        >
          <div
            className = 'card-pills'
          >
            <P14
              text   = { recipePillInfo[0] }
              colour = 'black-1'
            />

            {
              recipePillInfo.slice(1).map((pillText, index) => (
                <div
                  key = { index }
                >
                  <P14
                    text   = { pillText }
                    colour = 'grey-1'
                  />
                </div>
              ))
            }
          </div>
        </SlideUpAnimationWrapper>

        <div
          className = 'card-button'
        >
          <FadeInAnimationWrapper>
            { buttonChildren }
          </FadeInAnimationWrapper>
        </div>
      </div>
    </div>
  )
}
