import { GenerateNewMealsPage } from 'enums/GenerateNewMealsPage';
import { DashboardTextType } from 'types/DashboardTextType';

export default function useBotMessage(
  page:                       GenerateNewMealsPage,
  numberOfPeopleMessages:     DashboardTextType[],
  numberOfMealsMessages:      DashboardTextType[],
  budgetMessages:             DashboardTextType[],
  dietaryPreferencesMessages: DashboardTextType[],
  allergiesMessages:          DashboardTextType[],
  kitchenEquipmentMessages:   DashboardTextType[],
  grocerMessages:             DashboardTextType[],
  endingMessages:             DashboardTextType[]
) {
  const botMessage = () => {
    switch (page) {
      case GenerateNewMealsPage.NumberOfPeople:
        return numberOfPeopleMessages;
      case GenerateNewMealsPage.NumberOfMeals:
        return numberOfMealsMessages;
      case GenerateNewMealsPage.Budget:
        return budgetMessages;
      case GenerateNewMealsPage.DietaryPreferences:
        return dietaryPreferencesMessages;
      case GenerateNewMealsPage.Allergies:
        return allergiesMessages;
      case GenerateNewMealsPage.KitchenEquipment:
        return kitchenEquipmentMessages;
      case GenerateNewMealsPage.Grocer:
        return grocerMessages;
      case GenerateNewMealsPage.Ending:
        return endingMessages;
    }
  }

  return botMessage
}
