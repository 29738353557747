import React from 'react'

import './PageTooSmallFallback.scss'
import H3Text from 'components/Text/Headings/H3Text';

export default function PageTooSmallFallback() {
  return (
    <div
      className = 'page-too-small-body'
    >
      <img
        src = '/images/illustrations/page-too-small.svg'
        alt = 'Page too small'
      />

      <div
        className = 'page-too-small-text'
      >
        <H3Text
          text = 'Oops! Mealia needs a bit more room to work properly. Please increase your window size to continue.'
        />
      </div>
    </div>
  )
}
