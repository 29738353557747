import React from 'react';

import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import useLinkedSupermarket from 'utils/Hooks/Helper/useLinkedSupermarket'

type Props = {
  onClick: () => void
}

export default function TopNavBarSupermarketImage({
  onClick
}: Props) {
  const linkedSupermarket = useLinkedSupermarket();

  if (linkedSupermarket === SupportedSupermarket.Asda) {
    return (
      <img
        src     = '/images/logos/partners/asda.svg'
        alt     = 'Mealia Asda'
        onClick = { onClick }
        style   = {{
          cursor: 'pointer'
        }}
      />
    )
  }

  if (linkedSupermarket === SupportedSupermarket.Tesco) {
    return (
      <img
        src     = '/images/logos/partners/tesco.svg'
        alt     = 'Mealia Tesco'
        onClick = { onClick }
        style   = {{
          cursor: 'pointer'
        }}

      />
    )
  }
}
