import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import useOtherAlgorithmRecipesApi from 'utils/Hooks/Api/Algorithm/MealPlan/useOtherAlgorithmRecipes';

export default function useOtherAlgorithmRecipes() {
  const otherAlgorithmRecipesApi = useOtherAlgorithmRecipesApi();

  function otherAlgorithmRecipes(
    setAlgorithmRecipes: (algorithmRecipes: AlgorithmRecipe[]) => void
  ) {
    otherAlgorithmRecipesApi().then((response) => {
      const algorithmRecipes = response.data.map(data => new AlgorithmRecipe(data));

      setAlgorithmRecipes(algorithmRecipes);
    });
  }

  return otherAlgorithmRecipes;
}
