import { useContext } from 'react';

import UserContext from 'context/UserContext';
import useGetCurrentApi from 'utils/Hooks/Api/User/CurrentUser/useShow';
import User from 'models/User';

export default function useGetCurrent() {
  const { setUser }: { setUser: (user: User) => void } = useContext(UserContext);

  const getCurrentApi = useGetCurrentApi();

  function signIn(): Promise<any> {
    return getCurrentApi().then((response) => {
      const user: User = User.fromApiResponse(response.data);

      setUser(user);

      return response;
    });
  }

  return signIn;
}