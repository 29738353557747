import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import { OnboardingPage } from 'enums/OnboardingPage';
import { getEffectiveWeeklyBudget } from 'utils/Hooks/Helper/useUserPreferencesUtils';

export default function useHandleNextButtonClick(
  onboardingPage:     OnboardingPage,
  setOnboardingPage:  React.Dispatch<React.SetStateAction<OnboardingPage>>,
  userPreferences:    IUserPreferences,
  setUserPreferences: React.Dispatch<React.SetStateAction<IUserPreferences>>,
  setCompleted:       React.Dispatch<React.SetStateAction<boolean>>,
  createHousehold:    ( 
    numberOfAdults:        number,
    numberOfChildren:      number,
    numberOfMealsPerWeek:  number,
    weeklyBudget:          number,
    allergies:             string[],
    dietaryPreferences:    string[],
    kitchenEquipment:      string[],
    chosenSupermarket:     string,
    onSuccess:             () => void
  ) => void
) {
  const weeklyBudget = getEffectiveWeeklyBudget(userPreferences);

  const handleNextButtonClick = () => {
    if (onboardingPage === OnboardingPage.Ending) return;

    setOnboardingPage(prevPage => prevPage + 1);

    if (onboardingPage === OnboardingPage.NumberOfMeals) {
      setUserPreferences({
        ...userPreferences,
        weeklyBudget: weeklyBudget
      })
    }

    if (onboardingPage === OnboardingPage.Grocer) {
      createHousehold(
        userPreferences.numberOfAdults,
        userPreferences.numberOfChildren || 0,
        userPreferences.numberOfMealsPerWeek,
        userPreferences.weeklyBudget,
        userPreferences.allergies,
        userPreferences.dietaryPreferences ? [userPreferences.dietaryPreferences] : [],
        userPreferences.kitchenEquipment,
        userPreferences.chosenSupermarket,
        () => {
          setCompleted(true);
        }
      )
    }
  }

  return handleNextButtonClick;
}
