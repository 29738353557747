import React from 'react'
import { animated } from 'react-spring'

import './Root.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import { ReactComponent as BlackPlus } from 'assets/images/icons/_new/add/black.svg'
import { ReactComponent as BlackMinus } from 'assets/images/icons/_new/minus/black.svg'

import useAnimation from '../../Hooks/useAnimation'
import { ColourConstants } from 'utils/Constants'

type Props = {
  text:        string
  rightChild?: React.ReactNode
  bottomChild: React.ReactNode
}

export default function Root({
  text,
  rightChild,
  bottomChild
}: Props) {
  const [expandProps, contentRef, isOpen, toggleOpen] = useAnimation();

  return (
    <div
      className = 'instruction-step-root-container'
      onClick   = { toggleOpen }
    >
      <div
        className = 'instruction-step-root-content'
      >
        <div
          className = 'instruction-step-root-lhs'
        >
          <button
            className = 'instruction-step-root-lhs-button'
            onClick   = { toggleOpen }
          >
            {
              isOpen ?
              <BlackMinus />
              :
              <BlackPlus
                color = { ColourConstants.Black[1] }
              />
            }
          </button>

          <div
            className = 'instruction-step-root-lhs-text'
          >
            <P14
              text   = { text }
              colour = 'black-1'
            />
          </div>
        </div>

        { rightChild }
      </div>

      <animated.div
        style = { expandProps }
      >
        <div
          ref       = { contentRef }
          className = 'instruction-step-root-bottom'
        >
          { bottomChild }
        </div>
      </animated.div>
    </div>
  )
}
