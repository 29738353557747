import React from 'react'

import IItem from 'interfaces/backend/IItem'
import RootItemCard from '../_RootItemCard'

type Props = {
  item:        IItem
  onCardClick: (item: IItem) => void
}

export default function SavedAdditionalItemCard({
  item,
  onCardClick,
}: Props) {
  return (
    <RootItemCard
      item           = { item }
      onCardClick    = { onCardClick }
      buttonChildren = { null }
    />
  )
}