import { useState } from 'react';

import { ErrorType } from 'utils/Errors';
import { RouteName }  from 'router/Config';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useCreate from 'utils/Hooks/Actions/User/Password/useCreate';

export default function useForgotPassword() {
  const [email, setEmail]   = useState('');
  const [page, setPage]     = useState<'Input' | 'Confirmation'>('Input');
  const [error, setError]   = useState(null);
  
  const createPasswordReset = useCreate();
  const navigateTo = useNavigateTo();

  const forgotPasswordAction = () => {
    if (email === '') {
      setError(ErrorType.BlankField);
      return;
    }

    createPasswordReset(email, setPage, setError);
  }

  const navigateToLogin = () => {
    navigateTo(RouteName.Login);
  }

  const openEmailApp = () => {
    window.open(`https://${email.split('@')[1]}`, '_blank');
  }

  const closeModal = () => {
    setError(null);
  }

  return [
    email,
    setEmail,
    page,
    error,
    closeModal,
    forgotPasswordAction,
    navigateToLogin,
    openEmailApp
  ] as const;
}
