import React from 'react'

import './SettingsCard.scss'
import P18 from 'components/Text/Paragraphs/Medium/P18'

type Props = {
  title:        string
  children?:    React.ReactNode
  titleButton?: React.ReactNode
}

export default function SettingsCard({
  title,
  children,
  titleButton
}: Props) {
  return (
    <div
      className = 'settings-card'
    >
      <div
        className = 'settings-card-title'
      >
        <P18
          text = { title }
          colour = 'black-1'
        />

        { titleButton }
      </div>

      <div className = 'settings-card-divider' />

      {
        children &&
        <div
          className = 'settings-card-content'
        >
          { children }
        </div>
      }
     
    </div>
  )
}