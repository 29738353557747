import React, { useState } from 'react'

import './Textbox.scss'
import { ReactComponent as SendIcon } from 'assets/images/icon/send/root.svg'
import { ColourConstants } from 'utils/Constants'

type Props = {
  isLastMessageFromUser: boolean
  onSendClick:           (content: string, setValue: (value: string) => void) => void
}

export default function Textbox({
  isLastMessageFromUser,
  onSendClick
}: Props) {
  const [value, setValue] = useState('')

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSendClick(value, setValue)
    }
  }

  const shouldDisable = () => {
    return value === '' || isLastMessageFromUser
  }

  return (
    <div
      className = 'chatbot-textbox-container'
    >
      <input
        value       = { value }
        onChange    = { e => setValue(e.target.value) }
        className   = 'chatbot-textbox-input'
        placeholder = 'Ask me anything...'
        onKeyDown   = { handleKeyDown }
      />

      <button
        className = {`
          chatbot-textbox-send-button
          ${shouldDisable() ? 'disabled' : ''}
        `}
        onClick   = { () => onSendClick(value, setValue) }
        disabled  = { shouldDisable() }
      >
        <SendIcon
          color = { shouldDisable() ? ColourConstants.Grey[1] : ColourConstants.Black[1] }
        />
      </button>
    </div>
  )
}
