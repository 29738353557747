import React from 'react'

import RootItemCard from '../_RootItemCard/RootItemCard'
import IItem from 'interfaces/backend/IItem'
import AddToBasketButton from 'components/Buttons/AddToBasketButton'

type Props = {
  item:                     IItem
  onAddToBasketButtonClick: (item: IItem) => void
}

export default function AddableItemCard({
  item,
  onAddToBasketButtonClick
}: Props) {
  return (
    <RootItemCard
      item           = { item }
      onCardClick    = { () => {} }
      buttonChildren = {
        <AddToBasketButton
          onClick = { (e) => onAddToBasketButtonClick(item) }
          size    = 'medium'
        />
      }
    />
  )
}
