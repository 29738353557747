import { MyMealsFilterType } from 'enums/MyMealsFilterType';
import User from 'models/User';

export default function useFilterTexts(user: User) {
  const filterTexts = {
    [ MyMealsFilterType.NumberOfPeople ]:   `Servings (${user.household.numberOfPeople})`,
    [ MyMealsFilterType.Diet ]:             `Diet (${user.household.dietaryPreferencesAnnotation})`,
    [ MyMealsFilterType.Allergies ]:        `Allergies (${user.household.allergiesAnnotation})`,
    [ MyMealsFilterType.KitchenEquipment ]: `Kitchen Equipment (${user.household.kitchenEquipmentAnnotation})`
  }

  return filterTexts
}
