import { CoreErrorEndpoints } from 'services/Core/ErrorService/Config';
import CoreApi from 'services/Core/CoreApi';

export default class ErrorApi extends CoreApi {
  constructor() {
    super(CoreErrorEndpoints);
  }

  report(
    message:   string,
    backtrace: string,
  ) {
    const response = super.request(
      this.apiEndpoints.report(
        message,
        backtrace
      )
    );
        
    return this.respondWithJson(response);
  }
}
