import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import { OnboardingPage } from 'enums/OnboardingPage';
import { HouseholdConstants } from 'utils/Constants';

export default function useDisabledPrimaryButton(
  onboardingPage:  OnboardingPage,
  userPreferences: IUserPreferences
) {
  const disabledPrimaryButton = () => {
    switch (onboardingPage) {
      case OnboardingPage.Intro:
        return false;
      case OnboardingPage.NumberOfPeople:
        const numberOfAdults = userPreferences.numberOfAdults || 0;
        const numberOfChildren = userPreferences.numberOfChildren || 0;

        return (numberOfAdults + numberOfChildren) < 2;
      case OnboardingPage.NumberOfMeals:
        return !userPreferences.numberOfMealsPerWeek;
      case OnboardingPage.Budget:
        return false;
      case OnboardingPage.DietaryPreferences:
        return userPreferences.dietaryPreferencesPresent !== HouseholdConstants.YesNoOptions.No && !userPreferences.dietaryPreferences;
      case OnboardingPage.Allergies:
        return userPreferences.allergiesPresent !== HouseholdConstants.YesNoOptions.No && !userPreferences.allergies.length;
      case OnboardingPage.KitchenEquipment:
        return userPreferences.kitchenEquipment.length === 0;
      case OnboardingPage.Grocer:
        return !userPreferences.chosenSupermarket;
      case OnboardingPage.Ending:
        return true;
      default:
        return false;
    }
  }

  return disabledPrimaryButton
}
