import { getEffectiveWeeklyBudget } from 'utils/Hooks/Helper/useUserPreferencesUtils';
import IUserPreferences from 'interfaces/frontend/IUserPreferences'
import { DashboardTextType } from 'types/DashboardTextType'

export default function useBudgetMessages(
  userPreferences: IUserPreferences
) {
  const weeklyBudget = getEffectiveWeeklyBudget(userPreferences);

  const topLineTextObject: DashboardTextType[] = [
    {
      line: 1,
      value: 'Got it, what is your budget for these ',
      bold: false
    },
    {
      line: 1,
      value: `${userPreferences.numberOfMealsPerWeek}`,
      bold: true
    },
    {
      line: 1,
      value: ' meals?',
      bold: false
    }
  ]

  const bottomLineTextObject: DashboardTextType[] = [
    {
      line: 2,
      value: 'We recommend a budget around ',
      bold: false
    },
    {
      line: 2,
      value: `£${weeklyBudget},`,
      bold: true
    },
    {
      line: 2,
      value: 'or',
      bold: false
    },
    {
      line: 2,
      value: `£${(weeklyBudget / userPreferences.numberOfMealsPerWeek).toFixed(2)}`,
      bold: true
    },
    {
      line: 2,
      value: 'per meal.',
      bold: false
    },
  ]

  const budgetMessages = [
    ...topLineTextObject,
    ...bottomLineTextObject
  ]

  return budgetMessages
}
