import { CategoryType } from 'enums/CategoryType';
import { EnergyCost } from 'enums/EnergyCost';
import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreAlgorithmMealPlanEndpoints: BaseApiEndpoint = {
  show: () => ({
    url:    'algorithms/meal_plan/show',
    method: 'POST',
    auth:   true
  }),
  filter: (
    numberOfAdults:       number,
    numberOfChildren:     number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    energyCost:           EnergyCost | null,
    categories:           CategoryType[]
  ) => ({
    url:    'algorithms/meal_plan/filter',
    method: 'POST',
    auth:   true,
    body:   {
      number_of_adults:    numberOfAdults,
      number_of_children:  numberOfChildren,
      allergies:           allergies,
      dietary_preferences: dietaryPreferences,
      kitchen_equipment:   kitchenEquipment,
      energy_cost:         energyCost,
      categories:          categories
    }
  }),
  addAlgorithmRecipe: (
    algorithmRecipeId: number
  ) => ({
    url:    'algorithms/meal_plan/add_algorithm_recipe',
    method: 'POST',
    auth:   true,
    body:   {
      algorithm_recipe_id: algorithmRecipeId,
    }
  }),
  removeAlgorithmRecipe: (
    algorithmRecipeId: number
  ) => ({
    url:    'algorithms/meal_plan/remove_algorithm_recipe',
    method: 'PUT',
    auth:   true,
    body:   {
      algorithm_recipe_id: algorithmRecipeId
    }
  }),
  otherAlgorithmRecipes: () => ({
    url:    'algorithms/meal_plan/other_algorithm_recipes',
    method: 'GET',
    auth:   true
  }),
  createNewMealPlan: (
    numberOfAdults:       number,
    numberOfChildren:     number,
    numberOfMealsPerWeek: number,
    weeklyBudget:         number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    chosenSupermarket:    SupportedSupermarket
  ) => ({
    url:    'algorithms/meal_plan/create_new_meal_plan',
    method: 'POST',
    auth:   true,
    body:   {
      number_of_adults:         numberOfAdults,
      number_of_children:       numberOfChildren,
      number_of_meals_per_week: numberOfMealsPerWeek,
      weekly_budget:            weeklyBudget,
      allergies:                allergies,
      dietary_preferences:      dietaryPreferences,
      kitchen_equipment:        kitchenEquipment,
      chosen_supermarket:       chosenSupermarket
    }
  }),
  continueMealPlan: (
    maintainSubstitutions: boolean
  ) => ({
    url:    'algorithms/meal_plan/continue_meal_plan',
    method: 'POST',
    auth:   true,
    body:   {
      maintain_substitutions: maintainSubstitutions
    }
  }),
  changeSupermarket: (
    chosenSupermarket: SupportedSupermarket
  ) => ({
    url:    'algorithms/meal_plan/change_supermarket',
    method: 'POST',
    auth:   true,
    body:   {
      chosen_supermarket: chosenSupermarket
    }
  })
}
