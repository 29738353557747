export enum ErrorType {
  IncorectDetails     = 'Incorrect details',
  WrongProvider       = 'Wrong provider',
  EmailNotVerified    = 'Email not verified',
  EmailAlreadyInUse   = 'Email already in use',
  PasswordTooShort    = 'Password too short',
  BlankField          = 'Blank field',
  PasswordsDontMatch  = 'Passwords don\'t match',
  EmailNotValid       = 'Email not valid',
  SignatureHasExpired = 'Signature has expired',
  UsedPasswordToken   = 'Used password token',
  AlreadyConfirmed    = 'Already confirmed',
  
  Unknown = 'Unknown'
}

export const getError = (code: number): ErrorType => {
  switch (code) {
    case 1001:
      return ErrorType.IncorectDetails;
    case 1002:
      return ErrorType.WrongProvider;
    case 1003:
      return ErrorType.EmailNotVerified;
    case 1004:
      return ErrorType.EmailAlreadyInUse;
    case 1005:
      return ErrorType.PasswordTooShort;
    case 1006:
      return ErrorType.BlankField;
    case 1007:
      return ErrorType.PasswordsDontMatch;
    case 1008:
      return ErrorType.EmailNotValid;
    case 1009:
      return ErrorType.SignatureHasExpired;
    case 1010:
      return ErrorType.UsedPasswordToken;
    case 1011:
      return ErrorType.AlreadyConfirmed;
    default:
      console.log('Unknown error code: ', code);
      return ErrorType.Unknown;
  }
}
