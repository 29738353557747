import { EnergyCost } from 'enums/EnergyCost';
import { CategoryType } from 'enums/CategoryType';
import User from 'models/User';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useHandleCategoryClick(
  user:            User,
  setOtherRecipes: (otherRecipes: AlgorithmRecipe[]) => void,
  filter:          (numberOfAdults: number, numberOfChildren: number, allergies: string[], dietaryPreferences: string[], kitchenEquipment: string[], energyCost: EnergyCost, categories: CategoryType[]) => Promise<any>
) {
  const handleCategoryClick = (category: CategoryType) => {
    setOtherRecipes([]);
    
    filter(
      user.household.numberOfAdults,
      user.household.numberOfChildren,
      user.household.allergies,
      user.household.dietaryPreferences,
      user.household.kitchenEquipment,
      user.household.energyCost,
      category === CategoryType.All ? [] : [category]
    ).then((response) => {
      const algorithmRecipes = response.data.map(data => new AlgorithmRecipe(data));

      setOtherRecipes(algorithmRecipes);
    })
    .catch((err: any) => {
      console.log(err);
    });
  }

  return handleCategoryClick
}
