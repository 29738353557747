import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'

import { ErrorType } from 'utils/Errors';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useConfirm from 'utils/Hooks/Actions/User/useConfirm';
import { RouteName } from 'router/Config';
import { LocalConstants } from 'utils/Constants';

export default function useConfirmation() {
  const [error, setError] = useState<ErrorType | null>(null);

  const confirm = useConfirm();
  const navigateTo = useNavigateTo();

  const navigateToLogIn = () => {
    navigateTo(RouteName.Login);
  }

  const isMobile = useMediaQuery({ query: '(max-width: 1065px)' })

  const title = () => {
    switch (error) {
      case ErrorType.IncorectDetails:
        return 'The token is incorrect';
      case ErrorType.AlreadyConfirmed:
        return LocalConstants.PreLogin.Confirmation.Error.Title
      default:
        return LocalConstants.PreLogin.Confirmation.Success.Title
    }
  }

  const subtitle = () => {
    switch (error) {
      case ErrorType.IncorectDetails:
        return 'Please check you have copied the link from the email correctly.';
      case ErrorType.AlreadyConfirmed:
        return LocalConstants.PreLogin.Confirmation.Error.Subtitle
      default:
        if (isMobile) {
          return 'Thank you for confirming your email! Please Log in via desktop to start using Mealia.'
        } else {
          return LocalConstants.PreLogin.Confirmation.Success.Subtitle
        }
    }
  }

  useEffect(() => {
    confirm(setError, !isMobile);
  }, []);

  return [
    error,
    title,
    subtitle,
    navigateToLogIn
  ] as const;
}
