import React from 'react'

import './ResetPassword.scss'
import Form from 'pages/PreLogin/Shared/Form';
import Textfield from 'components/Inputs/Textfield'
import PrimaryButton from 'components/Buttons/PrimaryButton';
import TertiaryButton from 'components/Buttons/TertiaryButton'
import { LocalConstants } from 'utils/Constants';
import PreLoginCard from 'components/Cards/PreLoginCard';
import ErrorModal from 'components/Modals/ErrorModal';

import useResetPassword from 'utils/Hooks/Pages/PreLogin/useResetPassword';

export default function ResetPassword() {
  const [
    page,
    password,
    setPassword,
    passwordConfirmation,
    setPasswordConfirmation,
    error,
    closeModal,
    reset,
    navigateToLogin,
    continueToPlanning,
  ] = useResetPassword();

  return (
    <Form
      modal = {
        <ErrorModal
          close = { closeModal }
          error = { error }
        />
      }
    >
      <PreLoginCard
        title    = { page === 'Input' ? LocalConstants.PreLogin.ResetPassword.Input.Title : LocalConstants.PreLogin.ResetPassword.Confirmation.Title }
        subtitle = { page === 'Input' ? LocalConstants.PreLogin.ResetPassword.Input.SubTitle : LocalConstants.PreLogin.ResetPassword.Confirmation.SubTitle }
      >
        {
          page === 'Input' ?
          <>
            <div
              className = 'pre-login-textfield-container'
            > 
              <Textfield
                type        = 'password'
                placeholder = { LocalConstants.PreLogin.ResetPassword.Input.PasswordPlaceholder }
                value       = { password }
                setValue    = { setPassword }
              />

              <Textfield
                type        = 'password'
                placeholder = { LocalConstants.PreLogin.ResetPassword.Input.ConfirmPasswordPlaceholder }
                value       = { passwordConfirmation }
                setValue    = { setPasswordConfirmation }
              />
            </div>

            <div
              className = 'reset-password-buttons-container'
            >
              <PrimaryButton
                text           = { LocalConstants.PreLogin.ResetPassword.Input.PrimaryButtonCopy }
                size           = 'fill'
                onClickHandler = { reset }
              />
  
              <TertiaryButton
                text           = { LocalConstants.PreLogin.ResetPassword.Input.TertiaryButtonCopy }
                onClickHandler = { navigateToLogin }
              />
            </div>
          </>
          :
          <>
            <PrimaryButton
              text           = { LocalConstants.PreLogin.ResetPassword.Confirmation.PrimaryButtonCopy }
              size           = 'fill'
              onClickHandler = { continueToPlanning }
            />

            <TertiaryButton
              text           = { LocalConstants.PreLogin.ResetPassword.Confirmation.TertiaryButtonCopy }
              onClickHandler = { navigateToLogin }
            />
          </>
        }
      </PreLoginCard>
    </Form>
  )
}