import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreAlgorithmRecipeEndpoints: BaseApiEndpoint = {
  detailAlgorithmRecipe: (algorithmRecipeId: number) => ({
    url:    'algorithms/recipe/recipe_details',
    method: 'POST',
    auth:   true,
    body:   {
      algorithm_recipe_id: algorithmRecipeId
    }
  }),
  incrementAdditionalItem: (algorithmRecipeId: number, supermarketItemId: number) => ({
    url:    'algorithms/recipe/increment_additional_item',
    method: 'POST',
    auth:   true,
    body:   {
      algorithm_recipe_id: algorithmRecipeId,
      supermarket_item_id: supermarketItemId
    }
  }),
  decrementAdditionalItem: (algorithmRecipeId: number, supermarketItemId: number) => ({
    url:    'algorithms/recipe/decrement_additional_item',
    method: 'POST',
    auth:   true,
    body:   {
      algorithm_recipe_id: algorithmRecipeId,
      supermarket_item_id: supermarketItemId
    }
  }),
}
