import AlgorithmMealPlanApi from 'services/Core/Algorithm/MealPlanService/AlgorithmMealPlanApi';

export default function useRemoveAlgorithmRecipe() {
  function removeAlgorithmRecipe(algorithmRecipeId: number): Promise<any> {
    const algorithmMealPlan = new AlgorithmMealPlanApi();

    return algorithmMealPlan.removeAlgorithmRecipe(algorithmRecipeId);
  }

  return removeAlgorithmRecipe;
}
