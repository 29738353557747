import { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import UserContext from 'context/UserContext';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import UserApi from 'services/Core/UserService/UserApi';
import { RouteName } from 'router/Config';
import useRegister from 'utils/Hooks/Actions/User/Registration/useRegister';
import { LocalConstants } from 'utils/Constants';
import useCreate from 'utils/Hooks/Actions/User/Confirmation/useCreate';
import User from 'models/User';

export default function useRegistration() {
  const [page, setPage] = useState<'Input' | 'Confirmation' | 'Reconfirm'>('Input')
  const [isSSOUser, setIsSSOUser] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [email, setEmail]         = useState('');
  const [password, setPassword]   = useState('');

  const [error, setError] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 1064px)' });

  const { setUser }: { setUser: (user: User) => void } = useContext(UserContext);

  const create = useCreate();
  const register = useRegister();
  const navigateTo = useNavigateTo();

  const registerUser = () => {
    register(firstName, email, password, setPage, setError)
  }

  const reconfirm = () => {
    create(email, setError, setPage);
  }

  const contactUs = () => {
    const email = 'gabriel@mealia.co.uk';
    const subject = 'Mealia Confirmation Email Trouble';
    const body = 'I am having trouble confirming my email. Please help!';
    // Construct the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    // Open the mail app
    window.location.href = mailtoLink;
  }

  const googleRegisterSuccess = (response: any) => {
    const userApi = new UserApi();

    userApi.userGoogleRegister(response.code).then(response => {
      if (isMobile) {
        setIsSSOUser(true);
        setPage('Confirmation');

        return;
      } else {
        const user: User = User.fromApiResponse(response.data);

        setUser(user);

        navigateTo(RouteName.Onboarding)
      }
    })
  }

  const navigateToLogIn = () => {
    navigateTo(RouteName.Login);
  }

  const closeModal = () => {
    setError(null);
  }

  const title = () => {
    switch (page) {
      case 'Input':
        return LocalConstants.PreLogin.Register.Input.Title
      case 'Confirmation':
        return LocalConstants.PreLogin.Register.Confirmation.Title;
      case 'Reconfirm':
        return 'Resend Confirmation'
    }
  }

  const subtitle = () => {
    switch (page) {
      case 'Input':
        return LocalConstants.PreLogin.Register.Input.SubTitle
      case 'Confirmation':
        return LocalConstants.PreLogin.Register.Confirmation.SubTitle(email);
      case 'Reconfirm':
        return `Done! We've resent the confirmation email. \nPlease check your inbox and spam folder. If you still don't receive it, please contact our support team for further assistance.`
    }
  }

  return [
    page,
    firstName,
    setFirstName,
    email,
    setEmail,
    password,
    setPassword,
    registerUser,
    reconfirm,
    contactUs,
    googleRegisterSuccess,
    navigateToLogIn,
    error,
    closeModal,
    title,
    subtitle,
    isSSOUser
  ] as const;
}
