import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreItemEndpoints: BaseApiEndpoint = {
  searchForItem: (query: string) => ({
    url:    'items/search',
    method: 'POST',
    auth:   true,
    body:   {
      query: query
    }
  }),
  searchSuggestions: (query: string) => ({
    url:    'items/suggestion',
    method: 'POST',
    auth:   true,
    body: {
      query: query
    }
  }),
  findItemById: (supermarketItemId: number) => ({
    url:    'items/find_item',
    method: 'POST',
    auth:   true,
    body:   {
      supermarket_item_id: supermarketItemId
    }
  }),
}
