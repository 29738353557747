import React from 'react'

import './AddToBasketButton.scss'
import { ReactComponent as AddIcon } from 'assets/images/icons/recipe-card/add.svg'

type Props = {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  size?:   'medium' | 'large'
}

export default function AddToBasketButton({
  onClick,
  size = 'large'
}: Props) {
  return (
    <div
      onClick   = { onClick }
      className = {`
        add-to-basket-button
        add-to-basket-button-${size}
      `}
    >
      <AddIcon />
    </div>
  )
}
