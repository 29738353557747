import { useState } from 'react';

import IItem from 'interfaces/backend/IItem';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import { DisclaimerType } from 'types/DisclaimerType';

export default function useStates() {
  const [selectedAlgorithmTopItem, setSelectedAlgorithmTopItem]       = useState<IAlgorithmItem | null>(null);
  const [selectedAlgorithmBottomItem, setSelectedAlgorithmBottomItem] = useState<IAlgorithmItem | null>(null);
  const [selectedRecipeStepItem, setSelectedRecipeStepItem]           = useState<IAlgorithmItem | null>(null);
  const [selectedShoppingItem, setSelectedShoppingItem]               = useState<IItem | null>(null);
  const [disclaimer, setDisclaimer]                                   = useState<DisclaimerType | null>(null);
  const [topItemGridType, setTopItemGridType]                         = useState<'recipeItems' | 'swappingItems' | 'additionalItems'>('recipeItems');
  const [bottomItemGridType, setBottomItemGridType]                   = useState<'recipeItems' | 'swappingItems'>('recipeItems');

  return {
    selectedAlgorithmTopItem,
    setSelectedAlgorithmTopItem,
    selectedAlgorithmBottomItem,
    setSelectedAlgorithmBottomItem,
    selectedRecipeStepItem,
    setSelectedRecipeStepItem,
    selectedShoppingItem,
    setSelectedShoppingItem,
    disclaimer,
    setDisclaimer,
    topItemGridType,
    setTopItemGridType,
    bottomItemGridType,
    setBottomItemGridType
  }
}
