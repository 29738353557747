import React from 'react'

import './CategoryCard.scss'
import { CategoryType } from 'enums/CategoryType'
import P18 from 'components/Text/Paragraphs/SemiBold/P18'

type Props = {
  type:       CategoryType
  isSelected: boolean
  onClick:    (categoryType: CategoryType) => void
}

export default function CategoryCard({
  type,
  isSelected,
  onClick
}: Props) {
  const text = () => {
    switch (type) {
      case CategoryType.All:
        return 'All'
      case CategoryType.QuickMeals:
        return 'Quick Meals'
      case CategoryType.LowIngredients:
        return '5 Ingredients'
      case CategoryType.LowBudget:
        return 'Under £1.50'
      case CategoryType.LowEnergy:
        return 'Low Energy'
      default:
        throw new Error(`Invalid category type ${type}`)
    }
  }

  const image = () => {
    switch (type) {
      case CategoryType.All:
        return 'all'
      case CategoryType.QuickMeals:
        return 'quick-meals'
      case CategoryType.LowIngredients:
        return 'batch-cooking'
      case CategoryType.LowBudget:
        return 'low-budget'
      case CategoryType.LowEnergy:
        return 'one-pot-meal'
      default:
        throw new Error(`Invalid category type ${type}`)
    }
  }

  return (
    <button
      className = {`
        category-card-container
        ${isSelected ? 'selected' : ''}
      `}
      onClick   = { () => onClick(type) }
    >
      <img
        className = 'category-card-image'
        src       = { require(`assets/images/categories/category-${image()}.png`) }
        alt       = { image() }
      />

      <div
        className = 'category-card-text'
      >
        <P18
          text   = { text() }
          colour = 'white-1'
        />
      </div>
    </button>
  )
}
