import { CoreConfig } from 'services/Core/Config';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

const user_wrapper = `api_${CoreConfig.CORE_API_VERSION}_user`;

export const CoreUserPasswordEndpoints: BaseApiEndpoint = {
  create: (email: string) => ({
    url:    'users/passwords/create',
    method: 'POST',
    auth:   false,
    body:   {
      [user_wrapper]: {
        email: email
      }
    }
  }),
  update: (password: string, passwordConfirmation: string, resetPasswordToken: string) => ({
    url:    'users/passwords/update',
    method: 'PUT',
    auth:   false,
    body:   {
      [user_wrapper]: {
        password:              password,
        password_confirmation: passwordConfirmation,
        reset_password_token:  resetPasswordToken
      }
    }
  })
}
