import React from 'react'
import { Direction, getTrackBackground } from 'react-range';

import './SliderTrack.scss'
import { ColourConstants } from 'utils/Constants';

type Props = {
  orientation: 'vertical' | 'horizontal',
  children:    React.ReactNode,
  values:      number[],
  props:       any,
  min:         number,
  max:         number
}

export default function SliderTrack({
  orientation,
  children,
  values,
  props,
  min,
  max
}: Props) {
  return (
    <div
      onMouseDown  = { props.onMouseDown }
      onTouchStart = { props.onTouchStart }
      style        = {{
        ...props.style,
        height:  orientation === 'horizontal' ? '36px' : '100%',
        display: 'flex',
        flexDirection: orientation === 'horizontal' ? 'row' : 'column',
      }}
    >
      <div
        ref   = { props.ref }
        style = {{
          height: orientation === 'horizontal' ? '5px' : '100%',
          width:  orientation === 'horizontal' ? '100%' : '5px',
          borderRadius: '8px',
          background: getTrackBackground({
            values,
            colors: [ColourConstants.Pink[1], '#EEE'],
            min: min,
            max: max,
            direction: orientation === 'horizontal' ? Direction.Right : Direction.Down
          }),
          alignSelf: 'center'
        }}
      >
        { children }
      </div>
    </div>
  )
}
