import React from 'react'

import './NoAlternativesModal.scss'
import RootModal from '../_RootModal'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import ModalSecondaryButton from 'components/Buttons/ModalSecondaryButton'
import { DashboardTextType } from 'types/DashboardTextType'

type Props = {
  isOpen: boolean
  close: () => void
  addDifferentIngredient: () => void
}

export default function NoAlternativesModal({
  isOpen,
  close,
  addDifferentIngredient
}: Props) {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Uh-oh! I couldn't find any suitable alternatives for`,
    bold:  false
  }

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: `this item.`,
    bold:  false
  }

  return (
    <RootModal
      isOpen    = { isOpen }
      close     = { close }
      padding   = 'small'
    >
      <div
        className = 'no-alternatives-modal-container'
      >
        <MealiaBotCard
          textObjects = { [ topLineTextObject, bottomLineTextObject ] }
          closeModal  = { close }
        />

        {
          <div
            className = 'no-alternatives-modal-container-buttons'
          >
            <PrimaryButton
              text           = 'Close'
              onClickHandler = { close }
            />

            <ModalSecondaryButton
              text    = 'Add A Different Ingredient'
              onClick = { () => {
                close()
                addDifferentIngredient()
              } }
            />
          </div>
        }
      </div>
    </RootModal>
  )
}
