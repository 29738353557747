import { useContext } from 'react';

import AlertContext from 'context/AlertContext';
import useRemoveAlgorithmItemApi from 'utils/Hooks/Api/Algorithm/Item/useRemoveAlgorithmItem';
import { AlertType } from 'enums/AlertType';
import useShow from '../Basket/useShow';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useRemoveAlgorithmItem() {
  const { setAlert } = useContext(AlertContext);
  const removeAlgorithmItemApi = useRemoveAlgorithmItemApi();

  const show = useShow();

  function removeAlgorithmItem(
    algorithmChosenItemId: number, 
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void, 
    closeModal:            () => void,
    e:                     React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.stopPropagation()

    removeAlgorithmItemApi(algorithmChosenItemId).then((response) => {
      setAlgorithmRecipe(response.data);
      closeModal();
      setAlert(AlertType.RemovedIngredient)
    }).then(() => {
      // TODO: This only needs to be done for recipes in the meal plan
      show();
    });
  };

  return removeAlgorithmItem;
}
