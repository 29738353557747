import useNavigateTo from 'hooks/navigation/useNavigateTo';
import { RouteName } from 'router/Config';
import useGetAllSavedPlans from 'utils/Hooks/Actions/Algorithm/SavedPlan/useGetAllSavedPlans';
import AlgorithmSavedPlan from 'models/Algorithm/AlgorithmSavedPlan';

export default function useSavedPlans() {
  const algorithmSavedPlans = useGetAllSavedPlans();

  const navigateTo = useNavigateTo();

  const handleClick = (algorithmSavedPlan: AlgorithmSavedPlan) => {
    navigateTo(RouteName.SavedPlan, `?id=${algorithmSavedPlan.id}`);
  }

  return {
    algorithmSavedPlans,
    handleClick
  };
}
