import React from 'react';

import './DeleteItemModal.scss';
import RootModal from '../_RootModal';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import PrimaryButton from 'components/Buttons/PrimaryButton'
import MealiaBotCard from 'components/Cards/MealiaBotCard';
import P14 from 'components/Text/Paragraphs/Regular/P14'
import ModalSecondaryButton from 'components/Buttons/ModalSecondaryButton';
import { DashboardTextType } from 'types/DashboardTextType';

type Props = {
  isOpen:            boolean
  close:             () => void
  algorithmItem:     IAlgorithmItem
  deleteItemHandler: (algorithmItem: IAlgorithmItem, e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function DeleteItemModal({
  isOpen,
  close,
  algorithmItem,
  deleteItemHandler
}: Props) {

  if (!algorithmItem) {
    return null
  }

  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Are you sure you want to remove this ingredient?`,
    bold:  false
  }

  return (
    <RootModal
      isOpen    = { isOpen }
      close     = { close }
      padding   = 'small'
    >
      <div
        className = 'delete-item-modal-container'
      >
        <MealiaBotCard
          textObjects = { [ topLineTextObject ] }
          closeModal  = { close }
        />

        <div
          className = 'delete-item-modal-container-content'
        >
          <P14
            text   = { `This recipe contains ${algorithmItem.recipeIngredient.name}, so you will need ${algorithmItem.item.name} or an alternative to cook it.` }
            colour = 'black-1'
          />
        </div>

        <div
          className = 'delete-item-modal-buttons'
        >
          <PrimaryButton
            text           = 'Keep'
            onClickHandler = { close }
          />

          <ModalSecondaryButton
            text           = 'Remove'
            onClick        = { (e) => deleteItemHandler(algorithmItem, e) }
          />
        </div>
      </div>
    </RootModal>
  )
}
