import { useContext } from 'react';

import UserContext from 'context/UserContext';
import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import User from 'models/User';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';
import AlgorithmMealPlanContext from 'context/AlgorithmMealPlanContext';
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';

export default function useContexts() {
  const { algorithmBasket   }: { algorithmBasket: AlgorithmBasket }     = useContext(AlgorithmBasketContext)
  const { algorithmMealPlan }: { algorithmMealPlan: AlgorithmMealPlan } = useContext(AlgorithmMealPlanContext)
  const { user }: { user: User } = useContext(UserContext);

  return {
    algorithmBasket,
    algorithmMealPlan,
    user
  };
}
