import useRegisterApi from 'utils/Hooks/Api/User/Registration/useRegister';

export default function useRegister () {
  const registerApi = useRegisterApi();

  function register(
    firstName: string,
    email:     string,
    password:  string,
    setPage:   (page: 'Input' | 'Confirmation') => void,
    setError:  (error: any) => void
  ) {
    registerApi(firstName, email, password).then(() => {
      setPage('Confirmation');
    }).catch((error) => {
      setError(error);
    })
  }

  return register;
}
