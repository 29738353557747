import AlgorithmMealPlanApi from 'services/Core/Algorithm/MealPlanService/AlgorithmMealPlanApi';

export default function useAddAlgorithmRecipe() {
  function addAlgorithmRecipe(algorithmRecipeId: number): Promise<any> {
    const algorithmMealPlan = new AlgorithmMealPlanApi();

    return algorithmMealPlan.addAlgorithmRecipe(algorithmRecipeId);
  }

  return addAlgorithmRecipe;
}
