import React from 'react'

import './PinkCircle.scss'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'

type Props = {
  number: number
}

export default function PinkCircle({
  number
}: Props) {
  return (
    <div
      className = 'pink-circle-container'
    >
      <P16
        text   = { number.toString() }
        colour = 'white-1'
      />
    </div>
  )
}
