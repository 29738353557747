import { DashboardTextType } from 'types/DashboardTextType'

export default function useGrocerMessages() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Which supermarket do you want your`,
    bold:  false
  }

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: `ingredients to come from? 🛒 `,
    bold:  false
  }

  const grocerMessages = [
    topLineTextObject,
    bottomLineTextObject
  ]

  return grocerMessages
}
