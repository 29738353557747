import React, { useContext } from 'react'

import AlgorithmBasketContext from 'context/AlgorithmBasketContext'
import useLinkedSupermarket from 'utils/Hooks/Helper/useLinkedSupermarket'
import useCapitalize from 'hooks/utils/useCapitalize'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

export default function BasketDashboardCard() {
  const { algorithmBasket } = useContext(AlgorithmBasketContext)

  const linkedSupermarket = useLinkedSupermarket();
  const supermarket = useCapitalize(linkedSupermarket);

  const generateTopTextObjects = (): DashboardTextType[] => {
    if (algorithmBasket.hasNoRecipes) {
      return [{
        line: 1,
        value: 'Your basket is empty!',
        bold: false
      }];
    } else {
      return [
        {
          line: 1,
          value: 'Before checking out on ',
          bold: false
        },
        {
          line: 1,
          value: `${supermarket},`,
          bold: true
        }
      ];
    }
  }

  const generateBottomTextObjects = (): DashboardTextType[] => {
    if (algorithmBasket.hasNoRecipes) {
      return [{
        line: 2,
        value: 'You can start by making a plan and adding it to your basket.',
        bold: false
      }];
    } else {
      return [
        {
          line: 2,
          value: 'make sure you check the cupboard items you might need.',
          bold: false
        }
      ];
    }
  }


  return (
    <MealiaBotCard
      textObjects = { [ ...generateTopTextObjects(), ...generateBottomTextObjects() ] }
    />
  )
}
