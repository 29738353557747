import React from 'react'

import './RootGrid.scss'

type Props = {
  children:         React.ReactNode,
  numberOfColumns?: number
  containerClass?:  string
  gap?:             number
}

export default function RootGrid({
  children,
  numberOfColumns = 3,
  containerClass  = '',
  gap = 40
}: Props) {
  return (
    <div
      className = { `root-grid-container ${containerClass}` }
    >
      <ul 
        className = 'root-grid'
        style     = {{
          gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
          gap:                 `${gap}px`
        }}
      >
        { children }
      </ul>
    </div>
  )
}
