import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isOnRoute } from 'router/Navigator';
import { RouteName } from 'router/Config';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useRedirectedFrom from 'hooks/navigation/useRedirectedFrom';
import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';
import { KeyConstants } from 'utils/Constants';
import { NavigationModalType } from 'enums/Modals/NavigationModalType';
import useAskMealia from 'hooks/Pages/PostLogin/shared/useAskMealia';

export default function useNavigation() {
  const { algorithmBasket }: { algorithmBasket: AlgorithmBasket } = useContext(AlgorithmBasketContext);

  const [shouldFloat, setShouldFloat] = useState(false);
  const [activeModal, setActiveModal] = useState(NavigationModalType.None);
  
  const navigateTo = useNavigateTo();
  const navigate = useNavigate();
  const redirectedFrom = useRedirectedFrom();

  const {
    botPage,
    conversation,
    handleSendMessage
  } = useAskMealia();

  function navigateBack() {
    const path = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname;

    sessionStorage.setItem(KeyConstants.SessionStorage.navigatedFrom, path)

    navigate(-1);
  }

  function navigateToMyMeals() {
    navigateTo(RouteName.MyMeals);
  }

  function navigateToMyOrders() {
    navigateTo(RouteName.MyOrders);
  }

  function navigateToMyProfile() {
    navigateTo(RouteName.Settings);
  }

  function navigateToBasket() {
    navigateTo(RouteName.Basket);
  }

  function handleAskMealiaClick() {
    setActiveModal(NavigationModalType.AskMealia);
  }

  function handleSupermarketImageClick() {
    if (algorithmBasket?.checkedOut) return;

    setActiveModal(NavigationModalType.ChangeSupermarket);
  }

  function closeModal() {
    setActiveModal(NavigationModalType.None);
  }

  const handleScroll = () => {
    if (window.scrollY > 88) {
      setShouldFloat(true);
    } else {
      setShouldFloat(false);
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', navigateBack);

    return () => {
      window.removeEventListener('popstate', navigateBack);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onMyMeals    = isOnRoute([RouteName.MyMeals, RouteName.GenerateNewMeals, RouteName.Recipe]) || redirectedFrom(RouteName.MyMeals)
  const onMyOrders   = isOnRoute([RouteName.MyOrders, RouteName.SavedPlan, RouteName.SavedRecipe])
  const onGroceries  = isOnRoute([RouteName.Groceries, RouteName.Search]) || redirectedFrom(RouteName.Groceries)
  const onBasket     = isOnRoute([RouteName.Basket]) || redirectedFrom(RouteName.Basket);

  const basketValue = algorithmBasket?.totalValue;

  return {
    navigateBack,
    navigateToMyMeals,
    navigateToMyOrders,
    navigateToMyProfile,
    navigateToBasket,
    handleSupermarketImageClick,
    handleAskMealiaClick,
    handleSendMessage,
    activeModal,
    botPage,
    conversation,
    shouldFloat,
    closeModal,
    onMyMeals,
    onMyOrders,
    onGroceries,
    onBasket,
    basketValue
   };
}
