import { CoreMessageEndpoints } from 'services/Core/MessageService/Config';
import CoreApi from 'services/Core/CoreApi';
import { BotPage } from 'enums/BotPage';

export default class MessageApi extends CoreApi {
  constructor() {
    super(CoreMessageEndpoints);
  }

  show(name: BotPage) {
    const response = super.request(
      this.apiEndpoints.show(name)
    );

    return this.respondWithJson(response);
  }

  create(name: BotPage, content: string) {
    const response = super.request(
      this.apiEndpoints.create(name, content)
    );

    return this.respondWithJson(response);
  }

}
