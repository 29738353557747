import { BasketModalType } from 'enums/Modals/BasketModalType';

export default function useHandleRemoveItem(
  setTempItem: (tempItem: { name: string, id: number }) => void,
  openModal:   (modalType: BasketModalType) => void
) {
  const handleRemoveItem = (
    itemName: string,
    itemId: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();

    setTempItem({ name: itemName, id: itemId });

    openModal(BasketModalType.DeleteItem);
  }

  return handleRemoveItem;
}
