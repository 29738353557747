import { useContext } from 'react';

import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';
import useAddCupboardItemApi from 'utils/Hooks/Api/Algorithm/Basket/useAddCupboardItem';

export default function useAddCupboardItem() {
  const { setAlert }: { setAlert: (alert: AlertType) => void } = useContext(AlertContext);
  const addCupboardItemApi = useAddCupboardItemApi();

  function addCupboardItem(
    itemId: number,
    e:      React.MouseEvent<HTMLDivElement, MouseEvent>
  ): Promise<any> {
    e.stopPropagation()

    return addCupboardItemApi(itemId).then(() => {
      setAlert(AlertType.AddedToBasket)
    })
  };

  return addCupboardItem;
}
