import useSmoothScrollTo from 'hooks/ui/useSmoothScrollTo';
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useHandleAddCupboardItem(
  setAlgorithmRecipe:      (algorithmRecipe: AlgorithmRecipe) => void,
  addCupboardItemToBasket: (
    algorithmChosenItemId: number,
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void
  ) => void
) {
  const smoothScrollTo = useSmoothScrollTo();

  const handleAddCupboardItem = (
    algorithmItem: IAlgorithmItem,
    e:             React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();

    const scrollDuration = 600;

    const didScroll = smoothScrollTo('top-subsection-header-title-text-container', scrollDuration);

    if (didScroll) {
      setTimeout(() => {
        addCupboardItemToBasket(algorithmItem.id, setAlgorithmRecipe);
      }, scrollDuration + 100);
    } else {
      addCupboardItemToBasket(algorithmItem.id, setAlgorithmRecipe);
    }
  }

  return handleAddCupboardItem
}