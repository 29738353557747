import { CategoryType } from 'enums/CategoryType';
import { EnergyCost } from 'enums/EnergyCost';
import AlgorithmMealPlanApi from 'services/Core/Algorithm/MealPlanService/AlgorithmMealPlanApi';

export default function useFilter() {
  function filter(
    numberOfAdults:     number,
    numberOfChildren:   number,
    allergies:          string[],
    dietaryPreferences: string[],
    kitchenEquipment:   string[],
    energyCost:         EnergyCost | null,
    categories:         CategoryType[]
  ): Promise<any> {
    const algorithmMealPlan = new AlgorithmMealPlanApi();

    return algorithmMealPlan.filter(
      numberOfAdults,
      numberOfChildren,
      allergies,
      dietaryPreferences,
      kitchenEquipment,
      energyCost,
      categories
    );
  }

  return filter;
}
