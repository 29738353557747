//
// Entry point for the client-side application
//

import React    from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';

import 'styles'

import CoreRouter                    from 'router';
import ErrorBoundary                 from 'components/ErrorBoundary';
import { UserProvider }              from 'providers/UserProvider';
import { AlgorithmBasketProvider }   from 'providers/AlgorithmBasketProvider';
import { AlgorithmMealPlanProvider } from 'providers/AlgorithmMealPlanProvider';
import { AlertProvider }             from 'providers/AlertProvider';
import { LoadingProvider }           from 'providers/LoadingProvider';
import MouseflowScript               from 'components/Scripts/MouseflowScript';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ErrorBoundary>
    <MouseflowScript />

    <GoogleOAuthProvider
      clientId = { process.env.REACT_APP_GOOGLE_CLIENT_ID }
    >
      <UserProvider>
        <AlgorithmBasketProvider>
          <AlgorithmMealPlanProvider>
            <AlertProvider>
              <LoadingProvider>
                <CoreRouter />
              </LoadingProvider>
            </AlertProvider>
          </AlgorithmMealPlanProvider>
        </AlgorithmBasketProvider>
      </UserProvider>
    </GoogleOAuthProvider>
  </ErrorBoundary>
);