import { useEffect, useState } from 'react';

import useNavigateTo     from 'hooks/navigation/useNavigateTo';
import useGoogleRegister from 'utils/Hooks/Actions/User/useGoogleRegister';
import useSignIn         from 'utils/Hooks/Actions/User/Session/useSignIn';
import useGetCurrent     from 'utils/Hooks/Actions/User/useGetCurrent';
import useResendConfirmation from 'utils/Hooks/Actions/User/useResendConfirmation';
import { LocalConstants, KeyConstants } from 'utils/Constants';
import { RouteName } from 'router/Config';
import { ErrorType } from 'utils/Errors';
// import { PreLoginErrorType } from 'utils/Errors/PreLogin';

export default function useLogin() {
  const [email, setEmail]       = useState('');
  const [password, setPassword] = useState('');
  const [error, setError]       = useState(null);

  const signIn             = useSignIn();
  const googleRegister     = useGoogleRegister();
  const getCurrent         = useGetCurrent();
  const resendConfirmation = useResendConfirmation();
  const navigateTo         = useNavigateTo();

  useEffect(() => {
    attempLogin();
  }, []);

  const attempLogin = () => {
    if (localStorage.getItem(KeyConstants.LocalStorage.authToken) !== null) {
      userResponseHelper(getCurrent());
    }
  }

  const signInn = () => { // TODO: Fix this name
    userResponseHelper(signIn(email, password));
  };

  const googleLoginSuccess = (response: { code: string; }) => {
    userResponseHelper(googleRegister(response.code));
  }

  const googleLoginFailure = (response: any) => {
    console.error(response);
  }

  const resendEmailConfirmation = () => {
    resendConfirmation(email)
  }

  const navigateToForgotPassword = () => {
    navigateTo(RouteName.ForgotPassword);
  }

  const navigateToRegister = () => {
    navigateTo(RouteName.Register);
  }

  const closeModal = () => {
    setError(null);
  }

  const userResponseHelper = (promise: Promise<any>) => {
    promise
      .then(() => {
        navigateTo(RouteName.Onboarding);
      })
      .catch((error: ErrorType) => {
        localStorage.removeItem(KeyConstants.LocalStorage.authToken);

        setError(error);
      });
  }

  return [
    email,
    setEmail,
    password,
    setPassword,
    error,
    closeModal,
    signInn,
    googleLoginSuccess,
    googleLoginFailure,
    resendEmailConfirmation,
    navigateToForgotPassword,
    navigateToRegister,
    LocalConstants
  ] as const;
}
