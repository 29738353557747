import { OnboardingPage } from 'enums/OnboardingPage';
import IUserPreferences from 'interfaces/frontend/IUserPreferences';

import useHandleNextButtonClick from '../Operations/Actions/useHandleNextButtonClick';
import useHandlePreviousButtonClick from '../Operations/Actions/useHandlePreviousButtonClick';
import useHandleFinishLoading from '../Operations/Actions/useHandleFinishLoading';
import { RouteName } from 'router/Config';

export default function useActions(
  onboardingPage:     OnboardingPage,
  setOnboardingPage:  React.Dispatch<React.SetStateAction<OnboardingPage>>,
  userPreferences:    IUserPreferences,
  setUserPreferences: React.Dispatch<React.SetStateAction<IUserPreferences>>,
  setCompleted:       React.Dispatch<React.SetStateAction<boolean>>,
  navigateTo:         (routeName: RouteName) => void,
  createHousehold:    ( 
    numberOfAdults:        number,
    numberOfChildren:      number,
    numberOfMealsPerWeek:  number,
    weeklyBudget:          number,
    allergies:             string[],
    dietaryPreferences:    string[],
    kitchenEquipment:      string[],
    chosenSupermarket:     string,
    onSuccess:             () => void
  ) => void
) {
  const handlePreviousButtonClick = useHandlePreviousButtonClick(onboardingPage, setOnboardingPage, userPreferences, setUserPreferences);
  const handleNextButtonClick     = useHandleNextButtonClick(onboardingPage, setOnboardingPage, userPreferences, setUserPreferences, setCompleted, createHousehold);
  const handleFinishLoading       = useHandleFinishLoading(navigateTo)

  return {
    handlePreviousButtonClick,
    handleNextButtonClick,
    handleFinishLoading
  }
}
