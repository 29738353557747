import { useState } from 'react';

import IItem from 'interfaces/backend/IItem';
import { ShoppingCategory } from 'enums/ShoppingCategory';
import { ItemMapping } from 'types/Mappings/ItemMapping';

export default function useStates() {
  const [shoppingCategory, setShoppingCategory] = useState(ShoppingCategory.AlreadyInYourBasket);
  const [tempItem, setTempItem]                 = useState<{name: string, id: number}>(null);
  const [showLoadingBar, setShowLoadingBar]     = useState(false);
  const [shoppingItems, setShoppingItems]       = useState<IItem[]>([]);
  const [itemMapping, setItemMapping]           = useState<ItemMapping[]>([]);

  return {
    shoppingCategory,
    setShoppingCategory,
    tempItem,
    setTempItem,
    showLoadingBar,
    setShowLoadingBar,
    shoppingItems,
    setShoppingItems,
    itemMapping,
    setItemMapping
  };
}
