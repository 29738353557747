import { RouteName } from 'router/Config';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import AlgorithmSavedPlan from 'models/Algorithm/AlgorithmSavedPlan';

export default function useHandleRecipeCardClick(
  algorithmSavedPlan: AlgorithmSavedPlan,
  navigateTo:         (routeName: RouteName, params?: any) => void,
) {
  const handleRecipeCardClick = (algorithmRecipe: AlgorithmRecipe, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    const index = algorithmSavedPlan.algorithmRecipeMapping.findIndex((mapping) => mapping.algorithmRecipe.id === algorithmRecipe.id)

    navigateTo(RouteName.SavedRecipe, `/?id=${algorithmRecipe.id}&date=${algorithmSavedPlan.prettyCreatedAt}&index=${index + 1}`)
  }

  return handleRecipeCardClick;
}
