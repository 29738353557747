import React from 'react'

import './FreshItemModal.scss'
import RootModal from '../_RootModal/RootModal'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import FreshItemAlgorithmItemCard from 'components/Cards/AlgorithmItemCard/FreshItemAlgorithmItemCard'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

type Props = {
  isOpen: boolean,
  close: () => void,
  algorithmItem: IAlgorithmItem
}

export default function FreshItemModal({
  isOpen,
  close,
  algorithmItem
}: Props) {

  if (algorithmItem === null) {
    return null
  }

  const topLineTextObject: DashboardTextType[] = [
    {
      line:  1,
      value: `This recipe contains`,
      bold:  false
    },
    {
      line:  1,
      value: `${algorithmItem.recipeIngredient.name}.`,
      bold:  true
    }
  ]

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: `Here's the item we've recommended for it.`,
    bold:  false
  }

  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'small'
    >
      <div
        className = 'fresh-item-modal-container'
      >
        <MealiaBotCard
          textObjects = { [ ...topLineTextObject, bottomLineTextObject ] }
          closeModal  = { close }
        />

        <FreshItemAlgorithmItemCard
          algorithmItem = { algorithmItem }
        />
      </div>
    </RootModal>
  )
}
