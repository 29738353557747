import React from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

export default function SettingsDashboardCard() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Settings... Beep boop bap...`,
    bold:  false
  }

  return (
    <MealiaBotCard
      textObjects = { [ topLineTextObject ] }
    />
  )
}
