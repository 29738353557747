import React from 'react'

import './OrdinalPill.scss'
import P12 from 'components/Text/Paragraphs/Medium/P12'
import { ReactComponent as PinkBag } from 'assets/images/icons/_new/pink-bag.svg'

type Props = {
  ordinalNumber: string
}

export default function OrdinalPill({
  ordinalNumber
}: Props) {
  return (
    <div
      className = 'ordinal-pill-container'
    >
      <PinkBag
        className = 'pink-bag'
      />

      <P12
        text   = { `${ordinalNumber} Meal` }
        colour = 'black-1'
      />
    </div>
  )
}
