import { useContext } from 'react';

import UserContext from 'context/UserContext';
import User from 'models/User';

export default function useContexts() {
  const { user }: { user: User } = useContext(UserContext);

  return {
    user
  }
}
