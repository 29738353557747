import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useHandleRemoveAlgorithmItem(
  closeModal:          () => void,
  setAlgorithmRecipe:  (algorithmRecipe: AlgorithmRecipe) => void,
  removeAlgorithmItem: (
    algorithmChosenItemId: number,
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal:            () => void,
    e:                     React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
) {
  const handleRemoveAlgorithmItem = (
    algorithmItem: IAlgorithmItem,
    e:             React.MouseEvent<HTMLButtonElement>
  ) => {
    removeAlgorithmItem(algorithmItem.id, setAlgorithmRecipe, closeModal, e);
  }

  return handleRemoveAlgorithmItem
}
