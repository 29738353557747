import React from 'react'

import './Start.scss'
import Root from '../Root'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import FallbackIngredientImage from 'components/Images/Fallback/FallbackIngredientImage'
import { IngredientsUsedInStep } from 'interfaces/backend/IRecipeStep'

type Props = {
  algorithmItems: IAlgorithmItem[]
  onClick:        (ingredient: IngredientsUsedInStep, e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function Start({
  algorithmItems,
  onClick
}: Props) {
  const freshItems    = algorithmItems.filter((item: IAlgorithmItem) => !item.recipeIngredient.cupboardItem)
  const cupboardItems = algorithmItems.filter((item: IAlgorithmItem) => item.recipeIngredient.cupboardItem)

  // TODO: Also used in CupboardItemModal - refactor
  function prettyUnits(quantity: number, units: string): string {
    switch (units) {
      case 'LOOSE':
        return `${quantity}`
      case 'GRAMS':
        return `${quantity}g`
      case 'ML':
        return `${quantity}ml`
      default:
        throw new Error(`Unknown units: ${units}`)
    }
  }

  return (
    <Root
      text        = 'Getting Ready'
      bottomChild = {
        <div
          className = 'start-step-container'
        >
          {
            [freshItems, cupboardItems].map((items: IAlgorithmItem[], index) => (
              <div
                key = { index }
              >
                <div
                  className = 'start-step-text'
                >
                  <P14
                    text   = { `${index === 0 ? 'Ingredients ': 'Cupboard Items '} (${items.length})` }
                    colour = 'black-1'
                  />
                </div>

                <div
                  className = 'start-step-grid'
                >
                  {
                    items.map((algorithmItem: IAlgorithmItem, index) => (
                      <button
                        key       = { index }
                        className = 'start-step-grid-item'
                        onClick   = { (e) => onClick({
                          name:         algorithmItem.recipeIngredient.name,
                          imageUrl:     algorithmItem.item.imageUrl,
                          cupboardItem: algorithmItem.recipeIngredient.cupboardItem,
                        }, e) }
                      >
                        <FallbackIngredientImage
                          src       = { algorithmItem.recipeIngredient.cupboardItem ? algorithmItem.recipeIngredient.imageUrl : algorithmItem.item.imageUrl }
                          alt       = { algorithmItem.item.name }
                          className = 'start-step-grid-item-image'
                        />

                        {
                          !algorithmItem.recipeIngredient.cupboardItem && <div
                            className = 'start-step-grid-item-quantity-container'
                          >
                            <P14
                              text   = { prettyUnits(algorithmItem.contentRequired, algorithmItem.recipeIngredient.units) }
                              colour = 'black-1'
                            />
                          </div>
                        }

                        <P14
                          text   = { algorithmItem.recipeIngredient.name }
                          colour = 'black-1'
                        />
                      </button>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      }
    />
  )
}
