import React from 'react'

import './RoundedRectangle.scss'

type Props = {
  width: number
}

export default function RoundedRectangle({
  width
}: Props) {
  if (width % 1 !== 0 || width < 0 || width > 10) {
    throw new Error('RoundedRectangle - Width must be a whole number between 0 and 10')
  }

  return (
    <div
      className = 'rounded-rectangle-container'
    >
      <div
        className = 'rounded-background'
      />

      <div
        style     = {{ width: `${width * 10}%` }}
        className = {`
          rounded-rectangle
          ${ width === 10 ? 'rounded-rectangle-full' : '' }
        `}
      />
    </div>
  )
}
