import React from 'react'

import IItem from 'interfaces/backend/IItem'
import RootItemCard from '../_RootItemCard'
import FullWithCountIncrementorButton from 'components/Buttons/IncrementorButton/FullWithCount'

type Props = {
  item:                    IItem
  quantityRequired:        number
  onCardClick:             (item: IItem) => void
  incrementAdditionalItem: (item: IItem, e: React.MouseEvent<HTMLDivElement>) => void
  decrementAdditionalItem: (item: IItem, e: React.MouseEvent<HTMLDivElement>) => void
}

export default function AdjustableAdditionalItemCard({
  item,
  quantityRequired,
  onCardClick,
  incrementAdditionalItem,
  decrementAdditionalItem
}: Props) {
  return (
    <RootItemCard
      item           = { item }
      onCardClick    = { onCardClick }
      buttonChildren = {
        <FullWithCountIncrementorButton
          quantity     = { quantityRequired }
          onTrashClick = { (e: React.MouseEvent<HTMLDivElement>) => decrementAdditionalItem(item, e) }
          onAddClick   = { (e: React.MouseEvent<HTMLDivElement>) => incrementAdditionalItem(item, e) }
        />
      }
    />
  )
}