import { useContext } from 'react';
import UserContext from 'context/UserContext';
import User from 'models/User';

// TODO: Use UserModel: user.chosenSupermarket
export default function useLinkedSupermarket() {
  const { user }: { user: User } = useContext(UserContext);

  const linkedSupermarket = user.household.chosenSupermarket;

  return linkedSupermarket;
}
