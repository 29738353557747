import IItem from 'interfaces/backend/IItem';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useHandleDecreaseAdditionalItem(
  algorithmRecipe:         AlgorithmRecipe,
  setAlgorithmRecipe:      (algorithmRecipe: AlgorithmRecipe) => void,
  decrementAdditionalItem: (
    algorithmRecipeId:  number, 
    supermarketItemId:  number,
    setAlgorithmRecipe: (algorithmRecipe: AlgorithmRecipe) => void
  ) => void
) {
  const handleDecreaseAdditionalItem = (
    item: IItem
  ) => {
    decrementAdditionalItem(algorithmRecipe.id, item.supermarketItemId, setAlgorithmRecipe);
  }

  return handleDecreaseAdditionalItem;
}
