import { useState } from 'react';

import { RouteName } from 'router/Config';
import { ParamConstants } from 'utils/Constants';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useSignIn from 'utils/Hooks/Actions/User/Session/useSignIn';
import useUpdate from 'utils/Hooks/Actions/User/Password/useUpdate';

export default function useResetPassword() {
  const [page, setPage] = useState<'Input' | 'Confirmation'>('Input');
  const [error, setError] = useState(null);

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const signIn        = useSignIn();
  const resetPassword = useUpdate();
  const navigateTo    = useNavigateTo();

  const reset = () => {
    const params = new URLSearchParams(window.location.search)
    const resetPasswordToken = params.get(ParamConstants.ResetPasswordToken)

    if (!resetPasswordToken) return

    resetPassword(password, passwordConfirmation, resetPasswordToken, setPage, setError)
  }

  const navigateToLogin = () => {
    navigateTo(RouteName.Login);
  }

  const continueToPlanning = () => {
    const params = new URLSearchParams(window.location.search)
    const email = params.get('email')

    signIn(email, password)
  }

  const closeModal = () => {
    setError(null);
  }

  return [
    page,
    password,
    setPassword,
    passwordConfirmation,
    setPasswordConfirmation,
    error,
    closeModal,
    reset,
    navigateToLogin,
    continueToPlanning
  ] as const;
}
