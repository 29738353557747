import AlgorithmSavedPlanApi from 'services/Core/Algorithm/SavedPlanService/AlgorithmSavedPlanApi';

export default function useGetAllSavedPlans() {
  function getAllSavedPlans(): Promise<any> {
    const algorithmSavedPlanApi = new AlgorithmSavedPlanApi();

    return algorithmSavedPlanApi.getAllSavedPlans();
  }

  return getAllSavedPlans;
}
