import User from 'models/User';
import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import { RouteName } from 'router/Config';

export default function useHandleFinishLoading(
  navigateTo: (routeName: RouteName) => void,
  checkout:   () => void,
  user:       User,
  isExtensionInstalledPreviously: boolean,
) {
  const handleFinishLoading = () => {
    if (isExtensionInstalledPreviously) {
      checkout()

      openSupermarket();

      navigateTo(RouteName.CheckedOut);
    } else {
      navigateTo(RouteName.DownloadExtension);
    }
  }

  const openSupermarket = () => {
    switch (user.chosenSupermarket) {
      case SupportedSupermarket.Asda:
        window.open('https://groceries.asda.com?redirectedFrom=Mealia', '_blank');
        break;
      case SupportedSupermarket.Tesco:
        window.open('https://www.tesco.com/groceries/en-GB?redirectedFrom=Mealia', '_blank');
        break;
      default:
        break;
    }
  }

  return handleFinishLoading;
}
