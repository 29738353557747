import { useEffect } from 'react';

export default function useHideDropdown(
  setSuggestions: (suggestions: string[]) => void
) {
  useEffect(() => {
    function handleDocumentClick() {
      setSuggestions([])
    }
    
    // Attach the event listener to the document when the dropdown card is mounted
    document.addEventListener('click', handleDocumentClick);
    
    // Detach the event listener from the document when the dropdown card is unmounted
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
}
