import React from 'react'

import './ModalSecondaryButton.scss'
import P14 from 'components/Text/Paragraphs/SemiBold/P14'

type Props = {
  text:     string
  onClick:  (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function ModalSecondaryButton({
  text,
  onClick
}: Props) {
  return (
    <button
      className = 'modal-secondary-button'
      onClick   = { onClick }
    >
      <P14
        text   = { text }
        colour = 'pink-1'
      />
    </button>
  )
}
