import React from 'react'

import { LocalConstants } from 'utils/Constants'
import useReport from 'utils/Hooks/Actions/Error/useReport';

interface Props {
  error:     Error;
  backtrace: string;
}

export default function ErrorPage({
  error,
  backtrace
}: Props) {
  const report = useReport();

  React.useEffect(() => {
    if (error && backtrace) {
      report(error.message, backtrace);
    }    
  }, [ error, backtrace ]);

  return (
    <h1>
      { LocalConstants.Error.Generic }
    </h1>
  )
}
