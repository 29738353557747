import useResendConfirmationApi from 'utils/Hooks/Api/User/Confirmation/useCreate';

export default function useResendConfirmation() {
  const resendConfirmationApi = useResendConfirmationApi();

  function resendConfirmation(email: string): Promise<any> {
    return resendConfirmationApi(email);
  }

  return resendConfirmation;
}