import { useState } from 'react';
import { RecipeModalType } from 'enums/Modals/RecipeModalType';

export default function useModal() {
  const [activeModal, setActiveModal] = useState<RecipeModalType>(RecipeModalType.None);

  const openModal = (modalType: RecipeModalType) => {
    setActiveModal(modalType);
  };

  const closeModal = () => {
    setActiveModal(RecipeModalType.None);
  };

  return {
    activeModal,
    openModal,
    closeModal,
  };
}
