import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import AlgorithmMealPlanApi from 'services/Core/Algorithm/MealPlanService/AlgorithmMealPlanApi';

export default function useCreateNewMealPlan() {
  function createNewMealPlan(
    numberOfAdults:       number,
    numberOfChildren:     number,
    numberOfMealsPerWeek: number,
    weeklyBudget:         number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    chosenSupermarket:    SupportedSupermarket
  ): Promise<any> {
    const algorithmMealPlan = new AlgorithmMealPlanApi();

    return algorithmMealPlan.createNewMealPlan(
      numberOfAdults,
      numberOfChildren,
      numberOfMealsPerWeek,
      weeklyBudget,
      allergies,
      dietaryPreferences,
      kitchenEquipment,
      chosenSupermarket
    );
  }

  return createNewMealPlan;
}
