import React from 'react'

import './TitleText.scss'
import H1Text from 'components/Text/Headings/H1Text'
import H2Text from 'components/Text/Headings/H2Text'

type Props = {
  text:      string,
  title?:    'h1' | 'h2',
  id?:       string,
  children?: React.ReactNode
}

export default function TitleText({
  text,
  title = 'h1',
  id,
  children
}: Props) {
  return (
    <div
      className = 'title-text-container'
      id        = { `${ id }-title-text-container` }
    >
      <div
        className = 'title-text'
      >
        {
          title === 'h1' ? (
            <H1Text
              text = { text }
            />
          ) : (
            <H2Text
              text = { text }
            />
          )
        }
      </div>

      { children }
    </div>
  )
}
