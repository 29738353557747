import React, { useEffect, useState } from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

type Props = {
  savedPlansCount: number
}

export default function SavedPlansDashboardCard({
  savedPlansCount
}: Props) {
  const [forceUpdate, setForceUpdate] = useState(false)

  useEffect(() => {
    setForceUpdate(!forceUpdate)
  }, [savedPlansCount])

  const generateTopTextObjects = (): DashboardTextType[] => {
    if (savedPlansCount > 0) {
      return [
        {
          line: 1,
          value: 'You have ',
          bold: false
        },
        {
          line: 1,
          value: `${savedPlansCount}`,
          bold: true
        },
        {
          line: 1,
          value: `saved transferred basket${savedPlansCount === 1 ? '' : 's'}.`,
          bold: false
        }
      ];
  } else {
      return [{
        line: 1,
        value: `Uh-oh, looks like you don’t have any saved transferred baskets.`,
        bold: false
      }];
    }
  }

  const generateBottomTextObjects = (): DashboardTextType => {
    if (savedPlansCount > 0) {
      return {
        line: 2,
        value: 'Just click on one of them to view it.',
        bold: false
      };
    } else {
      return {
        line: 2,
        value: 'Once you checkout a basket, you will find it here.',
        bold: false
      };
    }
  }


  return (
    <MealiaBotCard
      textObjects = { [...generateTopTextObjects(), generateBottomTextObjects()] }
      startTyping = { forceUpdate }
    />
  )
}
