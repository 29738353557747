import React from 'react'

import { OnboardingQuestionProps } from '../Shared/OnboardingQuestionProps'
import DietaryPreference from 'components/features/UserPreferences/DietaryPreference'

export default function DietaryPreferences({
  userPreferences,
  setUserPreferences
}: OnboardingQuestionProps) {
  const handleDietSelection = (diet: string) => {
    setUserPreferences({
      ...userPreferences,
      dietaryPreferences: diet
    })
  }

  const handleYesClick = (option: string) => {
    setUserPreferences({
      ...userPreferences,
      dietaryPreferencesPresent: option
    })
  }

  return (
    <div
      style = {{
        width: '366px'
      }}
    >
      <DietaryPreference
        showYesNoButtons  = { true }
        selectedOption    = { userPreferences.dietaryPreferencesPresent }
        handleYesNoClick  = { handleYesClick }
        selectedOptions   = { [ userPreferences.dietaryPreferences ] }
        handleOptionClick = { handleDietSelection }
      />
    </div>
  )
}
