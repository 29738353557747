import { useContext } from 'react';

import useDecrementAdditionalItemApi from 'utils/Hooks/Api/Algorithm/Recipe/useDecrementAdditionalItem';
import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';
import useShow from '../Basket/useShow';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useDecrementAdditionalItem() {
  const { setAlert } = useContext(AlertContext);
  const decrementAdditionalItemApi = useDecrementAdditionalItemApi();

  const show = useShow();

  function decrementAdditionalItem(
    algorithmRecipeId:  number,
    supermarketItemId:  number,
    setAlgorithmRecipe: (algorithmRecipe: AlgorithmRecipe) => void
  ) {
    decrementAdditionalItemApi(algorithmRecipeId, supermarketItemId).then((response) => {
      const data: AlgorithmRecipe = response.data;

      setAlgorithmRecipe(data);

      setAlert(AlertType.RemovedIngredient)
    }).then(() => {
      // TODO: This only needs to be done for recipes in the meal plan
      show();
    });
  }

  return decrementAdditionalItem;
}
