import React from 'react'

import './RecipeInfoButton.scss'
import { ReactComponent as InfoIcon } from 'assets/images/icon/info/root.svg'

type Props = {
  onInfoClick: () => void
}

export default function RecipeInfoButton({
  onInfoClick
}: Props) {
  return (
    <button
      className = 'recipe-info-button'
      onClick   = { onInfoClick }
    >
      <InfoIcon
        color = '#FFFFFF'
      />
    </button>
  )
}
