import { DashboardTextType } from 'types/DashboardTextType'

export default function useAllergiesMessages() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Cool, what about allergies?`,
    bold:  false
  }

  const allergiesMessages = [
    topLineTextObject
  ]

  return allergiesMessages
}
