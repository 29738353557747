import { DashboardTextType } from 'types/DashboardTextType'

export default function useKitchenEquipmentMessages() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Almost there, what kitchen equipment do you have?`,
    bold:  false
  }

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: `Please select every option that's available to you.`,
    bold:  false
  }

  const kitchenEquipmentMessages = [
    topLineTextObject,
    bottomLineTextObject
  ]

  return kitchenEquipmentMessages
}
