import { RouteName } from 'router/Config';

import useHandleDropDownSuggestionClick from '../Operations/Actions/useHandleDropDownSuggestionClick';
import useSearch from '../Operations/Actions/useSearch';

export default function useActions(
  searchValue:    string,
  setSearchValue: (value: string) => void,
  navigateTo:     (routeName: RouteName, params?: any) => void
) {
  const handleDropDownSuggestionClick = useHandleDropDownSuggestionClick(setSearchValue, navigateTo);
  const search                        = useSearch(searchValue, navigateTo);

  return {
    handleDropDownSuggestionClick,
    search
  };
}
