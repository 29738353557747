import { BotPage } from 'enums/BotPage';
import useShowApi from 'utils/Hooks/Api/Message/useShow';

export default function useShow() {
  const showApi = useShowApi();

  async function show(name: BotPage) {
    try {
      const response = await showApi(name);

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  return show;
}
