import IUserPreferences from 'interfaces/frontend/IUserPreferences';

export function initUserPreferences(): IUserPreferences {
  return {
    numberOfAdults:            null,
    numberOfChildren:          null,
    numberOfMealsPerWeek:      null,
    weeklyBudget:              null,
    dietaryPreferencesPresent: null,
    dietaryPreferences:        null,
    allergiesPresent:          null,
    allergies:                 [],
    kitchenEquipment:          [],
    chosenSupermarket:         null
  };
}

export function getNumberOfPeople(userPreferences: IUserPreferences): number {
  return (userPreferences.numberOfAdults || 0) + (userPreferences.numberOfChildren || 0);
}

export function getEffectiveWeeklyBudget(userPreferences: IUserPreferences): number {
  if (getNumberOfPeople(userPreferences) <= 2) {
    return Math.max(1, Math.min(100, Math.ceil(userPreferences.numberOfMealsPerWeek * getNumberOfPeople(userPreferences) * 2.5)))
  } else {
    return Math.max(1, Math.min(100, Math.ceil(userPreferences.numberOfMealsPerWeek * getNumberOfPeople(userPreferences) * 1.5)))
  }
}

export function getBudgetLimits(userPreferences: IUserPreferences): [number, number] {
  const numberOfPeople = getNumberOfPeople(userPreferences);

  const calculateLimit = (factor: number, upperLimit: number) => {
    const result = Math.ceil(userPreferences.numberOfMealsPerWeek * numberOfPeople * factor);

    return Math.max(1, Math.min(upperLimit, result));
  }

  const upperLimit = () => {
    let factor = numberOfPeople <= 2 ? 3.5 : 2.5;

    return calculateLimit(factor, 100);
  }

  const lowerLimit = () => {
    let factor = numberOfPeople <= 2 ? 2 : 1.1;

    // Slider will throw an error if the lower limit is greater than or equal to the upper limit
    // so we need to make sure that the lower limit is always less than the upper limit
    return calculateLimit(factor, 99);
  }

  return [
    upperLimit(),
    lowerLimit()
  ];
}
