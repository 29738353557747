import React from 'react'

import './FooterNavBar.scss'
import P14 from 'components/Text/Paragraphs/Regular/P14'
import { ReactComponent as LogoSmall } from 'assets/images/logos/mealia/small.svg'
import { KeyConstants } from 'utils/Constants'
import SecondaryButton from 'components/Buttons/SecondaryButton'

export default function FooterNavBar() {
  const navigateToAbout = () => {
    window.open(`https://www.mealia.co.uk/about`, '_blank');
  }

  const navigateToPrivacyPolicy = () => {
    window.open(`https://www.mealia.co.uk/about`, '_blank');
  }

  return (
    <div
      className = 'footer-nav-bar-container'
    >
      <div
        className = 'footer-nav-bar-content'
      >
        <div
          className = 'footer-nav-bar-lhs'
        >
          <LogoSmall />

          <P14
            text   = { `Mealia Beta ${KeyConstants.BetaVersion}` }
            colour = 'grey-1'
          />

          <span
            className = 'footer-nav-bar-divider'
          />

          <P14
            text   = 'Reimagining Grocery Shopping'
            colour = 'grey-1'
          />
        </div>

        <div
          className = 'footer-nav-bar-links'
        >
          <SecondaryButton
            text    = 'About'
            onClick = { navigateToAbout }
          />

          <SecondaryButton
            text    = 'Privacy Policy'
            onClick = { navigateToPrivacyPolicy }
          />
        </div>
      </div>
    </div>
  )
}
