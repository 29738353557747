import React, { useContext } from 'react'

import './ReplaceableAlgorithmItemCard.scss'
import RootAlgorithmItemCard from '../_RootAlgorithmItemCard'

import P14 from 'components/Text/Paragraphs/Medium/P14'
import P14S from 'components/Text/Paragraphs/SemiBold/P14'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import HalfIncrementorButton from 'components/Buttons/IncrementorButton/Half'
import GreyButton from 'components/Buttons/GreyButton'
import UserContext from 'context/UserContext'
import User from 'models/User'

type Props = {
  algorithmItem:       IAlgorithmItem
  onCardClick:         (algorithmItem: IAlgorithmItem) => void
  onSubstitutionClick: (algorithmItem: IAlgorithmItem, e: React.MouseEvent<HTMLDivElement>) => void
  onTrashClick:        (algorithmItem: IAlgorithmItem, e: React.MouseEvent<HTMLDivElement>) => void
}

export default function ReplaceableAlgorithmItemCard({
  algorithmItem,
  onCardClick,
  onSubstitutionClick,
  onTrashClick
}: Props) {
  const { user }: { user: User } = useContext(UserContext)

  return (
    <div
      className = 'replaceable-algorithm-item-card'
    >
      <div
        className = 'tagline'
      >
        <P14
          text   = { algorithmItem.recipeIngredient.name }
          colour = 'grey-1'
        />

        {
          algorithmItem.recipeIngredient.verifyHalalStatus && user.household.isHalal ? <P14S
            text   = '(Please Check if Halal)'
            colour = 'black-1'
          />
          :
          !algorithmItem.default && <>
            <P14
              text   = '-'
              colour = 'grey-1'
            />

            <P14S
              text   = 'Ingredient Swapped'
              colour = 'pink-1'
            />
          </>
        }
      </div>

      <RootAlgorithmItemCard
        algorithmItem  = { algorithmItem }
        price          = { algorithmItem.item.price }
        onCardClick    = { onCardClick }
        buttonChildren = {
          <div
            className = 'replaceable-algorithm-item-card-buttons'
          >
            <GreyButton
              text           = 'Swap'
              onClickHandler = { (e) => onSubstitutionClick(algorithmItem, e) }
            />

            <HalfIncrementorButton 
              quantityRequired = { algorithmItem.quantityRequired }
              onTrashClick     = { (e) => onTrashClick(algorithmItem, e) }
            />
          </div>
        }
      />
    </div>
  )
}
