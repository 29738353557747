import UserConfirmationApi from 'services/Core/User/ConfirmationService/UserConfirmationApi';

export default function useCreate() {
  function create(email: string): Promise<any> {
    const userConfirmationApi = new UserConfirmationApi();

    return userConfirmationApi.create(email);
  }

  return create;
}
