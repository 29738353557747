import React from 'react'

import './GreyButton.scss'
import P12 from 'components/Text/Paragraphs/Medium/P12'

type Props = {
  text:           string
  onClickHandler: (...props: any) => void
}

export default function GreyButton({
  text,
  onClickHandler
}: Props) {

  return (
    <button
      className = 'grey-button-container'
      onClick   = { onClickHandler }
    > 
      <P12
        text   = { text }
        colour = { 'grey-1' }
      />
    </button>
  )
}
