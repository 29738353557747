import React, { useEffect, useRef } from 'react'

import './Root.scss'
import Textbox from '../Textbox'
import IMessage, { Sender } from 'interfaces/backend/IMessage'
import MessageRenderer from '../Message/MessageRenderer'
import { BotPage } from 'enums/BotPage'
import Prompt from '../Prompt'

type Props = {
  type:        BotPage
  messages:    IMessage[] | undefined
  onSendClick: (content: string, setValue: (value: string) => void) => void
}

export default function Root({
  type,
  messages,
  onSendClick
}: Props) {
  const scrollableRef = useRef();

  useEffect(() => {
    const scrollable = scrollableRef.current as any

    // TODO: Fix - this does not scroll to true bottom - need to account for padding
    if (scrollable) {
      scrollable.scrollTop = scrollable.scrollHeight
    }
  }, [messages])

  return (
    <div
      className = 'chatbot-root-container'
    >
      <div
        className = 'chatbot-root-container-scrollable'
        ref       = { scrollableRef }
      >
        <MessageRenderer
          messages = { messages }
        />
      </div>

      {
        messages?.filter(message => message.sender === Sender.Assistant).length === messages?.length && <div
          className = 'chatbot-root-container-prompt'
        >
          <Prompt
            type    = { type }
            onClick = { (prompt) => onSendClick(prompt, () => {}) }
          />
        </div>
      }
      
      <Textbox
        isLastMessageFromUser = { messages?.[messages.length - 1]?.sender === Sender.User }
        onSendClick           = { onSendClick }
      />
    </div>
  )
}
