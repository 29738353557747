import useActions  from './Core/useActions';
import useComputed from './Core/useComputed';
import useContexts from './Core/useContexts';
import useEffects  from './Core/useEffects';
import useHooks    from './Core/useHooks';
import useMessages from './Core/useMessages';
import useStates   from './Core/useStates';

export default function useOnboarding() {
  const  {
    user
  } = useContexts();

  const {
    navigateTo,
    createHousehold
  } = useHooks();

  const {
    forceUpdate,
    setForceUpdate,
    completed,
    setCompleted,
    onboardingPage,
    setOnboardingPage,
    userPreferences,
    setUserPreferences
  } = useStates();

  const {
    introductionMessages,
    numberOfPeopleMessages,
    numberOfMealsMessages,
    budgetMessages,
    dietaryPreferencesMessages,
    allergiesMessages,
    kitchenEquipmentMessages,
    grocerMessages,
    endingMessages
  } = useMessages(userPreferences);

  const {
    disabledPrimaryButton,
    botMessage
  } = useComputed(onboardingPage, userPreferences, introductionMessages, numberOfPeopleMessages, numberOfMealsMessages, budgetMessages, dietaryPreferencesMessages, allergiesMessages, kitchenEquipmentMessages, grocerMessages, endingMessages)

  const {
    handlePreviousButtonClick,
    handleNextButtonClick,
    handleFinishLoading
  } = useActions(onboardingPage, setOnboardingPage, userPreferences, setUserPreferences, setCompleted, navigateTo, createHousehold);

  useEffects(user, navigateTo, onboardingPage, setForceUpdate)

  return {
    forceUpdate,
    completed,
    onboardingPage,
    userPreferences,
    setUserPreferences,
    botMessage,
    disabledPrimaryButton,
    handlePreviousButtonClick,
    handleNextButtonClick,
    handleFinishLoading
  }
}
