import { useState } from 'react';

import { LoadingStatus } from 'enums/LoadingStatus';
import IConversation from 'interfaces/backend/IConversation';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import { MyMealsFilterType } from 'enums/MyMealsFilterType';

export default function useStates() {
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Initial);
  const [conversation, setConversation]   = useState<IConversation>(null);
  const [otherRecipes, setOtherRecipes]   = useState<AlgorithmRecipe[]>([]);
  const [filterType, setFilterType]       = useState<MyMealsFilterType | null>(null);

  const [showNotification, setShowNotification] = useState(false);

  return {
    loadingStatus,
    setLoadingStatus,
    conversation,
    setConversation,
    otherRecipes,
    setOtherRecipes,
    filterType,
    setFilterType,
    showNotification,
    setShowNotification
  }
}
