import React from 'react'

import './Root.scss'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import IRecipeStep, { IngredientsUsedInStep } from 'interfaces/backend/IRecipeStep'
import DefaultStep from './InstructionStep/Default'
import StartStep from './InstructionStep/Start'
import useImputeInstructions from './Hooks/useImputeInstructions'

type Props = {
  recipeSteps:    IRecipeStep[]
  algorithmItems: IAlgorithmItem[]
  onClick:        (ingredient: IngredientsUsedInStep, e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function Root({
  recipeSteps,
  algorithmItems,
  onClick
}: Props) {
  const imputedSteps = useImputeInstructions(recipeSteps, algorithmItems)

  return (
    <div
      className = 'instructions-container'
    >
      <StartStep
        algorithmItems = { algorithmItems }
        onClick        = { onClick }
      />

      {
        imputedSteps.map((step: IRecipeStep, index) => (
          <li
            key = { index }
          >
            <DefaultStep
              step    = { step }
              onClick = { onClick }
            />
          </li>
        ))
      }
    </div>
  )
}
