import React from 'react'

import './Basket.scss'
import Navigation from 'pages/PostLogin/Shared/Navigation'
import BasketDashboardCard from 'components/Cards/Dashboard/BasketDashboardCard'
import Selector from 'components/Buttons/Selector'
import ItemCardGrid from 'components/Grids/ItemCardGrid'
import ShoppingItemCardGrid from 'components/Grids/ShoppingItemCardGrid'
import TitleText from 'components/Text/TitleText'
import IItem from 'interfaces/backend/IItem'
import { ItemMapping } from 'types/Mappings/ItemMapping'
import { ItemGridCard } from 'enums/Grids/ItemGridCard'
import PersonalPlan from 'components/Cards/PersonalPlan'
import DeleteBasketItemModal from 'components/Modals/DeleteBasketItemModal'
import P14 from 'components/Text/Paragraphs/Medium/P14';
import { ReactComponent as TescoLogo } from 'assets/images/logos/tesco/groceries.svg'
import { ReactComponent as AsdaLogo } from 'assets/images/logos/asda/groceries.svg'
import { BasketModalType } from 'enums/Modals/BasketModalType'
import SupermarketLoadingPill from 'components/Pills/SupermarketLoadingPill'
import { SupportedSupermarket } from 'enums/SupportedSupermarket'
import { ShoppingCategory } from 'enums/ShoppingCategory'

import useBasket from 'hooks/Pages/PostLogin/11-Basket/useBasket'

export default function Basket() {
  const {
    activeModal,
    shoppingCategory,
    setShoppingCategory,
    tempItem,
    showLoadingBar,
    shoppingItems,
    itemMapping,
    algorithmBasket,
    algorithmMealPlan,
    user,
    linkedSupermarket,
    closeModal,
    handleAcceptDeleteItem,
    handleAddCupboardItem,
    handleStartLoading,
    handleFinishLoading,
    handleRemoveItem
  } = useBasket();

  return (
    <>
      <Navigation>
        <div
          className = 'algorithm-basket-container'
        >
          <BasketDashboardCard />

          <span
            style = {{ height: '8px' }}
          />

          <div
            className = 'basket-title-container'
          >
            <TitleText
              text = { shoppingCategory === ShoppingCategory.AlreadyInYourBasket ? 'My Basket' : 'My Meals' }
            >
              <div
                className = 'basket-selector-container'
              >
                {
                  !algorithmBasket.hasNoRecipes && <Selector
                    categoriesList      = { Object.values(ShoppingCategory) }
                    supertextCategory   = { ShoppingCategory.ThingsYouMightAlsoNeed }
                    supertext           = { String(itemMapping.filter((itemMap: ItemMapping) => !itemMap.inBasket).length) }
                    selectedCategory    = { shoppingCategory }
                    setSelectedCategory = { setShoppingCategory }
                  />
                }

                {
                  showLoadingBar ? <SupermarketLoadingPill
                    supermarketName = { linkedSupermarket }
                    onFinished      = { handleFinishLoading }
                  />
                  :
                  <button
                    onClick   = { handleStartLoading }
                    className = {`
                      basket-selector-supermarket-checkout-button
                      ${linkedSupermarket}
                    `}
                  >
                    <P14
                      text   = 'Checkout on'
                      colour = 'white-1'
                    />

                    {
                      linkedSupermarket === SupportedSupermarket.Asda ?
                        <AsdaLogo /> :
                        <TescoLogo />
                    }
                  </button>
                }
              </div>
            </TitleText>
          </div>

          <span />

          {
            shoppingCategory === ShoppingCategory.AlreadyInYourBasket &&
            <>
              {
                itemMapping.length > 0 && <ItemCardGrid
                  itemMapping         = { itemMapping }
                  itemGridCard        = { ItemGridCard.Deletable }
                  onCardClickHandler  = { () => {} }
                  onTrashClick        = { handleRemoveItem }
                />
              }
              
              {
                algorithmBasket.numberOfShoppingItems > 0 && <ShoppingItemCardGrid
                  shoppingItems      = { shoppingItems as unknown as IItem[] }
                  onCardClickHandler = { () => {} }
                />
              }
            </>
          }

          {
            shoppingCategory === ShoppingCategory.ThingsYouMightAlsoNeed &&
            <ItemCardGrid
              items               = { itemMapping.filter((itemMap: ItemMapping) => !itemMap.inBasket).map((itemMap: ItemMapping) => itemMap.item) }
              itemGridCard        = { ItemGridCard.CupboardItem }
              onCardClickHandler  = { () => {} }
              onAddToBasketClick  = { handleAddCupboardItem }
            />
          }

          {
            shoppingCategory === ShoppingCategory.YourPlan && <PersonalPlan
              totalCost              = { algorithmBasket.totalValue }
              pricePerServing        = { algorithmBasket.pricePerServing }
              numberOfMeals          = { algorithmBasket.numberOfRecipes }
              numberOfPeople         = { user.household.numberOfPeople }
              numberOfIngredients    = { algorithmBasket.numberOfItems }
              algorithmRecipeMapping = { algorithmMealPlan.algorithmRecipeMapping }
            />
          }

          <button
            onClick   = { handleStartLoading }
            className = {`
              basket-selector-supermarket-checkout-button-large
              ${linkedSupermarket}
            `}
          >
            <P14
              text   = 'Checkout on'
              colour = 'white-1'
            />

            {
              linkedSupermarket === SupportedSupermarket.Asda ?
                <AsdaLogo /> :
                <TescoLogo />
            }
          </button>
        </div>
      </Navigation>

      <DeleteBasketItemModal
        isOpen             = { activeModal === BasketModalType.DeleteItem }
        close              = { closeModal }
        ingredientName     = { tempItem?.name }
        deleteItemHandler  = { handleAcceptDeleteItem }
      />
    </>
  )
}
