import User from 'models/User';
import useFilterTexts from '../Operations/Computed/useFilterTexts';

export default function useComputed(user: User) {
  const filterTexts = useFilterTexts(user);

  return {
    filterTexts
  }
}
