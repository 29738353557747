import { Route } from 'types/Route'
import { AllRoutes, RouteName } from 'router/Config'

export function isOnRoute(routeNames: RouteName | RouteName[]): boolean {
  if (!Array.isArray(routeNames)) {
    routeNames = [routeNames];
  }

  return routeNames.some(routeName => findRoute(routeName).path === window.location.pathname.replace(/\/$/, ''));
}

export function pathOf(routeName: RouteName): string {
  return findRoute(routeName).path
}

function findRoute(routeName: RouteName): Route {
  return AllRoutes.find(route => route.name === routeName) as Route
}
