import React, { useContext } from 'react'

import './Alert.scss'
import P14 from 'components/Text/Paragraphs/SemiBold/P14'
import AlertContext from 'context/AlertContext';
import { AlertType } from 'enums/AlertType';

export default function Alert() {
  const { alert }: { alert: AlertType } = useContext(AlertContext);

  return (
    alert !== null && <div
      className = 'alert-container'
    >
      <P14
        text   = { alert }
        colour = 'white-1'
      />
    </div>
  )
}
