import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie';

import animationDataSmall from 'assets/animations/mascot_small.json';
import animationDataMedium from 'assets/animations/mascot_medium.json';
import { ReactComponent as MealiaIcon } from 'assets/images/logos/mealia/small.svg'

import './MealiaMascot.scss'
import useGetBrowser from 'hooks/browser/useGetBrowser';
import { SupportedBrowser } from 'enums/SupportedBrowser';

type Props = {
  size?: 'small' | 'medium'
  shouldPause?: boolean
}

export default function MealiaMascot({
  size = 'small',
  shouldPause = false
}: Props) {
  const currentBrowser = useGetBrowser();
  const [pause, setPause] = useState(false)

  const defaultOptions = {
    loop:          true,
    autoplay:      true,
    animationData: size === 'small' ? animationDataSmall : animationDataMedium,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const checkForModal = () => {
      const modalRoot = document.getElementsByClassName('root-modal-children');
      if (modalRoot.length > 0) {
        setPause(true);
      } else {
        setPause(false);
      }
    }

    const observer = new MutationObserver(checkForModal);

    // Start observing the document with the configured parameters
    observer.observe(document.body, { childList: true, subtree: true });

    // Check initially in case the modal is already present when component mounts
    checkForModal();

    return () => {
      observer.disconnect();
    }
  }, [])

  return (
    <div
      className = 'mealia-mascot-container'
    >
      {/* Lottie Animations on Safari are not working properly - slows entire app down */}
      {
        currentBrowser === SupportedBrowser.Unknown ?
        <MealiaIcon
          className = 'mealia-mascot'
        />
        :
        <Lottie
          options  = { defaultOptions }
          isPaused = { shouldPause && pause }
          height   = { size === 'small' ? 36 : 52 }
          width    = { size === 'small' ? 48 : 72 }
        />
      }
    </div>
  )
}
