import { CoreCurrentUserEndpoints } from 'services/Core/User/CurrentUserService/Config';
import CoreApi from 'services/Core/CoreApi';

export default class CurrentUserApi extends CoreApi {
  constructor() {
    super(CoreCurrentUserEndpoints);
  }

  show() {
    const response = super.request(
      this.apiEndpoints.show()
    );

    this.storeAuthHeader(response);

    return this.respondWithJson(response);
  }
}
