import React from 'react'

import OnboardingAnimation from 'components/Animations/Onboarding/OnboardingAnimation';

type Props = {
  onAnimationComplete: () => void
  completed:           boolean
}

export default function Ending({
  onAnimationComplete,
  completed
}: Props) {
  return (
    <OnboardingAnimation
      onAnimationComplete = { onAnimationComplete }
      completed           = { completed }
    />
  )
}