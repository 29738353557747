import React from 'react'

import './ProgressTracker.scss'
import P12  from 'components/Text/Paragraphs/Regular/P12'
import P12M from 'components/Text/Paragraphs/Medium/P12'

type Props = {
  indexAt: number
}

export default function ProgressTracker({
  indexAt
}: Props) {
  const progressItems = [
    'People', 'Meals', 'Budget', 'Diet', 'Allergy', 'Equipment', 'Grocer'
  ]

  return (
    <div
      className = 'progress-tracker-container'
    >
      {
        progressItems.map((item, index) => {
          return (
            <Progress
              key              = { index }
              name             = { item }
              active           = { indexAt >= index }
              justBecomeActive = { indexAt === index }
            />
          )
        })
      }
    </div>
  )
}

const Progress = ({
  name,
  active,
  justBecomeActive,
}) => {
  return (
    <div
      className = {`
        progress-container
        ${ active ? 'active' : '' }
        ${ justBecomeActive ? 'just-become-active' : '' }
      `}
    >
      {
        active ?
        <P12M
          text   = { name }
          colour = { 'black-1' } 
        />
        :
        <P12
          text   = { name }
          colour = { 'grey-2' } 
        />
      }
    </div>
  )
}
