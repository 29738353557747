import React, { useState } from 'react';

import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';

export const AlgorithmBasketProvider = (props: any) => {
  const [algorithmBasket, setAlgorithmBasket] = useState<AlgorithmBasket | null>(null);

  return (
    <AlgorithmBasketContext.Provider 
      value = { { algorithmBasket, setAlgorithmBasket } }
    >
      { props.children }
    </AlgorithmBasketContext.Provider>
  );
};
