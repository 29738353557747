import React from 'react'

import './FormCard.scss'

interface Props {
  children: React.ReactNode,
}

export default function FormCard({
  children
}: Props) {

  return (
    <div
      className = 'form-card'
    >
      { children }
    </div>
  )
}