import React, { useContext, useEffect, useState } from 'react'

import AlgorithmBasketContext from 'context/AlgorithmBasketContext'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket'

export default function MyMealsDashboardCard() {
  const [forceUpdate, setForceUpdate] = useState(false)

  const { algorithmBasket }: { algorithmBasket: AlgorithmBasket } = useContext(AlgorithmBasketContext)

  const generateTopTextObject = (): DashboardTextType[] => {
    return [
      {
        line: 1,
        value: 'All set! You have',
        bold: false
      },
      {
        line: 1,
        value: `${algorithmBasket?.numberOfRecipes}`,
        bold: true
      },
      {
        line: 1,
        value: 'meals lined up, totalling',
        bold: false
      },
      {
        line: 1,
        value: `£${algorithmBasket?.totalValue.toFixed(2)}`,
        bold: true
      }
    ];
  }

  const generateBottomTextObjects = (): DashboardTextType[] => {
    return [
      {
        line: 2,
        value: 'or an average of ',
        bold: false
      },
      {
        line: 2,
        value: `£${algorithmBasket?.pricePerServing.toFixed(2)}`,
        bold: true
      },
      {
        line: 2,
        value: 'per serving.',
        bold: false
      }
    ];
  }

  useEffect(() => {
    setForceUpdate(forceUpdate => !forceUpdate)
  }, [algorithmBasket])

  return (
    <MealiaBotCard
      textObjects = { [ ...generateTopTextObject(), ...generateBottomTextObjects() ] }
      startTyping = { forceUpdate }
    />
  )
}
