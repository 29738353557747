import Cookies from 'universal-cookie';

import BaseApi from 'services/BaseApi';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';
import { CoreConfig } from './Config';
import { KeyConstants } from 'utils/Constants';

export default class CoreApi extends BaseApi {
  public constructor(apiEndpoints: BaseApiEndpoint) {
    super();
    this.apiBaseUrl = CoreConfig.CORE_API_BASE_URL;
    this.apiVersion = CoreConfig.CORE_API_VERSION;
    this.apiHeaders = CoreConfig.CORE_API_HEADERS;

    this.apiEndpoints = apiEndpoints;
  }

  public request(config) {
    return super.request(
      config.url,
      config.method,
      config.auth,
      config.body,
      config.params
    );
  }

  public async respondWithJson(response: any) {
    return await super.respondWithJson(response);
  }

  public storeAuthHeader(response: any) {
    response.then((res) => {
      const token = res.headers.get('Authorization');

      if (token) {
        localStorage.setItem(KeyConstants.LocalStorage.authToken, token);
        
        let expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + (30 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + expiryDate.toUTCString();

        document.cookie = `authToken=${token}; path=/; samesite=strict; ${expires}`;
      } else {
        console.error('No Auth Token Found');
      }
    }).catch((error) => {
      console.error('Error Storing Auth Token:', error);
    });
  }

  public removeAuthHeader(response: any) {
    response.then(() => {
      localStorage.removeItem(KeyConstants.LocalStorage.authToken);

      const cookies = new Cookies();

      cookies.remove('authToken', { path: '/' });
    }).catch((error) => {
      console.error('Error Removing Auth Token:', error);
    });
  }
}
