import { RouteName } from 'router/Config';

export default function useHandleNewMealsClick(
  navigateTo: (routeName: RouteName) => void
) {
  const handleNewMealsClick = () => {
    navigateTo(RouteName.GenerateNewMeals)
  }

  return handleNewMealsClick;
}
