import { useEffect } from 'react';

import { OnboardingPage } from 'enums/OnboardingPage';

export default function useForceUpdate(
  onboardingPage: OnboardingPage,
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    // setTimeout(() => {
      setForceUpdate(forceUpdate => !forceUpdate)
    // }, 1000)
  }, [onboardingPage])
}
