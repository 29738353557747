import React, { useEffect } from 'react'

import './DownloadExtension.scss'
import Navigation from '../Shared/Navigation'
import DownloadExtensionDashboardCard from 'components/Cards/Dashboard/DownloadExtensionDashboardCard'
import TitleText from 'components/Text/TitleText';
import BrowserExtensionCard from 'components/Cards/BrowserExtensionCard';
import useGetBrowser from 'hooks/browser/useGetBrowser';
import { SupportedBrowser } from 'enums/SupportedBrowser';
import UnsupportedBrowserCard from 'components/Cards/UnsupportedBrowserCard';
import useIsExtensionInstalled from 'hooks/browser/useIsExtensionInstalled';
import H1Text from 'components/Text/Headings/H1Text';
import useLinkedSupermarket from 'utils/Hooks/Helper/useLinkedSupermarket';
import useCapitalize from 'hooks/utils/useCapitalize';
import { ReactComponent as MealiaIcon } from 'assets/images/logos/mealia/small.svg'
import { ReactComponent as AsdaLogo } from 'assets/images/logos/asda/onboarding.svg'
import { ReactComponent as TescoLogo } from 'assets/images/logos/tesco/onboarding.svg'
import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import { RouteName } from 'router/Config';
import useCheckout from 'utils/Hooks/Actions/Algorithm/Basket/useCheckout';

export default function DownloadExtension() {
  const currentBrowser = useGetBrowser();

  const linkedSupermarket = useLinkedSupermarket();
  const supermarket = useCapitalize(linkedSupermarket);

  const { isExtensionJustInstalled } = useIsExtensionInstalled();

  const navigateTo = useNavigateTo();
  const checkout = useCheckout();

  const openSupermarket = () => {
    switch (linkedSupermarket) {
      case SupportedSupermarket.Asda:
        window.open('https://groceries.asda.com?redirectedFrom=Mealia', '_blank');
        break;
      case SupportedSupermarket.Tesco:
        window.open('https://www.tesco.com/groceries/en-GB?redirectedFrom=Mealia', '_blank');
        break;
      case SupportedSupermarket.Morrisons:
        window.open('https://groceries.morrisons.com/webshop/startWebshop.do?redirectedFrom=Mealia', '_blank');
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (isExtensionJustInstalled) {
      setTimeout(() => {
        openSupermarket()

        checkout();

        navigateTo(RouteName.CheckedOut);
      }, 3000)
    }
  }, [isExtensionJustInstalled])

  return (
    <Navigation>
      {
        isExtensionJustInstalled ? 
        <div
          className = 'download-extension-installed-container'
        >
          <H1Text
            text = { `Congrats, the extension is installed. Now redirecting you to ${supermarket}...` }
          />

          <div
            className = 'installed-container'
          >
            <MealiaIcon
              className = 'mealia-mascot-installed'
            />

            {
              linkedSupermarket === SupportedSupermarket.Asda ?
                <AsdaLogo
                  className = { `supermarket-logo-installed ${linkedSupermarket}` }
                /> :
                <TescoLogo
                  className = { `supermarket-logo-installed ${linkedSupermarket}` }
                />
            }
          </div>
        </div>
        :
        <div
          className = 'download-extension-container'
        >
          <DownloadExtensionDashboardCard />

          <span
            style = {{ height: '8px' }}
          />

          <TitleText
            text = { `Download Mealia's Browser Extension` }
          />

          {
            currentBrowser === SupportedBrowser.Unknown && <>
              <span />

              <UnsupportedBrowserCard />

              <span />
            </>
          }

          {
            currentBrowser !== SupportedBrowser.Unknown && <div
              className = 'browser-extension-container'
            >
              <BrowserExtensionCard />
            </div>
          }

          {
            currentBrowser === SupportedBrowser.Unknown && <div
              className = 'browser-extension-container'
            >
              <BrowserExtensionCard
                supportedBrowser = { SupportedBrowser.Chrome }
              />

              <BrowserExtensionCard
                supportedBrowser = { SupportedBrowser.Firefox }
              />

              <BrowserExtensionCard
                supportedBrowser = { SupportedBrowser.Edge }
              />
            </div>
          }
        </div>
      }
    </Navigation>
  )
}
