import React from 'react'

import './OnboardingPill.scss'
import P14 from 'components/Text/Paragraphs/Regular/P14'

type Props = {
  option:         number | string,
  image?:         React.ReactNode,
  optionSelected: boolean,
  applyPadding:   boolean,
  onClickHandler: (option: number | string) => void
}

export default function OnboardingPill({
  option,
  image = null,
  optionSelected,
  applyPadding = true,
  onClickHandler
}: Props) {
  return (
    <button
      className = {`
        onboarding-pill-container
        ${optionSelected ? 'onboarding-pill-container-selected' : ''}
        ${applyPadding ? 'onboarding-pill-container-padding' : ''}
      `}
      onClick = { () => onClickHandler(option) }
    >
      { image && image }

      <P14
        text   = { String(option) }
        colour = { optionSelected ? 'white-1' : 'black-1'}
      />
    </button>
  )
}
