import IItem from 'interfaces/backend/IItem';
import useShoppingItemDetailsApi from 'utils/Hooks/Api/Algorithm/Basket/useShoppingItemDetails';

export default function useShoppingItemDetails() {
  const shoppingItemDetailsApi = useShoppingItemDetailsApi();

  const shoppingItemDetails = (
    setShoppingItems: (items: IItem[]) => void
  ): Promise<any> => {
    return shoppingItemDetailsApi().then((response: any) => {
      const data: IItem[] = response;

      setShoppingItems(data);
    });
  }

  return shoppingItemDetails;
}