import { BotPage } from 'enums/BotPage';

export const PromptConstants = {
  [BotPage.MyMeals]: {
    initialMessage: "You’re on the meal planning page. Here’s where you select and personalise the recipes that will go in your basket! How can I help you?",
    prompts: [
      'I just want vegetarian recipes.',
      'How do I change my meals?'
    ]
  },
  [BotPage.Recipe]: {
    initialMessage: "You’re on the recipe page. Here’s where you can see the ingredients and instructions for your recipe. If you have any questions, just ask!",
    prompts: [
      'I want to add an item to this recipe.',
      'Can I swap an item?'
    ]
  },
  [BotPage.Basket]: {
    initialMessage: "This is the basket page. Here’s where you can see all the ingredients you need for your recipes. If you have any questions, just ask!",
    prompts: [
      'I want to remove an item.',
      'How do I checkout?'
    ]
  }
}
