import React from 'react'

import './ModalRecipeImage.scss'
import RootRecipeImage from '../_RootRecipeImage'
import RecipeImagePill from 'components/Pills/RecipeImagePill'
import RecipeInfoButton from 'components/Buttons/RecipeInfoButton'

type Props = {
  imageUrl: string
  text:     string
}

export default function ModalRecipeImage({
  imageUrl,
  text
}: Props) {
  return (
    <div
      className = 'modal-recipe-image-container'
    >
      <RootRecipeImage
        imageUrl        = { imageUrl }
        overlayChildren = {
          <div
            className = 'modal-recipe-image-overlay-top-right'
          >
            <RecipeImagePill
              text = { text }
            />

            {/* <RecipeInfoButton
              // onInfoClick = { onInfoClick }
              onInfoClick = { () => {} }
            /> */}
          </div>
        }
      />
    </div>
  )
}
