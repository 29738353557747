import React, { useEffect, useState } from 'react'

import './ItemSwapper.scss'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import AlgorithmItemApi from 'services/Core/Algorithm/ItemService/AlgorithmItemApi'
import RootGrid from 'components/Grids/_RootGrid'
import ReplacingAlgorithmItemCard from 'components/Cards/AlgorithmItemCard/ReplacingAlgorithmItemCard'

type Props = {
  algorithmItem:      IAlgorithmItem | null
  handleSubstitution: (chosenAlgorithmItem: IAlgorithmItem, alternativeAlgorithmItem: IAlgorithmItem) => void
}

export default function ItemSwapper({
  algorithmItem,
  handleSubstitution
}: Props) {
  const [algorithmAlternativeItems, setAlgorithmAlternativeItems] = useState<IAlgorithmItem[]>([])

  useEffect(() => {
    if (!algorithmItem) return
    setAlgorithmAlternativeItems([]) // Prevents flashing of old data

    const algorithmItemApi = new AlgorithmItemApi();

    algorithmItemApi.alternativeAlgorithmItems(algorithmItem?.id).then((response) => {
      setAlgorithmAlternativeItems(response.data)
    });
  }, [algorithmItem])

  return (
    <div
      className = 'item-swapper-container'
    >
      <RootGrid
        numberOfColumns = { 3 }
        gap             = { 20 }
      >
        {
          algorithmAlternativeItems.map((alternativeAlgorithmItem: IAlgorithmItem) => (
            <div
              key = { alternativeAlgorithmItem.item.id }
            >
              <ReplacingAlgorithmItemCard
                algorithmItem = { alternativeAlgorithmItem }
                onCardClick   = { () => handleSubstitution(algorithmItem, alternativeAlgorithmItem) }
                highlight     = { true }
              />
            </div>
          ))
        }
      </RootGrid>
    </div>
  )
}
