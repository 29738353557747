import useSmoothScrollTo from 'hooks/ui/useSmoothScrollTo';

export default function useHandleAddItemClick(
  setTopItemGridType: (topItemGridType: 'recipeItems' | 'swappingItems' | 'additionalItems') => void
) {
  const smoothScrollTo = useSmoothScrollTo();

  const handleAddItemClick = () => {
    const scrollDuration = 600;

    const didScroll = smoothScrollTo('top-subsection-header-title-text-container', scrollDuration);

    if (didScroll) {
      setTimeout(() => {
        postScroll()
      }, scrollDuration + 100);
    } else {
      postScroll()
    }
  }

  const postScroll = () => {
    setTopItemGridType('additionalItems');
  }

  return handleAddItemClick;
}
