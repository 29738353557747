import React from 'react'

import './PersonalPlanRecipeListCard.scss'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'
import PinkCircle from 'components/base/Shapes/PinkCircle'
import { RecipeData } from 'interfaces/backend/Algorithm/IAlgorithmSavedPlan'

type Props = {
  recipeData: RecipeData[]
}

export default function PersonalPlanRecipeListCard({
  recipeData
}: Props) {
  return (
    <div
      className = 'personal-plan-recipe-list-card-container'
    >
      {
        recipeData.map((data, index) => {
          return (
            <div
              key       = { index }
              className = 'personal-plan-recipe-list-card-recipe'
            >
              <div
                className = 'personal-plan-recipe-list-card-recipe-lhs'
              >
                <img
                  src       = { data.imageUrl }
                  alt       = { data.name }
                  className = 'personal-plan-recipe-list-card-recipe-image'
                />

                <P16
                  text   = { data.name }
                  colour = 'black-1'
                />
              </div>

              <PinkCircle
                number = { data.quantity }
              />
            </div>
          )
        })
      }
    </div>
  )
}
