import React from 'react'

import './RootMessage.scss'
import { Sender } from 'interfaces/backend/IMessage'

type Props = {
  children: React.ReactNode
  sender: Sender
}

export default function RootMessage({
  children,
  sender
}: Props) {
  return (
    <div
      className = 'chatbot-message-root-message-container'
      style = {{
        marginLeft:  sender === Sender.User ? 'auto' : undefined,
        marginRight: sender === Sender.Assistant  ? 'auto' : undefined
      }}
    >
      { children }
    </div>
  )
}
