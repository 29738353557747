import { RouteName } from 'router/Config';

export default function useHandleFinishLoading(
  navigateTo: (routeName: RouteName) => void
) {
  const handleFinishLoading = () => {
    navigateTo(RouteName.MyMeals);
  }

  return handleFinishLoading;
}
