import { useEffect, useState } from 'react';

import IItem from 'interfaces/backend/IItem';
import ItemApi from 'services/Core/ItemService/ItemApi';
import { SearchModalType } from 'enums/Modals/SearchModalType';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import { RouteName } from 'router/Config';

export default function useSearch() {
  const [searchQuery, setSearchQuery]     = useState('')
  const [activeModal, setActiveModal]     = useState<SearchModalType>(SearchModalType.None)
  const [shoppingItems, setShoppingItems] = useState<IItem[]>([])
  const [selectedShoppingItem, setSelectedShoppingItem] = useState<IItem | null>(null);

  const navigateTo = useNavigateTo();

  const ingredientModalHandler = (shoppingItem: IItem) => {
    setSelectedShoppingItem(shoppingItem);
    setActiveModal(SearchModalType.Item);
  }

  const handleSearchClick = () => {
    navigateTo(RouteName.Groceries)
  }

  useEffect(() => {
    const category = window.location.search.split('=')[1]
    const query = decodeURIComponent(category)

    setSearchQuery(query)

    const itemApi = new ItemApi();

    itemApi.searchForItem(query).then((response) => {
      setShoppingItems(response);
    })
  }, [])

  const closeModal = () => {
    setActiveModal(SearchModalType.None)
  }

  return [
    searchQuery,
    shoppingItems,
    selectedShoppingItem,
    activeModal,
    closeModal,
    ingredientModalHandler,
    handleSearchClick
  ] as const;
}
