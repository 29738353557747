import React from 'react'
import Lottie from 'react-lottie';

import animationData from 'assets/animations/ask_mealia_typing.json';

export default function TypingAnimation() {
  const defaultOptions = {
    loop:          true,
    autoplay:      true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      options = { defaultOptions }
      height  = { 18 }
      width   = { 18 }
    />
  )
}
