const increaseNumber = (number: number) => {
  return number + 0.1;
}

// Should match breakpoints in src/styles/_breakpoints.scss
export const Breakpoints = {
  small: {
    min: 0,
    max: 1065
  },
  desktop: {
    min: increaseNumber(1065),
    max: null
  },
  wideDesktop: {
    min: increaseNumber(null),
    max: 10000
  }
}
