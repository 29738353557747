import React from 'react'

import './P16.scss'

type Props = {
  text: string
  colour?: 'white-1' | 'black-1' | 'black-2'
}

export default function P16({
  text,
  colour
}: Props) {
  // Respect line breaks
  const lines = text.split('\n');
  
  return (
    <p 
      className = {`
        p16-regular
        ${ colour }  
      `}
    >
      {
        lines.map((line, index) => (
          <React.Fragment
            key = { index }
          >
            { line }

            { index < lines.length - 1 && <br /> }
          </React.Fragment>
        ))
      }
    </p>
  )
}