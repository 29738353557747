import AlgorithmRecipeApi from 'services/Core/Algorithm/RecipeService/AlgorithmRecipeApi';

export default function useIncrementAdditionalItem() {
  function incrementAdditionalItem(algorithmRecipeId: number, supermarketItemId: number): Promise<any> {
    const algorithmRecipeApi = new AlgorithmRecipeApi();

    return algorithmRecipeApi.incrementAdditionalItem(algorithmRecipeId, supermarketItemId);
  }

  return incrementAdditionalItem;
}
