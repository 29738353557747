import React from 'react'

import './KitchenEquipmentPreference.scss'

import { ColourConstants, HouseholdConstants } from 'utils/Constants'
import OnboardingPills from 'components/Inputs/OnboardingPills'

import { ReactComponent as StoveIcon } from 'assets/images/onboarding/stove.svg'
import { ReactComponent as OvenIcon } from 'assets/images/onboarding/oven.svg'
import { ReactComponent as BlenderIcon } from 'assets/images/onboarding/blender.svg'
import { ReactComponent as MicrowaveIcon } from 'assets/images/onboarding/microwave.svg'
import { ReactComponent as KettleIcon } from 'assets/images/onboarding/kettle.svg'
import { ReactComponent as SlowCookerIcon } from 'assets/images/onboarding/slow-cooker.svg'
import { ReactComponent as AirFryerIcon } from 'assets/images/onboarding/air-fryer.svg'

type Props = {
  selectedOptions:   string[]
  handleOptionClick: (option: string) => void
}

export default function KitchenEquipmentPreference({
  selectedOptions,
  handleOptionClick
}: Props) {
  // TODO: Refactor this to use a map and the HouseholdConstants.KitchenEquipment array
  const images = [
    <StoveIcon
      color = { imageColour('Cooking Hub or Stove') }
    />,
    <OvenIcon
      color = { imageColour('Oven') }
    />,
    <BlenderIcon
      color = { imageColour('Blender') }
    />,
    <MicrowaveIcon
      color = { imageColour('Microwave') }
    />,
    <KettleIcon
      color = { imageColour('Kettle') }
    />,
    <SlowCookerIcon
      color = { imageColour('Slow Cooker') }
    />,
    <AirFryerIcon
      color = { imageColour('Air Fryer') }
    />
  ]

  function imageColour(option: string) {
    return selectedOptions.includes(option) ? ColourConstants.White[1] : ColourConstants.Black[1]
  }

  return (
    <div
      className = 'kitchen-equipment-preference-container'
    >
      <OnboardingPills
        answerOptions     = { HouseholdConstants.KitchenEquipment }
        images            = { images }
        selectedOptions   = { selectedOptions }
        applyPadding      = { true }
        handleOptionClick = { handleOptionClick }
      />
    </div>
  )
}
