import useHooks   from './Core/useHooks';
import useStates  from './Core/useStates';
import useActions from './Core/useActions';
import useEffects from './Core/useEffects';

export default function useGroceries() {
  const { 
    navigateTo,
    linkedSupermarket,
    basketCheckedOut
  } = useHooks();

  const {
    suggestions,
    setSuggestions,
    searchValue,
    setSearchValue
  } = useStates();

  const {
    handleDropDownSuggestionClick,
    search
  } = useActions(searchValue, setSearchValue, navigateTo);

  useEffects(searchValue, setSuggestions, basketCheckedOut, navigateTo);

  return {
    linkedSupermarket,
    suggestions,
    searchValue,
    setSearchValue,
    search,
    handleDropDownSuggestionClick
  } 
}
