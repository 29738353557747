import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreAlgorithmSavedPlanEndpoints: BaseApiEndpoint = {
  getAllSavedPlans: () => ({
    url:    'algorithms/saved_plan/index',
    method: 'GET',
    auth:   true,
  }),
  show: (algorithmSavedPlanId: number) => ({
    url:    'algorithms/saved_plan/show',
    method: 'GET',
    auth:   true,
    params: {
      id: algorithmSavedPlanId
    }
  }),
}
