import React from 'react'

import './Search.scss'
import Navigation from '../Shared/Navigation'
import TitleText from 'components/Text/TitleText'
import ShoppingItemModal from 'components/Modals/ShoppingItemModal'
import ItemCardGrid from 'components/Grids/ItemCardGrid';
import { ItemGridCard } from 'enums/Grids/ItemGridCard';
import { SearchModalType } from 'enums/Modals/SearchModalType'

import useSearch from 'utils/Hooks/Pages/PostLogin/useSearch'
import SearchDashboardCard from 'components/Cards/Dashboard/SearchDashboardCard'
import SecondaryButton from 'components/Buttons/SecondaryButton'

export default function Search() {
  const [
    searchQuery,
    shoppingItems,
    selectedShoppingItem,
    activeModal,
    closeModal,
    ingredientModalHandler,
    handleSearchClick
  ] = useSearch();

  return (
    <>
      <Navigation>
        <div
          className = 'search-container'
        >
          <SearchDashboardCard
            searchQuery = { searchQuery }
          />

          <span
            style = {{ height: '8px' }}
          />

          <TitleText
            text = { 'Searching: ' + searchQuery }
          >
            <SecondaryButton
              text    = 'Search'
              icon    = 'search'
              onClick = { handleSearchClick }
            />
          </TitleText>

          <span />

          {
            shoppingItems.length > 0 && <ItemCardGrid
              items              = { shoppingItems }
              itemGridCard       = { ItemGridCard.Adjustable }
              onCardClickHandler = { ingredientModalHandler }
            />
          }
        </div>
      </Navigation>

      <ShoppingItemModal
        isOpen       = { activeModal === SearchModalType.Item }
        close        = { closeModal }
        shoppingItem = { selectedShoppingItem }
      />
    </>
  )
}
