import AlgorithmItemApi from 'services/Core/Algorithm/ItemService/AlgorithmItemApi';

export default function useReplaceAlgorithmItem() {
  function replaceAlgorithmItem(algorithmChosenItemId: number): Promise<any> {
    const algorithmItemApi = new AlgorithmItemApi();

    return algorithmItemApi.replaceAlgorithmItem(algorithmChosenItemId);
  }

  return replaceAlgorithmItem;
}
