import React, { useState } from 'react';

import AlgorithmMealPlanContext from 'context/AlgorithmMealPlanContext';
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';

export const AlgorithmMealPlanProvider = (props: any) => {
  const [algorithmMealPlan, setAlgorithmMealPlan] = useState<AlgorithmMealPlan | null>(null);

  return (
    <AlgorithmMealPlanContext.Provider 
      value = { { algorithmMealPlan, setAlgorithmMealPlan } }
    >
      { props.children }
    </AlgorithmMealPlanContext.Provider>
  );
};
