import React from 'react'

import './Root.scss'
import OnboardingPill from './OnboardingPill'

type Props = {
  answerOptions:     (number | string)[]
  images?:           React.ReactNode[]
  selectedOptions:   (number | string)[],
  applyPadding?:     boolean
  handleOptionClick: (option: number | string) => void
}

export default function Root({
  answerOptions,
  images = [],
  selectedOptions,
  applyPadding = false,
  handleOptionClick
}: Props) {
  const optionSelected = (option: number | string) => selectedOptions.includes(option)

  return (
    <ul
      className = 'onboarding-pills-container'
    >
      { 
        answerOptions.map((option, index) => (
          <li
            key = { index }
          >
            <OnboardingPill
              option         = { option }
              image          = { images[index] }
              optionSelected = { optionSelected(option) }
              applyPadding   = { applyPadding }
              onClickHandler = { handleOptionClick }
            />
          </li>
        ))
      }
    </ul>
  )
}
