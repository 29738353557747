import React, { useState } from 'react'

import './Textfield.scss'
import { ReactComponent as PasswordIcon } from 'assets/images/icons/textfield/password-icon.svg'
import { ReactComponent as EmailIcon }  from 'assets/images/icons/textfield/email-icon.svg'
import { ReactComponent as EyeIcon } from 'assets/images/icons/textfield/eye-icon.svg'
import { ColourConstants } from 'utils/Constants'

export enum Icons {
  EmailIcon,
  PasswordIcon
}

type Props = {
  type?:       'text' | 'password'
  placeholder: string
  size?:       'small' | 'medium' | 'large' | 'fill'
  icon?:       Icons
  value:       string
  setValue:    (value: string) => void
}

export default function Textfield({
  type = 'text',
  placeholder,
  size = 'medium',
  icon,
  value,
  setValue
}: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused]       = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handlePasswordVisibility = () => {
    if (type === 'password') {
      setShowPassword(!showPassword);
    }
  }

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  const getIcon = () => {
    const color = isFocused ? ColourConstants.Black[1] : '#93989A'

    switch (icon) {
    case Icons.EmailIcon:
      return <EmailIcon
        color = { color }
      />
    case Icons.PasswordIcon:
      return <PasswordIcon
        color = { color }
      />
    default:
      return null
    }
  }

  return (
    <div
      className = {`
        textfield-container
        ${ size === 'small' ? 'textfield-container-small' : '' }
        ${ size === 'large' ? 'textfield-container-large' : '' }
        ${ size === 'fill' ? 'textfield-container-fill' : '' }
        ${ isFocused ? 'textfield-container-focused' : '' }
      `}
    >
      <div
        className = 'textfield-input-container'
      >
        { icon !== undefined && 
          <div
            className = 'textfield-icon'
          >
            { getIcon() }
          </div>
        }

        <input
          type        = { showPassword ? 'text' : type }
          className   = 'textfield-input'
          placeholder = { placeholder }
          value       = { value }
          onChange    = { handleChange }
          onFocus     = { handleFocus }
          onBlur      = { handleBlur }
        />

        { type === 'password' && (
          <button
            onClick   = { handlePasswordVisibility }
            className = 'textfield-password-icon'
          >
            <EyeIcon 
              color = '#93989A'
            />
          </button>
        )}
      </div>
    </div>
  )
}
