import React from 'react'

import './ItemCardGrid.scss'
import RootGrid from '../_RootGrid/RootGrid'
import IItem from 'interfaces/backend/IItem'
import { ItemGridCard } from 'enums/Grids/ItemGridCard'

import AdjustableItemCard from 'components/Cards/ItemCard/AdjustableItemCard'
import CupboardItemCard   from 'components/Cards/ItemCard/CupboardItemCard'
import DeletableItemCard  from 'components/Cards/ItemCard/DeletableItemCard'
import { ItemMapping }    from 'types/Mappings/ItemMapping'

type Props = {
  items?:               IItem[]
  itemMapping?:         ItemMapping[]
  itemGridCard:         ItemGridCard
  onCardClickHandler:   (item: IItem) => void
  onAddToBasketClick?:  (item: IItem, e: React.MouseEvent<HTMLDivElement>) => void
  onTrashClick?:        (itemName: string, itemId: number, e: React.MouseEvent<HTMLDivElement>) => void
  numberOfColumns?:     number
}

export default function ItemCardGrid({
  items,
  itemMapping,
  itemGridCard,
  onCardClickHandler,
  onAddToBasketClick,
  onTrashClick,
  numberOfColumns = 3
}: Props) {
  return (
    <RootGrid
      numberOfColumns = { numberOfColumns }
      gap             = { 20 }
      containerClass  = 'item-card-grid'
    >
      {
        items && items.map((item: IItem) => (
          <li
            key = { item.id }
          >
            {
              itemGridCard === ItemGridCard.Adjustable && 
                <AdjustableItemCard
                  item        = { item }
                  onCardClick = { onCardClickHandler }
                />
            }

            {
              itemGridCard === ItemGridCard.CupboardItem &&
                <CupboardItemCard
                  item                = { item }
                  onCardClick         = { onCardClickHandler }
                  onAddToBasketClick  = { onAddToBasketClick }
                />
            }
          </li>
        ))
      }

      {
        itemMapping && 
        itemMapping
          .filter((itemMap: ItemMapping) => itemMap.inBasket)
          .map((itemMap: ItemMapping) => (
          <li
            key = { itemMap.item.id }
          >
            {
              itemGridCard === ItemGridCard.Deletable &&
                <DeletableItemCard
                  item             = { itemMap.item }
                  quantityRequired = { itemMap.quantityRequired }
                  onCardClick      = { onCardClickHandler }
                  onTrashClick     = { onTrashClick }
                />
            }
          </li>
        ))
      }
    </RootGrid>
  )
}
