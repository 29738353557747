import React from 'react'

import './RecipeDetailsCard.scss'
import RecipeInfoCard from 'components/Cards/RecipeInfoCard'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import H2Text from 'components/Text/Headings/H2Text'
import RoundedRectangle from 'components/base/Shapes/RoundedRectangle'
import P12 from 'components/Text/Paragraphs/Medium/P12'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'

type Props = {
  algorithmRecipe: AlgorithmRecipe
  onClick:         () => void
}

export default function RecipeDetailsCard({
  algorithmRecipe,
  onClick
}: Props) {
  return (
    <RecipeInfoCard>
      <div
        className = 'recipe-details-card-top'
      >
        <H2Text
          text = { algorithmRecipe.recipe.name }
        />

        <P14
          text = { algorithmRecipe.recipe.description }
        />
      </div>

      <div
        className = 'recipe-details-card-bottom'
      >
        <P14
          text = 'Nutritional Score'
        />

        <div
          className = 'recipe-nutrition-card-content'
        >
          <div
            className = 'recipe-nutrition-card-content-left'
          >
            <P12 
              text   = '0'
              colour = 'black-1'
            />

            <RoundedRectangle 
              width = { algorithmRecipe.nutritionalScore }
            />

            <P12 
              text   = '10'
              colour = 'black-1'
            />
          </div>

          <button
            className = 'recipe-nutrition-card-button'
            onClick   = { onClick }
          >
            <P12
              text   = 'Nutritional Information'
              colour = 'black-1'
            />
          </button>
        </div>
      </div>
    </RecipeInfoCard>
  )
}