import { useContext } from 'react';

import AlgorithmBasketContext from 'context/AlgorithmBasketContext';
import useCheckoutApi from 'utils/Hooks/Api/Algorithm/Basket/useCheckout';
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';

export default function useCheckout() {
  const checkoutApi = useCheckoutApi();

  const { setAlgorithmBasket }: { setAlgorithmBasket: (algorithmBasket: AlgorithmBasket) => void } = useContext(AlgorithmBasketContext);

  function checkout() {
    checkoutApi().then((response) => {
      const algorithmBasket = new AlgorithmBasket(response.data);
      
      setAlgorithmBasket(algorithmBasket);
    })
  };

  return checkout;
}
