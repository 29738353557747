import React from 'react'

type Props = {
  src:           string
  alt:           string
  className:     string
  fallbackImage: string
}

export default function FallbackImage({
  src,
  alt,
  className,
  fallbackImage
}: Props) {
  return (
    <img
      className = { className }
      alt       = { alt }
      src       = { src }
      onError   = { (event: any) => {
        const fallbackSrc = fallbackImage

        if (event.target.src !== fallbackSrc) {
          event.target.src     = fallbackSrc;
          event.target.onError = null;
        }
      }}
    />
  )
}
