import React from 'react'

import { OnboardingQuestionProps } from '../Shared/OnboardingQuestionProps'
import NumberOfPeoplePreference from 'components/features/UserPreferences/NumberOfPeoplePreference'

export default function NumberOfPeople({
  userPreferences,
  setUserPreferences
}: OnboardingQuestionProps) {
  const handleNumberOfAdultsChange = (value: number) => {
    setUserPreferences({
      ...userPreferences,
      numberOfAdults: value,
    })
  }

  const handleNumberOfChildrenChange = (value: number) => {
    setUserPreferences({
      ...userPreferences,
      numberOfChildren: value,
    })
  }

  return (
    <NumberOfPeoplePreference
      adultSelectedOptions   = { [ userPreferences.numberOfAdults ] }
      handleAdultOptionClick = { handleNumberOfAdultsChange }
      childSelectedOptions   = { [ userPreferences.numberOfChildren ] }
      handleChildOptionClick = { handleNumberOfChildrenChange }
    />
  )
}