import { DashboardTextType } from 'types/DashboardTextType'

export default function useDietaryPreferencesMessages() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Thank you, do you have any dietary preferences?`,
    bold:  false
  }

  const dietaryPreferencesMessages = [
    topLineTextObject
  ]

  return dietaryPreferencesMessages
}
