import React from 'react'

import './RoundedTopNavBarButton.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'

type Props = {
  onClickHandler: () => void
  isOnRoute:      boolean
  type:           'myMeals' | 'myOrders' | 'myProfile'
}

export default function RoundedTopNavBarButton({
  onClickHandler,
  isOnRoute,
  type
}: Props) {
  const buttonText = () => {
    switch (type) {
      case 'myMeals':
        return 'My Meals'
      case 'myOrders':
        return 'My Orders'
      case 'myProfile':
        return 'My Profile'
    }
  }

  return (
    <button
      onClick      = { onClickHandler }
      className    = {`
        rounded-top-nav-bar-button-container
        ${isOnRoute ? 'highlight-background' : ''}
      `}
    >
      <P14
        text   = { buttonText() }
        colour = { isOnRoute ? 'white-1' : 'black-1' }
      />
    </button>
  )
}
