import useBasketCheckedOut from 'utils/Hooks/Helper/useBasketCheckedOut';
import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useLinkedSupermarket from 'utils/Hooks/Helper/useLinkedSupermarket';

export default function useHooks() {
  const navigateTo = useNavigateTo();
  const linkedSupermarket = useLinkedSupermarket();
  const basketCheckedOut = useBasketCheckedOut();

  return {
    navigateTo,
    linkedSupermarket,
    basketCheckedOut
  };
}
