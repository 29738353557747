import { CoreUserPasswordEndpoints } from 'services/Core/User/PasswordService/Config';
import CoreApi from 'services/Core/CoreApi';

export default class UserPasswordApi extends CoreApi {
  constructor() {
    super(CoreUserPasswordEndpoints);
  }

  create(email: string) {
    const response = super.request(
      this.apiEndpoints.create(email)
    );

    return this.respondWithJson(response);
  }

  update(password: string, passwordConfirmation: string, resetPasswordToken: string) {
    const response = super.request(
      this.apiEndpoints.update(password, passwordConfirmation, resetPasswordToken)
    );

    return this.respondWithJson(response);
  }
}
