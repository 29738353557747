import { GenerateNewMealsPage } from 'enums/GenerateNewMealsPage';
import IUserPreferences from 'interfaces/frontend/IUserPreferences';
import { RouteName } from 'router/Config';

import useHandleNextButtonClick from '../Operations/Actions/useHandleNextButtonClick';
import useHandlePreviousButtonClick from '../Operations/Actions/useHandlePreviousButtonClick';
import useHandleCancelClick from '../Operations/Actions/useHandleCancelClick';
import { SupportedSupermarket } from 'enums/SupportedSupermarket';

export default function useActions(
  page:               GenerateNewMealsPage,
  setPage:            React.Dispatch<React.SetStateAction<GenerateNewMealsPage>>,
  userPreferences:    IUserPreferences,
  setUserPreferences: React.Dispatch<React.SetStateAction<IUserPreferences>>,
  navigateTo:         (routeName: RouteName) => void,
  createNewMealPlan:  ( 
    numberOfAdults:        number,
    numberOfChildren:      number,
    numberOfMealsPerWeek:  number,
    weeklyBudget:          number,
    allergies:             string[],
    dietaryPreferences:    string[],
    kitchenEquipment:      string[],
    chosenSupermarket:     SupportedSupermarket
  ) => void
) {
  const handlePreviousButtonClick = useHandlePreviousButtonClick(page, setPage, userPreferences, setUserPreferences, navigateTo);
  const handleNextButtonClick     = useHandleNextButtonClick(page, setPage, userPreferences, setUserPreferences, createNewMealPlan);
  const handleCancelClick         = useHandleCancelClick(navigateTo);

  return {
    handlePreviousButtonClick,
    handleNextButtonClick,
    handleCancelClick
  }
}
