import useShow from 'utils/Hooks/Actions/Algorithm/Basket/useShow';
import useShowMealPlan from 'utils/Hooks/Actions/Algorithm/MealPlan/useShow';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import useOtherAlgorithmRecipes from 'utils/Hooks/Actions/Algorithm/MealPlan/useOtherAlgorithmRecipes';
import useSmoothScrollTo from 'hooks/ui/useSmoothScrollTo';

export default function useHandleAddRecipe(
  addAlgorithmRecipe: (algorithmRecipeId: number, e?: React.MouseEvent<HTMLDivElement, MouseEvent>, onSuccess?: () => void) => void,
  setOtherRecipes:    (otherRecipes: AlgorithmRecipe[]) => void
) {
  const show = useShow();
  const showMealPlan = useShowMealPlan();
  const otherAlgorithmRecipes = useOtherAlgorithmRecipes();

  const smoothScrollTo = useSmoothScrollTo();

  const handleAddRecipe = (algorithmRecipe: AlgorithmRecipe, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const scrollDuration = 600;

    e.stopPropagation();

    smoothScrollTo('last-algorithm-recipe', scrollDuration);

    setTimeout(() => {
      addAlgorithmRecipe(algorithmRecipe.id, e as any, () => {
        show();
        showMealPlan();
        otherAlgorithmRecipes(setOtherRecipes);
      });
    }, scrollDuration + 100);
  }

  return handleAddRecipe;
}
