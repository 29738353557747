import useNavigateTo from 'hooks/navigation/useNavigateTo';
import useShow from 'utils/Hooks/Actions/Algorithm/Basket/useShow';
import useRemoveItem from 'utils/Hooks/Actions/Algorithm/Basket/useRemoveItem';
import useLinkedSupermarket from 'utils/Hooks/Helper/useLinkedSupermarket';
import useBasketCheckedOut from 'utils/Hooks/Helper/useBasketCheckedOut';
import useShoppingItemDetails from 'utils/Hooks/Actions/Algorithm/Basket/useShoppingItemDetails';
import useDetailBasketItems from 'utils/Hooks/Actions/Algorithm/Basket/useDetailBasketItems'
import useAddCupboardItem from 'utils/Hooks/Actions/Algorithm/Basket/useAddCupboardItem';
import useIsExtensionInstalled from 'hooks/browser/useIsExtensionInstalled';
import useCheckout from 'utils/Hooks/Actions/Algorithm/Basket/useCheckout';

export default function useHooks() {
  const navigateTo = useNavigateTo();
  const show = useShow();
  const removeItem = useRemoveItem();
  const linkedSupermarket = useLinkedSupermarket();
  const basketCheckedOut = useBasketCheckedOut();
  const shoppingItemDetails = useShoppingItemDetails();
  const itemDetails = useDetailBasketItems();
  const addCupboardItem = useAddCupboardItem();
  const checkout = useCheckout();
  const { isExtensionInstalledPreviously } = useIsExtensionInstalled();

  return {
    navigateTo,
    show,
    removeItem,
    linkedSupermarket,
    basketCheckedOut,
    shoppingItemDetails,
    itemDetails,
    addCupboardItem,
    checkout,
    isExtensionInstalledPreviously
  }
}
