import React from 'react'

import './Default.scss'
import Root from '../Root'
import IRecipeStep, { IngredientsUsedInStep } from 'interfaces/backend/IRecipeStep'
import P20 from 'components/Text/Paragraphs/Medium/P20'
import FallbackIngredientImage from 'components/Images/Fallback/FallbackIngredientImage'

type Props = {
  step:     IRecipeStep
  onClick: (ingredient: IngredientsUsedInStep, e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function Default({
  step,
  onClick
}: Props) {
  return (
    <Root
      text        = { `Step ${step.order} - ${step.title}` }
      rightChild  = {
        <div
          className = 'default-step-image-container'
        >
          {
            step.ingredientsUsedInStep.map((ingredient, index) => (
              <div
                key       = { index }
                className = 'default-step-image-wrapper'
              >
                <button
                  onClick = { (e) => onClick(ingredient, e) }
                >
                  <FallbackIngredientImage
                    src       = { ingredient.imageUrl }
                    alt       = { ingredient.name }
                    className = 'default-step-image'
                  />
                </button>
              </div>
            ))
          }
        </div>
      }
      bottomChild = {
        <div>
          <div
            className = 'default-step-description-space'
          />

          <P20
            text   = { step.description }
            colour = 'black-1'
          />
        </div>
      }
    />
  )
}
