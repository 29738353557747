import React from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'
import useLinkedSupermarket from 'utils/Hooks/Helper/useLinkedSupermarket'
import useCapitalize from 'hooks/utils/useCapitalize';

export default function DownloadExtensionDashboardCard() {
  const linkedSupermarket = useLinkedSupermarket();
  const supermarket = useCapitalize(linkedSupermarket);

  const generateTopTextObjects: DashboardTextType = {
    line: 1,
    value: 'First time checking out with Mealia?',
    bold: false
  }

  const generateBottomTextObject = (): DashboardTextType[] => {
    return [
      {
        line: 2,
        value: 'You’ll need our browser extension to transfer your basket to',
        bold: false
      },
      {
        line: 2,
        value: `${supermarket}.`,
        bold: true
      }
    ];
  }

  return (
    <MealiaBotCard
      textObjects = { [ generateTopTextObjects, ...generateBottomTextObject() ] }
    />
  )
}
