import React from 'react'

import './PrimaryButton.scss'
import P14 from 'components/Text/Paragraphs/SemiBold/P14'
import { ReactComponent as ArrowIcon } from 'assets/images/icons/_new/arrow.svg'

type Props = {
  text:           string
  icon?:          'arrow'
  height?:        'medium' | 'large' | 'x-large'
  size?:          'fill' | 'padding'
  disabled?:      boolean
  onClickHandler: (...props: any) => void
}

export default function PrimaryButton({
  text,
  icon,
  height = 'medium',
  size = 'fill',
  disabled = false,
  onClickHandler
}: Props) {
  return (
    <button
      onClick   = { onClickHandler }
      className = {`
        primary-button
        ${ size === 'fill' ? 'primary-button-fill' : '' }
        ${ height === 'large' ? 'primary-button-large' : '' }
        ${ height === 'x-large' ? 'primary-button-x-large' : '' }
        ${ disabled ? 'primary-button-disabled' : '' }
        ${ icon !== undefined ? 'primary-button-icon' : '' }
      `}
    >
      <P14
        text = { text }
      />

      {
        icon === 'arrow' ? <ArrowIcon /> : null
      }
    </button>
  )
}
