export enum OnboardingPage {
  Intro,
  NumberOfPeople,
  NumberOfMeals,
  Budget,
  DietaryPreferences,
  Allergies,
  KitchenEquipment,
  Grocer,
  Ending
}
