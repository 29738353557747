import useUpdateApi from 'utils/Hooks/Api/User/Password/useUpdate';

export default function useUpate() {
  const updateApi = useUpdateApi();

  function update(
    password:             string,
    passwordConfirmation: string,
    resetPasswordToken:   string,
    setPage:              (page: 'Input' | 'Confirmation') => void,
    setError:             (error: any) => void
  ) {
    updateApi(password, passwordConfirmation, resetPasswordToken).then(() => {
      setPage('Confirmation');
    }).catch((error) => {
      setError(error);
    });
  }

  return update;
}
