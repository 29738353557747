import { useContext, useEffect, useState } from 'react'

import UserContext from 'context/UserContext';
import useSignOut from 'utils/Hooks/Actions/User/Session/useSignOut';
import useUpdate from 'utils/Hooks/Actions/User/Registration/useUpdate';
import User from 'models/User';

export default function useSettings() {
  const { user }: { user: User } = useContext(UserContext);

  const [firstName, setFirstName] = useState(user.firstName)
  const [email, setEmail]         = useState(user.email)

  const update   = useUpdate();
  const signOut  = useSignOut();

  useEffect(() => {
    // Check if the user object has changed, apart from the first time
    if (user.firstName === firstName) return
      
    update(firstName)    
  }, [firstName])

  return [
    firstName,
    setFirstName,
    email,
    setEmail,
    signOut
  ] as const;
}
