export default function useOrdinalize() {
  const ordinalStrings = [
    'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 
    'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelveth', 'Thirteenth', 'Fourteenth'
  ]

  function ordinalize(number: number) {
    return ordinalStrings[number - 1];
  }

  return ordinalize;
}
