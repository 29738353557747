import { useEffect } from 'react';

import IItem from 'interfaces/backend/IItem';
import { ItemMapping } from 'types/Mappings/ItemMapping';

export default function useLoadItems(
  setShoppingItems:    (items: IItem[]) => void,
  shoppingItemDetails: (setShoppingItems: (items: IItem[]) => void) => Promise<any>,
  setItemMapping:      (itemMapping: ItemMapping[]) => void,
  itemDetails:         (setItemMapping: (itemMapping: ItemMapping[]) => void) => Promise<any>
) {
  useEffect(() => {
    shoppingItemDetails(setShoppingItems);
    itemDetails(setItemMapping);
  }, []);
}
