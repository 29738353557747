import React from 'react'

import './HalfIncrementorButton.scss'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'
import { ReactComponent as TrashIcon } from 'assets/images/icons/_new/trash/white.svg'
import { ColourConstants } from 'utils/Constants'

type Props = {
  quantityRequired: number
  onTrashClick:     (e: React.MouseEvent<HTMLDivElement>) => void
}

export default function HalfIncrementorButton({
  quantityRequired,
  onTrashClick
}: Props) {
  return (
    <div
      className = 'half-incrementor-button-container'
    >
      <div
        onClick = { (e) => onTrashClick(e) }
        className = 'trash-icon-container'
      >
        <TrashIcon
          color     = { ColourConstants.White[1] }
          className = 'trash-icon'
        />
      </div>
      
      <div
        className = 'quantity-required-container'
      >
        <P16
          text   = { String(quantityRequired) }
          colour = 'white-1'
        />
      </div>
    </div>
  )
}
