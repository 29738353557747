import { useContext } from 'react';

import AlertContext from 'context/AlertContext';
import useReplaceAlgorithmItemApi from 'utils/Hooks/Api/Algorithm/Item/useReplaceAlgorithmItem';
import { AlertType } from 'enums/AlertType';
import useShow from '../Basket/useShow';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useReplaceAlgorithmItem() {
  const replaceAlgorithmItemApi = useReplaceAlgorithmItemApi();
  const { setAlert } = useContext(AlertContext);

  const show = useShow();

  function replaceAlgorithmItem(
    algorithmChosenItemId: number,
    setAlgorithmRecipe:    (algorithmRecipe: AlgorithmRecipe) => void,
    closeModal:            () => void
  ) {
    replaceAlgorithmItemApi(algorithmChosenItemId).then((response) => {
      const algorithmRecipe: AlgorithmRecipe = response.data;

      setAlgorithmRecipe(algorithmRecipe);

      closeModal();

      setAlert(AlertType.IngredientChanged);
    }).then(() => {
      // TODO: This only needs to be done for recipes in the meal plan
      show();
    });
  };

  return replaceAlgorithmItem;
}
