import Household from './Household';

import IUser                 from 'interfaces/backend/IUser';
import IPartnerAuthorisation from 'interfaces/backend/IPartnerAuthorisation';
import { SupportedSupermarket } from 'enums/SupportedSupermarket';

export default class User implements IUser {
  id:                    number;
  email:                 string;
  firstName:             string;
  household?:            Household;
  partnerAuthorisations: IPartnerAuthorisation[];

  constructor(data: IUser) {
    Object.assign(this, data);

    if (data.household) {
      this.household = new Household(data.household);
    }
  }

  static fromApiResponse(data: User): User {
    const instance = new User(data);

    return instance;
  }

  get chosenSupermarket(): SupportedSupermarket | null {
    return this.household?.chosenSupermarket;
  }
}
