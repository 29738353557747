import React from 'react'

import './UnsupportedBrowserCard.scss'
import P18 from 'components/Text/Paragraphs/Medium/P18'
import { ReactComponent as AlertIcon } from 'assets/images/icon/alert/root.svg'

export default function UnsupportedBrowserCard() {
  return (
    <div
      className = 'unsupported-browser-card-container'
    >
      <AlertIcon
        className = 'unsupported-browser-card-icon'
      />

      <P18
        text   = { `At the moment, our web extension for checkout is not available on this browser. To complete your shopping, you'll need to switch to a different browser where you can download the necessary extension.` }
        colour = 'white-1'
      />
    </div>
  )
}
