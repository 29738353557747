import React, { useContext } from 'react'

import './RecipeStatsCard.scss'
import { ReactComponent as PricePerServingIcon } from 'assets/images/icons/recipe-page/price-per-serving-icon.svg'
import { ReactComponent as TimeIcon } from 'assets/images/icons/recipe-page/time-icon.svg'
import { ReactComponent as TotalPeopleIcon } from 'assets/images/icons/recipe-page/total-people-icon.svg'
import { ReactComponent as CookingIcon } from 'assets/images/icons/recipe-page/cooking-icon.svg'
import { ReactComponent as FlameIcon } from 'assets/images/icons/recipe-page/flame-icon.svg'
import { ReactComponent as EnergyIcon } from 'assets/images/icons/recipe-page/energy-icon.svg'
import { ReactComponent as TickIcon } from 'assets/images/icon/tick/black.svg'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import RecipeInfoCard from 'components/Cards/RecipeInfoCard'
import UserContext from 'context/UserContext'
import User from 'models/User'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'

type Props = {
  algorithmRecipe:  AlgorithmRecipe
  recipeInMealPlan: boolean
}

export default function RecipeStatsCard({
  algorithmRecipe,
  recipeInMealPlan
}: Props) {
  const { user }: { user: User } = useContext(UserContext)

  return (
    <RecipeInfoCard>
      <div
        className = 'recipe-stats-card-container'
      >
        <div className = 'recipe-stats-grid'>
          <div
            className = 'recipe-stat'
          >
            <TotalPeopleIcon />
            
            <P14
              text   = {`£${algorithmRecipe.pricePerServing.toFixed(2)} Serving`}
              colour = 'grey-1'
            />
          </div>

          <div
            className = 'recipe-stat'
          >
            <TimeIcon />

            <P14
              text   = {`Prep in ${ algorithmRecipe.recipe.preparationTime } mins`}
              colour = 'grey-1'
            />
          </div>

          <div
            className = 'recipe-stat'
          >
            <PricePerServingIcon />

            <P14
              text   = { `${ user.household.numberOfPeople } Servings` }
              colour = 'grey-1'
            />
          </div>

          <div
            className = 'recipe-stat'
          >
            <FlameIcon />

            <P14
              text   = {`Cook in ${ algorithmRecipe.recipe.cookingTime } mins`}
              colour = 'grey-1'
            />
          </div>

          <div
            className = 'recipe-stat'
          >
            <CookingIcon />

            <P14
              text   = {`${ algorithmRecipe.recipe.difficulty } Cooking Level`}
              colour = 'grey-1'
            />
          </div>

          <div
            className = 'recipe-stat'
          >
            <EnergyIcon />

            <P14
              text   = {`${algorithmRecipe.recipe.energyCost} Energy Cost`}
              colour = 'grey-1'
            />
          </div>
        </div>

        <div
          className = 'recipe-tags-card-container'
        >
          <P14
            text = 'Recipe Status'
          />

          <div
            className = {`
              recipe-tags-card
              ${recipeInMealPlan ? 'added' : ''}
            `}
          >
            <P14
              text   = { recipeInMealPlan ? 'Added to Your Meals' : 'Not in Your Meals' }
              colour = 'white-1'
            />

            {
              recipeInMealPlan && <TickIcon />
            }
          </div>
        </div>
      </div>
      
    </RecipeInfoCard>
  )
}