import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';
import useRemoveAlgorithmRecipe from 'utils/Hooks/Actions/Algorithm/MealPlan/useRemoveAlgorithmRecipe';

export default function useHandleRemoveRecipe(
  setOtherRecipes: (otherRecipes: AlgorithmRecipe[]) => void
) {
  const removeAlgorithmRecipe = useRemoveAlgorithmRecipe();

  const handleRemoveRecipe = (algorithmRecipeId: number, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    // TODO: This does not update otherRecipes
    removeAlgorithmRecipe(algorithmRecipeId, setOtherRecipes, e)
  }

  return handleRemoveRecipe;
}
