// Constants which should not be localised.

const S3_BUCKET = 'https://mealia-development-images.s3.eu-west-1.amazonaws.com'

export const KeyConstants = {
  LocalStorage: {
    authToken: 'authToken'
  },
  SessionStorage: {
    scrollPosition:  'scrollPosition',
    shouldScroll:    'shouldScroll',
    addedToMealPlan: 'addedToMealPlan',
    navigatedFrom:   'navigatedFrom'
  },
  Images: {
    Fallback: {
      item: `${S3_BUCKET}/placeholder.svg`,
      ingredient: `${S3_BUCKET}/placeholder-ingredient.svg`
    }
  },
  BetaVersion: '0.2'
}
