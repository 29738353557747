import React from 'react'
import { useGoogleLogin } from '@react-oauth/google';

import './GoogleButton.scss'
import { ReactComponent as GoogleIcon } from 'assets/images/icons/google-button/google-icon.svg';
import P14 from 'components/Text/Paragraphs/SemiBold/P14';

export enum GoogleContext {
  SignIn,
  SignUp
}

type Props = {
  context:   GoogleContext
  size?:     'small' | 'medium'
  onSuccess: (response: any, ...props: any) => void
  onError:   (response: any) => void
}

export default function GoogleButton({
  context,
  size = 'medium',
  onSuccess,
  onError
}: Props) {

  const handler = useGoogleLogin({
    onSuccess: onSuccess,
    onError:   onError,
    flow:      'auth-code'
  })

  return (
    <button
      className = {`
        google-button
        ${ size === 'small' ? 'small' : '' }
      `}
      onClick   = { handler }
    >
      <GoogleIcon />

      <P14
        text = { context === GoogleContext.SignIn ? 'Log in with Google' : 'Sign up with Google' }
        colour = 'white-1'
      />
    </button>
  )
}
