import UserSessionApi from 'services/Core/User/SessionService/UserSessionApi';

export default function useSignOut() {
  function signOut(): Promise<any> {
    const userSessionApi = new UserSessionApi();

    return userSessionApi.signOut();
  }

  return signOut;
}
