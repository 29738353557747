import { RecipeModalType } from 'enums/Modals/RecipeModalType';

export default function useHandleRecipeImageClick(
  openModal: (modalType: RecipeModalType) => void
) {
  const handleRecipeImageClick = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.preventDefault();

    openModal(RecipeModalType.Image);
  }

  return handleRecipeImageClick;
}
