import { useContext } from 'react';

import UserContext from 'context/UserContext';
import useGoogleRegisterApi from 'utils/Hooks/Api/User/useGoogleRegister';
import User from 'models/User';

export default function useGoogleRegister() {
  const { setUser }: { setUser: (user: User) => void } = useContext(UserContext);

  const googleRegisterApi = useGoogleRegisterApi();

  function googleRegister(code: string): Promise<any> {
    return googleRegisterApi(code).then((response) => {
      const user: User = User.fromApiResponse(response.data);

      setUser(user);

      return response;
    });
  }

  return googleRegister;
}