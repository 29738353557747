import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import HouseholdApi from 'services/Core/HouseholdService/HouseholdApi';

export default function useCreateHousehold() {
  function createHousehold(
    numberOfAdults:       number,
    numberOfChildren:     number,
    numberOfMealsPerWeek: number,
    weeklyBudget:         number,
    allergies:            string[],
    dietaryPreferences:   string[],
    kitchenEquipment:     string[],
    chosenSupermarket:    SupportedSupermarket
  ): Promise<any> {
    const householdApi = new HouseholdApi();

    return householdApi.createHousehold(
      numberOfAdults,
      numberOfChildren,
      numberOfMealsPerWeek,
      weeklyBudget,
      allergies,
      dietaryPreferences,
      kitchenEquipment,
      chosenSupermarket
    );
  }

  return createHousehold;
}
