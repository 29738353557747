import React from 'react'

import './P12.scss'

type Props = {
  text: string,
  colour?: 'white-1' | 'black-1' | 'grey-1' | 'pink-1'
}

export default function P12({
  text,
  colour = 'white-1'
}: Props) {
  return (
    <p 
      className = {`
        p12-semi-bold
        ${colour}
      `}
    >
      { text }
    </p>
  )
}