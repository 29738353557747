import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe';

export default function useRecipeInMealPlan(
  algorithmMealPlan: AlgorithmMealPlan,
  algorithmRecipe:   AlgorithmRecipe | null
) {
  const recipeInMealPlan = () => {
    if (!algorithmRecipe) {
      return false;
    }

    return algorithmMealPlan.isRecipeInMealPlan(algorithmRecipe.id);
  }

  return recipeInMealPlan();
}