import { useContext } from 'react';

import UserContext from 'context/UserContext';
import useFilterApi from 'utils/Hooks/Api/Algorithm/MealPlan/useFilter';
import User from 'models/User';
import { EnergyCost } from 'enums/EnergyCost';
import { CategoryType } from 'enums/CategoryType';

export default function useFilter() {
  const filterApi = useFilterApi();

  const { user, setUser }: { user: User, setUser: (user: User) => void } = useContext(UserContext);

  function filter(
    numberOfAdults:     number,
    numberOfChildren:   number,
    allergies:          string[],
    dietaryPreferences: string[],
    kitchenEquipment:   string[],
    energyCost:         EnergyCost | null,
    categories:         CategoryType[]
  ): Promise<any> {
    return filterApi(
      numberOfAdults,
      numberOfChildren,
      allergies,
      dietaryPreferences,
      kitchenEquipment,
      energyCost,
      categories
    ).then((response) => {
      const newUser = new User({
        ...user,
        household: {
          ...user.household,
          numberOfAdults,
          numberOfChildren,
          allergies,
          dietaryPreferences,
          kitchenEquipment,
          energyCost,
          categories
        }
      });

      setUser(newUser)

      return response;
    })
  }

  return filter;
}
