import React from 'react';

import './Register.scss';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import GoogleButton, { GoogleContext } from 'components/Buttons/GoogleButton';
import TertiaryButton from 'components/Buttons/TertiaryButton';
import Textfield from 'components/Inputs/Textfield';
import Divider from 'components/base/Divider';
import { LocalConstants } from 'utils/Constants';
import Form from 'pages/PreLogin/Shared/Form';
import P14 from 'components/Text/Paragraphs/Regular/P14';
import P14M from 'components/Text/Paragraphs/Medium/P14';
import PreLoginCard from 'components/Cards/PreLoginCard';
import ErrorModal from 'components/Modals/ErrorModal';
import { ReactComponent as PreLoginMobileIllustration } from 'assets/images/illustrations/pre-login.svg'

import useRegister from 'utils/Hooks/Pages/PreLogin/useRegister';

import H1Text from 'components/Text/Headings/H1Text';
import BetaPill from 'components/Pills/BetaPill';

export default function Register() {
  const [
    page,
    firstName,
    setFirstName,
    email,
    setEmail,
    password,
    setPassword,
    register,
    reconfirm,
    contactUs,
    googleRegisterSuccess,
    navigateToLogIn,
    error,
    closeModal,
    title,
    subtitle,
    isSSOUser
  ] = useRegister();

  return (
    <Form
      modal = {
        <ErrorModal
          close  = { closeModal }
          error  = { error }
          email  = { email }
        />
      }
      preloginResponsive = {
        <div
          className = 'pre-login-register-container'
        >
          <PreLoginMobileIllustration />

          <div
            className = 'pre-login-register-top-container'
          >
            <H1Text
              text = { page === 'Input' ? 'Create an account' : isSSOUser ? `You're in!` : 'Check Your Email' }
            />

            <BetaPill />
          </div>

          <div
            style = { { marginTop: '-15px', width: '100%' } }
          >
            <P14
              text = { page === 'Input' ? 'It takes a few seconds.' : isSSOUser ? 'All good, thank you for signing up.' : `We sent a verification link to ${email}` }
            />
          </div>

          {
            page === 'Input' && <>
              <GoogleButton
                context   = { GoogleContext.SignUp }
                size      = 'small'
                onError   = { (response) => { console.log(response) } }
                onSuccess = { googleRegisterSuccess }
              />

              <Divider />

              <div
                className = 'pre-login-register-textfield-container'
              >
                <Textfield
                  placeholder = { LocalConstants.PreLogin.Register.Input.FirstNamePlaceholder }
                  size        = 'fill'
                  value       = { firstName }
                  setValue    = { setFirstName }
                />

                <Textfield
                  placeholder = { LocalConstants.PreLogin.Register.Input.EmailPlaceholder }
                  size        = 'fill'
                  value       = { email }
                  setValue    = { setEmail }
                />

                <Textfield
                  type        = 'password'
                  placeholder = { LocalConstants.PreLogin.Register.Input.PasswordPlaceholder }
                  size        = 'fill'
                  value       = { password }
                  setValue    = { setPassword }
                />
              </div>

              <PrimaryButton
                text           = { LocalConstants.PreLogin.Register.Input.PrimaryButtonCopy }
                size           = 'fill'
                onClickHandler = { register }
              />
            </>
          }

          <P14M
            text   = { isSSOUser ? 'Mealia is optimised for desktop use at the moment. Sign up now, and log in from your desktop. We’ll also notify you as soon as our mobile version launches!' : `Mealia is optimised for desktop use at the moment. Sign up now, and we'll send you a direct link for desktop access, as well as notify you as soon as our mobile version launches!` }
            colour = 'grey-1'
          />
        </div>
      }
    >
      <PreLoginCard
        title        = { title() }
        subtitle     = { subtitle() }
        showBetaPill = { page === 'Input' }
      >
        {
          page === 'Input' &&
          <>
            <GoogleButton
              context   = { GoogleContext.SignUp }
              onError   = { (response) => { console.log(response) } }
              onSuccess = { googleRegisterSuccess }
            />

            <Divider />

            <div
              className = 'pre-login-textfield-container'
            >
              <Textfield
                placeholder = { LocalConstants.PreLogin.Register.Input.FirstNamePlaceholder }
                value       = { firstName }
                setValue    = { setFirstName }
              />

              <Textfield
                placeholder = { LocalConstants.PreLogin.Register.Input.EmailPlaceholder }
                value       = { email }
                setValue    = { setEmail }
              />

              <Textfield
                type        = 'password'
                placeholder = { LocalConstants.PreLogin.Register.Input.PasswordPlaceholder }
                value       = { password }
                setValue    = { setPassword }
              />
            </div>

            <PrimaryButton
              text           = { LocalConstants.PreLogin.Register.Input.PrimaryButtonCopy }
              size           = 'fill'
              onClickHandler = { register }
            />

            <div
              className = 'register-tertiary-button-container'
            >
              <P14
                text   = { LocalConstants.PreLogin.Register.Input.OtherCopy }
                colour = 'pink-1'
              />
              
              <TertiaryButton
                text           = { LocalConstants.PreLogin.Register.Input.TertiaryButtonCopy }
                onClickHandler = { navigateToLogIn }
              />
            </div>
          </>
        }
        { 
          page === 'Confirmation' &&
          <>
            <PrimaryButton
              text           = { LocalConstants.PreLogin.Register.Confirmation.PrimaryButtonCopy }
              size           = 'fill'
              onClickHandler = { navigateToLogIn }
            />

            <div
              className = 'register-tertiary-button-container'
            >
              <P14
                text   = { LocalConstants.PreLogin.Register.Confirmation.OtherCopy }
                colour = 'pink-1'
              />

              <TertiaryButton
                text           = { LocalConstants.PreLogin.Register.Confirmation.TertiaryButtonCopy }
                onClickHandler = { reconfirm }
              />
            </div>
          </>
        }
        {
          page === 'Reconfirm' &&
          <>
            <PrimaryButton
              text           = { 'Back to Log In' }
              size           = 'fill'
              onClickHandler = { navigateToLogIn }
            />

            <div
              className = 'register-tertiary-button-container'
            >
              <P14
                text   = { LocalConstants.PreLogin.Register.Confirmation.OtherCopy }
                colour = 'pink-1'
              />

              <TertiaryButton
                text           = { 'Contact Us' }
                onClickHandler = { contactUs }
              />
            </div>
          </>
        }
      </PreLoginCard>
    </Form>
  )
};
