import React from 'react'

import RootGrid from 'components/Grids/_RootGrid'
import SkeletonAlgorithmRecipeCard from 'components/Cards/AlgorithmRecipeCard/SkeletonAlgorithmRecipeCard'

type Props = {
  numberOfCards: number
}

export default function AlgorithmRecipeSkeletonGrid({
  numberOfCards
}: Props) {
  return (
    <RootGrid
      numberOfColumns = { 2 }
    >
      {
        Array(numberOfCards).fill(0).map((_, index) => (
          <li
            key = { index }
          >
            <SkeletonAlgorithmRecipeCard />
          </li>
        ))
      }
    </RootGrid>
  )
}
