import { useContext } from 'react';

import UserContext from 'context/UserContext';
import User from 'models/User';
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan';
import AlgorithmMealPlanContext from 'context/AlgorithmMealPlanContext';

export default function useContexts() {
  const { user }: { user: User } = useContext(UserContext);
  const { algorithmMealPlan }: { algorithmMealPlan: AlgorithmMealPlan } = useContext(AlgorithmMealPlanContext)

  return {
    user,
    algorithmMealPlan
  };
}
