import React from 'react'

import './P16.scss'

type Props = {
  text: string
  colour?: 'black-1' | 'white-1' | 'red-1' | 'pink-1' | 'grey-1' | 'pink-2'
}

export default function P16({
  text,
  colour = 'black-1'
}: Props) {
  return (
    <p 
      className = {`
        p16-medium
        ${colour}
      `}
    >
      { text }
    </p>
  )
}