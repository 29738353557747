import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreErrorEndpoints: BaseApiEndpoint = {
  report: (
    message:   string,
    backtrace: string,
  ) => ({
    url:    'errors/report',
    method: 'POST',
    auth:   true,
    body: {
      message:   message,
      backtrace: backtrace
    }
  })
}
