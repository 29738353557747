import { useEffect, useState } from 'react';

import useGetAllSavedPlansApi from 'utils/Hooks/Api/Algorithm/SavedPlan/useGetAllSavedPlans';
import AlgorithmSavedPlan from 'models/Algorithm/AlgorithmSavedPlan';

export default function useGetAllSavedPlans() {
  const getAllSavedPlansApi = useGetAllSavedPlansApi();
  const [algorithmSavedPlans, setAlgorithmSavedPlans] = useState<AlgorithmSavedPlan[]>([]);

  function getAllSavedPlans() {
    getAllSavedPlansApi().then((response) => {
      const data: AlgorithmSavedPlan[] = response.data.map(savedPlan => new AlgorithmSavedPlan(savedPlan));

      setAlgorithmSavedPlans(data);
    });
  }

  useEffect(() => {
    getAllSavedPlans();
  }, []);

  return algorithmSavedPlans;
}
