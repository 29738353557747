import AlgorithmBasketApi from 'services/Core/Algorithm/BasketService/AlgorithmBasketApi';

export default function useAddCupboardItem() {
  function addCupboardItem(itemId: number): Promise<any> {
    const algorithmBasketApi = new AlgorithmBasketApi();

    return algorithmBasketApi.addCupboardItem(itemId);
  }

  return addCupboardItem;
}
