import React from 'react';

import './Confirmation.scss';
import Form from 'pages/PreLogin/Shared/Form';
import PreLoginCard from 'components/Cards/PreLoginCard';
import useConfirmation from 'utils/Hooks/Pages/PreLogin/useConfirmation';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { ErrorType } from 'utils/Errors';
import H1Text from 'components/Text/Headings/H1Text';
import P14 from 'components/Text/Paragraphs/Regular/P14';

export default function Confirmation() {
  const [
    error,
    title,
    subtitle,
    navigateToLogIn
  ] = useConfirmation();

  return (
    <Form
      preloginResponsive = { 
        <div
          className = 'confirmation-prelogin-responsive'
        >
          <div
            className = 'confirmation-prelogin-responsive-text-container'
          >
            <H1Text
              text = { title() }
            />

            <P14
              text = { subtitle() }
            />
          </div>
        </div>
      }
    >
      <PreLoginCard
        title    = { title() }
        subtitle = { subtitle() }
      >
        {
          error === ErrorType.AlreadyConfirmed && <PrimaryButton
            text           = { 'Back to Log In' }
            size           = 'fill'
            onClickHandler = { navigateToLogIn }
          />
        }
      </PreLoginCard>
    </Form>
  )
}
