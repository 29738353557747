import React, { useContext } from 'react'

import './CheckedOut.scss'
import Navigation from '../Shared/Navigation'
import CheckedOutDashboardCard from 'components/Cards/Dashboard/CheckedOutDashboardCard'
import TitleText from 'components/Text/TitleText'
import PersonalPlan from 'components/Cards/PersonalPlan'
import UserContext from 'context/UserContext'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import { RouteName } from 'router/Config'
import useNavigateTo from 'hooks/navigation/useNavigateTo'
import useContinueMealPlan from 'utils/Hooks/Actions/Algorithm/MealPlan/useContinueMealPlan'
import LoadingContext from 'context/LoadingContext'
import AlgorithmBasketContext from 'context/AlgorithmBasketContext'
import User from 'models/User'
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket'
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan'
import AlgorithmMealPlanContext from 'context/AlgorithmMealPlanContext'

export default function CheckedOut() {
  const { algorithmMealPlan }: { algorithmMealPlan: AlgorithmMealPlan } = useContext(AlgorithmMealPlanContext)
  const { algorithmBasket   }: { algorithmBasket: AlgorithmBasket } = useContext(AlgorithmBasketContext);
  const { user              }: { user: User }                       = useContext(UserContext);
  const { setLoading, setCompleted }: { setLoading: (loading: boolean) => void, setCompleted: (completed: boolean) => void } = useContext(LoadingContext);

  const navigateTo = useNavigateTo();
  const continueMealPlan = useContinueMealPlan();

  const createNewPlan = () => {
    navigateTo(RouteName.GenerateNewMeals)
  }

  const continuePlan = () => {
    setLoading(true);

    const successCallback = () => {
      setCompleted(true);

      // TODO: Right now we refresh the page, but we want to navigate to the MyMeals page once the animation is complete
    }

    continueMealPlan(true, successCallback);
  }

  return (
    <Navigation>
      <div
        className = 'checked-out-container'
      >
        <CheckedOutDashboardCard />

        <span
          style = {{ height: '8px' }}
        />

        <TitleText
          text = 'My Meals'
        >
          <div
            className = 'checked-out-buttons-container'
          >
            {/* Broken on backend so remove for now */}
            {/* <SecondaryButton
              text    = 'Continue this Basket'
              onClick = { continuePlan }
            /> */}

            <SecondaryButton
              text    = 'Create new Basket'
              onClick = { createNewPlan }
            />
          </div>
        </TitleText>

        <span />

        <PersonalPlan
          totalCost              = { algorithmBasket?.totalValue }
          pricePerServing        = { algorithmBasket?.pricePerServing }
          numberOfMeals          = { algorithmBasket?.numberOfRecipes }
          numberOfPeople         = { user?.household.numberOfPeople }
          numberOfIngredients    = { algorithmBasket?.numberOfItems }
          algorithmRecipeMapping = { algorithmMealPlan?.algorithmRecipeMapping }
        />
      </div>
    </Navigation>
  )
}
