import React from 'react'

import RootAlgorithmItemCard from '../_RootAlgorithmItemCard'

import P14 from 'components/Text/Paragraphs/Medium/P14'
import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'

type Props = {
  algorithmItem: IAlgorithmItem
  onCardClick:   (algorithmItem: IAlgorithmItem) => void
}

export default function SavedItemAlgorithmItemCard({
  algorithmItem,
  onCardClick
}: Props) {
  return (
    <div
      className = 'replaceable-algorithm-item-card'
    >
      <div
        className = 'tagline'
      >
        <P14
          text   = { algorithmItem.recipeIngredient.name }
          colour = 'grey-1'
        />
      </div>

      <RootAlgorithmItemCard
        algorithmItem  = { algorithmItem }
        price          = { algorithmItem.item.price }
        onCardClick    = { onCardClick }
      />
    </div>
  )
}
