import React from 'react'
import { Direction, Range } from 'react-range';

import SliderThumb from '../SliderThumb';
import SliderTrack from '../SliderTrack';

type Props = {
  type:        'money' | 'people'
  orientation: 'vertical' | 'horizontal'
  values:      number[]
  setValues:   (values: number[]) => void
  min:         number
  max:         number
  onDragEnd:   (values: number[]) => void
}

export default function SliderRange({
  type,
  orientation,
  values,
  setValues,
  min,
  max,
  onDragEnd
}: Props) {
  return (
    <Range
      direction     = { orientation === 'horizontal' ?  Direction.Right : Direction.Down }
      values        = { values }
      step          = { 1 }
      min           = { min }
      max           = { max }
      onChange      = { (values) => setValues(values) }
      onFinalChange = { (values) => onDragEnd(values) }
      renderTrack   = {({ props, children }) => (
        <SliderTrack
          orientation = { orientation }
          children    = { children }
          props       = { props }
          values      = { values }
          min         = { min }
          max         = { max }
        />
      )}
      renderThumb={({ props, index }) => (
        <SliderThumb
          type        = { type }
          key         = { index }
          orientation = { orientation }
          value       = { values[0] }
          props       = { props }
        />
      )}
    />
  )
}
