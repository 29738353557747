import React from 'react'

import './ChatbotModal.scss'
import IMessage from 'interfaces/backend/IMessage'
import Root from '../Root'
import RootModal from 'components/Modals/_RootModal'
import { BotPage } from 'enums/BotPage'

type Props = {
  type:        BotPage
  isOpen:      boolean
  close:       () => void
  messages:    IMessage[]
  onSendClick: (content: string, setValue: (value: string) => void) => void
}

export default function ChatbotModal({
  type,
  isOpen,
  close,
  messages,
  onSendClick
}: Props) {
  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'small'
    >
      <div
        className = 'chatbot-modal-container'
      >
        <Root
          type        = { type }
          messages    = { messages }
          onSendClick = { onSendClick }
        />
      </div>
    </RootModal>
    
  )
}
