import React from 'react'

import './BrowserExtensionCard.scss'
import P14 from 'components/Text/Paragraphs/SemiBold/P14'
import P14R from 'components/Text/Paragraphs/Regular/P14'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { ReactComponent as ChromeIcon } from 'assets/images/icon/browsers/chrome.svg'
import { ReactComponent as FirefoxIcon } from 'assets/images/icon/browsers/firefox.svg'
import { ReactComponent as EdgeIcon } from 'assets/images/icon/browsers/edge.svg'
import useGetBrowser from 'hooks/browser/useGetBrowser';
import { SupportedBrowser } from 'enums/SupportedBrowser'

type Props = {
  supportedBrowser?: SupportedBrowser
}

export default function BrowserExtensionCard({
  supportedBrowser
}: Props) {
  const currentBrowser = useGetBrowser()

  const capitalize = (str: string) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  const capitalizedBrowser = () => {
    return capitalize(supportedBrowser || currentBrowser)
  }

  const downloadExtension = () => {
    switch (supportedBrowser || currentBrowser) {
      case SupportedBrowser.Chrome:
        window.open(`https://chrome.google.com/webstore/detail/mealia/${process.env.REACT_APP_CHROME_EXTENSION_ID}`)
        break
      case SupportedBrowser.Firefox:
        window.open('https://addons.mozilla.org/en-GB/firefox/addon/mealia/')
        break
      case SupportedBrowser.Edge:
        window.open(`https://microsoftedge.microsoft.com/addons/detail/mealia/${process.env.REACT_APP_EDGE_EXTENSION_ID}`)
        break
    }
  }

  const fullBrowserName = () => {
    switch (supportedBrowser || currentBrowser) {
      case SupportedBrowser.Chrome:
        return 'Google Chrome'
      case SupportedBrowser.Firefox:
        return 'Mozilla Firefox'
      case SupportedBrowser.Edge:
        return 'Microsoft Edge'
    }
  }
  
  const browserIcon = () => {
    switch (supportedBrowser || currentBrowser) {
      case SupportedBrowser.Chrome:
        return <ChromeIcon />
      case SupportedBrowser.Firefox:
        return <FirefoxIcon />
      case SupportedBrowser.Edge:
        return <EdgeIcon />
    }
  }
  return (
    <div
      className = 'browser-extension-card-container'
    >
      <div
        className = 'browser-extension-card-lhs'
      >
        { browserIcon() }

        <div
          className = 'browser-extension-card-lhs-title'
        >
          <P14
            text   = { `Mealia for ${capitalizedBrowser()}` }
            colour = 'black-1'
          />

          <P14R
            text   = { `Mealia browser extension for ${fullBrowserName()}.` }
            colour = 'black-1'
          />
        </div>
      </div>

      <div
        className = 'browser-extension-card-rhs'
      >
        <PrimaryButton
          text           = 'Download the Extension'
          onClickHandler = { downloadExtension }
        />
      </div>
    </div>
  )
}
