import { DashboardTextType } from 'types/DashboardTextType'

export default function useNumberOfPeopletMessages() {
  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Let's start with the number of servings per meal.`,
    bold:  false
  }

  const bottomLineTextObject: DashboardTextType = {
    line:  2,
    value: 'How many people will you be cooking for?',
    bold:  false
  }

  const numberOfPeopletMessages = [
    topLineTextObject,
    bottomLineTextObject
  ]

  return numberOfPeopletMessages
}
