import React from 'react'

import './PersonalPlanSavedOutline.scss'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import { RecipeData } from 'interfaces/backend/Algorithm/IAlgorithmSavedPlan'
import AlgorithmSavedPlan from 'models/Algorithm/AlgorithmSavedPlan'

type Props = {
  algorithmSavedPlans: AlgorithmSavedPlan[]
  handleClick:         (algorithmSavedPlan: AlgorithmSavedPlan) => void
}

export default function PersonalPlanSavedOutline({
  algorithmSavedPlans,
  handleClick
}: Props) {
  return (
    <div
      className = 'personal-plan-saved-outline-container'
    >
      <div
        className = 'personal-plan-saved-outline'
      >
        {
          algorithmSavedPlans.map((algorithmSavedPlan, index) => {
            return (
              <button
                key       = { index }
                className = 'personal-plan-saved-outline-recipe'
                onClick   = { () => handleClick(algorithmSavedPlan) }
              >
                <div
                  className = 'personal-plan-saved-outline-lhs'
                >
                  <ul
                    className = 'personal-plan-saved-outline-image-list'
                  >
                    {
                      algorithmSavedPlan.recipeData.slice(0, 3).map((recipeData: RecipeData, index: number) => {
                        return (
                          <img
                            key       = { index }
                            src       = { recipeData.imageUrl }
                            alt       = { 'Saved Plan Recipe' }
                            className = 'personal-plan-recipe-list-card-recipe-image'
                          />
                        )
                      })
                    }
                  </ul>

                  <P16
                    text   = { `Plan Added on ${algorithmSavedPlan.prettyCreatedAt}` }
                    colour = 'black-1'
                  />
                </div>

                <div
                  className = 'personal-plan-saved-outline-rhs'
                >
                  <div
                    className = 'personal-plan-saved-outline-rhs-pill'
                  >
                    <P14
                      text   = { `£${algorithmSavedPlan.totalCost.toFixed(2)} Total Cost` }
                      colour = 'white-1'
                    />
                  </div>

                  <div
                    className = 'personal-plan-saved-outline-rhs-pill'
                  >
                    <P14
                      text   = { `£${algorithmSavedPlan.pricePerServing.toFixed(2)} Per Serving` }
                      colour = 'white-1'
                    />
                  </div>

                  <div
                    className = 'personal-plan-saved-outline-rhs-pill personal-plan-saved-outline-rhs-pill-pink'
                  >
                    <P14
                      text   = { `${algorithmSavedPlan.recipeData.length} Meals` }
                      colour = 'white-1'
                    />
                  </div>
                </div>
              </button>
            )
          })
        }
      </div>
    </div>
  )
}
