import { RouteName } from 'router/Config';

export default function useHandleCancelClick(
  navigateTo: (routeName: RouteName) => void
) {
  const handleCancelClick = () => {
    navigateTo(RouteName.MyMeals)
  }

  return handleCancelClick
}
