import AlgorithmRecipeApi from 'services/Core/Algorithm/RecipeService/AlgorithmRecipeApi';

export default function useDecrementAdditionalItem() {
  function decrementAdditionalItem(algorithmRecipeId: number, supermarketItemId: number): Promise<any> {
    const algorithmRecipeApi = new AlgorithmRecipeApi();

    return algorithmRecipeApi.decrementAdditionalItem(algorithmRecipeId, supermarketItemId);
  }

  return decrementAdditionalItem;
}
