import { createContext } from 'react';

import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket';

export const AlgorithmBasketContext = createContext({
  algorithmBasket:    null as AlgorithmBasket | null, 
  setAlgorithmBasket: ( algorithmbasket: AlgorithmBasket ) => {}
});

export default AlgorithmBasketContext;
