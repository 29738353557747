import { BotPage } from 'enums/BotPage';
import useCreateApi from 'utils/Hooks/Api/Message/useCreate';

export default function useCreate() {
  const createApi = useCreateApi();

  async function create(name: BotPage, content: string) {
    try {
      const response = await createApi(name, content);

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  return create;
}
