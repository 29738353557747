import React from 'react'

import './SecondaryButton.scss'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import { ReactComponent as PlusIcon } from 'assets/images/icons/_new/plus/black.svg'
import { ReactComponent as NoteIcon } from 'assets/images/icons/_new/note/black.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/icon/left-arrow/root.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icon/search/root.svg'
import { ColourConstants } from 'utils/Constants'

type Props = {
  text:    string
  icon?:   'plus' | 'note' | 'arrow' | 'search'
  onClick: () => void
}

export default function SecondaryButton({
  text,
  icon,
  onClick
}: Props) {
  return (
    <button
      className = 'secondary-button-container'
      onClick   = { onClick }
    >
      {
        icon === 'plus' && <PlusIcon
          color = { ColourConstants.Black[1] }
        />
      }

      {
        icon === 'note' && <NoteIcon
          color = { ColourConstants.Black[1] }
        />
      }

      {
        icon === 'arrow' && <ArrowIcon
          color = { ColourConstants.Black[1] }
        />
      }

      {
        icon === 'search' && <SearchIcon
          color = { ColourConstants.Black[1] }
        />
      }

      <P14
        text   = { text }
        colour = { 'black-1' }
      />
    </button>
  )
}
