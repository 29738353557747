import { BotPage } from 'enums/BotPage';
import { BaseApiEndpoint } from 'services/BaseApiEndpoint';

export const CoreMessageEndpoints: BaseApiEndpoint = {
  show: (name: BotPage) => ({
    url:    `messages/show`,
    method: 'GET',
    auth:   true,
    params: {
      name: name
    }
  }),
  create: (name: BotPage, content: string) => ({
    url:    'messages/create',
    method: 'POST',
    auth:   true,
    body:   {
      name:    name,
      content: content
    }
  }),
}
