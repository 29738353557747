import React from 'react'

import './FullWithCountIncrementorButton.scss'
import { ReactComponent as TrashIcon } from 'assets/images/icons/_new/trash/white.svg'
import { ReactComponent as PlusIcon } from 'assets/images/icons/_new/plus/black.svg'
import { ColourConstants } from 'utils/Constants'
import P16 from 'components/Text/Paragraphs/SemiBold/P16'

type Props = {
  quantity:     number,
  onTrashClick: (e: React.MouseEvent<HTMLDivElement>) => void,
  onAddClick:   (e: React.MouseEvent<HTMLDivElement>) => void
}

export default function FullWithCountIncrementorButton({
  quantity,
  onTrashClick,
  onAddClick
}: Props) {
  return (
    <div
      className = 'full-with-count-incrementor-button-container'
    >
      <div
        onClick = { (e) => onTrashClick(e) }
        className = 'icon-container'
      >
        <TrashIcon
          color     = { ColourConstants.White[1] }
          className = 'trash-icon'
        />
      </div>

      <P16
        text   = { String(quantity) }
        colour = 'white-1'
      />
      
      <div
        onClick = { (e) => onAddClick(e) }
        className = 'icon-container'
      >
        <PlusIcon
          color     = { ColourConstants.White[1] }
          className = 'add-icon'
        />
      </div>
    </div>
  )
}
