import React, { useEffect, useRef } from 'react'

import './Notification.scss'

import P14 from 'components/Text/Paragraphs/SemiBold/P14'
import { ReactComponent as LeftArrow } from 'assets/images/icon/left-arrow/root.svg'
import { ColourConstants } from 'utils/Constants'

type Props = {
  showNotification:     boolean
  numberOfOtherRecipes: number
  onClick:              () => void
}

export default function Notification({
  showNotification,
  numberOfOtherRecipes,
  onClick
}: Props) {
  const notificationRef = useRef(null);

  useEffect(() => {
    if (notificationRef.current) {
      if (showNotification) {
        notificationRef.current.style.bottom = '60px';
      } else {
        notificationRef.current.style.bottom = '-90px';
      }
    }
  }, [showNotification]);

  return (
    <button
      className = { 'notification-container' }
      ref       = { notificationRef }
      onClick   = { onClick }
    >
      <P14
        text   = { `Browse ${numberOfOtherRecipes} Other Recipes` }
        colour = 'white-1'
      />

      <LeftArrow
        color     = { ColourConstants.White[1] }
        transform = 'rotate(-90)'
      />
    </button>
  )
}
