import React from 'react'

import './CupboardItemAlgorithmItemCard.scss'
import RootAlgorithmItemCard from '../_RootAlgorithmItemCard'

import IAlgorithmItem from 'interfaces/backend/Algorithm/IAlgorithmItem'
import P14 from 'components/Text/Paragraphs/Medium/P14'
import AddToBasketButton from 'components/Buttons/AddToBasketButton'
import GreyButton from 'components/Buttons/GreyButton'

type Props = {
  algorithmItem:       IAlgorithmItem
  onCardClick:         (algorithmItem: IAlgorithmItem) => void
  onSubstitutionClick: (algorithmItem: IAlgorithmItem, e: React.MouseEvent<HTMLDivElement>) => void
  onAddToBasketClick:  (algorithmItem: IAlgorithmItem, e: React.MouseEvent<HTMLDivElement>) => void
}

export default function CupboardItemAlgorithmItemCard({
  algorithmItem,
  onCardClick,
  onSubstitutionClick,
  onAddToBasketClick
}: Props) {
  return (
    <div
      className = 'cupboard-item-algorithm-item-card'
    >
      <div
        className = 'tagline'
      >
        <P14
          text   = { algorithmItem.recipeIngredient.name }
          colour = 'grey-1'
        />
      </div>
    
      <RootAlgorithmItemCard
        algorithmItem  = { algorithmItem }
        price          = { algorithmItem.totalPrice }
        onCardClick    = { onCardClick }
        buttonChildren = {
          <div
            className = 'replaceable-algorithm-item-card-buttons'
          >
            <GreyButton
              text           = 'Swap'
              onClickHandler = { (e) => onSubstitutionClick(algorithmItem, e) }
            />

            <AddToBasketButton
              onClick = { (e) => onAddToBasketClick(algorithmItem, e) }
              size    = 'medium'
            />
          </div>
        }
      />
    </div>
  )
}
