import React from 'react'

import './RedButton.scss'
import P14 from 'components/Text/Paragraphs/SemiBold/P14';

type Props = {
  text:           string,
  onClickHandler: () => void
};

export default function RedButton({
  text,
  onClickHandler
}: Props) {
  return (
    <button 
      className = 'red-button'
      onClick   = { onClickHandler }
    >
      <P14
        text = { text }
      />
    </button>
  )
}
