import { createContext } from 'react';

export const LoadingContext = createContext({
  loading:      false,
  setLoading:   (loading: boolean) => {},
  completed:    false,
  setCompleted: (completed: boolean) => {}
});

export default LoadingContext;
