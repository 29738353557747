import React from 'react'

import MealiaBotCard from 'components/Cards/MealiaBotCard'
import { DashboardTextType } from 'types/DashboardTextType'

type Props = {
  createdAt:     string
  numberOfMeals: number
}

export default function SavedPlanDashboardCard({
  createdAt,
  numberOfMeals
}: Props) {
  const generateTopTextObjects = (): DashboardTextType[] => {
    return [
      {
        line: 1,
        value: 'Your order from the ',
        bold: false
      },
      {
        line: 1,
        value: `${createdAt}.`,
        bold: true
      }
    ];
  }

  const generateBottomTextObjects = (): DashboardTextType[] => {
    return [
      {
        line: 2,
        value: `You had`,
        bold: false
      },
      {
        line: 2,
        value: `${numberOfMeals}`,
        bold: true
      },
      {
        line: 2,
        value: `meals in this order.`,
        bold: false
      }
    ]
  }
  
  return (
    <MealiaBotCard
      textObjects = { [ ...generateTopTextObjects(), ...generateBottomTextObjects() ] }
    />
  )
}
