import React from 'react'

import './RecipeImagePill.scss'
import P12 from 'components/Text/Paragraphs/SemiBold/P12'

type Props = {
  text: string
}

export default function RecipeImagePill({
  text
}: Props) {
  return (
    <div
      className = 'recipe-image-pill-container'
    >
      <P12
        text = { text }
      />
    </div>
  )
}
