import IAlgorithmMealPlan from 'interfaces/backend/Algorithm/IAlgorithmMealPlan';
import { AlgorithmRecipeMapping } from 'types/Mappings/AlgorithmRecipeMapping';

export default class AlgorithmMealPlan implements IAlgorithmMealPlan {
  id:                     number;
  algorithmRecipeMapping: AlgorithmRecipeMapping[]

  constructor(data: IAlgorithmMealPlan) {
    Object.assign(this, data);
  }

  get imageUrls(): string[] {
    return this.algorithmRecipeMapping.map((mapping) => mapping.algorithmRecipe.recipe.imageUrl) as string[];
  }

  isRecipeInMealPlan(algorithmRecipeId: number): boolean {
    return this.algorithmRecipeMapping.some(mapping => mapping.algorithmRecipe.id === algorithmRecipeId);
  }
}
