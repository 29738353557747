import React, { useState } from 'react';

import UserContext from 'context/UserContext';
import User from 'models/User';

export const UserProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <UserContext.Provider 
      value = { { user, setUser } }
    >
      { props.children }
    </UserContext.Provider>
  );
};
