import React, { useContext } from 'react'

import './WelcomeBack.scss'

import Navigation from '../Shared/Navigation'
import WelcomeBackDashboardCard from 'components/Cards/Dashboard/WelcomeBackDashboardCard'
import TitleText from 'components/Text/TitleText'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import PersonalPlan from 'components/Cards/PersonalPlan'
import UserContext from 'context/UserContext'
import useNavigateTo from 'hooks/navigation/useNavigateTo'
import { RouteName } from 'router/Config'
import { LocalStorageConstants } from 'utils/Constants'
import useContinueMealPlan from 'utils/Hooks/Actions/Algorithm/MealPlan/useContinueMealPlan'
import LoadingContext from 'context/LoadingContext'
import AlgorithmBasketContext from 'context/AlgorithmBasketContext'
import User from 'models/User'
import AlgorithmBasket from 'models/Algorithm/AlgorithmBasket'
import AlgorithmMealPlan from 'models/Algorithm/AlgorithmMealPlan'
import AlgorithmMealPlanContext from 'context/AlgorithmMealPlanContext'

export default function WelcomeBack() {
  const { algorithmMealPlan }: { algorithmMealPlan: AlgorithmMealPlan } = useContext(AlgorithmMealPlanContext)
  const { algorithmBasket }: { algorithmBasket: AlgorithmBasket } = useContext(AlgorithmBasketContext);
  const { user }: { user: User } = useContext(UserContext);
  const { setLoading, setCompleted }: { setLoading: (loading: boolean) => void, setCompleted: (completed: boolean) => void } = useContext(LoadingContext);
  
  const navigateTo = useNavigateTo();
  const continueMealPlan = useContinueMealPlan();

  const continuePlan = () => {
    localStorage.removeItem(LocalStorageConstants.lastVisit);

    setLoading(true);

    const successCallback = () => {
      setCompleted(true);

      // TODO: Right now we refresh the page, but we want to navigate to the MyMeals page once the animation is complete
    }

    continueMealPlan(false, successCallback);
  }

  const createNewPlan = () => {
    navigateTo(RouteName.GenerateNewMeals)
  }

  return (
    <Navigation>
      <div
        className = 'welcome-back-container'
      >
        <WelcomeBackDashboardCard />

        <span
          style = {{ height: '8px' }}
        />

        <TitleText
          text = 'Welcome Back'
        >
          <div
            className = 'welcome-back-buttons-container'
          >
            <SecondaryButton
              text    = { 'Continue this Basket' }
              onClick = { continuePlan }
            />

            <SecondaryButton
              text    = { 'Create new Basket' }
              onClick = { createNewPlan }
            />
          </div>
        </TitleText>

        <span />

        <PersonalPlan
          totalCost              = { algorithmBasket?.totalValue }
          pricePerServing        = { algorithmBasket?.pricePerServing }
          numberOfMeals          = { algorithmBasket?.numberOfRecipes }
          numberOfPeople         = { user?.household.numberOfPeople }
          numberOfIngredients    = { algorithmBasket?.numberOfItems }
          algorithmRecipeMapping = { algorithmMealPlan?.algorithmRecipeMapping || [] }
        />
      </div>
    </Navigation>
  )
}
