import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { DebounceInput } from 'react-debounce-input'

import './SearchBox.scss'
import { ReactComponent as SearchIcon } from 'assets/images/icons/top-menu/search-icon.svg'

type Props = {
  placeholder: string,
  value:       string,
  setValue:    (value: string) => void
  onEnter:     () => void
}

const SearchBox = forwardRef(({
  placeholder,
  value,
  setValue,
  onEnter
}: Props, ref) => {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef(null);

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onEnter()
    }
  }

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    }
  }));


  return (
    <div 
      className = {`
        search-box-container
        ${isFocused ? 'search-box-container-focused' : ''}
      `}
      onFocus   = { handleFocus }
      onBlur    = { handleBlur }
    >
      <div className = 'search-box-input-container'>
        <SearchIcon />

        {/* Currently not debouncing */}
        <DebounceInput
          inputRef    = { inputRef }
          type        = 'text'
          className   = 'search-box-placeholder'
          placeholder = { placeholder }
          value       = { value }
          onChange    = { handleChange }
          onKeyDown   = { handleKeyDown }
        />
      </div>
    </div>
  )
})

export default SearchBox;
