import IHousehold from 'interfaces/backend/IHousehold';

import { SupportedSupermarket } from 'enums/SupportedSupermarket';
import { EnergyCost } from 'enums/EnergyCost';
import { CategoryType } from 'enums/CategoryType';
import { HouseholdConstants } from 'utils/Constants';

export default class Household implements IHousehold {
  id:                   number;
  weeklyBudget:         number;
  numberOfMealsPerWeek: number;
  numberOfAdults:       number;
  numberOfChildren:     number;
  chosenSupermarket:    SupportedSupermarket;
  energyCost:           EnergyCost;
  dietaryPreferences:   string[];
  allergies:            string[];
  kitchenEquipment:     string[];
  categories:           CategoryType[];

  constructor(data: IHousehold) {
    Object.assign(this, data);
  }

  get numberOfPeople(): number {
    return this.numberOfAdults + this.numberOfChildren;
  }

  get isHalal(): boolean {
    return this.dietaryPreferences.includes('Halal');
  }

  get allergiesAnnotation(): string {
    if (this.numberOfAllergies >= 1) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  get dietaryPreferencesAnnotation(): string {
    if (this.numberOfDietaryPreferences >= 1) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  get kitchenEquipmentAnnotation(): string {
    if (this.numberOfKitchenEquipment === HouseholdConstants.KitchenEquipment.length) {
      return 'All';
    } else if (this.numberOfKitchenEquipment === 0) {
      return 'None';
    } else {
      return this.numberOfKitchenEquipment.toString();
    }
  }

  private get numberOfAllergies(): number {
    return this.allergies.length;
  }

  private get numberOfDietaryPreferences(): number {
    return this.dietaryPreferences.length;
  }

  private get numberOfKitchenEquipment(): number {
    return this.kitchenEquipment.length;
  }
}
