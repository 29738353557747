import React from 'react'

import './DisclaimerModal.scss'
import RootModal from '../_RootModal'
import MealiaBotCard from 'components/Cards/MealiaBotCard'
import P14 from 'components/Text/Paragraphs/Regular/P14'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { DisclaimerType, getDisclaimerValue } from 'types/DisclaimerType'
import { DashboardTextType } from 'types/DashboardTextType'
import AlgorithmRecipe from 'models/Algorithm/AlgorithmRecipe'

type Props = {
  isOpen:          boolean
  close:           () => void
  disclaimer:      DisclaimerType
  algorithmRecipe: AlgorithmRecipe
}

export default function DisclaimerModal({
  isOpen,
  close,
  disclaimer,
  algorithmRecipe
}: Props) {
  function actualDisclaimer(): {description: string} {
    const value = getDisclaimerValue(disclaimer);

    let description: string;

    if (typeof value === 'function') {
      description = value(algorithmRecipe.recipe.name, algorithmRecipe.recipe.link);
    } else {
      description = value as string;
    }

    return {
      description: description
    }
  }

  const topLineTextObject: DashboardTextType = {
    line:  1,
    value: `Some information about this recipe.`,
    bold:  false
  }

  return (
    <RootModal
      isOpen  = { isOpen }
      close   = { close }
      padding = 'small'
    >
      <div
        className = 'mealia-bot-modal-container'
      >
        <MealiaBotCard
          textObjects = { [topLineTextObject] }
          closeModal  = { close }
        />

        <div
          className = 'mealia-bot-modal-content'
        >
          <P14
            text   = { actualDisclaimer().description }
            colour = 'black-1'
          />
        </div>

        <PrimaryButton
          text           = { 'Close' }
          onClickHandler = { close }
        />
      </div>
    </RootModal>
  )
}
