import React from 'react'

import './ShoppingItemCardGrid.scss'
import ShoppingItemCard from 'components/Cards/ShoppingItemCard'
import IItem from 'interfaces/backend/IItem'
import RootGrid from '../_RootGrid'

type Props = {
  shoppingItems?:     IItem[]
  onCardClickHandler: (shoppingItem: IItem) => void
}

export default function ShoppingItemCardGrid({
  shoppingItems,
  onCardClickHandler
}: Props) {
  return (
    <RootGrid
      containerClass = 'shopping-item-card-grid'
    >
      {
        shoppingItems && shoppingItems.map((shoppingItem: IItem) => (
          <li
            key = { shoppingItem.supermarketItemId }
          >
            <ShoppingItemCard
              shoppingItem       = { shoppingItem }
              onCardClickHandler = { onCardClickHandler }
            />
          </li>
        ))
      }
    </RootGrid>
  )
}
