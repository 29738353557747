import React, { useEffect, useState } from 'react';

import SkeletonAlgorithmRecipeCard from 'components/Cards/AlgorithmRecipeCard/SkeletonAlgorithmRecipeCard';
import RootGrid from 'components/Grids/_RootGrid';

type Props = {
  images:            string[]
  onAllImagesLoaded: () => void
}

export default function ImageLoadChecker({
  images,
  onAllImagesLoaded
}: Props) {
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);

  useEffect(() => {
    if (images.length === 0) {
      onAllImagesLoaded();
      return;
    }

    const imageElements = [];

    images.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setLoadedImagesCount((prevCount) => prevCount + 1);
      };

      imageElements.push(img);
    });

    return () => {
      imageElements.forEach((img) => {
        img.onload = null;
      });
    };
  }, [images.length]);

  useEffect(() => {
    if (loadedImagesCount === images.length) {
      onAllImagesLoaded();
    }
  }, [loadedImagesCount]);

  return (
    <RootGrid
      numberOfColumns = { 2 }
    >
      {
        Array(images.length).fill(0).map((_, index) => (
          <li
            key = { index }
          >
            <SkeletonAlgorithmRecipeCard />
          </li>
        ))
      }
    </RootGrid>
  );
}
